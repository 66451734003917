import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Job } from 'domain/entities';
import { JobRepository } from 'repositories/job-repository';
import * as Moment from 'moment-timezone';
import { FileRepository } from 'repositories/file-repository';
import { JobType } from 'domain/enums';

@Component({
	selector: 'dg-modal-progress',
	templateUrl: './dg-modal-progress.component.html',
	styleUrls: ['./dg-modal-progress.component.sass']
})
export class DgModalProgressComponent implements OnChanges {
	@Input() modalTitle: string;
	@Input() modalClass: string;
	@Input() modalForm: string;
	@Input() open = true;
	@Input() operationId: number;
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	public job: Job;
	public jobStarted: string;
	public prevPercentDone: number;
	public percentComplete: number;

	constructor(private jobRepository: JobRepository,
		private fileRepository: FileRepository) {
	}

	public onBackgroundClicked(): void {
		this.close.emit();
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.open.currentValue) {
			this.jobInit();
			await this.update();
		}
	}

	private jobInit(): void {
		this.jobStarted = '';
		this.percentComplete = 0;
	}

	private jobStarter(job: Job): NodeJS.Timer {
		return setInterval(() => {
			this.jobStarted = Moment(this.job.dateStarted).from(Moment());
		}, 1000);
	}

	private async update(): Promise<void> {
		const handle = setInterval(async () => {
			if (this.operationId) {
				const job = await this.jobRepository.get(this.operationId);
				this.job = job;
				const jobStarterHandler = this.jobStarter(job);
				this.detectPercent(job);
				if (!this.job.isCompleted) {
					this.prevPercentDone = 0;
				} else {
					if (this.job.jobType === JobType.ExportUsage || this.job.jobType === JobType.ServiceItemsExport) {

						const id = this.job.tag.fileid;
						const filename = this.job.tag.filename;

						try {
							await this.fileRepository.downloadFile(id, `${filename}`);
						} finally {
							this.close.emit();
							clearInterval(jobStarterHandler);
						}

						clearInterval(handle);
					}
				}
			}
		}, 1000);
	}

	private detectPercent(job: Job): void {
		if (job.isCompleted || job.isFailed || job.isSucceded) {
			this.percentComplete = 100;
		} else if (job.percentComplete === null) {
			this.percentComplete = null;
		} else {
			this.percentComplete = job.percentComplete;
		}
	}
}
