import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { CustomerProductKitProduct, KitProduct, ProductKit } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ProductKitRepository extends BaseRepository {
	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getAll$(): Observable<ProductKit[]> {
		const key = 'kit-templates';

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/product-kits`)
			.pipe(
				map(res => {
					const productsKit = res.body.data.map(x => new ProductKit().deserialize(x));

					this.setCacheValue(key, productsKit, null);
					return productsKit;
				}));
	}

	async getAll(destroy$?: Subject<void>): Promise<ProductKit[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ProductKit[]>>(`${this.config.apiUrl}${this.config.apiVersion}/product-kits`);
		return res.body.data.map(x => new ProductKit().deserialize(x));
	}

	async getAllByCustomer(customerId: Guid, destroy$?: Subject<void>): Promise<ProductKit[]> {
		let params = new HttpParams();
		if(!customerId.isEmpty()) {
			params = params.set('customerId', customerId.toString());
		}

		const res = await this.http.promise(destroy$).get<ApiData<ProductKit[]>>(`${this.config.apiUrl}${this.config.apiVersion}/product-kits`, { params });
		return res.body.data.map(x => new ProductKit().deserialize(x));
	}

	getKitById(id: Guid): Observable<ProductKit> {

		const key = 'kit-template';

		if (this.cacheHasKey(key)) {
			return this.getCache<ProductKit[]>(key)
				.pipe(map(kit => kit.find(x => x.id.equals(id))));
		}

		return this.getAll$().pipe(map(kit => kit.find(x => x.id.equals(id))));
	}

	public addTemplate(template: ProductKit): Observable<Guid> {
		const key = 'kit-templates';

		return this.createEntity(`/product-kits`, template)
			.pipe(tap(s => this.addCacheCollectionItem(key, s)), map(s => s.id));
	}

	public updateProductKit(productKit: ProductKit): Observable<void> {
		const key = 'kit-templates';

		const observable =
			this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/product-kits/${productKit.id}`, null, productKit)
				.pipe(map(() => this.updateCacheCollectionItem(key, productKit.id, productKit)));

		return observable;
	}

	public deleteTemplate(id: Guid): Observable<void> {
		const key = 'kit-templates';

		return this.deleteEntity(`/product-kits/${id}`)
			.pipe(tap(() => this.removeFromCacheCollection(key, id)), map(e => {}));
	}

	getKitProducts(kitId: Guid): Observable<KitProduct[]> {
		const key = 'kit-products';

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/product-kit-products/${kitId}`)
			.pipe(
				map(res => {
					const kitProduct = res.body.data.map(x => new KitProduct().deserialize(x));

					this.setCacheValue(key, kitProduct, null);
					return kitProduct;
				}));
	}

	getKitProductById(kitId: Guid, id: Guid): Observable<KitProduct> {
		const key = 'kit-product';

		if (this.cacheHasKey(key)) {
			return this.getCache<KitProduct[]>(key)
				.pipe(map(kit => kit.find(x => x.id.equals(id))));
		}

		return this.getKitProducts(kitId).pipe(map(kit => kit.find(x => x.id.equals(id))));
	}

	public addKitProduct(template: KitProduct, kitId: Guid): Observable<Guid> {
		const key = 'kit-products';

		return this.createEntity(`/product-kit-products/${kitId}/products`, template)
			.pipe(tap(s => this.addCacheCollectionItem(key, s)), map(s => s.id));
	}

	public updateKitProduct(kitProduct: KitProduct, kitId: Guid): Observable<void> {
		const key = 'kit-products';

		const observable =
			this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/product-kit-products/${kitId}/products/${kitProduct.id}`, null, kitProduct)
				.pipe(map(() => this.updateCacheCollectionItem(key, kitProduct.id, kitProduct)));

		return observable;
	}

	public deleteKitProduct(kitId: Guid, id: Guid): Observable<void> {
		const key = 'kit-products';

		return this.deleteEntity(`/product-kit-products/${kitId}/products/${id}`)
			.pipe(tap(() => this.removeFromCacheCollection(key, id)), map(e => {}));
	}

	async getCustomerProductKitProducts(customerId: Guid, proxyProductId: Guid, destroy$?: Subject<void>): Promise<CustomerProductKitProduct[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CustomerProductKitProduct[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products`);
		return res.body?.data;
	}

	async getCustomerProductKitById(customerId: Guid, proxyProductId: Guid, destroy$: Subject<void>): Promise<CustomerProductKitProduct | null> {
		const products = await this.getCustomerProductKitProducts(customerId, proxyProductId, destroy$);
		const product = products.find(product => product.productId.equals(proxyProductId));

		if (!product) {
			throw new Error(`Product not found`);
		}

		return product;
	}

	async addCustomerProductKit(customerId: Guid, proxyProductId: Guid, instance: CustomerProductKitProduct, destroy$?: Subject<void>): Promise<Guid> {
		const res = await this.http.promise(destroy$).post<ApiData<Guid>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products`, instance);
		return res.body.data;
	}

	async updateCustomerProductKit(customerId: Guid, proxyProductId: Guid, instance: CustomerProductKitProduct, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products/${instance.productId}`, instance);
		return res.body;
	}

	async deleteCustomerProductKit(customerId: Guid, proxyProductId: Guid, id: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products/${id}`);
		return res.body;
	}
}

