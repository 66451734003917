import { ChangeDetectorRef, Component, forwardRef, Input, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = (): void => {
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgTileInputComponent),
	multi: true
};

@Component({
	selector: 'dg-tile-input',
	templateUrl: './dg-tile-input.component.html',
	styleUrls: ['./dg-tile-input.component.sass'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DgTileInputComponent {
	@Input() id: string;
	@Input() name: string;
	@Input() label: string;
	@Input() pattern: string;
	@Input() isRequired = false;
	@Input() isNumber = false;
	@Input() isReadonly = false;
	@Input() isEmail = false;
	@Input() maxLength = '9999';

	value: string;

	private _onTouchedCallback: () => void = noop;
	private _onChangeCallback: (_: any) => void = noop;

	constructor(protected readonly cdr: ChangeDetectorRef) {}

	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
			this.cdr.markForCheck();
		}
	}

	registerOnChange(fn: any): void {
		this._onChangeCallback = fn;
		this.cdr.markForCheck();
	}

	registerOnTouched(fn: any): void {
		this._onTouchedCallback = fn;
		this.cdr.markForCheck();
	}

	public onChanged(): void {
		this._onChangeCallback( this.isEmail ? this.value?.toLowerCase() :  this.value);
		this._onTouchedCallback();
		this.cdr.markForCheck();
	}

	public onTouched(): void {
		this._onTouchedCallback();
		this.cdr.markForCheck();
	}
}
