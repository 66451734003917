import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpAuth } from 'core/auth';
import { ServiceItem, ServiceItemAliasDto } from 'domain/entities';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { ApiData, Guid } from 'domain/types';
import { ServiceItemIpndSettings } from 'domain/models';

@Injectable({
	providedIn: 'root'
})
export class ServiceItemsRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	get(serviceId: number): Observable<ServiceItem[]> {
		const key = `serviceitems-${serviceId}`;
		const condition = this.cache.get('serviceItems-needsRefresh');

		if (this.cacheHasKey(key) && condition !== true) {
			return this.getCache<ServiceItem[]>(key);
		}

		const params = new HttpParams()
			.set('service', serviceId.toString());

		const requestOptions = { params: params };

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/service-items`, requestOptions)
				.pipe(
					map(res => {
						const items = res.body.data.map(x => new ServiceItem().deserialize(x));

						this.setCacheValue(key, items, null);

						return items;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getServiceItemsByServiceId(serviceId: number, destroy$?: Subject<void>): Promise<ServiceItem[]> {
		const params = new HttpParams()
			.set('service', serviceId.toString());

		const requestOptions = { params: params };
		
		const serviceItemResponse = await this.http.promise(destroy$).get<ApiData<ServiceItem[]>>(`${this.config.apiUrl}${this.config.apiVersion}/service-items`, requestOptions);
		return serviceItemResponse.body.data?.map(x => new ServiceItem().deserialize(x));
	}

	getServiceItemById(serviceItemId: Guid, serviceId: any): Observable<ServiceItem> {
		const key = `serviceitem-${serviceItemId}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<ServiceItem[]>(key)
				.pipe(map(serviceItem => serviceItem.find(x => x.id.equals(serviceItemId))));
		}

		return this.get(serviceId).pipe(map(serviceItem => serviceItem.find(x => x.id.equals(serviceItemId))));
	}

	getCustomerServiceItems$(customerId: Guid): Observable<ServiceItem[]> {
		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/service-items`)
				.pipe(map(res => res.body.data.map(x => new ServiceItem().deserialize2(x))));

		this.cache.set('serviceItems-' + customerId + '-needsRefresh', 'false');

		return observable;
	}

	async getCustomerServiceItems(customerId: Guid, destroy$?: Subject<void>): Promise<ServiceItem[]> {

		const serviceItemResponse = await this.http.promise(destroy$).get<ApiData<ServiceItem[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/service-items`);
		return serviceItemResponse.body.data?.map(x => new ServiceItem().deserialize2(x));
	}

	getCustomerServiceItemById(serviceItemId: Guid, customerId: Guid): Observable<ServiceItem> {
		return this.getCustomerServiceItems$(customerId).pipe(map(serviceItem => serviceItem.find(x => x.id.equals(serviceItemId))));
	}

	getServiceItemAliases(serviceItemId: Guid): Observable<ServiceItemAliasDto[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${serviceItemId}/aliases`)
			.pipe(
				map(res => res.body.data.map(x => new ServiceItemAliasDto().deserialize(x))));
	}

	validateServiceItemAliases(serviceId: number, serviceItemId: Guid, serviceItemAliases: ServiceItemAliasDto[]): Observable<HttpResponse<void>> {

		const payload = {
			serviceId,
			serviceItemId,
			serviceItemAliases
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/aliases/validate`, payload);
	}

	addServiceItem(serviceId: number, customerId: Guid, item: ServiceItem): Observable<Guid> {
		const key = `serviceitems-${serviceId}`;
		const key2 = `customers-${customerId}-serviceitems`;

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/service-items`, item)
			.pipe(
				map(res => {

					const newId = res.body.data;
					item.id = newId;

					if (this.cacheHasKey(key)) {
						this.addCacheCollectionItem(key, item);
					}
					if (this.cacheHasKey(key2)) {
						this.addCacheCollectionItem(key2, item);
					}

					return newId;

				}));
	}

	public updateServiceItem(serviceId: number, customerId: Guid, item: ServiceItem): Observable<void> {
		const key = `serviceitems-${serviceId}`;
		const key2 = `customers-${customerId}-serviceitems`;

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${item.id}`, null, item)
			.pipe(
				map(() => {

					if (this.cacheHasKey(key)) {
						this.updateCacheCollectionItem(key, item.id, item);
					}
					if (this.cacheHasKey(key2)) {
						this.updateCacheCollectionItem(key2, item.id, item);
					}

				}));
	}

	public deleteCustomerServiceItem(customerId: Guid, id: Guid): Observable<void> {

		const key = `customers-${customerId}-serviceitems`;

		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${id}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, id)),
				map(e => { }));
	}

	public deleteServiceItem(serviceId: number, id: Guid): Observable<void> {
		const key = `serviceitems-${serviceId}`;

		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${id}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, id)),
				map(e => this.cache.set('serviceItems-needsRefresh', true)));
	}

	public exportServiceItems(tag: string): Observable<any> {

		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { headers: headers };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/service-items/export`, tag, options)
			.pipe(map(res => res.body));
	}


	async getServiceItemIpndSettings(id: Guid, destroy$?: Subject<void>): Promise<ServiceItemIpndSettings | null> {
		const serviceItemIpndResponse = await this.http.promise(destroy$).get<ServiceItemIpndSettings>(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${id}/ipnd`);
		return serviceItemIpndResponse.body;
	}

	async addServiceItemIpndSettings(serviceItemId: Guid, instance: ServiceItemIpndSettings, destroy$?: Subject<void>): Promise<Guid> {
		instance.serviceItemId = serviceItemId;

		const res = await this.http.promise(destroy$).post<Guid>(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${serviceItemId}/ipnd`, instance);
		return res.body;
	}

	async updateServiceItemIpndSettings(serviceItemId: Guid, instance: ServiceItemIpndSettings, destroy$?: Subject<void>): Promise<void>  {
		instance.serviceItemId = serviceItemId;

		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${serviceItemId}/ipnd/${instance.id}`, instance);
		return res.body;
	}

	async ipndSetStatusNotSent(serviceItemId: Guid, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post<void>(`${this.config.apiUrl}${this.config.apiVersion}/service-items/${serviceItemId}/ipnd/set-status-not-sent`, null);
		
		return res.body;
	}
}
