<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(1)}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(2)}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(3)}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step3_title' | transloco}}</span>
        </li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(4)}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(5)}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step5_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(6)}">
			<span class="steps__label">{{'global-export.step6_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step6_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(7)}">
			<span class="steps__label">{{'global-export.step7_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step7_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(8)}">
			<span class="steps__label">{{'global-export.step8_label' | transloco}}</span>
			<span class="steps__label">{{'halo-charges-export.step8_title' | transloco}}</span>
		</li>
	</ul>
	<div *ngIf="wizardSettings" class="form__content">
		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(1)}">
			<h3 class="form__section-title">
				{{'halo-charges-export.step1_title' | transloco}}
			</h3>
			<form #ContractsForm="ngForm">
				<div class="form__group block halo-charges-step1-align">
					<div class="form__group-item form__group-item--750-fs">
                        <p>{{'halo-charges-export.step1_header-company' | transloco}}</p>
						<p>{{'halo-charges-export.step1_header-invoice' | transloco}}</p>
						<p>{{'halo-charges-export.step1_header-contract' | transloco}}</p>
                        <p>{{'halo-charges-export.step1_header-recurring' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoicesMapping; let i = index"
						class="form__group-item form__group-item--750-fs">
                        <p>{{item.companyName}}</p>
						<p>{{item.invoiceNumber}}</p>
					
						<ng-select *ngIf="item.contracts?.length" required bindLabel="name"
							bindValue="id" name="selectedHaloContractId{{i}}"
							[items]="item.contracts" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloContractId"
							(change)="onChangeContract(item)">
						</ng-select>

                        <ng-select *ngIf="item.contracts?.length" required bindLabel="invoiceNumber"
                            bindValue="id" class="margin-l__20"
                            name="selectedHaloInvoiceId{{i}}" [items]="item.selectedContratInvoices" [searchable]="true" [clearable]="false"
                            [(ngModel)]="item.selectedHaloInvoiceId" (change)="onChangeInvoice(item)">
                        </ng-select>

						<p *ngIf="!item.contracts?.length">
							{{'halo-charges-export.step1_contracts-not-found' | transloco}}
						</p>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
							[disabled]="!ContractsForm.valid || !invoicesMapping.length || !isContractInvoicesValid"
							(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>

		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(2)}">
			<h3 class="form__section-title">
				{{'halo-charges-export.step2_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'halo-charges-export.step2_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-1"
							[value]="1" [(ngModel)]='wizardSettings.selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-1">
							{{'halo-charges-export.field-selectedGroupingTypeId-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-2"
							[value]="2" [(ngModel)]='wizardSettings.selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-2">
							{{'halo-charges-export.field-selectedGroupingTypeId-opt-1' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-3"
							[value]="3" [(ngModel)]='wizardSettings.selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-3">
							{{'halo-charges-export.field-selectedGroupingTypeId-opt-2' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="onGroupingTypeSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(3)}">
			<h3 class="form__section-title">
				{{'halo-charges-export.step3_title' | transloco}}
			</h3>
			<form #ProductsForm="ngForm">
				<div *ngIf="wizardSettings.selectedGroupingTypeId === 1" class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'halo-charges-export.step3_header-service' | transloco}}</p>
						<p>{{'halo-charges-export.step3_header-record' | transloco}}</p>
						<p>{{'halo-charges-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of invoiceLinesResponses | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedHaloItemId']; let i1 = index" class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="name" required
									bindValue="id" name="selectedHaloItemId{{i}}_{{i1}}" [items]="productsMapping"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
									<ng-template>
										<input class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="wizardSettings.selectedGroupingTypeId === 2" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'halo-charges-export.step3_header-cost-centre' | transloco}}</p>
						<p>{{'halo-charges-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoiceLinesResponses; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="name" required bindValue="id"
							name="selectedHaloItemId{{i}}" [items]="productsMapping" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div *ngIf="wizardSettings.selectedGroupingTypeId === 3" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'halo-charges-export.step3_header-site' | transloco}}</p>
						<p>{{'halo-charges-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoiceLinesResponses; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="name" required bindValue="id"
							name="selectedHaloItemId{{i}}" [items]="productsMapping" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(4)}">
			<h3 class="form__section-title">
				{{'halo-charges-export.step4_title' | transloco}}
			</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-charges-export.step4-field_doNotExportProducts' | transloco}}</p>
						<dg-checkbox name="doNotExportProducts" ngDefaultControl 
							[id]="'doNotExportProducts'" [(ngModel)]="wizardSettings.doNotExportProducts"></dg-checkbox>
					</div>
					<div *ngIf="!wizardSettings.doNotExportProducts">
						<div *ngFor="let item of transactionResponses; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.code}}-{{item.transactionName}}</p>
						<ng-select style="min-width: 40%" bindLabel="name"
							required bindValue="id" name="selectedHaloItemId{{i}}"
							[items]="productsMapping" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
						</ng-select>
					</div>
					</div>
					
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(5)}">
			<h3 class="form__section-title">
				{{'halo-charges-export.step5_title' | transloco}}
			</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-charges-export.step5-field_combineSurchargesWithTaxes' | transloco}}</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [(ngModel)]="wizardSettings.combineSurchargesWithTaxes"></dg-checkbox>
					</div>
					<div *ngIf="!wizardSettings.combineSurchargesWithTaxes">
						<div *ngFor="let item of surchargeResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedHaloItemId{{i}}" [items]="productsMapping"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(6)}">
			<h3 class="form__section-title">
				{{wizardSettings.combineSurchargesWithTaxes ? 
					('halo-charges-export.step6_title-combineSurchargesWithTaxes' | transloco) : 
					'halo-charges-export.step6_title' | transloco }}
			</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!wizardSettings.combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-charges-export.step6-field_sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'halo-charges-export.step6-field_sureTaxMultiline-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
							[(ngModel)]="wizardSettings.sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="wizardSettings.sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-charges-export.step6-field_groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'halo-charges-export.step6-field_groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
							[(ngModel)]="wizardSettings.groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="wizardSettings.sureTaxMultiline && wizardSettings.groupTaxByState">
						<div *ngFor="let line of taxesLinesStateResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedHaloItemId{{i}}" [items]="productsMapping" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="wizardSettings.sureTaxMultiline && !wizardSettings.groupTaxByState">
						<div *ngFor="let line of taxesLinesResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedHaloItemId{{i}}" [items]="productsMapping" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!wizardSettings.sureTaxMultiline">
						<div *ngFor="let item of totalTaxesResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" bindValue="id"
								required name="selectedHaloItemId{{i}}" [items]="productsMapping"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div *ngIf="wizardSettings.combineSurchargesWithTaxes" class="form__group block">
					<div *ngFor="let item of surchargesWithTaxesResponses; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="name" bindValue="id" required
							name="selectedHaloItemId{{i}}" [items]="productsMapping" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(7)}">
			<h3 class="form__section-title">
				{{'halo-charges-export.step7_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'halo-charges-export.step7_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input type="radio" id="KeepItems" name="radio-delete" [value]="false"
							[(ngModel)]="wizardSettings.deleteExisting">
						<label class="radiobox-label" for="KeepItems">
							{{'halo-charges-export.step7-field_deleteExisting-false' | transloco}}
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="DeleteItems" name="radio-delete" [value]="true"
							[(ngModel)]="wizardSettings.deleteExisting">
						<label class="radiobox-label" for="DeleteItems">
							{{'halo-charges-export.step7-field_deleteExisting-true' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" (click)="NextStep()">
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					{{'global.export_btn-next' | transloco}}
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(8)}">
			<h3 class="form__section-title">{{'halo-charges-export.step8_title' | transloco}}</h3>
			<div class="form__section-subtitle">
				{{'halo-charges-export.step8_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='wizardSettings.selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'halo-charges-export.field_priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='wizardSettings.selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'halo-charges-export.field_priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title">
					<b>
						{{'halo-charges-export.confirmation_title' | transloco}}
					</b>
				</div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'halo-charges-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item">
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
