import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Invoice } from 'domain/entities';
import { CustomerRepository } from 'repositories/customer-repository';

import { HashMap, TranslateParams, TranslocoEvents, TranslocoService } from '@ngneat/transloco';
import { ToasterService } from 'core/toaster-service';
import { NavigatorService } from 'services';
import { Subject, combineLatest } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { DgDialogService } from 'tools/dialog';
import { CacheService } from 'services/cache.service';
import { GroupAction } from '../actions';
import { GroupActionCollection } from '../group-action-collection';
import { InvoiceActionsService } from '../invoice-actions.service';

@Component({
	selector: 'dg-invoice-actions-bar',
	templateUrl: './dg-invoice-action-bar.component.html',
	styleUrls: ['./dg-invoice-action-bar.component.sass']
})
export class DgInvoiceActionBarComponent implements OnDestroy, OnChanges {
	@Input() selected: Invoice[];
	@Input() excluded: Invoice[];
	@Input() actionButtonClass?: string = '';
	@Input() invoicesLength: number;
	@Input() dropDownMode = false;
	@Output() action: EventEmitter<GroupAction> = new EventEmitter<GroupAction>();

	actionCollection: GroupActionCollection;
	isSelectedAll: boolean;
	isActionsDropdown = false;
	public isDropDownOpen = false;
	private readonly unsubscribe$: Subject<void> = new Subject();
	private t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	constructor(
		private readonly invoiceService: InvoiceActionsService,
		private readonly customerRepository: CustomerRepository,
		private readonly navigatorService: NavigatorService,
		private readonly toasterService: ToasterService,
		private readonly transloco: TranslocoService,
		private readonly cache: CacheService,
		protected dialogService: DgDialogService
	) {
		this.t = this.transloco.translate.bind(this.transloco);
		combineLatest([
			this.transloco.langChanges$,
			this.transloco.events$.pipe(
				filter(e => e.type === 'translationLoadSuccess'),
				startWith(<TranslocoEvents>{})
			)
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(async (payload) => {
				const allCustomers = await this.customerRepository.getAll();
				this.actionCollection = new GroupActionCollection(allCustomers, invoiceService, customerRepository, navigatorService, toasterService, transloco);

				if (this.selected) {
					await this.refreshActions(this.selected, this.excluded);
				}
			});
	}

	public async refresh(selectedInvoices: Invoice[], excluded?: Invoice[]): Promise<void> {
		await this.refreshActions(selectedInvoices, excluded);
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		await this.refreshActions(this.selected, this.excluded);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async onGroupAction(action: GroupAction): Promise<void> {
		this.isDropDownOpen = false;

		if (this.isSelectedAll) {
			const confirm = await this.dialogService.confirm(action.confirmation?.title, this.t('invoice-action-bar.modal_msg', { invoicesLength: this.invoicesLength }));
			if (!confirm) return;
		}
		this.action.emit(action);
	}

	public toggleDropDown(): void {
		this.isDropDownOpen = !this.isDropDownOpen;
	}

	private async refreshActions(selected: Invoice[], excluded: Invoice[]): Promise<void> {
		if (!this.actionCollection) return;

		this.isSelectedAll = JSON.parse(sessionStorage.getItem('isSelectedAll'));

		if (this.dropDownMode && this.isSelectedAll) {
			this.actionCollection.displayAllActions(selected, excluded);
		} else {
			await this.actionCollection.refreshAvailableActions(selected);
		}
	}

	get isActionAvailable(): boolean {
		return ((this.selected.length > 0 || this.excluded.length > 0) && this.actionCollection.actions.some(a => a.available)) ||
				this.isSelectedAll;
	}

	// detect click in actions component
	@HostListener('click')
	clickInside(): void {
		this.isActionsDropdown = true;
	}

	// whenever click out of actions component hide actions dropdown
	@HostListener('document:click')
	clickout(): void {
		if (!this.isActionsDropdown) {
			this.isDropDownOpen = false;
		}
		this.isActionsDropdown = false;
	}
}
