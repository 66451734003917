import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class FsItem implements Serializable<FsItem>{
	id: Guid;
	opened: boolean;
	selected: boolean;
	folders: object;
	files: object;
	name: string;
	type: string;
	marginList: object;
	order: number;
	parent: Folder;
	previous_name: string;
	customerId: number;
	username: string;
	timestamp: Date;
	timemoment: string;
	contextMenu: boolean;
	readonly: boolean;
	fileType: string;

	constructor() {
		this.readonly = false;
	}

	deserialize(json: any): FsItem {

		this.id = json.id;
		this.folders = json.folders == undefined ? [] : json.folders;
		this.files = json.files == undefined ? [] : json.files;
		this.name = json.name;
		this.type = json.type;
		this.customerId = json.customerId;
		this.username = json.username;
		this.timestamp = json.timestamp;
		this.readonly = json.readonly == undefined ? false : json.readonly;
		this.contextMenu = false;

		return this;
	}
}

export class FilesReport {
	customerId: Guid;
	periodId: Guid;
	parameters: any;
	reportId: number;
	id: number;
	name: string;
	username: string;
	deserialize(json: any): FilesReport {

		this.reportId = json.id;
		this.id = json.id;
		this.name = json.name;
		this.parameters = json.parameters;
		this.customerId = json.customerId;
		this.periodId = json.periodId;
		this.username = json.username;

		return this;
	}
}

export class Folder extends FsItem implements Serializable<Folder> {
	folders: Folder[] = [];
	files: File[] = [];
	parent: Folder;
	uploading: boolean;

	constructor() {
		super();
		this.folders = [];
		this.files = [];
	}

	deserialize(json: any): Folder {

		super.deserialize(json);

		this.id = json.id;
		this.name = json.name;

		return this;
	}

	parse(json: any): Folder {

		this.deserialize(json);

		if (json.items) {

			// parse folders
			const folders =
				json.items.filter(x => x.type == 'folder').map(x => {
					return new Folder().deserialize(x);
				});

			// parse files
			const files =
				json.items.filter(x => x.type == 'file').map(x => {
					return new File().deserialize(x);
				});

			// restore folder structure
			for (const folder of folders) {

				if (folder.parentId == null) {
					folder.parent = this;
					this.folders.push(folder);

					continue;
				}

				const parent = folders.filter(x => x.id == folder.parentId)[0];
				if (parent) {
					folder.parent = parent;
					parent.folders.push(folder);
				}
			}

			// restore files folder structure
			for (const file of files) {

				if (file.parentId == null) {
					file.parent = this;
					this.files.push(file);

					continue;
				}

				const parent = folders.filter(x => x.id == file.parentId)[0];
				if (parent) {
					file.parent = parent;
					parent.files.push(file);
				}
			}
		}

		return this;
	}

}

export class File extends FsItem implements Serializable<File> {
	blobPath: string;
	parent: Folder;
	busy: boolean;

	constructor() {
		super();
	}

	deserialize(json: any): File {

		super.deserialize(json);

		this.blobPath = json.blobPath;
		this.fileType = json.name.substr(json.name.lastIndexOf('.') + 1);

		return this;
	}

	get iconClass(): string {

		var ext = this.name.substr(this.name.lastIndexOf('.') + 1);

		switch (ext) {
			case 'zip': return 'fa-file-zip-o';

			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'tif':
			case 'tiff':
			case 'gif': return 'fa-file-image-o';

			case 'pdf': return 'fa-file-pdf-o';

			case 'xls':
			case 'xlsx': return 'fa-file-excel-o';

			case 'doc':
			case 'docx': return 'fa-file-word-o';

			case 'csv':
			case 'txt': return 'fa-file-text-o';

			default:
				return 'fa-file-o';
		}
	}
}

export class FileResponse {
	id: Guid;
	parentId: Guid;
	userId: Guid;
	fileName: string;
	username: string;
}
