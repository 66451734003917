import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { XeroTenants } from 'domain/entities';
import { Subject } from 'rxjs';
import { XeroAuthService } from 'services';

@Component({
	selector: 'dg-xero-tenants',
	templateUrl: './xero-tenants.component.html',
	styleUrls: ['./xero-tenants.component.sass']
})
export class XeroTenantsComponent implements OnInit, OnDestroy {
	xeroTenants: XeroTenants[] = [];
	tenantId: number;
	uniqueId = this.route.snapshot.params.uniqueId;
	isError = false;
	private readonly unsubscribe$ = new Subject<void>();
	constructor(
		private readonly xeroService: XeroAuthService,
		private readonly route: ActivatedRoute) {
	}

	async ngOnInit(): Promise<void> {
		try {
			this.xeroTenants = await this.xeroService.getXeroTenants(this.uniqueId, this.unsubscribe$).withBusy();
			window.close();
		} catch (error) {
			this.isError = true;
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async onTenantSelect(): Promise<void> {
		try {
			await this.xeroService.postSelectedTenant(this.uniqueId, this.tenantId);
			window.close();
		} catch (error) {
			this.isError = true;
		}
	}
}
