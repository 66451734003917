import { Guid } from 'domain/types';
import { Serializable } from '.';
import * as Integrations from 'pages/+portal-settings/portal-integrations/integration-service';

export class BpointEntity implements Serializable<BpointEntity> {
	id: Guid;
	userName: string;
	merchantNumber: string;
	password: string;
	currency: string;
	alt: string = Integrations.IntegrationService.BPoint;
	iconSrc = 'BPoint_logo.png';
	title = 'BPoint';
	isConfigured = false;
	isOtherPayment = false;
	deserialize(json: any): BpointEntity {
		this.id = json.id;
		this.userName = json.userName;
		this.merchantNumber = json.merchantNumber;
		this.password = json.password;
		this.currency = json.currency;

		if (this.userName?.length) {
			this.isConfigured = true;
		}

		return this;
	}
}