import { CustomerDetails, Invoice } from 'domain/entities';
import * as Moment from 'moment-timezone';

import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { ToasterService } from 'core/toaster-service';
import { NavigatorService } from 'services';
import { InvoiceActionsService } from './invoice-actions.service';
import { InvoiceStatus } from 'domain/enums';

export enum GroupActionType {
	Email = 'email',
	ReEmail = 'reemail',
	Zip = 'zip',
	Pend = 'onhold',
	UnPend = 'offhold',
	Regenerate = 'regenerate',
	MarkSent = 'marksent',
	Delete = 'delete',
	Export = 'export',
	CreateCreditRecord = 'credit',
	Charge = 'charge',
	Print = 'print',
	MarkPaid = 'markpaid',
	GenerateMiscellaneous = 'generate-miscellaneous',
	Archive = 'archive',
	RestoreFromArchive = 'restore-archive'
}

export enum GroupActionResultStatus {
	Noop = 0,
	NavigatedToOperation = 10,
	Executed = 20
}

export abstract class GroupAction {
	type: GroupActionType;
	name: string;
	icon: string;
	isAvailable: (n: Invoice, c: CustomerDetails) => boolean;
	items: Invoice[] = [];
	excluded: Invoice[] = [];
	public t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	get confirmation(): Confirmation {
		return undefined;
	}

	get count(): number {
		return this.items.length;
	}

	get available(): boolean {
		return this.count > 0;
	}

	constructor(
		protected toasterService: ToasterService,
		protected invoiceService: InvoiceActionsService,
		protected navigatorService: NavigatorService,
		protected transloco: TranslocoService
	) {
		this.t = transloco.translate.bind(transloco);
	}

	abstract do(url?: string): Promise<GroupActionResultStatus>;

	navigateToOperation(data: any): void {
		const operationId = data.operationId || data.data.operationId;
		this.navigatorService.toOperation(operationId);
	}
}

export class RegenerateAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Regenerate;
		this.name = this.t('invoice-action-bar.actions-regenerate_title');
		this.icon = 'fa-refresh';

		this.isAvailable = (invoice, customer) =>
			!invoice.isCreditNote
			&& !invoice.isOnHold
			&& customer.isActive
			&& invoice.invoiceType === 'Agreement'
			&& (invoice.status === InvoiceStatus.Generated
				|| invoice.status === InvoiceStatus.Failed
				|| invoice.status === InvoiceStatus.SendFailed
			);
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-regenerate-confrimation_title');
		let message = this.t('invoice-action-bar.actions-regenerate-confrimation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-regenerate-confrimation_title-plural');
			message = this.t('invoice-action-bar.actions-regenerate-confrimation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.reGenerateInvoices(this.items, this.excluded);
		this.navigateToOperation(data);
		return GroupActionResultStatus.NavigatedToOperation;
	}
}

export class DeleteAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Delete;
		this.name = this.t('invoice-action-bar.actions-delete_title');
		this.icon = 'fa-trash-o';

		this.isAvailable = (invoice, customer) =>
			!invoice.isOnHold
			&& (
				invoice.status !== InvoiceStatus.Sent
				&& invoice.status !== InvoiceStatus.SentManually
				&& invoice.status !== InvoiceStatus.Uninvoiced
				&& invoice.status !== InvoiceStatus.PaidFailed
				&& invoice.status !== InvoiceStatus.Paid
				&& invoice.status !== InvoiceStatus.PaidManually
				&& invoice.status !== InvoiceStatus.PartiallyPaid
				&& invoice.status !== InvoiceStatus.PartiallyPaidManually
			);
	}

	get confirmation(): Confirmation {
		const title = this.t(this.items.length > 1 ? 'invoice-action-bar.actions-delete-confirmation_title' : 'invoice-action-bar.actions-delete-confirmation_title-plural');
		let message = this.t('invoice-action-bar.actions-delete-confirmation_msg');

		if (this.items.length > 1) {
			message = this.t('invoice-action-bar.actions-delete-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(url?: string): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.invoiceDelete(this.items, this.excluded, url);
		this.navigateToOperation(data);
		return GroupActionResultStatus.NavigatedToOperation;
	}
}

export class EmailAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Email;
		this.name = this.t('invoice-action-bar.actions-email_title');
		this.icon = 'fa-paper-plane-o';

		this.isAvailable = (invoice, customer) =>
			invoice.canBeEmailed
			&& !invoice.isOnHold
			&& invoice.email
			&& invoice.status !== InvoiceStatus.Sent
			&& invoice.status !== InvoiceStatus.SentManually
			&& invoice.status !== InvoiceStatus.Uninvoiced
			&& invoice.status !== InvoiceStatus.Paid
			&& invoice.status !== InvoiceStatus.PaidManually
			&& invoice.status !== InvoiceStatus.PartiallyPaid
			&& invoice.status !== InvoiceStatus.PartiallyPaidManually
			&& invoice.status !== InvoiceStatus.Failed
			&& invoice.status !== InvoiceStatus.Draft
			&& (invoice.status === InvoiceStatus.Generated ||
				invoice.status !== InvoiceStatus.SendFailed);
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-email-confirmation_title');
		let message = this.t('invoice-action-bar.actions-email-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-email-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-email-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.sendEmails(this.items, this.excluded);
		this.navigateToOperation(data);
		return GroupActionResultStatus.NavigatedToOperation;
	}
}

export class MarkSentAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.MarkSent;
		this.name = this.t('invoice-action-bar.actions-mark-sent_title');
		this.icon = 'fa-check-square-o';
		this.isAvailable = invoice =>
			!invoice.isOnHold
			&& (
				invoice.status === InvoiceStatus.Generated
				|| invoice.status === InvoiceStatus.SendFailed
			)
		;
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-mark-sent-confirmation_title');
		let message = this.t('invoice-action-bar.actions-mark-sent-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-mark-sent-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-mark-sent-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.markSent(this.items, this.excluded);
		if (data.operationId) {
			this.navigateToOperation(data);
			return GroupActionResultStatus.NavigatedToOperation;
		} else {
			for (const invoice of this.items) {
				invoice.status = InvoiceStatus.SentManually;
			}
		}
		return GroupActionResultStatus.Executed;
	}
}

export class PendAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Pend;
		this.name = this.t('invoice-action-bar.actions-pend_title');
		this.icon = 'fa-lock';
		this.isAvailable = (invoice, customer) => !invoice.isOnHold
			&& invoice.status !== InvoiceStatus.Uninvoiced
			&& invoice.status !== InvoiceStatus.Paid
			&& invoice.status !== InvoiceStatus.Draft
			&& invoice.status !== InvoiceStatus.PaidManually
			&& invoice.status !== InvoiceStatus.PartiallyPaid
			&& invoice.status !== InvoiceStatus.PartiallyPaidManually;
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		await this.invoiceService.putOnHold(this.items, this.excluded);
		this.items = this.items.filter(invoice =>
			invoice.status !== InvoiceStatus.Paid &&
			invoice.status !== InvoiceStatus.PaidManually &&
			invoice.status !== InvoiceStatus.PartiallyPaid &&
			invoice.status !== InvoiceStatus.Draft &&
			invoice.status !== InvoiceStatus.PartiallyPaidManually);

		for (const invoice of this.items) {
			invoice.isOnHold = true;

		}
		return GroupActionResultStatus.Executed;
	}
}

export class ReEmailAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.ReEmail;
		this.name = this.t('invoice-action-bar.actions-re-email_title');
		this.icon = 'fa-repeat';
		this.isAvailable = (invoice, customer) =>
			invoice.canBeEmailed
			&& !invoice.isOnHold
			&& invoice.email
			&&
			(
				invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.PartiallyPaid
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
				|| invoice.status === InvoiceStatus.PaidFailed
				|| invoice.status === InvoiceStatus.SendFailed
			);
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-re-email-confirmation_title');
		let message = this.t('invoice-action-bar.actions-re-email-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-re-email-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-re-email-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.sendREmails(this.items, this.excluded);
		this.navigateToOperation(data);
		return GroupActionResultStatus.NavigatedToOperation;
	}
}

export class UnPendAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.UnPend;
		this.name = this.t('invoice-action-bar.actions-un-pend_title');
		this.icon = 'fa-unlock';
		this.isAvailable = (invoice, customer) => invoice.isOnHold;
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		await this.invoiceService.putOffHold(this.items, this.excluded);
		for (const invoice of this.items) {
			invoice.isOnHold = false;
		}
		return GroupActionResultStatus.Executed;
	}
}

export class ExportAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Export;
		this.name = this.t('invoice-action-bar.actions-export_title');
		this.icon = 'fa-share';
		this.isAvailable = (invoice, customer) =>
			!invoice.isOnHold
			&& (invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.PaidFailed
				|| invoice.status === InvoiceStatus.PaidManually
				|| invoice.status === InvoiceStatus.PartiallyPaid
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
				|| invoice.status === InvoiceStatus.Paid);
	}

	async do(): Promise<GroupActionResultStatus> {
		return GroupActionResultStatus.Noop;
	}
}

export class ZipAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Zip;
		this.name = this.t('invoice-action-bar.actions-zip_title');
		this.icon = 'fa-file-archive-o';
		this.isAvailable = invoice =>
			!invoice.isOnHold
			&& (
				invoice.status === InvoiceStatus.Generated
				|| invoice.status === InvoiceStatus.Emailed
				|| invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.Paid
				|| invoice.status === InvoiceStatus.PaidManually
				|| invoice.status === InvoiceStatus.PartiallyPaid
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
				|| invoice.status === InvoiceStatus.PaidFailed
				|| invoice.status === InvoiceStatus.SendFailed);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.download(this.items, this.excluded);
		this.navigateToOperation(data);
		return GroupActionResultStatus.NavigatedToOperation;
	}
}

export class CreateCreditRecordAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.CreateCreditRecord;
		this.name = this.t('invoice-action-bar.actions-credit_title');
		this.icon = 'fa fa-credit-card';

		// invoice doesn't have a credit note already
		// and invoice is not a credit note
		// and was sent
		this.isAvailable = (invoice, customer) =>
			!invoice.creditNoteId
			&& customer.isActive
			&& !invoice.isCreditNote
			&& !invoice.isOnHold
			&& (invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.PaidManually
				|| invoice.status === InvoiceStatus.PartiallyPaid
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
				|| invoice.status === InvoiceStatus.Paid);
	}

	async do(): Promise<GroupActionResultStatus> {
		return GroupActionResultStatus.Noop;
	}
}

export class ChargeAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Charge;
		this.name = this.t('invoice-action-bar.actions-charge_title');
		this.icon = 'fa-money';
		this.isAvailable = (invoice, customer) =>
			!invoice.isOnHold
			&& invoice.canCharge
			&& !invoice.isCreditNote
			&& !invoice.creditNoteId
			&& invoice.amount > 0
			&& (
				invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.PaidFailed
				|| invoice.status === InvoiceStatus.PartiallyPaid
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
			);
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-charge-confirmation_title');
		let message = this.t('invoice-action-bar.actions-charge-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-charge-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-charge-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	// ONLY AVAILABLE FROM COMPONENT
	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.invoiceCharge(this.items, undefined, this.excluded);
		this.navigateToOperation(data);
		return GroupActionResultStatus.NavigatedToOperation;
	}
}

export class PrintAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Print;
		this.name = this.t('invoice-action-bar.actions-print_title');
		this.icon = 'fa-print';
		this.isAvailable = (invoice, customer) =>
			!invoice.isOnHold
			&& (
				invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.Paid
				|| invoice.status === InvoiceStatus.PaidManually
				|| invoice.status === InvoiceStatus.PaidFailed
				|| invoice.status === InvoiceStatus.PartiallyPaid
				|| invoice.status === InvoiceStatus.Generated
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
			);
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-print-confirmation_title');
		let message = this.t('invoice-action-bar.actions-print-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-print-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-print-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		this.icon = 'fa-spinner fa-spin';
		try {
			await this.invoiceService.invoicesPrint(this.items, this.excluded, 'application/pdf', 'invoice.pdf');
			this.icon = 'fa-print';
			return GroupActionResultStatus.Executed;
		} catch (error) {
			this.icon = 'fa-print';
			throw error;
		}
	}
}

export class MarkPaidAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.MarkPaid;
		this.name = this.t('invoice-action-bar.actions-mark-paid_title');
		this.icon = 'fa-dollar';
		this.isAvailable = invoice =>
			!invoice.isOnHold
			&& !invoice.isCreditNote
			&& (
				invoice.status === InvoiceStatus.Sent
				|| invoice.status === InvoiceStatus.SentManually
				|| invoice.status === InvoiceStatus.PartiallyPaidManually
				|| invoice.status === InvoiceStatus.PaidFailed
			)
		;
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-mark-paid-confirmation_title');
		let message = this.t('invoice-action-bar.actions-mark-paid-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-mark-paid-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-mark-paid-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.changeStatus(this.items, InvoiceStatus.PaidManually, this.excluded);
		if (data.operationId) {
			this.navigateToOperation(data);
			return GroupActionResultStatus.NavigatedToOperation;
		}
		return GroupActionResultStatus.Executed;
	}
}

export class GenerateMiscellaneousAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.GenerateMiscellaneous;
		this.name = this.t('invoice-action-bar.actions-generate-misc_title');
		this.icon = 'fa-file';

		const startOfNextMonth = Moment().add(1, 'M').startOf('month');
		this.isAvailable = invoice =>
			!invoice.isOnHold
			&& invoice.invoiceType === 'Miscellaneous'
			&& (invoice.status === InvoiceStatus.Draft ||
				invoice.status === InvoiceStatus.Failed)
		;
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-generate-misc-confirmation_title');
		let message = this.t('invoice-action-bar.actions-generate-misc-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-generate-misc-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-generate-misc-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const data = await this.invoiceService.generateMiscellaneousInvoices(this.items, this.excluded);
		if (data.operationId) {
			this.navigateToOperation(data);
			return GroupActionResultStatus.NavigatedToOperation;
		}
		return GroupActionResultStatus.Executed;
	}
}

export class Confirmation {
	constructor(public title: string, public message: string) {
	}
}

export class ArchiveAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.Archive;
		this.name = this.t('invoice-action-bar.actions-archive_title');
		this.icon = 'fa-archive';
		this.isAvailable = invoice =>
			(
				!invoice.isArchived
			)
		;
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-archive-confirmation_title');
		let message = this.t('invoice-action-bar.actions-archive-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-archive-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-archive-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const resp = await this.invoiceService.archive(this.items, this.excluded);
		if (resp.data.operationId) {
			this.navigateToOperation(resp.data);
			return GroupActionResultStatus.NavigatedToOperation;
		} else {
			for (const invoice of this.items) {
				invoice.isArchived = true;
			}
		}
		return GroupActionResultStatus.Executed;
	}
}

export class RestoreFromArchiveAction extends GroupAction {
	constructor(
		toasterService: ToasterService,
		invoiceService: InvoiceActionsService,
		navigatorService: NavigatorService,
		transloco: TranslocoService
	) {

		super(toasterService, invoiceService, navigatorService, transloco);

		this.type = GroupActionType.RestoreFromArchive;
		this.name = this.t('invoice-action-bar.actions-restore-archive_title');
		this.icon = 'fa-recycle';
		this.isAvailable = invoice =>
			(
				invoice.isArchived
			)
		;
	}

	get confirmation(): Confirmation {
		let title = this.t('invoice-action-bar.actions-restore-archive-confirmation_title');
		let message = this.t('invoice-action-bar.actions-restore-archive-confirmation_msg');

		if (this.items.length > 1) {
			title = this.t('invoice-action-bar.actions-restore-archive-confirmation_title-plural');
			message = this.t('invoice-action-bar.actions-restore-archive-confirmation_msg-plural', { itemsLength: this.items.length });
		}

		return new Confirmation(title, message);
	}

	async do(): Promise<GroupActionResultStatus> {
		if (!this.items || this.items.length === 0)
			return GroupActionResultStatus.Noop;

		const resp = await this.invoiceService.restoreArchive(this.items, this.excluded);
		if (resp.data.operationId) {
			this.navigateToOperation(resp.data);
			return GroupActionResultStatus.NavigatedToOperation;
		} else {
			for (const invoice of this.items) {
				invoice.isArchived = false;
			}
		}
		return GroupActionResultStatus.Executed;
	}
}
