
export enum StandardReportType {
	UsageTotalCalls = 'UsageTotalCalls',
	UsageCallTypes = 'UsageCallTypes',
	UsageDetails = 'UsageDetailsLine',
	Profitability = 'Profitability',
	AgedBalance = 'AgedBalance',
	InvoiceRegister = 'InvoiceRegister',
	CustomerInvoices = 'CustomersInvoiceDifferences',
	Usage = 'Usage',
	Commissions = 'Commission',
	Fcc = 'FCC477',
	Fus = 'FusTax',
	Csi = 'CSITaxes',
	CsiDetailed = 'CSITaxesDetailed',
	SuretaxDetailed = 'SuretaxTaxesDetailed',
}