import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpAuth } from 'core/auth';
import { ToasterService } from 'core/toaster-service';
import {
	CurrencyEntity,
	DeliveryEntity,
	EmailSetting,
	ExtraFieldEntity,
	InvoiceControlSettings,
	InvoiceSettings,
	OrganizationSettings,
	PaymentTerm,
	PortalSettings,
	ServiceItemTypes,
	UsageRecordType
} from 'domain/entities';
import { AppConfig } from 'core/app-config';
import { Guid, ApiData } from 'domain/types';
import { SsoSettings } from 'domain/entities/sso-settings';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { PaymentReceipt, PaymentReceiptTemplate } from 'domain/models';

@Injectable({
	providedIn: 'root'
})
export class SettingsRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		private readonly toasterService: ToasterService,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getCurrencies(): Observable<CurrencyEntity[]> {
		const key = 'currencies';

		if (this.cacheHasKey(key))
			return this.getCache<CurrencyEntity[]>(key);

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/currencies`)
			.pipe(
				map(res => {
					const currencies = res.body.data.map(x => new CurrencyEntity().deserialize(x));
					this.setCacheValue(key, currencies, null);
					return currencies;
				}));
	}

	// ======== INVOICE ===========

	async getInvoiceSettings(cancel$?: Subject<void>): Promise<InvoiceSettings> {
		const res = await this.http.promise(cancel$).get<ApiData<InvoiceSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/invoice`);
		const invoiceSettings = new InvoiceSettings().deserialize(res.body?.data);

		return invoiceSettings;
	}

	async getInvoiceControlSettings(cancel$?: Subject<void>): Promise<InvoiceControlSettings> {
		const invoiceControlSettings = await this.http.promise(cancel$).get<ApiData<InvoiceControlSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/invoice/number`);
		return invoiceControlSettings.body?.data;
	}

	async updateInvoiceSettings(settings: InvoiceSettings, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/settings/invoice`, settings);
		return res.body;
	}

	async updateInvoiceControlSettings(settings: InvoiceControlSettings, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/settings/invoice/number`, settings);
		return res.body;
	}


	uploadLogo(file: File): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('file', file, file.name);

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/settings/invoice/image`, null, formData);
	}

	deleteLogo(): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/settings/invoice/image`);
	}

	// ======== END OF INVOICE ===========

	// ======== PAYMENT_RECEIPTS ===========

	async getPaymentReceipt(cancel$?: Subject<void>): Promise<PaymentReceipt> {
		const res = await this.http.promise(cancel$).get<ApiData<PaymentReceipt>>(`${this.config.apiUrl}${this.config.apiVersion}/payments-receipt/settings`);
		return res.body?.data;
	}

	async getPaymentReceiptTemplate(cancel$?: Subject<void>): Promise<PaymentReceiptTemplate> {
		const res = await this.http.promise(cancel$).get<ApiData<PaymentReceiptTemplate>>(`${this.config.apiUrl}${this.config.apiVersion}/payments-receipt/templates`);
		return res.body?.data;
	}

	async updatePaymentReceipt(settings: PaymentReceipt, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/payments-receipt/settings`, settings);
		return res.body;
	}

	async updatePaymentReceiptTemplate(settings: PaymentReceiptTemplate, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/payments-receipt/templates`, settings);
		return res.body;
	}

	// ======== END OF PAYMENT_RECEIPTS ===========


	// ======== SSO Settings===========

	getSsoSettings(ct: number): Observable<SsoSettings> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/settings/single-sign-on1`, { params: { ct } })
			.pipe(map(res => new SsoSettings().deserialize(res.body.data)));
	}

	public updateSsoSettings(settings: SsoSettings): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/settings/single-sign-on2`, null, settings);
	}

	public testSsoSettings(clientType: number): Observable<boolean> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/settings/single-sign-on/test`, {
			params: { ct: clientType }
		})
			.pipe(map(res => {
				return res.status === 200;
			}), catchError(() => of(false)));
	}

	// ======== END SSO Settings===========

	// ======== SETTINGS ===========

	async getPortalSettings(cancel$?: Subject<void>): Promise<PortalSettings> {
		const me = await this.http.promise(cancel$).get<ApiData<PortalSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/portal`);
		const portalSettings = new PortalSettings().deserialize(me.body?.data);

		return portalSettings;
	}

	public updatePortalSettings(settings: PortalSettings): Observable<HttpResponse<any>> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/settings/portal`, null, settings);
	}

	async updatePortalSettingsAsync(settings: PortalSettings, destroy$?: Subject<void>): Promise<void> {
		await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/settings/portal`, settings);
	}

	async getEmailSettings(destroy$: Subject<void>): Promise<EmailSetting> {
		const res = await this.http.promise(destroy$).get<ApiData<EmailSetting[]>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/email`);
		const settings = new EmailSetting().deserialize(res.body.data[0]);
		return settings;
	}

	async updateEmailSettings(settings: EmailSetting, destroy$?: Subject<void>): Promise<void> {
		await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/settings/email`, settings);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getExtraFieldsLabels$(): Observable<ExtraFieldEntity[]> {
		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/extra-fields/labels`)
				.pipe(
					map(res => {
						const extraFields = res.body.data.map(x => new ExtraFieldEntity().deserialize(x));
						return extraFields;
					}), catchError(err => {
						return throwError(() => err);
					}));

		return observable;
	}

	async getExtraFieldsLabels(destroy$?: Subject<void>): Promise<ExtraFieldEntity[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ExtraFieldEntity[]>>(`${this.config.apiUrl}${this.config.apiVersion}/extra-fields/labels`);
		return res.body.data.map(x => new ExtraFieldEntity().deserialize(x));
	}

	public updateExtraFieldsLabels(labels: ExtraFieldEntity): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/extra-fields/labels`, labels)
			.pipe(catchError(err => {
				return throwError(() => err);
			}));
	}

	// ======== END OF SETTINGS ===========

	// ======== ORGANISATION ===========

	async getOrgSettings(destroy$?: Subject<void>): Promise<OrganizationSettings> {
		const res = await this.http.promise(destroy$).get<ApiData<OrganizationSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/organisation`);

		return new OrganizationSettings().deserialize(res.body.data);
	}

	public updateOrgSettings(settings: OrganizationSettings): Observable<HttpResponse<any>> {

		const observable =
			this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/settings/organisation`, null, settings);

		return observable;
	}

	// ======== END OF ORGANISATION ===========

	// ======== SUB-ORGANISATION ===========

	async getSubOrganizations(cancel$?: Subject<void>): Promise<OrganizationSettings[]> {
		const res = await this.http.promise(cancel$).get<ApiData<OrganizationSettings[]>>(`${this.config.apiUrl}${this.config.apiVersion}/sub-organizations`);
		const subOrgs = res.body.data.map(x => new OrganizationSettings().deserialize(x));
		return subOrgs;
	}

	async getSubOrganizationById(subOrgId: Guid, destroy$?: Subject<void>): Promise<OrganizationSettings> {
		const subOrgs = await this.getSubOrganizations(destroy$);
		return subOrgs.find(x => x.id.equals(subOrgId));
	}

	async addSubOrganization(settings: OrganizationSettings, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/sub-organizations`, settings);
		return res.body;
	}

	async updateSubOrganization(settings: OrganizationSettings, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/sub-organizations/${settings.id}`, settings);
		return res.body;
	}

	async deleteSubOrganization(ids: Guid[], destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/sub-organizations/delete`, { records: ids });
		return res.body;
	}

	uploadSubLogo(id: Guid, file: File): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('file', file, file.name);

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/sub-organizations/${id}/logo/upload`, null, formData);
	}

	deleteSubLogo(id: Guid): Observable<any> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/sub-organizations/${id}/logo`);
	}

	// ======== END OF SUB-ORGANISATION ===========

	// ======== DELIVERY ===========
	getDeliverySettings(): Observable<DeliveryEntity[]> {
		const key = 'delivery-settings';
		const condition = this.cache.get('delivery-needsRefresh');

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCache<DeliveryEntity[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/delivery-methods`)
				.pipe(
					map(res => {
						const delivery = res.body.data.map(x => new DeliveryEntity().deserialize(x));
						this.setCacheValue(key, delivery, null);
						return delivery;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	public addDeliverySettings(delivery: DeliveryEntity): Observable<Guid> {
		const key = 'delivery-settings';

		const obj = {
			id: delivery.id,
			name: delivery.name,
			default: delivery.isDefault,
			email: delivery.isEmail,
			print: delivery.isPrint
		};

		return this.createEntity(`/delivery-methods`, obj)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateDeliverySettings(delivery: DeliveryEntity): Observable<void> {
		const key = 'delivery-settings';

		const obj = {
			id: delivery.id,
			name: delivery.name,
			default: delivery.isDefault,
			email: delivery.isEmail,
			print: delivery.isPrint
		};

		return this.updateEntity(`/delivery-methods`, obj)
			.pipe(
				tap(s => this.updateCacheCollectionItem(key, delivery.id, delivery))
				, map(s => null));
	}

	public deleteDeliverySettings(ids: Guid[]): Observable<HttpResponse<void>> {

		const key = 'delivery-settings';

		const observable =
			this.deleteEntities(`/delivery-methods/delete`, ids)
				.pipe(
					tap(() => this.removeManyFromCacheCollection(key, ids)));

		return observable;
	}

	// ======== END OF DELIVERY ===========

	// ======== PAYMENT TERMS ===========
	getPaymentTerms(): Observable<PaymentTerm[]> {
		const key = 'payment-terms';
		const condition = this.cache.get('payment-terms-refresh');

		if (this.cacheHasKey(key) && condition !== true) {
			return this.getCache<PaymentTerm[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/payment-terms`)
				.pipe(
					map(res => {
						const terms = res.body.data.map(x => new PaymentTerm().deserialize(x));

						this.setCacheValue(key, terms, null);
						this.cache.set('payment-terms-refresh', false);
						return terms;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	public getPaymentTermById(id: Guid): Observable<PaymentTerm> {
		const key = 'payment-terms';

		if (this.cacheHasKey(key)) {
			return this.getCache<PaymentTerm[]>(key)
				.pipe(map(terms => terms.find(x => x.id.equals(id))));
		}

		return this.getPaymentTerms().pipe(map(terms => terms.find(x => x.id.equals(id))));
	}

	public addPaymentTerm(term: PaymentTerm): Observable<Guid> {

		return this.createEntity(`/payment-terms`, term)
			.pipe(map(s => s.id));
	}

	public updatePaymentTerm(term: PaymentTerm): Observable<void> {
		return this.updateEntity(`/payment-terms`, term)
			.pipe(map(s => null));
	}

	public deletePaymentTerms(ids: Guid[]): Observable<HttpResponse<void>> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/payment-terms/delete`, { records: ids })
			.pipe(tap(() => {
				this.cache.removeKey('payment-terms');
				this.cache.set('needToResetAgreements', true);
			}));
	}

	// ======== END OF PAYMENT TERMS ===========

	// ======== SERVICE ITEM TYPES ===========
	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getServiceItemTypes$(): Observable<ServiceItemTypes[]> {
		const key = 'service-item-types';

		if (this.cacheHasKey(key)) {
			return this.getCache<ServiceItemTypes[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/service-item-types`)
				.pipe(
					map(res => {
						const types = res.body.data.map(x => new ServiceItemTypes().deserialize(x));

						this.setCacheValue(key, types, null);
						return types;
					}));

		this.cache.set$(key, observable);

		return observable;
	}


	async getServiceItemTypes(destroy$?: Subject<void>): Promise<ServiceItemTypes[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ServiceItemTypes[]>>(`${this.config.apiUrl}${this.config.apiVersion}/service-item-types`);
		return res.body.data?.map(x => new ServiceItemTypes().deserialize(x));
	}


	public getServiceItemTypeById(id: Guid): Observable<ServiceItemTypes> {
		const key = 'service-item-types';

		if (this.cacheHasKey(key)) {
			return this.getCache<ServiceItemTypes[]>(key)
				.pipe(map(types => types.find(x => x.id.equals(id))));
		}

		return this.getServiceItemTypes$().pipe(map(types => types.find(x => x.id.equals(id))));
	}

	public addServieItemType(type: ServiceItemTypes): Observable<Guid> {
		const key = 'service-item-types';

		return this.createEntity(`/service-item-types`, type)
			.pipe(
				tap(s => this.cache.removeKey(key))
				, map(s => s.id));
	}

	public updateServiceItemType(type: ServiceItemTypes): Observable<any> {
		const key = 'service-item-types';

		return this.updateEntity(`/service-item-types`, type)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));
	}

	public deleteServiceItemType(ids: number[]): Observable<HttpResponse<void>> {
		const data = {
			records: ids
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/service-item-types/delete`, data);
	}

	// ======== END OF SERVICE ITEM TYPES ===========

	// ======== USAGE RECORD TYPES ===========

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getUsageRecordTypes$(): Observable<UsageRecordType[]> {
		const observable =
			this.http.get<ApiData<UsageRecordType[]>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/usage-record-types`)
				.pipe(
					map(res => {
						const types = res.body.data.map(x => new UsageRecordType().deserialize(x));
						return types;
					}));

		return observable;
	}

	async getUsageRecordTypes(destroy$?: Subject<void>): Promise<UsageRecordType[]> {
		const res = await this.http.promise(destroy$).get<ApiData<UsageRecordType[]>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/usage-record-types`);
		const types = res.body.data.map(x => new UsageRecordType().deserialize(x));
		return types;
	}

	public getUsageRecordTypesById(id: Guid): Observable<UsageRecordType> {
		return this.http.get<ApiData<UsageRecordType>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/usage-record-types/${id}`)
			.pipe(
				map(res => new UsageRecordType().deserialize(res.body.data[0])));
	}

	public updateUsageRecordType(item: UsageRecordType): Observable<any> {
		const key = 'usage-record-types';

		return this.updateEntity(`/settings/usage-record-types`, item)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
			);
	}

	public addUsageRecordType(item: UsageRecordType): Observable<Guid> {
		const key = 'service-item-types';

		return this.createEntity(`/settings/usage-record-types`, item)
			.pipe(
				tap(s => this.cache.removeKey(key))
				, map(s => s.id));
	}

	async deleteUsageRecordTypes(ids: Guid[], destroy$?: Subject<void>): Promise<HttpResponse<void>> {
		return this.http.promise(destroy$).post<void>(`${this.config.apiUrl}${this.config.apiVersion}/settings/usage-record-types/delete`, { records: ids }, null);
	}

	// ======== END OF USAGE RECORD TYPES ===========
}
