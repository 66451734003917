<div class="page-tile page-tile--new_" [class.page-tile-modal]="isModalView" [formGroup]="form">
	<div class="page-tile__panel">
		<button type="button" class="btn" (click)="backTo()">
			<i class="fa fa-chevron-left" aria-hidden="true"></i>
		</button>
		<div *ngxPermissionsOnly="[permissions.ManageCustomerSites]">
			<button
				type="button"
				class="btn"
				[title]="'dg-tile.save-btn_hint' | transloco"
				[disabled]="!(form.valid && form.dirty) || (isSaving$ | async) === true" (click)="onSubmit()">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': (isSaving$ | async) === false, 'fa-spinner fa-spin btn-spinner': (isSaving$ | async) === true}">
				</i>
			</button>
			<button
				*ngIf="isDiscard"
				type="button"
				class="btn"
				[disabled]="!form.dirty || (isSaving$ | async) === true"
				[title]="'portal-settings-invoicing.control-panel-discard-btn_hint' | transloco" (click)="discard()">
				<i class="fa fa-undo" aria-hidden="true"></i>
			</button>
		</div>
	</div>
	<div class="page-tile__body page-tile__body--hidden-none">

		<dg-tile-input *ngIf="!isCustomerSiteAdd" formControlName="siteCode" [id]="'txtSiteCode'" [label]="'customer-sites.details-fields_siteCode'"
			[isReadonly]="true" [name]="'siteCode'">
		</dg-tile-input>

		<dg-tile-input formControlName="name" [id]="'txtName'" [label]="'customer-sites.details-fields_name'"
			[isRequired]="true" [name]="'name'">
		</dg-tile-input>

		<dg-search-input formControlName="address1" [id]="'txtAddr1'"
			[label]="'customer-sites.details-fields_address1' | transloco" [name]="'addr1'" [refreshIcon]="selectedEntity.validated"
			[validateAddressFields]="addressData" (retrieveAddress)="onAddressData($event)"
			(validationSubmit)="onValidationSubmit()" (validationAnswer)="onValidationAnswer($event)">
		</dg-search-input>

		<dg-tile-input formControlName="address2" [id]="'txtAddr2'" [label]="'customer-sites.details-fields_address2'"
			[name]="'addr2'">
		</dg-tile-input>

		<dg-tile-input formControlName="city" [id]="'txtCity'" [label]="'customer-sites.details-fields_city'"
			[name]="'city'">
		</dg-tile-input>

		<dg-tile-input formControlName="state" [id]="'txtState'" [label]="'customer-sites.details-fields_state'"
					   [name]="'state'">
		</dg-tile-input>

		<dg-tile-input formControlName="postalCode" [id]="'txtPostalCode'"
			[isRequired]="isPostalRequired" [label]="'customer-sites.details-fields_postalCode'" [name]="'postalCode'">
		</dg-tile-input>

		<div class="page-tile__group">
			<label class="page-tile__group-label" for="txtCountry">
				{{'customer-sites.details-fields_country' | transloco}}
			</label>
			<ng-select labelForId="txtCountry" name="country" autocomplete="new-password" formControlName="country"
				[clearable]="false">
				<ng-option value="AF">Afghanistan</ng-option>
				<ng-option value="AL">Albania</ng-option>
				<ng-option value="DZ">Algeria</ng-option>
				<ng-option value="AD">Andorra</ng-option>
				<ng-option value="AO">Angola</ng-option>
				<ng-option value="AG">Antigua And Deps</ng-option>
				<ng-option value="AR">Argentina</ng-option>
				<ng-option value="AM">Armenia</ng-option>
				<ng-option value="AU">Australia</ng-option>
				<ng-option value="AT">Austria</ng-option>
				<ng-option value="AZ">Azerbaijan</ng-option>
				<ng-option value="BS">Bahamas</ng-option>
				<ng-option value="BH">Bahrain</ng-option>
				<ng-option value="BD">Bangladesh</ng-option>
				<ng-option value="BB">Barbados</ng-option>
				<ng-option value="BY">Belarus</ng-option>
				<ng-option value="BE">Belgium</ng-option>
				<ng-option value="BZ">Belize</ng-option>
				<ng-option value="BJ">Benin</ng-option>
				<ng-option value="BT">Bhutan</ng-option>
				<ng-option value="BO">Bolivia</ng-option>
				<ng-option value="BA">Bosnia Herzegovina</ng-option>
				<ng-option value="BW">Botswana</ng-option>
				<ng-option value="BR">Brazil</ng-option>
				<ng-option value="BN">Brunei</ng-option>
				<ng-option value="BG">Bulgaria</ng-option>
				<ng-option value="BF">Burkina</ng-option>
				<ng-option value="BI">Burundi</ng-option>
				<ng-option value="KH">Cambodia</ng-option>
				<ng-option value="CM">Cameroon</ng-option>
				<ng-option value="CA">Canada</ng-option>
				<ng-option value="CV">Cape Verde</ng-option>
				<ng-option value="CF">Central African Rep</ng-option>
				<ng-option value="TD">Chad</ng-option>
				<ng-option value="CL">Chile</ng-option>
				<ng-option value="CN">China</ng-option>
				<ng-option value="CO">Colombia</ng-option>
				<ng-option value="KM">Comoros</ng-option>
				<ng-option value="CG">Congo</ng-option>
				<ng-option value="CD">Congo (Democratic Rep)</ng-option>
				<ng-option value="CR">Costa Rica</ng-option>
				<ng-option value="HR">Croatia</ng-option>
				<ng-option value="CU">Cuba</ng-option>
				<ng-option value="CY">Cyprus</ng-option>
				<ng-option value="CZ">Czech Republic</ng-option>
				<ng-option value="DK">Denmark</ng-option>
				<ng-option value="DJ">Djibouti</ng-option>
				<ng-option value="DM">Dominica</ng-option>
				<ng-option value="DO">Dominican Republic</ng-option>
				<ng-option value="EC">Ecuador</ng-option>
				<ng-option value="EG">Egypt</ng-option>
				<ng-option value="SV">El Salvador</ng-option>
				<ng-option value="GQ">Equatorial Guinea</ng-option>
				<ng-option value="ER">Eritrea</ng-option>
				<ng-option value="EE">Estonia</ng-option>
				<ng-option value="ET">Ethiopia</ng-option>
				<ng-option value="FJ">Fiji</ng-option>
				<ng-option value="FI">Finland</ng-option>
				<ng-option value="FR">France</ng-option>
				<ng-option value="GA">Gabon</ng-option>
				<ng-option value="GM">Gambia</ng-option>
				<ng-option value="GE">Georgia</ng-option>
				<ng-option value="DE">Germany</ng-option>
				<ng-option value="GH">Ghana</ng-option>
				<ng-option value="GR">Greece</ng-option>
				<ng-option value="GD">Grenada</ng-option>
				<ng-option value="GT">Guatemala</ng-option>
				<ng-option value="GN">Guinea</ng-option>
				<ng-option value="GW">Guinea-Bissau</ng-option>
				<ng-option value="GY">Guyana</ng-option>
				<ng-option value="HT">Haiti</ng-option>
				<ng-option value="HN">Honduras</ng-option>
				<ng-option value="HU">Hungary</ng-option>
				<ng-option value="IS">Iceland</ng-option>
				<ng-option value="IN">India</ng-option>
				<ng-option value="ID">Indonesia</ng-option>
				<ng-option value="IR">Iran</ng-option>
				<ng-option value="IQ">Iraq</ng-option>
				<ng-option value="IE">Ireland (Republic)</ng-option>
				<ng-option value="IL">Israel</ng-option>
				<ng-option value="IT">Italy</ng-option>
				<ng-option value="JM">Jamaica</ng-option>
				<ng-option value="JP">Japan</ng-option>
				<ng-option value="JO">Jordan</ng-option>
				<ng-option value="KZ">Kazakhstan</ng-option>
				<ng-option value="KE">Kenya</ng-option>
				<ng-option value="KI">Kiribati</ng-option>
				<ng-option value="KP">Korea North</ng-option>
				<ng-option value="KR">Korea South</ng-option>
				<ng-option value="KW">Kuwait</ng-option>
				<ng-option value="KG">Kyrgyzstan</ng-option>
				<ng-option value="LA">Laos</ng-option>
				<ng-option value="LV">Latvia</ng-option>
				<ng-option value="LB">Lebanon</ng-option>
				<ng-option value="LS">Lesotho</ng-option>
				<ng-option value="LR">Liberia</ng-option>
				<ng-option value="LY">Libya</ng-option>
				<ng-option value="LI">Liechtenstein</ng-option>
				<ng-option value="LT">Lithuania</ng-option>
				<ng-option value="LU">Luxembourg</ng-option>
				<ng-option value="MK">Macedonia</ng-option>
				<ng-option value="MG">Madagascar</ng-option>
				<ng-option value="MW">Malawi</ng-option>
				<ng-option value="MY">Malaysia</ng-option>
				<ng-option value="MV">Maldives</ng-option>
				<ng-option value="ML">Mali</ng-option>
				<ng-option value="MT">Malta</ng-option>
				<ng-option value="MH">Marshall Islands</ng-option>
				<ng-option value="MR">Mauritania</ng-option>
				<ng-option value="MU">Mauritius</ng-option>
				<ng-option value="MX">Mexico</ng-option>
				<ng-option value="FM">Micronesia</ng-option>
				<ng-option value="MD">Moldova</ng-option>
				<ng-option value="MC">Monaco</ng-option>
				<ng-option value="MN">Mongolia</ng-option>
				<ng-option value="MA">Morocco</ng-option>
				<ng-option value="MZ">Mozambique</ng-option>
				<ng-option value="MM">Myanmar</ng-option>
				<ng-option value="NA">Namibia</ng-option>
				<ng-option value="NR">Nauru</ng-option>
				<ng-option value="NP">Nepal</ng-option>
				<ng-option value="NL">Netherlands</ng-option>
				<ng-option value="NZ">New Zealand</ng-option>
				<ng-option value="NI">Nicaragua</ng-option>
				<ng-option value="NE">Niger</ng-option>
				<ng-option value="NG">Nigeria</ng-option>
				<ng-option value="NO">Norway</ng-option>
				<ng-option value="OM">Oman</ng-option>
				<ng-option value="PK">Pakistan</ng-option>
				<ng-option value="PW">Palau</ng-option>
				<ng-option value="PA">Panama</ng-option>
				<ng-option value="PG">Papua New Guinea</ng-option>
				<ng-option value="PY">Paraguay</ng-option>
				<ng-option value="PE">Peru</ng-option>
				<ng-option value="PH">Philippines</ng-option>
				<ng-option value="PL">Poland</ng-option>
				<ng-option value="PT">Portugal</ng-option>
				<ng-option value="QA">Qatar</ng-option>
				<ng-option value="RO">Romania</ng-option>
				<ng-option value="RU">Russian Federation</ng-option>
				<ng-option value="RW">Rwanda</ng-option>
				<ng-option value="KN">St Kitts & Nevis</ng-option>
				<ng-option value="LC">St Lucia</ng-option>
				<ng-option value="VC">Saint Vincent and the Grenadines</ng-option>
				<ng-option value="WS">Samoa</ng-option>
				<ng-option value="SM">San Marino</ng-option>
				<ng-option value="ST">Sao Tome and Principe</ng-option>
				<ng-option value="SA">Saudi Arabia</ng-option>
				<ng-option value="SN">Senegal</ng-option>
				<ng-option value="CS">Serbia</ng-option>
				<ng-option value="SC">Seychelles</ng-option>
				<ng-option value="SL">Sierra Leone</ng-option>
				<ng-option value="SG">Singapore</ng-option>
				<ng-option value="SK">Slovakia</ng-option>
				<ng-option value="SI">Slovenia</ng-option>
				<ng-option value="SB">Solomon Islands</ng-option>
				<ng-option value="SO">Somalia</ng-option>
				<ng-option value="ZA">South Africa</ng-option>
				<ng-option value="ES">Spain</ng-option>
				<ng-option value="LK">Sri Lanka</ng-option>
				<ng-option value="SD">Sudan</ng-option>
				<ng-option value="SR">Suriname</ng-option>
				<ng-option value="SZ">Swaziland</ng-option>
				<ng-option value="SE">Sweden</ng-option>
				<ng-option value="CH">Switzerland</ng-option>
				<ng-option value="SY">Syria</ng-option>
				<ng-option value="TW">Taiwan</ng-option>
				<ng-option value="TJ">Tajikistan</ng-option>
				<ng-option value="TZ">Tanzania</ng-option>
				<ng-option value="TH">Thailand</ng-option>
				<ng-option value="TG">Togo</ng-option>
				<ng-option value="TO">Tonga</ng-option>
				<ng-option value="TT">Trinidad and Tobago</ng-option>
				<ng-option value="TN">Tunisia</ng-option>
				<ng-option value="TR">Turkey</ng-option>
				<ng-option value="TM">Turkmenistan</ng-option>
				<ng-option value="TV">Tuvalu</ng-option>
				<ng-option value="UG">Uganda</ng-option>
				<ng-option value="UA">Ukraine</ng-option>
				<ng-option value="AE">United Arab Emirates</ng-option>
				<ng-option value="GB">United Kingdom</ng-option>
				<ng-option value="US">United States</ng-option>
				<ng-option value="UY">Uruguay</ng-option>
				<ng-option value="UZ">Uzbekistan</ng-option>
				<ng-option value="VU">Vanuatu</ng-option>
				<ng-option value="VA">Vatican City</ng-option>
				<ng-option value="VE">Venezuela</ng-option>
				<ng-option value="VN">Vietnam</ng-option>
				<ng-option value="YE">Yemen</ng-option>
				<ng-option value="ZM">Zambia</ng-option>
				<ng-option value="ZW">Zimbabwe</ng-option>
			</ng-select>
		</div>

		<dg-tile-input
			formControlName="contactPhone"
			[id]="'txtPhone'"
			[label]="'customer-sites.details-fields_contactPhone'"
			[name]="'contactPhone'">
		</dg-tile-input>

		<dg-tile-input formControlName="referenceCode" [id]="'txtCode'" [label]="'customer-sites.details-fields_referenceCode'"
			[name]="'code'">
		</dg-tile-input>

		<dg-tile-input formControlName="externalReference" [id]="'txtCode'"
			[label]="'customer-sites.details-fields_externalReference'" [name]="'externalReference'">
		</dg-tile-input>

		<dg-tile-select *ngIf="isSureTax" formControlName="exemptionCodeId" [id]="'txtExCode'"
			[items]="exemptionCodes" [label]="'customer-sites.details-fields_exemptionCodeId'" [bindLabel]="'description'"
			[bindValue]="'id'" [name]="'exCode'" [isDescription]="true">
		</dg-tile-select>

		<fieldset *ngIf="isCSI">
			<dg-tile-input formControlName="csiTaxExemptionCode" [id]="'txtExemptCode'"
				[label]="'customer-sites.details-fields_csiTaxExemptionCode'" [name]="'csiTaxExemptionCode'">
			</dg-tile-input>
			<dg-tile-input formControlName="csiTaxExemptionList" [id]="'txtExemptList'"
				[label]="'customer-sites.details-fields_csiTaxExemptionList'" [name]="'csiTaxExemptionList'">
			</dg-tile-input>
		</fieldset>

		<dg-tile-select formControlName="rateCardId" [id]="'txtRateCardId'" [items]="ratings"
			[label]="'customer-sites.details-fields_rateCardId'" [bindLabel]="'name'" [bindValue]="'id'" [name]="'ratings'">
		</dg-tile-select>

		<div class="page-tile__group">
			<label class="page-tile__group-label">{{'customer-sites.details-fields_hideTaxes' | transloco}}
				<i class="fa fa-question-circle tooltip tooltip--tile tooltip--wise-1" aria-hidden="true">
					<span>
						{{'customer-sites.details-fields_hideTaxes-tooltip' | transloco}}
					</span>
				</i>
			</label>
			<div class="page-tile__group-input page-tile__group-input--border-none">
				<dg-checkbox name="hideTaxes" formControlName="hideTaxes" ngDefaultControl [id]="'hideTaxes'">
				</dg-checkbox>
			</div>
		</div>

		<div *ngIf="isAvalaraCommunication" class="page-tile__group">
			<label class="page-tile__group-label">{{'customer-sites.details-fields_incorporated' | transloco}}
				<i class="fa fa-question-circle tooltip tooltip--incorporated" aria-hidden="true">
					<span>{{'customer-sites.details-fields_incorporated-tooltip' | transloco }}
						<ul>
							<li>{{'customer-sites.details-fields_incorporated-tooltip-li1' | transloco }}</li>
							<li>{{'customer-sites.details-fields_incorporated-tooltip-li2' | transloco }}</li>
						</ul>
					</span>
				</i>
			</label>
			<div class="page-tile__group-input page-tile__group-input--border-none">
				<dg-checkbox name="isIncorporated" formControlName="incorporated" ngDefaultControl
					[id]="'isIncorporated'"></dg-checkbox>
			</div>
		</div>
	</div>

	<div *ngIf="config.portalSettings.showCustomerSiteExtraFields && extraFieldsLabels" class="page-tile__body page-tile__body--500">
		<div class="page-tile__title">
			{{'customer-product.additional-section_title' | transloco}}
		</div>
		<div>
			<fieldset>
				<dg-tile-input
					*ngFor="let field of [0,1,2,3,4]; let i = index" maxLength="800"
					[id]="'field' + (i+1)" [name]="'field' + (i+1)"
					[label]="extraFieldsLabels['extraField' + (i+1)]"
					formControlName="extraField{{i+1}}">
				</dg-tile-input>
			</fieldset>
		</div>
	</div>
</div>
