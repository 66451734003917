<div class="page-tile__group">
	<label class="page-tile__group-label" for="{{id}}">{{label | transloco}}
		<i *ngIf="isRequired" class="fa fa-asterisk" aria-hidden="true"></i>
		<div *ngIf="provider" class="wrapper tooltip tooltip--tile" (click)="onValidateClick()">
			<span>{{tooltip | transloco}}</span>
			<div class="wrapper">
				<img class="address-lookup-img" src="/assets/img/{{iconName}}"/>
			</div>
			<div *ngIf="loading" class="wrapper">
				<div class="loader"></div>
			</div>
		</div>
	</label>

	<input *ngIf="!isNumber" class="page-tile__group-input" id="{{id}}" name="{{name}}" [(ngModel)]="value"
		   (ngModelChange)="onChanged()" [readonly]="isReadonly" [required]="isRequired" [email]="isEmail"
		   (blur)="onTouched()" [disabled]="disabled" maxlength="{{maxLength}}" autocomplete="off" trim="blur"
		   (input)="onInput($event)" (focus)="show = true" (focusout)="onClickedOutside($event)">
	<div id="search-results" *ngIf="results !== null && show" >
		<div class="search-result" (mousedown)="onItemClick(item.recordId)" *ngFor="let item of results.data"
			 [ngClass]="{'selected': results.data[$any(item)] === item}" >
			{{item.addressLine}}
		</div>
	</div>

	<input *ngIf="isNumber" class="page-tile__group-input page-tile__group-input--number" id="{{id}}" name="{{name}}"
		   [(ngModel)]="value" (ngModelChange)="onChanged()" pattern="{{pattern}}" [readonly]="isReadonly"
		   [required]="isRequired" [disabled]="disabled" step="any"
		   min="0" type="number" (blur)="onTouched()" dgNumberTrim autocomplete="new-password"/>
</div>
