import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { XeroTenants } from 'domain/entities';
import { XeroData } from 'domain/models';
import { ApiData } from 'domain/types';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class XeroAuthService {
	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {}

	async getXeroAccounts(destroy$?: Subject<void>): Promise<XeroData[]> {
		const xeroAccountsRepsonse = await this.http.promise(destroy$).get<ApiData<XeroData[]>>(`${this.config.apiUrl}${this.config.apiVersion}/xero/accounts`);
		return xeroAccountsRepsonse.body?.data;
	}

	async getXeroPayments(destroy$?: Subject<void>): Promise<XeroData[]> {
		const xeroPaymentsResponse = await this.http.promise(destroy$).get<ApiData<XeroData[]>>(`${this.config.apiUrl}${this.config.apiVersion}/xero/payments`);
		return xeroPaymentsResponse.body?.data;
	}

	async getXeroAuthorization(destroy$?: Subject<void>): Promise<string> {
		const xeroAuthResponse = await this.http.promise(destroy$).get<ApiData<string>>(`${this.config.apiUrl}${this.config.apiVersion}/xero/connect`);
		return xeroAuthResponse.body?.data;
	}

	async getXeroTest(destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/xero/test`);
		return res.body;
	}

	async getXeroTenants(uniqueId: string, destroy$?: Subject<void>): Promise<XeroTenants[]> {
		const params = new HttpParams()
			.set('uniqueId', uniqueId.toString());

		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/xero/tenants`, { params });
		return res.body?.data?.map(x => new XeroTenants().deserialize(x));
	}

	async postSelectedTenant(uniqueId: string, tenantId: number, destroy$?: Subject<void>): Promise<void> {
		const requestOptions = { uniqueId, tenantId };

		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/xero/select-tenant`, requestOptions);
		return res.body;
	}
}
