import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Invoice } from 'domain/entities';
import { InvoiceStatus } from 'domain/enums';
import { InvoiceHelper } from 'tools/invoice-helper';

@Component({
	selector: 'dg-invoice-status-cell-renderer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<span [ngClass]="statusClass">{{statusLabel}}</span>
	`
})
export class InvoiceStatusCellRendererComponent implements ICellRendererAngularComp {
	params: ICellRendererParams<Invoice>;
	statusClass: string;
	statusLabel: string;

	constructor(private readonly _cdr: ChangeDetectorRef) {}

	agInit(params: ICellRendererParams<Invoice>): void {
		this.params = params;
		this.statusClass = this.statusToLabelClass(params.data.status);
		this.statusLabel = InvoiceHelper.mapInvoiceStatus(params.data.status);
		this._cdr.markForCheck();
	}

	refresh(params: ICellRendererParams<Invoice>): boolean {
		this.statusClass = this.statusToLabelClass(params.data.status);
		this.statusLabel = InvoiceHelper.mapInvoiceStatus(params.data.status);
		this._cdr.markForCheck();
		return false;
	}

	statusToLabelClass(status: InvoiceStatus): string {
		if (!status) return 'label';

		switch (status) {
			case InvoiceStatus.Generated:
				return 'label label--warning label--status';
			case InvoiceStatus.Paid:
			case InvoiceStatus.PaidManually:
				return 'label label--success-custom label--status';
			case InvoiceStatus.Sent:
			case InvoiceStatus.SentManually:
			case InvoiceStatus.PartiallyPaid:
			case InvoiceStatus.PartiallyPaidManually:
				return 'label label--success label--status';
			case InvoiceStatus.Failed:
			case InvoiceStatus.PaidFailed:
			case InvoiceStatus.SendFailed:
				return 'label label--error label--status';
			case InvoiceStatus.Draft:
				return 'label label--grey label--status';
			case InvoiceStatus.Generating:
				return 'label label--default label--status';
			default:
				return 'label';
		}
	}

}
