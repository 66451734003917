import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Moment from 'moment-timezone';
import { DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import { AppConfig } from 'core/app-config';
import { GenericEvent } from 'domain/types';

interface PickerSelected {
	startDate: Moment.Moment; // lib uses DayJs
	endDate: Moment.Moment; // lib uses DayJs
}

export interface DgRangeSelection {
	from: string;
	to: string;
}
@Component({
	selector: 'dg-smart-datepicker',
	templateUrl: './dg-smart-datepicker.component.html',
	styleUrls: ['./dg-smart-datepicker.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgSmartDatepickerComponent implements OnInit {
	@ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
	@Input() customContainerCssClass = '';
	@Input() isDisabled = false;
	@Input() preSelect = true;
	@Input() label?;

	selected: PickerSelected;
	selectedRange = '';
	dateFrom = '';
	dateTo = '';

	locale: LocaleConfig = {
		format: 'YYYY-MM-DD',
		displayFormat: 'YYYY-MM-DD'
	};

	billingFullDate: number[];
	@Output() rangeSelection = new EventEmitter<DgRangeSelection>();

	constructor(
		private readonly route: ActivatedRoute,
		private readonly cdr: ChangeDetectorRef,
		private readonly config: AppConfig
	) {
		this.dateFrom = this.route.snapshot.queryParams.dateFrom ?? '';
		this.dateTo = this.route.snapshot.queryParams.dateTo ?? '';

		this.cdr.markForCheck();
	}

	ngOnInit(): void {
		const currMonthDays = Moment.utc().daysInMonth();

		let bilDay = this.config.portalSettings.defaultBillingStartDay || 1;

		if (bilDay > currMonthDays) {
			bilDay = currMonthDays;
		}

		this.billingFullDate = [Moment.utc().year(), Moment.utc().month(), bilDay];

		if (this.preSelect) {
			if (!this.dateFrom && !this.dateTo) {
				//set current billingDate by default otherwise get from query params
				this.onDateChanged('billingDate');
			} else {
				this.selectedRange = this.dateFrom + ' - ' + this.dateTo;
				this.rangeSelection.emit({ from: this.dateFrom, to: this.dateTo });
			}
		}

		this.cdr.markForCheck();
	}

	openDatepicker(e: GenericEvent<HTMLSpanElement>): void {
		this.pickerDirective.open(e);

		this.cdr.markForCheck();
	}

	ngModelChange(selected: PickerSelected): void {
		if (selected.startDate && selected.endDate) {
			this.rangeSelection.emit({ from: selected.startDate.format('YYYY-MM-DD'), to: selected.endDate.format('YYYY-MM-DD') });
			this.selectedRange = `${selected.startDate.format('YYYY-MM-DD')} - ${selected.endDate.format('YYYY-MM-DD')}`;
		} else {
			this.selectedRange = `${this.dateFrom} - ${this.dateTo}`;
		}

		this.cdr.markForCheck();
	}

	public onDateChanged(value: string): void {
		if (value !== 'picker') {
			switch (value) {
				case 'billingDate':
					this.dateFrom = Moment.utc(this.billingFullDate).clone().startOf('date').format('YYYY-MM-DD');
					this.dateTo = Moment.utc(this.billingFullDate).clone().add(1, 'month').subtract(1, 'd')
						.set({
							hour: 23,
							minute: 59,
							second: 59
						})
						.format('YYYY-MM-DD');
					break;
				case 'currMonth':
					this.dateFrom = Moment.utc().startOf('month').format('YYYY-MM-DD');
					this.dateTo = Moment.utc().endOf('month').format('YYYY-MM-DD');
					break;
				case 'prevMonth':
					this.dateFrom = Moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
					this.dateTo = Moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
					break;
				case 'last7':
					this.dateFrom = Moment.utc().subtract(7, 'd').format('YYYY-MM-DD');
					this.dateTo = Moment.utc().format('YYYY-MM-DD');
					break;
				case 'last30':
					this.dateFrom = Moment.utc().subtract(30, 'd').format('YYYY-MM-DD');
					this.dateTo = Moment.utc().format('YYYY-MM-DD');
			}

			this.selectedRange = this.dateFrom + ' - ' + this.dateTo;
			this.cdr.markForCheck();
			
			this.rangeSelection.emit({ from: this.dateFrom, to: this.dateTo });
		}

		this.cdr.markForCheck();
	}

	public dateLabels(type: string): string {
		switch (type) {
			case 'currentMYLabel':
				return Moment.utc().format('MMM YYYY');
			case 'previousMYLabel':
				return Moment.utc().subtract(1, 'months').endOf('month').format('MMM YYYY');
			case 'currentDLabel':
				return Moment.utc().format('DD');
			case 'currentMDLabel':
				return Moment.utc().format('MMM DD');
			case 'last7MDLabel':
				return Moment.utc().subtract(7, 'd').format('MMM DD');
			case 'last30MDLabel':
				return Moment.utc().subtract(30, 'd').format('MMM DD');
			case 'billingStartLabel':
				return Moment.utc(this.billingFullDate).format('MMM DD');
			case 'billingEndLabel':
				return Moment.utc(this.billingFullDate).clone().add(1, 'month').subtract(1, 'd').format('MMM DD');
		}
	}

	public resetPicker(): void {
		this.selected = undefined;
		this.selectedRange = '';
		this.dateFrom = '';
		this.dateTo = '';

		this.cdr.markForCheck();
	}
}
