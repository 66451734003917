import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';

export interface RecordStatus {
	entityType: string;
	entityName: string;
	customerName: string;
	agreementName: string;
	periodLabel: string;
	priceBook: string;
	rateCard: string;
	serviceItem: string;
	productName: string;
	usedInCW: boolean;
}

export interface InputEntityRecord {
	[key: string]: [RecordStatus];
}

interface ExtArray<T> extends Array<T> {
	show: number;
	entityName: string;
}

@Component({
	selector: 'dg-delete-entity',
	templateUrl: './dg-delete-entity.component.html',
	styleUrls: ['./dg-delete-entity.component.sass']
})
export class DgDeleteEntityComponent implements OnChanges{
	@Input() open = true;
	@Input() entityType: string;
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('records') inputRecords: InputEntityRecord;
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close = new EventEmitter<void>();
	records: ExtArray<RecordStatus>[];
	recordsLables: string[];
	show = 7;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	constructor(public readonly transloco: TranslocoService) {
		this.t = transloco.translate.bind(transloco);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputRecords.currentValue) {
			this.records = Object.keys(changes.inputRecords.currentValue).map(key => {
				const currentValue = changes.inputRecords.currentValue[key] as ExtArray<RecordStatus>;
				currentValue.show = this.show;
				currentValue.entityName = key;
				return currentValue;
			});
		}
	}

	public onClose(): void {
		this.close.emit();
	}

	get recordsLength(): number {
		return this.records.length;
	}

	get recordTitle(): string {
		return this.recordsLength === 1 ? this.t('dg-delete-entity.record_title') : this.t('dg-delete-entity.records_title');
	}

	get recordMsg(): string {
		return this.recordsLength === 1 ? this.t('dg-delete-entity.record_msg') : this.t('dg-delete-entity.records_msg');
	}

	onMore(items: ExtArray<RecordStatus>): void {
		items.show = items.length;
	}
}
