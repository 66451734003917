import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IntegrationSettings } from '../domain/entities';
import { map } from 'rxjs/operators';
import { BaseRepository } from 'repositories';
import { HttpAuth } from 'core/auth';
import { AppConfig } from 'core/app-config';
import { ApiData } from 'domain/types';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class IntegrationSettingsRepository extends BaseRepository {
	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	updateIntegrationSettings$(): Observable<void> {
		const observable =
			this.http.get<ApiData<IntegrationSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings`).pipe(
				map(res => {
					const settings = new IntegrationSettings().deserialize(res.body.data);
					this.config.setIntegrationSettings(settings);
				})
			);

		return observable;
	}

	async getSettings(destroy$?: Subject<void>): Promise<IntegrationSettings> {
		const res = await this.http.promise(destroy$).get<ApiData<IntegrationSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings`);
		const settings = res.body?.data ? new IntegrationSettings().deserialize(res.body.data) : new IntegrationSettings();

		return settings;
	}

	async updateIntegrationSettings(destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).get<ApiData<IntegrationSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/settings`);
		const settings = res.body?.data ? new IntegrationSettings().deserialize(res.body.data) : new IntegrationSettings();
		this.config.setIntegrationSettings(settings);
	}
}
