import { Routes } from '@angular/router';
import * as Resolvers from 'resolvers/Resolvers';

import { MainLayoutComponent } from 'layout/main-layout/main-layout.component';
import { InvoiceExportComponent } from './global/invoice-export/invoice-export.component';
import { InvoiceExportMultilineComponent } from './global/invoice-export-multiline/invoice-export-multiline.component';
import { QbInvoiceExportMultilineComponent } from './global/qb-invoice-export-multiline/qb-invoice-export-multiline.component';
import { DgJobProgressPageComponent } from 'shared/components';
import { XeroTenantsComponent } from './global/xero-tenants/xero-tenants.component';
import { XeroExportComponent } from './global/xero-export/xero-export.component';
import { CwInvoiceNumberComponent } from './global/cw-invoice-number/cw-invoice-number.component';
import { AutotaskExportComponent } from './global/autotask-export/autotask-export.component';
import { DgInvoiceNumberComponent } from './global/at-invoice-number/at-invoice-number.component';
import { QbDesktopExportComponent } from './global/qb-desktop-export/qb-desktop-export.component';
import { CwReturnTaxesComponent } from './global/cw-return-taxes/cw-return-taxes.component';
import { HaloExportComponent } from './global/halo-export/halo-export.component';
import { CreditNoteComponent } from './global/credit-note/credit-note.component';
import { authGuard } from 'core/auth/auth.guard';
import { HaloChargesExportComponent } from './global/halo-charges-export/halo-charges-export.component';
import { permissions } from 'tools/permissions';
import { NgxPermissionsGuard } from 'ngx-permissions';

export const DgPagesRouting: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./+login/login.module').then(m => m.LoginModule)
	},
	{ path: 'xero/authentication/:uniqueId', component: XeroTenantsComponent },
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{
		path: '',
		canMatch: [authGuard],
		component: MainLayoutComponent,
		children: [
			{
				path: 'dashboard',
				data: { breadcrumb: 'layout-header.breadcrumbs_dashboard' },
				loadChildren: () => import('./+dashboard/dashboard.module').then(m => m.DashboardModule)
			}, {
				path: 'customers',
				data: { breadcrumb: 'layout-header.breadcrumbs_customers' },
				loadChildren: () => import('./+customers/customers.module').then(m => m.CustomersModule)
			}, {
				path: 'product-templates',
				data: { breadcrumb: 'layout-header.breadcrumbs_product-templates' },
				loadChildren: () => import('./+product-templates/product-templates.module').then(m => m.ProductTemplatesModule)
			}, {
				path: 'product-kits',
				data: { breadcrumb: 'layout-header.breadcrumbs_product-kit-templates' },
				loadChildren: () => import('./+product-kits/product-kits.module').then(m => m.ProductKitsModule)
			}, {
				path: 'service-items',
				data: { breadcrumb: 'layout-header.breadcrumbs_service-items' },
				loadChildren: () => import('./global/+service-items/service-items.module').then(m => m.ServiceItemsModule)
			}, {
				path: 'external-quotes',
				data: { breadcrumb: 'layout-header.breadcrumbs_external-quotes' },
				loadChildren: () => import('./global/+external-quotes/external-quotes.module').then(m => m.ExternalQuotesModule)
			}, {
				path: 'customer-portal',
				data: { breadcrumb: 'layout-header.breadcrumbs_customer-portal' },
				loadChildren: () => import('./global/+customer-portal/customer-portal.module').then(m => m.CustomerPortalModule)
			}, {
				path: 'settings',
				data: { breadcrumb: 'layout-header.breadcrumbs_settings',
					permissions: {
						only: [permissions.ManageSettings],
						redirectTo: '/dashboard'
					}
				},
				canActivate: [NgxPermissionsGuard],
				loadChildren: () => import('./+portal-settings/portal-settings.module').then(m => m.PortalSettingsModule)
			}, {
				path: 'files',
				data: { breadcrumb: 'layout-header.breadcrumbs_files' },
				loadChildren: () => import('./+files/files-page/files.module').then(m => m.FilesModule)
			}, {
				path: 'import',
				data: { breadcrumb: 'layout-header.breadcrumbs_import' },
				loadChildren: () => import('./global/+import-page/import.module').then(m => m.ImportModule)
			}, {
				path: 'rating',
				data: { breadcrumb: 'layout-header.breadcrumbs_rating' },
				loadChildren: () => import('./+rating/rating.module').then(m => m.RatingModule)
			}, {
				path: 'bundles',
				data: { breadcrumb: 'layout-header.breadcrumbs_bundles' },
				loadChildren: () => import('./global/+bundles/bundles.module').then(m => m.BundlesModule)
			}, {
				path: 'bulk-update',
				data: { breadcrumb: 'layout-header.breadcrumbs_bulk-update' },
				loadChildren: () => import('./global/+bulk-update/bulk-update.module').then(m => m.BulkUpdateModule)
			}, {
				path: 'reconciliation',
				data: { breadcrumb: 'layout-header.breadcrumbs_reconciliation' },
				loadChildren: () => import('./global/+reconciliation/reconciliation.module').then(m => m.ReconciliationModule)
			}, {
				path: '',
				loadChildren: () => import('./+auto-billing/auto-billing.module').then(m => m.AutoBillingModule)
			}, {
				path: '',
				loadChildren: () => import('./+invoicing/invoicing.module').then(m => m.InvoicingModule)
			}, {
				path: '',
				loadChildren: () => import('./+ipnd-files/ipnd-files.module').then(m => m.IpndFilesModule)
			}, {
				path: '',
				loadChildren: () => import('./global/+reseller-users/reseller-users.module').then(m => m.ResellerUsersModule)
			}, {
				path: '',
				loadChildren: () => import('./global/+feeds/feeds.module').then(m => m.FeedsModule)
			}, {
				path: '',
				data: { breadcrumb: 'layout-header.breadcrumbs_standard-reports' },
				loadChildren: () => import('./+reports/reports.module').then(m => m.ReportsModule)
			}, {
				path: '',
				loadChildren: () => import('./+reports-custom/custom-reports.module').then(m => m.CustomReportsModule)
			}, {
				path: 'invoice-export',
				data: { breadcrumb: 'layout-header.breadcrumbs_invoice-exporting' },
				component: InvoiceExportComponent,
				resolve: {
					agreementTypes: Resolvers.AgreementTypesResolver, wizardSettings: Resolvers.AgreementWizardSettingsResolver,
					vendors: Resolvers.VendorsResolver
				}
			}, {
				path: 'invoice-export-multiline',
				data: { breadcrumb: 'layout-header.breadcrumbs_invoice-exporting-multiline' },
				component: InvoiceExportMultilineComponent,
				resolve: {
					wizardSettings: Resolvers.MultilineWizardSettingsResolver,
					vendors: Resolvers.VendorsResolver
				}
			}, {
				path: 'qb-invoice-export-multiline',
				data: { breadcrumb: 'layout-header.breadcrumbs_qb-invoice-exporting-multiline' },
				component: QbInvoiceExportMultilineComponent,
			},
			{
				path: 'qbd-invoice-export',
				data: { breadcrumb: 'layout-header.breadcrumbs_qbd-invoice-exporting-multiline' },
				component: QbDesktopExportComponent
			},
			{
				path: 'cw-invoice-sync',
				data: { breadcrumb: 'layout-header.breadcrumbs_connectWise-invoice-number-sync' },
				component: CwInvoiceNumberComponent
			},
			{
				path: 'cw-return-taxes',
				data: { breadcrumb: 'layout-header.breadcrumbs_connectWise-return-taxes' },
				component: CwReturnTaxesComponent
			},
			{
				path: 'at-invoice-sync',
				data: { breadcrumb: 'layout-header.breadcrumbs_autotask-invoice-number-sync' },
				component: DgInvoiceNumberComponent
			},
			{
				path: 'xero-export-multiline',
				data: { breadcrumb: 'layout-header.breadcrumbs_xero-invoice-exporting-multiline' },
				component: XeroExportComponent
			},
			{
				path: 'autotask-export-multiline',
				data: { breadcrumb: 'layout-header.breadcrumbs_autotask-invoice_exporting' },
				component: AutotaskExportComponent
			},
			{
				path: 'halo-export-multiline',
				data: { breadcrumb: 'layout-header.breadcrumbs_halo-invoice_exporting' },
				component: HaloExportComponent
			},
			{
				path: 'halo-export-charges',
				data: { breadcrumb: 'layout-header.breadcrumbs_halo-charges_exporting' },
				component: HaloChargesExportComponent
			},
			{
				path: 'credit-note',
				data: { breadcrumb: 'layout-header.breadcrumbs_credit-note-generate' },
				component: CreditNoteComponent
			},
			{ path: 'operations/:operationId', component: DgJobProgressPageComponent },
		],
	},

	{ path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];
