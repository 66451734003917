import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { Job } from 'domain/entities';
import * as Moment from 'moment-timezone';
import { ToasterService } from 'core/toaster-service';
import { ExtraFieldRepository, JobRepository } from 'repositories';
import { CacheService } from 'services/cache.service';
import { OperationService } from '../operation-service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'dg-operation-progress',
	templateUrl: './operation-progress-page.component.html',
	styleUrls: ['./operation-progress-page.component.sass']
})
export class OperationProgressPageComponent implements OnInit, OnDestroy {
	public job: Job;
	public jobStarted: string;
	public operationId: number;
	public percentDone: number;
	public prevPercentDone: number;
	public interval: number;

	timeoutId: NodeJS.Timeout;
	redirectUrl: string;
	numberItemsCompleted1: number;
	numberItemsCompleted2: number;
	numberItemsCompleted3: number;
	numberItemsCompleted4: number;
	numberItemsCompleted5: number;
	messageItemsCompleted1: string;
	messageItemsCompleted2: string;
	messageItemsCompleted3: string;
	messageItemsCompleted4: string;
	messageItemsCompleted5: string;
	serviceItemCustomerId: string;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	private destroy$ = new Subject<void>();

	get jobTitle(): string {
		return this.job?.jobType ? this.t(`operation-progress-page.jobTitle-${this.job.jobType}`) : '';
	}

	constructor(
		private readonly route: ActivatedRoute,
		private readonly jobRepository: JobRepository,
		private readonly extraFieldRepository: ExtraFieldRepository,
		private readonly operationService: OperationService,
		private readonly cache: CacheService,
		private readonly toasterService: ToasterService,
		readonly transloco: TranslocoService
	) {
		this.t = transloco.translate.bind(transloco);
		this.operationId = Number(route.snapshot.params.operationId);

		this.operationService.activeOperation$
			.pipe(takeUntil(this.destroy$))
			.subscribe(id => {
				if (this.operationId === id) {
					if (!this.interval) {
						this.interval = 1000;
					}
					if (!this.job.isCompleted) {
						this.timeoutId = setTimeout(async () => {
							const job = await this.jobRepository.get(this.operationId, this.destroy$);

							this.percentDone = job.percentComplete;
							if (this.percentDone !== this.prevPercentDone && this.prevPercentDone === 0) {
								this.interval = 1000;
								this.prevPercentDone = this.percentDone;
							} else if (this.percentDone === this.prevPercentDone) {
								if (this.interval * 1.5 > 30000) {
									this.interval = 30000;
								} else {
									this.interval = this.interval * 1.5;
									this.prevPercentDone = this.percentDone;
								}
							}
							this.job = job;
							this.operationService.changeActiveOperation(this.operationId);
						}, this.interval);
					} else {
						this.updateStats(this.job.jobType);
						if (this.job.jobType === 14) {
							this.refreshCustomers();
							this.resetBundles();
							this.refreshAfterServiceItemCreated();
							this.cache.set('needToResetAgreements', true);
							this.cache.set('rating-needsRefresh', true);
							this.cache.set('gl-codes-needsRefresh', true);
							this.cache.removeKey('taxRates-needsRefresh');
						}
						if (
							this.job.jobType === 15 ||
							this.job.jobType === 23 ||
							this.job.jobType === 29 ||
							this.job.jobType === 30 ||
							this.job.jobType === 33 ||
							this.job.jobType === 38 ||
							this.job.jobType === 39
						) {
							this.refreshCustomers();
							this.cache.set('payment-terms-refresh', true);
							this.cache.set('needToResetAgreements', true);
						}
						if (this.job.jobType === 3 ||
							this.job.jobType === 4 ||
							this.job.jobType === 7 ||
							this.job.jobType === 8 ||
							this.job.jobType === 10) {
						}
						if (this.job.jobType === 26) {
							this.cache.set('rating-needsRefresh', true);
						}

						if (this.job.jobType === 2) {
							this.cache.set('needToResetAgreements', true);
							this.cache.set('invoices-generated', true);
						}
					}
				}
			});
	}

	async ngOnInit(): Promise<void> {
		this.job = await this.jobRepository.get(this.operationId, this.destroy$).withBusy();

		if (!this.job.isCompleted) {
			this.prevPercentDone = 0;
			this.operationService.changeActiveOperation(this.operationId);
		} else {
			this.updateStats(this.job.jobType);

			if (this.job.jobType === 14) {
				this.refreshCustomers();
				this.resetBundles();
				this.refreshAfterServiceItemCreated();
				this.cache.set('needToResetAgreements', true);
				this.cache.set('rating-needsRefresh', true);
				this.cache.set('gl-codes-needsRefresh', true);
				this.cache.removeKey('taxRates-needsRefresh');
			}
			if (this.job.jobType === 15 ||
				this.job.jobType === 23 ||
				this.job.jobType === 29 ||
				this.job.jobType === 30 ||
				this.job.jobType === 33 ||
				this.job.jobType === 38 ||
				this.job.jobType === 39) {
				this.refreshCustomers();
				this.cache.set('payment-terms-refresh', true);
				this.cache.set('needToResetAgreements', true);
			}
			if (this.job.jobType === 3 ||
				this.job.jobType === 4 ||
				this.job.jobType === 7 ||
				this.job.jobType === 8 ||
				this.job.jobType === 10) {
			}
			if (this.job.jobType === 26) {
				this.cache.set('rating-needsRefresh', true);
			}
			if (this.job.jobType === 2) {
				this.cache.set('needToResetAgreements', true);
				this.cache.set('invoices-generated', true);
			}
		}

		setInterval(() => this.jobStarted = Moment(this.job.dateStarted).from(Moment()), 1000);

		if (this.job.tag?.url) {
			this.redirectUrl = this.job.tag.url;
			if (this.job.jobType !== 14 && this.redirectUrl !== '/customers') {
				this.serviceItemCustomerId = this.redirectUrl.split('/')[2];
			}
		}

		if (
			this.job.jobType === 15 ||
			this.job.jobType === 23 ||
			this.job.jobType === 29 ||
			this.job.jobType === 33 ||
			this.job.jobType === 38 ||
			this.job.jobType === 39) {
			this.redirectUrl = '/customers';
		}

	}
	ngOnDestroy(): void {
		clearTimeout(this.timeoutId);
		
		this.destroy$.next();
		this.destroy$.complete();
	}

	private refreshCustomers(): void {
		this.refreshExtraFields();
	}

	private refreshExtraFields(): void {
		this.extraFieldRepository.reset();
	}

	private resetBundles(): void {
		this.cache.removeKey('bundles');
	}

	private refreshAfterServiceItemCreated(): void {
		this.redirectUrl = this.job.tag.url;
		this.serviceItemCustomerId = this.redirectUrl.split('/')[2];
		this.cache.set('serviceItems-' + this.serviceItemCustomerId + '-needsRefresh', 'true');
		this.cache.set('serviceItems-needsRefresh', true);
		this.cache.set('sites-' + this.serviceItemCustomerId + '-needsRefresh', true);
	}

	private updateStats(jobType: number): void {
		this.numberItemsCompleted1 = this.job.stats?.value1 ? this.job.stats.value1 : 0;
		this.numberItemsCompleted2 = this.job.stats?.value2 ? this.job.stats.value2 : 0;

		if (this.job.stats
			&& (jobType === 2 ||
				jobType === 3 ||
				jobType === 10 ||
				jobType === 15 ||
				jobType === 5 ||
				jobType === 16 ||
				jobType === 26 ||
				jobType === 20 ||
				jobType === 22 ||
				jobType === 23 ||
				jobType === 28 ||
				jobType === 27 ||
				jobType === 12 ||
				jobType === 29 ||
				jobType === 30 ||
				jobType === 25 ||
				jobType === 32 ||
				jobType === 33 ||
				jobType === 34 ||
				jobType === 35 ||
				jobType === 36 ||
				jobType === 37 ||
				jobType === 38 ||
				jobType === 39 ||
				jobType === 49 ||
				jobType === 55 ||
				jobType === 56 ||
				jobType === 58 ||
				jobType === 4 ||
				(jobType === 14 && (
					this.job.tag.url === '/dashboard' ||
					this.job.tag.url === '/service-items' ||
					this.job.tag.url === '/bundles' ||
					this.job.tag.url === '/rating' ||
					this.job.tag.url === '/product-templates' ||
					this.job.tag.url === '/customers' ||
					this.job.tag.url === '/import')))) {

			if (jobType === 15 ||
				jobType === 23 ||
				jobType === 30 ||
				jobType === 33 ||
				jobType === 38 ||
				jobType === 39) {
				this.numberItemsCompleted4 = this.job.stats.value4 ? this.job.stats.value4 : 0;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_added');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_updated');
				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_deleted');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (jobType === 2 || jobType === 5 || jobType === 10 || jobType === 56 || jobType === 58) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.numberItemsCompleted3 = this.job.stats?.value3 ? this.job.stats.value3 : 0;
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_generated-invoice') + this.pluralize(this.numberItemsCompleted1);
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_failed-invoice') + this.pluralize(this.numberItemsCompleted2);
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_suppress') + this.pluralize(this.numberItemsCompleted3);
			} else if (jobType === 3) {
				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted3);
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_record') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_deleted');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');

			} else if (jobType === 16) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_exported');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_duplicated');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
			} else if (jobType === 14) {
				let importItemName = this.job.tag.url === '/service-items' ? this.t('operation-progress-page.stats-label_si') : this.t('operation-progress-page.stats-label_record');
				if (this.job.tag.url === '/product-templates') {
					importItemName = this.t('operation-progress-page.stats-label_pt');
				}
				if (this.job.tag.url === '/customers') {
					importItemName = this.t('operation-progress-page.stats-label_customer');
				}
				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.numberItemsCompleted4 = this.job.stats.value4 ? this.job.stats.value4 : 0;
				this.messageItemsCompleted1 = importItemName + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_added');
				this.messageItemsCompleted2 = importItemName + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_updated');
				this.messageItemsCompleted3 = importItemName + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_ignored');
				this.messageItemsCompleted4 = importItemName + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (jobType === 26) {
				this.numberItemsCompleted2 = null;
				this.numberItemsCompleted4 = this.job.stats.value4 ? this.job.stats.value4 : 0;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_record') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_cloned');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_record') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (jobType === 20) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_agreement') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_agreement') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_addition') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (
				jobType === 12 ||
				jobType === 22 ||
				jobType === 25 ||
				jobType === 34 ||
				jobType === 36 ||
				jobType === 37 ||
				jobType === 49 ||
				jobType === 55) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
			} else if (jobType === 28) {
				this.numberItemsCompleted5 = this.job.messages.length;
				this.numberItemsCompleted3 = this.job.stats?.value3 ?? 0;
				this.numberItemsCompleted4 = this.job.stats?.value4 ?? 0;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_payment') + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_payment') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted5 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted5);
			} else if (jobType === 27) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_charge') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_charge') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
			} else if (jobType === 32 || jobType === 35) {
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_sync-success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_sync-failed');
			} else if (jobType === 4) {
				const importItemName = this.t('operation-progress-page.stats-label_invoice');

				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.messageItemsCompleted1 = importItemName + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = importItemName + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted3 = importItemName + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_ignored');
			}
		} else if (jobType === 30 && this.job.isFailed) {
			this.toasterService.warning(this.job.messages[0].message);
		} else if (jobType === 9 && this.job.isFailed) {
			this.numberItemsCompleted4 = this.job.messages.length;
		}
	}

	pluralize(index: number): string {
		return index !== 1 ? 's' : '';
	}
}
