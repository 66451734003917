import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { ToasterService } from 'core/toaster-service';
import { Job } from 'domain/entities';
import { JobType } from 'domain/enums';
import * as Moment from 'moment-timezone';
import { JobRepository } from 'repositories';
import { concatMap, Subject, takeUntil, takeWhile, timer } from 'rxjs';
import { CacheService } from 'services/cache.service';

const POLL_INTERVAL = 2000;

@Component({
	selector: 'dg-job-progress-page',
	templateUrl: './dg-job-progress-page.component.html',
	styleUrls: ['./dg-job-progress-page.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgJobProgressPageComponent implements OnInit, OnDestroy {
	job: Job;
	jobStarted: string;
	operationId = Number(this.route.snapshot.params.operationId);

	redirectUrl: string;

	numberItemsCompleted1: number;
	numberItemsCompleted2: number;
	numberItemsCompleted3: number;
	numberItemsCompleted4: number;
	numberItemsCompleted5: number;
	messageItemsCompleted1: string;
	messageItemsCompleted2: string;
	messageItemsCompleted3: string;
	messageItemsCompleted4: string;
	messageItemsCompleted5: string;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	JobType = JobType;

	private destroy$ = new Subject<void>();
	get jobTitle(): string {
		return this.job?.jobType ? this.t(`operation-progress-page.jobTitle-${this.job.jobType}`) : '';
	}

	get percentComplete(): number {
		return (this.job?.isCompleted || this.job?.isFailed || this.job?.isSucceded) ? 100 : this.job?.percentComplete ?? undefined;
	}

	constructor(
		private readonly route: ActivatedRoute,
		private readonly jobRepository: JobRepository,
		private readonly cache: CacheService,
		private readonly cdr: ChangeDetectorRef,
		private readonly toasterService: ToasterService,
		readonly transloco: TranslocoService
	) {
		this.t = transloco.translate.bind(transloco);

		timer(0, POLL_INTERVAL).pipe(
			takeUntil(this.destroy$),
			concatMap(() => this.jobRepository.get(this.operationId, this.destroy$)),
			takeWhile(job => !job.isCompleted, true)
		).subscribe(job => {
			this.job = job;

			if (this.job.dateStarted) {
				this.jobStarted = Moment(this.job.dateStarted).from(Moment());
			}

			if (job.isCompleted) {
				this.updateStats(job.jobType);

				if (job.jobType === JobType.InvoicesGenerate) {
					this.cache.set('invoices-generated', true);
				}
			}

			if ((job.jobType !== JobType.InvoicesZip) && (job.tag?.url)) {
				this.redirectUrl = this.job.tag.url;

				if (job.tag.url.indexOf('?') > -1) {
					this.redirectUrl = this.job.tag.url.split('?')[0]; //remove query params
				}
			} else {
				switch (job.jobType) {
					case JobType.CwCustomersSync:
					case JobType.QbCustomersSync:
					case JobType.ResendingEmails:
					case JobType.AtCustomersSync:
					case JobType.KaseyaCustomersSync:
					case JobType.HaloCustomersSync:
						this.redirectUrl = '/customers';
						break;
					case JobType.InvoicesZip:
					case JobType.ExportTransactions:
					case JobType.ExportCustomers:
					case JobType.ExportInvoicesMultiline:
					case JobType.ExportRateCard:
						this.redirectUrl = '/files';
						break;
					case JobType.GenerateTransactions:
						this.redirectUrl = '/transactions';
						break;
					case JobType.GenerateStandardReport:
						this.redirectUrl = '/standard-reports/results';
						break;
					default:
						this.redirectUrl = '/dashboard';
				}
			}

			this.cdr.markForCheck();
		});
	}

	async ngOnInit(): Promise<void> {
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private updateStats(jobType: number): void {
		this.numberItemsCompleted1 = this.job.stats?.value1 ? this.job.stats.value1 : 0;
		this.numberItemsCompleted2 = this.job.stats?.value2 ? this.job.stats.value2 : 0;

		if (this.job.stats
			&& (jobType === JobType.InvoicesGenerate ||
				jobType === JobType.InvoicesDelete ||
				jobType === JobType.InvoiceCredit ||
				jobType === JobType.CwCustomersSync ||
				jobType === JobType.InvoicesReGenerate ||
				jobType === JobType.CwInvoicesExport ||
				jobType === JobType.CloneRateCard ||
				jobType === JobType.CwAdditionsExport ||
				jobType === JobType.CwExportInvoicesMultiline ||
				jobType === JobType.QbCustomersSync ||
				jobType === JobType.InvoiceExportXeroMultiline ||
				jobType === JobType.ChargeInvoices ||
				jobType === JobType.InvoiceExportXero ||
				jobType === JobType.ResendingEmails ||
				jobType === JobType.XeroCustomersSync ||
				jobType === JobType.QbExportInvoicesMultiline ||
				jobType === JobType.CwInvoiceNumberSync ||
				jobType === JobType.AtCustomersSync ||
				jobType === JobType.AtExportInvoiceMultiline ||
				jobType === JobType.AtInvoiceNumberSync ||
				jobType === JobType.QbdExportInvoices ||
				jobType === JobType.CwReturnTaxes ||
				jobType === JobType.KaseyaCustomersSync ||
				jobType === JobType.HaloCustomersSync ||
				jobType === JobType.HaloExportInvoiceMultiline ||
				jobType === JobType.HaloExportCharges ||
				jobType === JobType.ReceiptPayment ||
				jobType === JobType.GenerateStandardReport ||
				jobType === JobType.InvoicesSend ||
				(jobType === JobType.ImportFromCsv && (
					this.job.tag.url === '/dashboard' ||
					this.job.tag.url === '/service-items' ||
					this.job.tag.url === '/bundles' ||
					this.job.tag.url === '/rating' ||
					this.job.tag.url === '/product-templates' ||
					this.job.tag.url === '/customers' ||
					this.job.tag.url === '/import')))) {

			if (jobType === JobType.CwCustomersSync ||
				jobType === JobType.QbCustomersSync ||
				jobType === JobType.XeroCustomersSync ||
				jobType === JobType.AtCustomersSync ||
				jobType === JobType.KaseyaCustomersSync ||
				jobType === JobType.HaloCustomersSync) {
				this.numberItemsCompleted4 = this.job.stats.value4 ? this.job.stats.value4 : 0;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_added');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_updated');
				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_deleted');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_customer') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (
				jobType === JobType.InvoicesGenerate ||
				jobType === JobType.InvoicesReGenerate ||
				jobType === JobType.InvoiceCredit ||
				jobType === JobType.ReceiptPayment ||
				jobType === JobType.GenerateStandardReport) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.numberItemsCompleted3 = this.job.stats?.value3 ? this.job.stats.value3 : 0;
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_generated-invoice') + this.pluralize(this.numberItemsCompleted1);
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_failed-invoice') + this.pluralize(this.numberItemsCompleted2);
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_suppress') + this.pluralize(this.numberItemsCompleted3);
			} else if (jobType === JobType.InvoicesDelete) {
				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted3);
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_record') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_deleted');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');

			} else if (jobType === JobType.CwInvoicesExport) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_exported');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_duplicated');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
			} else if (jobType === JobType.ImportFromCsv) {
				let importItemName = this.job.tag.url === '/service-items' ? this.t('operation-progress-page.stats-label_si') : this.t('operation-progress-page.stats-label_record');
				if (this.job.tag.url === '/product-templates') {
					importItemName = this.t('operation-progress-page.stats-label_pt');
				}
				if (this.job.tag.url === '/customers') {
					importItemName = this.t('operation-progress-page.stats-label_customer');
				}
				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.numberItemsCompleted4 = this.job.stats.value4 ? this.job.stats.value4 : 0;
				this.messageItemsCompleted1 = importItemName + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_added');
				this.messageItemsCompleted2 = importItemName + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_updated');
				this.messageItemsCompleted3 = importItemName + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_ignored');
				this.messageItemsCompleted4 = importItemName + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (jobType === JobType.CloneRateCard) {
				this.numberItemsCompleted2 = null;
				this.numberItemsCompleted4 = this.job.stats.value4 ? this.job.stats.value4 : 0;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_record') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_cloned');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_record') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (jobType === JobType.CwAdditionsExport) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_agreement') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_agreement') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_addition') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
			} else if (
				jobType === JobType.InvoiceExportXero ||
				jobType === JobType.CwExportInvoicesMultiline ||
				jobType === JobType.QbExportInvoicesMultiline ||
				jobType === JobType.AtExportInvoiceMultiline ||
				jobType === JobType.QbdExportInvoices ||
				jobType === JobType.CwReturnTaxes ||
				jobType === JobType.HaloExportInvoiceMultiline ||
				jobType === JobType.HaloExportCharges) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
			} else if (jobType === JobType.InvoiceExportXeroMultiline) {
				this.numberItemsCompleted5 = this.job.messages.length;
				this.numberItemsCompleted3 = this.job.stats?.value3 ?? 0;
				this.numberItemsCompleted4 = this.job.stats?.value4 ?? 0;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted3 = this.t('operation-progress-page.stats-label_payment') + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_payment') + this.pluralize(this.numberItemsCompleted4) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted5 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted5);
			} else if (jobType === JobType.ChargeInvoices) {
				this.numberItemsCompleted4 = this.job.messages.length;
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_charge') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_charge') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted4 = this.t('operation-progress-page.stats-label_message') + this.pluralize(this.numberItemsCompleted4);
			} else if (jobType === JobType.CwInvoiceNumberSync || jobType === JobType.AtInvoiceNumberSync) {
				this.messageItemsCompleted1 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_sync-success');
				this.messageItemsCompleted2 = this.t('operation-progress-page.stats-label_invoice') + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_sync-failed');
			} else if (jobType === JobType.InvoicesSend) {
				const importItemName = this.t('operation-progress-page.stats-label_invoice');

				this.numberItemsCompleted3 = this.job.stats.value3 ? this.job.stats.value3 : 0;
				this.messageItemsCompleted1 = importItemName + this.pluralize(this.numberItemsCompleted1) + this.t('operation-progress-page.stats-label_success');
				this.messageItemsCompleted2 = importItemName + this.pluralize(this.numberItemsCompleted2) + this.t('operation-progress-page.stats-label_failed');
				this.messageItemsCompleted3 = importItemName + this.pluralize(this.numberItemsCompleted3) + this.t('operation-progress-page.stats-label_ignored');
			}
		} else if (jobType === JobType.XeroCustomersSync && this.job.isFailed) {
			this.toasterService.warning(this.job.messages[0].message);
		} else if (jobType === JobType.GenerateReport && this.job.isFailed) {
			this.numberItemsCompleted4 = this.job.messages.length;
		}

		this.cdr.markForCheck();
	}

	pluralize(index: number): string {
		return index !== 1 ? 's' : '';
	}
}
