import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { IntegrationType } from 'domain/entities';
import { Subject } from 'rxjs';
import { permissions } from 'tools/permissions';

@Component({
	selector: 'dg-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.sass']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
	openNav = false;
	isReportDropDown = false;
	isQuotesDropDown = false;
	isAutoBilling = false;
	isITQuoter = false;
	isIpnd = false;
	isQuoter = false;
	permissions = permissions;
	isDatagateAdmin = this.config.isDatagateAdmin;
	isLive = this.config.portalSettings.isLive;
	private readonly unsubscribe$ = new Subject<void>();
	constructor(readonly config: AppConfig) {
		this.isAutoBilling = config.portalSettings.useAutoBilling;
		this.isQuoter = config.integrationSettings.hasIntegrationType(IntegrationType.Quoter);
		this.isITQuoter = config.integrationSettings.hasIntegrationType(IntegrationType.ITQuoter);
		this.isIpnd = config.integrationSettings.hasIntegrationType(IntegrationType.Ipnd);
	}

	async ngOnInit(): Promise<void> {
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	public onToggleReportDropDown(): void {
		this.isReportDropDown = !this.isReportDropDown;

		if (this.isReportDropDown) {
			this.openNav = true;
		}
	}

	public onToggleQuotesDropDown(): void {
		this.isQuotesDropDown = !this.isQuotesDropDown;

		if (this.isQuotesDropDown) {
			this.openNav = true;
		}
	}

	public toggleSideMenu(): void {
		this.openNav = !this.openNav;

		if (!this.openNav) {
			this.isReportDropDown = false;
			this.isQuotesDropDown = false;
		}
	}
}
