import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { AccountManager, ExtraFieldValue } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class ExtraFieldRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	private GROUP_CACHE_KEY = 'groups';

	private GROUPS_URL = 'groups';

	private BUSINESS_UNITS_URL = 'business-units';

	private BUSINESS_UNIT_CACHE_KEY = 'business-units';

	public getGroups$(): Observable<ExtraFieldValue[]> {
		return this.get(this.GROUPS_URL, this.GROUP_CACHE_KEY);
	}

	async getGroups(destroy$?: Subject<void>): Promise<ExtraFieldValue[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ExtraFieldValue[]>>(`${this.config.apiUrl}${this.config.apiVersion}/${this.GROUPS_URL}`);
		return res.body.data.map(x => new ExtraFieldValue().deserialize(x));
	}

	public updateGroup(group: ExtraFieldValue): Observable<void> {
		return this.update(this.GROUPS_URL, this.GROUP_CACHE_KEY, group);
	}

	public addGroup(group: ExtraFieldValue): Observable<Guid> {
		return this.add(this.GROUPS_URL, this.GROUP_CACHE_KEY, group);
	}

	public deleteGroup(id: Guid): Observable<Guid> {
		return this.delete(this.GROUPS_URL, this.GROUP_CACHE_KEY, id);
	}

	public getBusinessUnits(): Observable<ExtraFieldValue[]> {
		return this.get(this.BUSINESS_UNITS_URL, this.BUSINESS_UNIT_CACHE_KEY);
	}

	public updateBusinessUnit(group: ExtraFieldValue): Observable<void> {
		return this.update(this.BUSINESS_UNITS_URL, this.BUSINESS_UNIT_CACHE_KEY, group);
	}

	public addBusinessUnit(group: ExtraFieldValue): Observable<Guid> {
		return this.add(this.BUSINESS_UNITS_URL, this.BUSINESS_UNIT_CACHE_KEY, group);
	}

	public deleteBusinessUnit(id: Guid): Observable<Guid> {
		return this.delete(this.BUSINESS_UNITS_URL, this.BUSINESS_UNIT_CACHE_KEY, id);
	}

	private get(url: string, cacheKey: string): Observable<ExtraFieldValue[]> {

		if (this.cacheHasKey(cacheKey))
			return this.getCache<ExtraFieldValue[]>(cacheKey);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/${url}`)
				.pipe(
					map(res => {
						var groups = res.body.data.map(x => new ExtraFieldValue().deserialize(x));

						this.setCacheValue(cacheKey, groups, null);

						return groups;
					}));

		this.cache.set$(cacheKey, observable);

		return observable;
	}

	reset() {
		this.cache.removeKey(this.BUSINESS_UNIT_CACHE_KEY);
		this.cache.removeKey(this.GROUP_CACHE_KEY);
	}

	private add(url: string, cacheKey: string, value: ExtraFieldValue): Observable<Guid> {

		if (value.value)
			value.value = value.value.trim();

		const observable =
			this.createEntity(`/${url}`, value)
				.pipe(
					map(entity => entity.id)
					, tap(() => this.addCacheCollectionItem(cacheKey, value)));

		return observable;

	}

	private update(url: string, cacheKey: string, value: ExtraFieldValue): Observable<void> {

		if (value.value)
			value.value = value.value.trim();

		const observable =
			this.updateEntity(`/${url}/${value.id}`, value)
				.pipe(
					map(e => {
					})
					, tap(() => {
						this.updateCacheCollectionItem(cacheKey, value.id, value);
					}));

		return observable;
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	public getAccountManager$(): Observable<AccountManager[]> {
		const key = 'account-managers';
		const condition = this.cache.get('account-managers-refresh');

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCache<AccountManager[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/account-managers`)
				.pipe(
					map(res => {
						const managers = res.body.data.map(x => new AccountManager().deserialize(x));

						this.setCacheValue(key, managers, null);

						return managers;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getAccountManagers(destroy$?: Subject<void>): Promise<AccountManager[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ExtraFieldValue[]>>(`${this.config.apiUrl}${this.config.apiVersion}/account-managers`);
		return res.body.data?.map(x => new AccountManager().deserialize(x));
	}

	public getAccountManagerById(id: Guid): Observable<AccountManager> {
		const key = 'account-managers';

		if (this.cacheHasKey(key)) {
			return this.getCache<AccountManager[]>(key)
				.pipe(map(manager => manager.find(x => x.id.equals(id))));
		}

		return this.getAccountManager$().pipe(map(manager => manager.find(x => x.id.equals(id))));
	}

	public addAccountManager(item: AccountManager): Observable<Guid> {
		const key = 'account-managers';

		const observable =
			this.createEntity(`/account-managers`, item)
				.pipe(
					map(entity => entity.id)
					, tap(() => {
						this.addCacheCollectionItem(key, item);
						this.cache.set('account-managers-refresh', true);
					}));

		return observable;

	}

	public updateAccountManager(item: AccountManager): Observable<void> {
		const key = 'account-managers';

		const observable =
			this.updateEntity(`/account-managers/${item.id}`, item)
				.pipe(
					map(e => { })
					, tap(() => {
						this.updateCacheCollectionItem(key, item.id, item);
					}));
		return observable;
	}

	public delete(url: string, cacheKey: string, id: Guid): Observable<Guid> {

		const observable =
			this.deleteEntity(`/${url}/${id}`)
				.pipe(
					tap(() => this.removeFromCacheCollection(cacheKey, id))
					, map(x => null));

		return observable;
	}

	public deleteAccountManager(ids: Guid[]): Observable<HttpResponse<void>> {

		const key = 'account-managers';

		const observable =
			this.deleteEntities(`/account-managers/delete`, ids)
				.pipe(
					tap(() => this.removeManyFromCacheCollection(key, ids)));

		return observable;
	}

}
