import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ToasterService } from 'core/toaster-service';
import { ApiData, Guid } from 'domain/types';
import { FileResponse, FilesReport, Folder, FsItem } from 'domain/entities';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class FileRepository {

	constructor(
		private readonly http: HttpAuth,
		protected toasterService: ToasterService,
		private readonly config: AppConfig
	) {

	}

	async getRoot(destroy?: Subject<void>): Promise<Folder> {
		const res = await this.http.promise(destroy).get<ApiData<Folder>>(`${this.config.apiUrl}${this.config.apiVersion}/folders`);
		return new Folder().parse(res.body.data);
	}

	public getReports(): Observable<any> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/reports`)
			.pipe(
				map(res => res.body.data));
	}

	public generateReport(report: FilesReport, parameters: any, folderId: Guid): Observable<any> {
		const params = {
			id: report.reportId,
			folderId: folderId,
			parameters: JSON.stringify(parameters),
			tag: JSON.stringify({ url: `/files` })
		};

		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { headers: headers };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/reports/generate`, params, options)
			.pipe(
				map(res => {
					return res.body;
				}));
	}

	public deleteFolder(id: Guid): Observable<void> {

		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/folders/${id}`)
			.pipe(map(w => null));
	}

	public deleteFile(id: Guid): Observable<void> {

		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/files/${id}`)
			.pipe(map(w => null));
	}

	public createFolder(newFolder: Folder): Observable<Folder> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/folders`, newFolder)
			.pipe(
				map(res => {
					const f = new Folder();
					f.deserialize(res.body.data);
					return f;
				}));
	}

	public downloadFile(id: Guid): Observable<HttpResponse<Blob>> {

		const options = { responseType: 'blob' };

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/files/${id}`, options);
	}

	public updateFolder(editedElement: FsItem): Observable<void> {
		const objectToUpdate = {
			parentId: editedElement.parent.id,
			name: editedElement.name
		};

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/folders/${editedElement.id}`, null, objectToUpdate)
			.pipe(
				map(w => null));
	}

	public updateFile(editedElement: FsItem): Observable<number> {
		const objectToUpdate = {
			parentId: editedElement.parent.id,
			name: editedElement.name
		};

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/files/${editedElement.id}`, null, objectToUpdate)
			.pipe(
				map(w => null));
	}

	public moveObject(dataToMove: any): Observable<number> {

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/${dataToMove.type}s/${dataToMove.id}`, null, dataToMove)
			.pipe(
				map(w => null));
	}

	public fileUpload(file: File, folderId: Guid | undefined): Observable<FileResponse> {
		const formData: FormData = new FormData();
		formData.append('file', file, file.name);
		formData.append('folderId', folderId ? folderId.toString() : '');

		const headers = new HttpHeaders();
		const options = { headers: headers };

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/file-upload`, options, formData)
			.pipe(
				map(res => {
					const obj = res.body;
					const fileRes = new FileResponse();

					fileRes.id = obj.id;
					fileRes.fileName = obj.fileName;
					fileRes.parentId = obj.parentId;
					fileRes.userId = obj.userId;
					fileRes.username = obj.username;
					return fileRes;
				})
				, catchError(er => {
					return throwError(() => er);
				}));
	}
}
