import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { NavigatorService } from 'services';
import { CustomerRepository } from 'repositories/customer-repository';

import { TranslocoEvents, TranslocoService } from '@ngneat/transloco';
import { Invoice } from 'domain/entities';
import { combineLatest, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { ToasterService } from 'core/toaster-service';
import { GroupAction } from '../actions';
import { GroupActionCollection } from '../group-action-collection';
import { InvoiceActionsService } from '../invoice-actions.service';

@Component({
	selector: 'dg-invoice-actions-float',
	templateUrl: './dg-invoice-actions-float.component.html',
	styleUrls: ['./dg-invoice-actions-float.component.sass']
})
export class DgInvoiceActionsFloatComponent implements OnDestroy, OnChanges {
	@Input() selected: Invoice[];
	@Output() action: EventEmitter<GroupAction> = new EventEmitter<GroupAction>();

	actionCollection: GroupActionCollection;
	private readonly unsubscribe$: Subject<void> = new Subject();
	constructor(
		private invoiceService: InvoiceActionsService,
		private customerRepository: CustomerRepository,
		private navigatorService: NavigatorService,
		private toasterService: ToasterService,
		private transloco: TranslocoService
	) {
		combineLatest([
			this.transloco.langChanges$,
			this.transloco.events$.pipe(
				filter(e => e.type === 'translationLoadSuccess'),
				startWith(<TranslocoEvents>{})
			)
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(async (payload) => {
				const allCustomers = await this.customerRepository.getAll();
				this.actionCollection = new GroupActionCollection(allCustomers, invoiceService, customerRepository, navigatorService, toasterService, transloco);
			});
	}

	async ngOnChanges(): Promise<void> {
		if (!this.actionCollection) return;

		await this.actionCollection.refreshAvailableActions(this.selected);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onGroupAction(action: GroupAction): void {
		this.action.emit(action);
	}
}
