import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PortalSettings, IntegrationSettings, IntegrationCategory, IntegrationType, PortalIntegration } from 'domain/entities';
import { MyDetails } from 'domain/entities/my-details-entities';

export const DG_APP_CONFIG = new InjectionToken<AppConfigModel>('Datagate Application Configuration');

export interface AppConfigModel {
	apiurl: string;
	apiVersion: string;
	ecpImageUrl: string;
	loginAsUrl: string;
	blobUrl: string;
	openReplayProjectKey: string;
}

export interface PortalIntegrationSettings {
	getAll(): PortalIntegration[];
	getAllByCategory(category: IntegrationCategory): PortalIntegration[];
	hasIntegrationType(type: IntegrationType): boolean;
	hasTaxEngine(type: IntegrationType): boolean;
	hasAccountingEngine(type: IntegrationType): boolean;
	hasQuoterEngine(type: IntegrationType): boolean;
}

@Injectable()
export class AppConfig {
	private _myDetails: MyDetails;
	private _portalSettings: PortalSettings;
	private _integrationSettings: IntegrationSettings;
	private readonly _appConfig: AppConfigModel;

	get apiUrl(): string {
		return this.getConfig('apiurl');
	}

	get apiVersion(): string {
		return this.getConfig('apiversion');
	}

	get ecpImageUrl(): string {
		return this.getConfig('ecpImageUrl');
	}

	get loginAsUrl(): string {
		return this.getConfig('loginAsUrl');
	}

	get blobUrl(): string {
		return this.getConfig('blobUrl');
	}

	get openReplayProjectKey(): string {
		return this.getConfig('openReplayProjectKey');
	}

	get myDetails(): MyDetails {
		if (!this._myDetails) {
			throw new Error('MyDetails not yet resolved!');
		}

		return this._myDetails;
	}

	get isDatagateAdmin(): boolean {
		if (!this._myDetails) {
			throw new Error('MyDetails not yet resolved!');
		}
		
		return this._myDetails.role?.some(role => role.includes('Datagate Admin')) || false;
	}

	get portalSettings(): PortalSettings {
		if (!this._portalSettings) {
			throw new Error('PortalSettings not yet resolved!');
		}

		return this._portalSettings;
	}

	get integrationSettings(): PortalIntegrationSettings {
		if (!this._integrationSettings) {
			throw new Error('IntegrationSettings not yet resolved!');
		}

		const integrations = this._integrationSettings?.integrations ?? [];

		return {
			getAll: (): PortalIntegration[] => integrations,
			getAllByCategory: (category: IntegrationCategory): PortalIntegration[] =>
				integrations.filter(i => i.category === category),
			hasIntegrationType: (type: IntegrationType): boolean =>
				integrations.some(i => i.type === type),
			hasTaxEngine: (type: IntegrationType): boolean =>
				integrations
					.filter(i => i.category === IntegrationCategory.Tax)
					.some(i => i.type === type),
			hasAccountingEngine: (type: IntegrationType): boolean =>
				integrations
					.filter(i => i.category === IntegrationCategory.Accounting)
					.some(i => i.type === type),
			hasQuoterEngine: (type: IntegrationType): boolean =>
				integrations
					.filter(i => i.category === IntegrationCategory.Quoter)
					.some(i => i.type === type)
		};
	}


	constructor(
		@Inject(DG_APP_CONFIG) private readonly appConfigModel: AppConfigModel
	) {
		this._appConfig = appConfigModel;
	}

	setMyDetails(me: MyDetails): void {
		this._myDetails = me;
	}

	setPortalSettings(settings: PortalSettings): void {
		this._portalSettings = settings;
	}

	setIntegrationSettings(integrationSettings: IntegrationSettings): void {
		this._integrationSettings = integrationSettings;
	}

	private getConfig(key: string): string {
		if (!this._appConfig) {
			throw new Error('app.config not yet resolved!');
		}

		return this._appConfig[key];
	}
}
