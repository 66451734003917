import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { Job } from 'domain/entities';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';
import { ApiData } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class JobRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	async get(id: number, destroy$?: Subject<void>): Promise<Job> {
		const res = await this.http.promise(destroy$).get<ApiData<Job>>(`${this.config.apiUrl}${this.config.apiVersion}/operations/${id}`);

		const job = new Job().deserialize(res.body);
		return job;
	}
}
