import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	BrandingTheme,
	Invoice, XeroAccount, XeroBrandingThemes,
	XeroExportMultilineModel, XeroTaxLineByStateMapping,
	XeroTaxLineMapping,
	XeroTaxMapping
} from 'domain/entities';
import { ApiDataError, Guid } from 'domain/types';
import { LocalSettingsRepository } from 'repositories';
import { XeroExportInvoiceRepository } from 'repositories/xero-export-invoice-repository';
import { Subject } from 'rxjs';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-xero-export-multiline',
	templateUrl: './xero-export.component.html',
	styleUrls: ['./xero-export.component.sass']
})
export class XeroExportComponent implements OnDestroy {
	currentStep = 1;
	invoices: Invoice[];
	excluded: Invoice[];
	xeroAccounts: XeroAccount[];
	xeroPayments: XeroAccount[];
	exportParams: any = new Object();
	xeroTaxMapping: XeroTaxMapping[];
	xeroTaxLinesByState: XeroTaxLineByStateMapping[];
	xeroTaxLines: XeroTaxLineMapping[];
	loading = false;
	selectedGroupingTypeId: number;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public xeroTaxMultiline = false;
	public groupTaxByState = false;
	public isExported = false;
	public exportedInvoices: string[];
	xeroBrandingThemes: XeroBrandingThemes;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly route: ActivatedRoute,
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly invoiceService: InvoiceActionsService,
		private readonly repository: XeroExportInvoiceRepository,
		private readonly localSettingsRepository: LocalSettingsRepository,
		private readonly location: Location
	) {
		this.cacheModel = this.cache.get('exportXeroInvoicesMultiline');
		this.cacheModelExcluded = this.cache.get('exportXeroInvoicesMultiline-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (this.invoices.length) {
			void this.XeroTypeSelected();
		} else {
			this.location.back();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public async XeroTypeSelected(force: boolean = false): Promise<void> {
		this.loading = true;
		const groupingTypeId = +this.selectedGroupingTypeId;
		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceService.selectedAll && actionFilters ? actionFilters : '';
		try {
			const ids = await this.invoiceService.xeroInvoicesMultilineFilter(this.invoices, force, this.excluded, this.destroy$);
			const res = await this.repository.GroupingTypePost(groupingTypeId, ids, this.excluded, filterParams, this.destroy$);

			this.xeroTaxMapping = res.xeroTaxes;
			this.xeroTaxLinesByState = res.xeroTaxLinesByState;
			this.xeroTaxLines = res.xeroTaxLines;
			this.xeroAccounts = res.xeroAccounts;
			this.xeroPayments = res.xeroPayments;
			this.xeroBrandingThemes = res.xeroBrandingThemes;

			const cacheExportParam = this.localSettingsRepository.GetXeroExtendedWizardSetting();
			if (cacheExportParam) {
				this.exportParams = cacheExportParam;
			} else {
				this.exportParams.invoiceStatus = 4;
			}

		} catch (error) {
			const apiError = error as ApiDataError<any>;
			if (Array.isArray(apiError.error.references)) {
				this.isExported = true;
				this.exportedInvoices = apiError.error.references;
			} else {
				throw error;
			}
		} finally {
			this.loading = false;
		}
	}

	public CheckStep(value: number): string {
		return this.currentStep === value ? 'steps--active' : '';
	}

	async Export(): Promise<void> {
		this.loading = true;
		this.localSettingsRepository.SetXeroExtendedWizardSetting(this.exportParams);
		const exportModel = new XeroExportMultilineModel();

		exportModel.extendedExport = true;
		exportModel.reference = this.exportParams.reference;
		exportModel.accountCode = this.exportParams.accountCode;
		exportModel.paymentCode = !this.exportParams.paymentCode ? null : this.exportParams.paymentCode;
		exportModel.invoiceStatus = this.exportParams.invoiceStatus;
		exportModel.sentToContact = this.exportParams.sentToContact;
		exportModel.useGlCode = this.exportParams.useGlCode;
		exportModel.taxMultiline = this.xeroTaxMultiline;
		exportModel.taxByState = this.groupTaxByState;

		exportModel.taxes = this.xeroTaxMapping;
		exportModel.taxLines = this.xeroTaxLines;
		exportModel.taxLinesByState = this.xeroTaxLinesByState;
		exportModel.brandingThemesMapping = this.xeroBrandingThemes?.brandingThemesMapping;

		try {
			const result = await this.invoiceService.exportInvoicesToXeroMultiline(this.invoices, exportModel, this.cacheModel.Tag, this.excluded, this.destroy$);
			this.navigator.toOperation(result.operationId);
		} finally {
			this.loading = false;

		}
	}

	NextStep(): void {
		if (this.currentStep < 3) {
			this.currentStep = this.currentStep + 1;
		}
	}

	PreviosStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	public BackStep(): void {
		this.PreviosStep();
	}

	async onExportConfirm(): Promise<void> {
		this.isExported = false;
		this.exportedInvoices = [];
		await this.XeroTypeSelected(true);
	}

	xeroBrandingThemesCompareFn(item: BrandingTheme, selected: Guid): boolean {
		return item.brandingThemeID.toString() === selected.toString();
	}
}
