import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import {
	Invoice,
	XeroTaxMapping,
	XeroTaxLineByStateMapping,
	XeroTaxLineMapping,
	XeroAccount, XeroBrandingThemes
} from 'domain/entities';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { AppConfig } from 'core/app-config';
import { ToasterService } from 'core/toaster-service';

@Injectable({
	providedIn: 'root'
})
export class XeroExportInvoiceRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly invoiceService: InvoiceActionsService,
		private readonly toasterService: ToasterService,
		private readonly config: AppConfig
	) {
	}

	public async GroupingTypePost(groupingType: number, invoices: number[], excluded?: Invoice[], filterParams?: any, destroy$?: Subject<void>): Promise<any> {
		const model = {
			groupingType: groupingType,
			invoices: invoices,
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll
		};
		try {
			const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/xero/export-multiline/details`, model, { params: filterParams });

			const xeroTaxes = res.body.data.taxes.map(x => new XeroTaxMapping().deserialize(x));
			const xeroTaxLinesByState = res.body.data.taxLinesByState.map(x => new XeroTaxLineByStateMapping().deserialize(x));
			const xeroTaxLines = res.body.data.taxLines.map(x => new XeroTaxLineMapping().deserialize(x));
			const xeroAccounts = res.body.data.accounts.map(x => new XeroAccount().deserialize(x));
			const xeroPayments = res.body.data.payments.map(x => new XeroAccount().deserialize(x));

			let xeroBrandingThemes: XeroBrandingThemes;
			if (res.body.data.xeroBrandingThemes) {
				xeroBrandingThemes = new XeroBrandingThemes().deserialize(res.body.data.xeroBrandingThemes);
			}

			const responseModel = {
				xeroTaxes: xeroTaxes,
				xeroTaxLinesByState: xeroTaxLinesByState,
				xeroTaxLines: xeroTaxLines,
				xeroAccounts: xeroAccounts,
				xeroPayments: xeroPayments,
				xeroBrandingThemes: xeroBrandingThemes,
			};
			return responseModel;
		} catch (error: HttpResponse<any> | any) {

			const xeroError = error.error;

			if (xeroError?.Message && xeroError.Status === 403) {
				this.toasterService.warning(xeroError.Message);
			}

			throw error;
		}
	}
}
