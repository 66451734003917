import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { Api, ApiData, Guid } from 'domain/types';
import { ProductTemplate, Service, ServiceUsageRecordType, Supplier } from 'domain/entities';
import { Observable, Subject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class ProductTemplateRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getAll$(): Observable<ProductTemplate[]> {
		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/product-templates`)
				.pipe(
					map(res => {
						const templates = res.body.data.map(x => new ProductTemplate().deserialize(x));
						return templates;
					}));

		return observable;
	}

	async getAll(destroy$?: Subject<void>): Promise<ProductTemplate[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ProductTemplate[]>>(`${this.config.apiUrl}${this.config.apiVersion}/product-templates`);
		return res.body.data.map(x => new ProductTemplate().deserialize(x));
	}

	async getProductTemplates(destroy$?: Subject<void>): Promise<ProductTemplate[]> {
		const templates = await this.http.promise(destroy$).get<ApiData<ProductTemplate[]>>(`${this.config.apiUrl}${this.config.apiVersion}/product-templates`);

		return templates.body?.data?.map(x => new ProductTemplate().deserialize(x)) || [];
	}

	getProductTemplateById(templateId: Guid): Observable<ProductTemplate> {
		const key = `template-${templateId}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<ProductTemplate[]>(key)
				.pipe(map(template => template.find(x => x.id.equals(templateId))));
		}

		return this.getAll$().pipe(map(template => template.find(x => x.id.equals(templateId))));
	}

	public updateTemplate(template: ProductTemplate): Observable<void> {

		const key = 'templates';

		return this.updateEntity(`/product-templates/${template.id}`, template)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));
	}

	public addTemplate(template: ProductTemplate): Observable<Guid> {

		const key = 'templates';

		return this.createEntity(`/product-templates`, template)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	async deleteTemplate(id: Guid, destroy$?: Subject<void>): Promise<Api<void>> {
		const res = await this.http.promise(destroy$).delete<Api<void>>(`${this.config.apiUrl}${this.config.apiVersion}/product-templates/${id}`);
		return res?.body;
	}

	async deleteTemplates(ids: Guid[], destroy$?: Subject<void>): Promise<Api<void>> {
		const res = await this.http.promise(destroy$).post<Api<void>>(`${this.config.apiUrl}${this.config.apiVersion}/product-templates/delete`, { records: ids });
		return res?.body;
	}

}

@Injectable({
	providedIn: 'root'
})
export class ServiceRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getAll$(): Observable<Service[]> {

		const key = 'services';

		if (this.cacheHasKey(key))
			return this.getCache<Service[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/services`)
				.pipe(
					map(res => {
						const srv = res.body.data.map(x => new Service().deserialize(x));

						this.setCacheValue(key, srv, null);

						return srv;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getAll(destroy$?: Subject<void>): Promise<Service[]> {
		const res = await this.http.promise(destroy$).get<ApiData<Service[]>>(`${this.config.apiUrl}${this.config.apiVersion}/services`);
		return res.body.data.map(x => new Service().deserialize(x));
	}

	getServiceUsageRecordTypes(serviceId: number): Observable<ServiceUsageRecordType[]> {

		const key = 'usagerecordtypes-' + serviceId;

		if (this.cache.get(key) != null) {
			const types = this.cache.get<ServiceUsageRecordType[]>(key);

			// reset helper values in case they were set and placed in cache
			for (const t of types) {
				t.value = null;
				t.bundleQuantityTypeId = null;
			}

			return of(types);
		}

		if (this.cache.get$(key) != null)
			return this.cache.get$(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/services/${serviceId}/usageRecordTypes`)
				.pipe(
					map(res => {
						const types = res.body.data.map(x => new ServiceUsageRecordType().deserialize(x));

						this.setCacheValue(key, types, null);

						return types;
					}));

		this.cache.set$(key, observable);

		return observable;
	}
}

@Injectable({
	providedIn: 'root'
})
export class SupplierRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	getAll$(): Observable<Supplier[]> {
		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/suppliers`)
				.pipe(
					map(res => {
						const sups = res.body.data.map(x => new Supplier().deserialize(x));
						return sups;
					}));

		return observable;
	}

	async getAll(destroy?: Subject<void>): Promise<Supplier[]> {
		const res = await this.http.promise(destroy).get<ApiData<Supplier[]>>(`${this.config.apiUrl}${this.config.apiVersion}/suppliers`);
		return res.body.data.map(x => new Supplier().deserialize(x));
	}
}
