import { HashMap, TranslateParams } from '@ngneat/transloco';
import { ColDef, HeaderValueGetterFunc, HeaderValueGetterParams, ValueGetterFunc, ValueGetterParams } from 'ag-grid-enterprise';
import * as Moment from 'moment';
import { Utils } from './utils';


export function usersColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			lockPosition: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			headerClass: 'ag-cell-header-checkbox',
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'customer-users-page.grid-headers_displayName',
			field: 'displayName',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellStyle: { 'white-space': 'normal' },
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-users-page.grid-headers_email',
			field: 'userName',
			filter: 'agTextColumnFilter',
			cellStyle: { 'white-space': 'normal' },
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-users-page.grid-headers_status',
			field: 'isActiveToStatus',
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-users-page.grid-headers_dateCreated',
			field: 'dateCreatedLabel',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-users-page.grid-headers_viewAs',
			field: '',
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			cellRenderer: 'childLinkViewRenderer',
			cellStyle: { 'white-space': 'normal' },
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function feedFilesColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{ headerName: 'feeds.files.grid-headers_feed', field: 'feedName', lockVisible: true, filter: 'agTextColumnFilter', headerValueGetter: translateHeaderFunc(t) },
		{ headerName: 'feeds.files.grid-headers_file', field: 'fileName', lockVisible: true, filter: 'agTextColumnFilter', valueFormatter: params => params.data.fileName, headerValueGetter: translateHeaderFunc(t) },
		{
			headerName: 'feeds.files.grid-headers_downloadDate',
			field: 'downloadDate',
			filter: 'agDateColumnFilter',
			comparator: Utils.compareDates,
			headerValueGetter: translateHeaderFunc(t),
		},
		{ headerName: 'feeds.files.grid-headers_stage', field: 'stage', lockVisible: true, filter: 'agTextColumnFilter', headerValueGetter: translateHeaderFunc(t) },
		{
			headerName: 'feeds.files.grid-headers_download',
			field: '',
			suppressMenu: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			cellRenderer: 'CellDownloadRenderer',
			cellStyle: { 'white-space': 'normal' },
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}


export function glCodesColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'portal-settings-gl-code-page.grid-headers_code',
			field: 'code',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-gl-code-page.grid-headers_description',
			field: 'description',
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function invoiceLayoutsColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'portal-settings-invoice-layout-page.grid-headers_name',
			field: 'name',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-invoice-layout-page.grid-headers_description',
			field: 'description',
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-invoice-layout-page.grid-headers_invoiceTemplateType',
			field: 'invoiceTemplateTypeId',
			filter: 'agTextColumnFilter',
			cellRenderer: 'childCellInvoiceLayoutRenderer',
			headerValueGetter: translateHeaderFunc(t),
			valueGetter: translateCellFunc(t, 'invoiceTemplateTypeId'),
		},
		{
			headerName: 'portal-settings-invoice-layout-page.grid-headers_additionalFilePath',
			field: 'additionalFilePath',
			cellRenderer: 'childLinkRenderer',
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function managersColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'portal-settings-manager-page.grid-headers_name',
			field: 'name',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			width: 150,
			filterParams: { defaultOption: 'equals' },
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-manager-page.grid-headers_commissionRate',
			field: 'commissionRate',
			filter: 'agTextColumnFilter',
			width: 150,
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function sureTaxColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'portal-settings-suretax-page.grid-headers_code',
			field: 'code',
			lockVisible: true,
			maxWidth: 200,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-suretax-page.grid-headers_description',
			field: 'description',
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-suretax-page.grid-headers_glCode',
			field: 'glCode',
			maxWidth: 200,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function bundlesColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			minWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'bundles.bundles-page.grid-headers_name',
			field: 'name',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}
export function productKitsColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'product-kits-page.grid-headers_name',
			field: 'name',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'product-kits-page.grid-headers_description',
			field: 'description',
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'product-kits-page.grid-headers_cost',
			field: 'cost',
			filter: 'agTextColumnFilter',
			width: 150,
			type: 'numericColumn',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'product-kits-page.grid-headers_sell',
			field: 'sell',
			width: 150,
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			type: 'numericColumn',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function priceBookProductsColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			minWidth: 40,
			lockVisible: true,
			width: 40,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'price-book-products.grid-headers_name',
			field: 'name',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			cellStyle: { 'white-space': 'normal' },
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_label',
			field: 'label',
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			cellStyle: { 'white-space': 'normal' },
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_ratingTypeName',
			field: 'ratingTypeName',
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_proRata',
			field: 'proRata',
			cellStyle: { 'white-space': 'normal' },
			cellClass: 'ag-cell-charges',
			sortable: false,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_hidden',
			field: 'hidden',
			cellStyle: { 'white-space': 'normal' },
			cellClass: 'ag-cell-charges',
			sortable: false,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_transactionCode',
			field: 'transactionCode',
			hide: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_glCodeName',
			field: 'glCodeName',
			hide: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_productCategoryName',
			field: 'productCategoryName',
			hide: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_costAmount',
			field: 'costAmount',
			filter: 'agTextColumnFilter',
			valueFormatter: data => data.value.toFixed(2),
			headerClass: 'ag-cell-header-numeric',
			cellClass: 'ag-cell-numeric',
			type: 'numericColumn',
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'price-book-products.grid-headers_ratingAmount',
			field: 'ratingAmount',
			filter: 'agTextColumnFilter',
			valueFormatter: data => data.value.toFixed(2),
			headerClass: 'ag-cell-header-numeric',
			cellClass: 'ag-cell-numeric',
			type: 'numericColumn',
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function reportUsageCallTypeColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'report-usage-call-type.grid-headers_name',
			field: 'name',
			filter: 'agTextColumnFilter',
			width: 150,
			rowGroup: true,
			hide: true,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'report-usage-call-type.grid-headers_supRecordType',
			field: 'supRecordType',
			filter: 'agTextColumnFilter',
			width: 150,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'report-usage-call-type.grid-headers_total_Minutes',
			field: 'total_Minutes',
			filter: 'agTextColumnFilter',
			width: 150,
			aggFunc: 'sum',
			cellClass: 'ag-cell-numeric',
			type: 'numericColumn',
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			valueFormatter: data => Utils.roundTwoDecimals(data.value),
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function reportUsageTotalCallsColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'report-usage-total-calls.grid-headers_customer',
			field: 'customer',
			filter: 'agTextColumnFilter',
			width: 150,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'report-usage-total-calls.grid-headers_customerCode',
			field: 'customerCode',
			filter: 'agTextColumnFilter',
			width: 150,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'report-usage-total-calls.grid-headers_service',
			field: 'service',
			filter: 'agTextColumnFilter',
			width: 150,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'report-usage-total-calls.grid-headers_minutes',
			field: 'minutes',
			filter: 'agTextColumnFilter',
			width: 150,
			cellClass: 'ag-cell-numeric',
			type: 'numericColumn',
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			valueFormatter: data => Utils.roundTwoDecimals(data.value),
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function serviceItemTypesColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			lockPosition: true,
			headerName: 'Checkbox',
			field: '',
			checkboxSelection: true,
			maxWidth: 40,
			lockVisible: true,
			width: 40,
			hide: false,
			headerClass: 'ag-cell-header-checkbox',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true
			},
			menuTabs: []
		},
		{
			headerName: 'portal-settings-service-item-types.grid-headers_name',
			field: 'name',
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-service-item-types.grid-headers_createdBy',
			field: 'createdBy',
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-service-item-types.grid-headers_dateCreatedMoment',
			field: 'dateCreatedMoment',
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-settings-service-item-types.grid-headers_dateModifiedMoment',
			field: 'dateModifiedMoment',
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function qbdInvoicesColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_invoiceNumber',
			field: 'invoiceNumber',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_customerLabel',
			field: 'customerLabel',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_invoiceDate',
			field: 'invoiceDate',
			lockVisible: true,
			valueFormatter: data => Moment.utc(data.value).format('L'),
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_amount',
			field: 'amount',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			// type: 'numericColumn',
			valueFormatter: data => Utils.roundTwoDecimals(data.value),
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_invoiceStatus',
			field: 'invoiceStatus',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_invoiceError',
			field: 'invoiceError',
			lockVisible: true,
			cellStyle: { 'white-space': 'normal' },
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-invoices.grid-fields_action',
			field: '',
			filter: 'agTextColumnFilter',
			maxWidth: 200,
			cellRenderer: 'QBDInvoiceDeleteRenderer',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function quotesColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_name',
			field: 'name',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_number',
			field: 'number',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			cellRenderer: 'childLinkRenderer',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_totalUpfront',
			field: 'totalUpfront',
			filter: 'agTextColumnFilter',
			type: 'rightAligned',
			valueFormatter: data => data.value ? data.value.toFixed(2) : '0.00',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_totalRecurring',
			field: 'totalRecurring',
			filter: 'agTextColumnFilter',
			type: 'rightAligned',
			valueFormatter: data => data.value ? data.value.toFixed(2) : '0.00',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_owner',
			field: 'owner',
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_organization',
			field: 'organization',
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-it-quoter-quotes.grid-headers_action',
			field: 'accepted',
			filter: 'agTextColumnFilter',
			cellRenderer: 'ChildAcceptRenderer',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function qbdSynclogsColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'portal-integration-qbd-synclogs.grid-fields_date',
			field: 'date',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-synclogs.grid-fields_logType',
			field: 'logType',
			lockVisible: true,
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'portal-integration-qbd-synclogs.grid-fields_description',
			field: 'description',
			lockVisible: true,
			cellStyle: { 'white-space': 'normal' },
			filter: 'agTextColumnFilter',
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function invoiceTaxColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<any>[] {
	return [
		{
			headerName: 'customer-invoice-products-page.tax-grid-headers_tax',
			field: 'taxTypeDesc',
			lockPosition: true,
			suppressMenu: true,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-invoice-products-page.tax-grid-headers_taxTypeCode',
			field: 'taxTypeCode',
			lockPosition: true,
			suppressMenu: true,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-invoice-products-page.tax-grid-headers_rate',
			field: 'taxRate',
			lockPosition: true,
			suppressMenu: true,
			valueFormatter: data => Number.isInteger(data.value) ? Utils.roundTwoDecimals(data.value) : data.value,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-invoice-products-page.tax-grid-headers_amount',
			field: 'taxAmount',
			type: 'numericColumn',
			lockPosition: true,
			suppressMenu: true,
			valueFormatter: data => Number.isInteger(data.value) ? Utils.roundTwoDecimals(data.value) : data.value,
			headerValueGetter: translateHeaderFunc(t)
		},
		{
			headerName: 'customer-invoice-products-page.tax-grid-headers_taxInclusive',
			field: 'taxInclusive',
			lockPosition: true,
			suppressMenu: true,
			headerValueGetter: translateHeaderFunc(t)
		}
	];
}

export function translateHeaderFunc<TData>(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): HeaderValueGetterFunc<TData> {
	return (params: HeaderValueGetterParams<TData>) => t(params.colDef.headerName);
}

export function translateCellFunc<TData>(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T, cell: string): ValueGetterFunc<TData> {
	return (params: ValueGetterParams<TData>) => t(params.data[cell]);
}
