<dg-page>
	<div page-title>{{'operation-progress-page.title' | transloco}}</div>
	<div page-body>
		<div *ngIf="job" class="page__operation-progress">
			<div class="page__operation-progress-container">
				<h3>{{jobTitle}}</h3>
				<progress id="prgComplete" class="container-process"
					[attr.value]="percentComplete !== undefined ? percentComplete : null"
					[attr.max]="percentComplete !== undefined ? 100 : null">
					{{percentComplete !== undefined ? ("%" + job.percentComplete) : ""}}
				</progress>

				<p *ngIf="job.isSucceded" class="process-message">
					{{'operation-progress-page.job-isSucceded_label' | transloco}}
				</p>
				<p *ngIf="job.isFailed" class="process-message">
					{{'operation-progress-page.job-isFailed_label' | transloco}}
				</p>
				<p *ngIf="!job.isCompleted && percentComplete !== undefined" class="process-message_gray">
					<span>
						{{percentComplete}}% {{'operation-progress-page.job-percentComplete_label' | transloco}}
					</span>
				</p>
				<!-- eslint-disable-next-line @angular-eslint/template/conditional-complexity -->
				<div *ngIf="job.isCompleted && (
					job.jobType === JobType.CwCustomersSync
					|| job.jobType === JobType.QbCustomersSync
					|| job.jobType === JobType.InvoicesGenerate
					|| job.jobType === JobType.CwInvoicesExport
					|| job.jobType === JobType.InvoiceCredit
					|| job.jobType === JobType.InvoicesReGenerate
					|| job.jobType === JobType.CloneRateCard
					|| job.jobType === JobType.CwAdditionsExport
					|| job.jobType === JobType.CwExportInvoicesMultiline
					|| job.jobType === JobType.InvoicesDelete
					|| job.jobType === JobType.InvoiceExportXero
					|| job.jobType === JobType.ChargeInvoices
					|| job.jobType === JobType.InvoiceExportXeroMultiline
					|| job.jobType === JobType.ResendingEmails
					|| job.jobType === JobType.XeroCustomersSync
					|| job.jobType === JobType.AtCustomersSync
					|| job.jobType === JobType.AtExportInvoiceMultiline
					|| job.jobType === JobType.QbExportInvoicesMultiline
					|| job.jobType === JobType.CwInvoiceNumberSync
					|| job.jobType === JobType.AtInvoiceNumberSync
					|| job.jobType === JobType.QbdExportInvoices
					|| job.jobType === JobType.CwReturnTaxes
					|| job.jobType === JobType.KaseyaCustomersSync
					|| job.jobType === JobType.HaloCustomersSync
					|| job.jobType === JobType.HaloExportInvoiceMultiline
					|| job.jobType === JobType.HaloExportCharges
					|| job.jobType === JobType.ReceiptPayment
					|| job.jobType === JobType.GenerateStandardReport
					|| job.jobType === JobType.InvoicesSend
					|| (job.jobType === JobType.ImportFromCsv && (job.tag.url === '/dashboard' || job.tag.url === '/service-items'
					|| job.tag.url === '/rating' || job.tag.url === '/product-templates'
					|| job.tag.url === '/customers'|| job.tag.url === '/import'
					|| job.tag.url === '/bundles'))) && job.stats">
					<p class="process-message_gray">{{numberItemsCompleted1}} {{messageItemsCompleted1}}</p>
					<p class="process-message_gray">{{numberItemsCompleted2}} {{messageItemsCompleted2}}</p>
					<p class="process-message_gray">{{numberItemsCompleted3}} {{messageItemsCompleted3}}</p>
					<p class="process-message_gray">{{numberItemsCompleted4}} {{messageItemsCompleted4}}</p>
					<p class="process-message_gray">{{numberItemsCompleted5}} {{messageItemsCompleted5}}</p>
				</div>
				<div *ngIf="job.messages && job.messages.length > 0">
					<p class="process-message">
						{{'operation-progress-page.failedRecords_label' | transloco}}
					</p>
					<div *ngFor="let item of job.messages">
						<p class="process-message_gray">{{item.message}}</p>
					</div>
				</div>

				<p class="process-message_gray">
					{{'operation-progress-page.jobStarted_label' | transloco}} {{jobStarted}}
				</p>
			</div>
			<div *ngIf="job.isCompleted" class="page__operation-progress-footer">
				<button
					type="button"
					class="btn btn--primary page__operation-progress-button"
					title="OK"
					[routerLink]="redirectUrl">
					OK
				</button>
			</div>
		</div>
	</div>
</dg-page>
