<div #template>
	<ol class="breadcrumbs">
		<ng-container *ngFor="let route of breadcrumbs">
			<li *ngIf="!route.terminal" class="breadcrumbs__item"
				[ngClass]="{'': !isCrumbDisabled, 'breadcrumbs__item--disabled': isCrumbDisabled}">
				<a href="" class="breadcrumbs__element" [routerLink]="[route.url]">
					{{ route.displayName | transloco }}
				</a>
			</li>
			<li *ngIf="route.terminal" class="breadcrumbs__item breadcrumbs__item--active"
				aria-current="page">
				{{ route.displayName | transloco }}
			</li>
		</ng-container>
	</ol>
</div>
<div *ngIf="template.children.length === 0" class="container">
    <div class="nav-wrapper">
        <div *ngFor="let route of breadcrumbs" class="breadcrumb" [ngClass]="{'last': route.terminal}">
            <!-- disable link of last item -->
            <a *ngIf="!route.terminal" href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
            <span *ngIf="route.terminal">{{ route.displayName }}</span>
        </div>
    </div>
</div>
