import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { CallTypesRatingMethod, RateCardRate } from 'domain/models';
import { ApiData, ApiDataPaging, Guid } from 'domain/types';
import {
	Bundle,
	BundleQuantity,
	PriceBook,
	PriceBookProduct,
	RateCard,
	Rating,
	RatingAssignment,
	RatingType,
	TaxRateType
} from 'domain/entities';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class RatingRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getAllRating$(ignoreCache?: boolean): Observable<Rating[]> {
		const cacheKey = 'all-ratings';
		const condition = this.cache.get('rating-needsRefresh');

		if (!ignoreCache) {
			if (this.cache.get(cacheKey) != null && !condition) {
				return of(this.cache.get(cacheKey));
			}
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/rating`)
				.pipe(
					map(res => {
						const rt = res.body.data.map(x => new Rating().deserialize(x));

						this.cache.set(cacheKey, rt);
						this.cache.set('rating-needsRefresh', false);
						return rt;
					}));

		return observable;
	}

	async getAllRating(ignoreCache: boolean = false, destroy$?: Subject<void>): Promise<Rating[]> {
		const cacheKey = 'all-ratings';
		const condition = this.cache.get('rating-needsRefresh');

		const cached = this.getCacheSync<Rating[]>(cacheKey);

		if (!ignoreCache) {
			if (cached && !condition)
				return cached;
		}

		const res = await this.http.promise(destroy$).get<ApiData<Rating[]>>(`${this.config.apiUrl}${this.config.apiVersion}/rating`);
		const ratings = res.body.data.map(x => new Rating().deserialize(x));
		this.cache.set(cacheKey, ratings);
		this.cache.set('rating-needsRefresh', false);

		return ratings;
	}

	public deleteItems(ids: Guid[]): Observable<HttpResponse<void>> {
		const key = 'all-ratings';
		return this.deleteEntities(`/rating/delete`, ids)
			.pipe(
				tap(() => this.removeManyFromCacheCollection(key, ids)));
	}

	public getAllAssignments(): Observable<RatingAssignment[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/rating/assignments`)
			.pipe(map(res => res.body.data.map(x => new RatingAssignment().deserialize(x))));
	}

	async getAllAssignmentsAsync(destroy$?: Subject<void>): Promise<RatingAssignment[]> {
		const res = await this.http.promise(destroy$).get<ApiData<RatingAssignment[]>>(`${this.config.apiUrl}${this.config.apiVersion}/rating/assignments`);
		return res.body.data.map(x => new RatingAssignment().deserialize(x));
	}

	async getCustomerAssignmentById(id: Guid, destroy$?: Subject<void>): Promise<RatingAssignment> {
		const res = await this.http.promise(destroy$).get<ApiData<RatingAssignment>>(`${this.config.apiUrl}${this.config.apiVersion}/rating/assignments/${id}`);

		return res.body?.data;
	}

	public createAssignment(entity: RatingAssignment): Observable<Guid> {

		const key = 'assignments';

		return super.createEntity(`/rating/assignments`, entity)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateAssignment(entity: RatingAssignment): Observable<void> {

		const key = 'assignments';

		return super.updateEntity(`/rating/assignments/${entity.id}`, entity)
			.pipe(
				tap(s => this.updateCacheCollectionItem(key, entity.id, s))
				, map(s => null));
	}

	public deleteAssignment(id: Guid): Observable<void> {

		const key = 'assignments';

		return super.deleteEntity(`/rating-assignments/${id}`)
			.pipe(
				tap(s => this.removeFromCacheCollection(key, id))
				, map(s => null));
	}
}

@Injectable({
	providedIn: 'root'
})
export class RatecardRepository extends BaseRepository {
	totalRateCardRateRows$ = new BehaviorSubject<number>(0);
	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	public getById(id: Guid): Observable<RateCard> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${id}`)
			.pipe(
				map(res => {
					const ratecard = new RateCard().deserialize(res.body);
					this.cache.set('rateCardRecords-needsRefresh', false);

					return ratecard;
				}));
	}

	exportRateCard(id: Guid): Observable<number> {
		return this.http.get<ApiData<number>>(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${id}/export`)
			.pipe(map(res => res.body.data));
	}

	async getTaxRates(cancel$?: Subject<void>): Promise<TaxRateType[]> {
		const taxRateTypes = await this.http.promise(cancel$).get<ApiData<TaxRateType[]>>(`${this.config.apiUrl}${this.config.apiVersion}/tax-rates`);

		return taxRateTypes?.body?.data.map(x => new TaxRateType(Guid.empty, '').deserialize(x));
	}

	addRatecard(ratecard: RateCard): Observable<number> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards`, ratecard)
			.pipe(map(res => res.body.data));
	}

	cloneRatecard(item: Rating, id: Guid): Observable<HttpResponse<void>> {
		const ratecard = {
			name: item.name,
			description: item.description,
			tag: JSON.stringify({ url: `/rating` })
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${id}/clone`, ratecard);

	}

	updateRatecard(ratecard: RateCard): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${ratecard.id}`, null, ratecard);
	}

	async getRatePage(destroy$: Subject<void>, id: Guid, p: number, filter?: string, pageSize?: number, sortBy?: string, dir?: boolean): Promise<ApiDataPaging<RateCardRate[]>> {
		const rateCardRateResponse = await this.http.promise(destroy$).get<ApiDataPaging<RateCardRate[]>>(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${id}/rates?page=${p}&filter=${filter}&pagesize=${pageSize}&orderby=${sortBy}&dir=${dir}`);
		this.totalRateCardRateRows$.next(rateCardRateResponse.body?.records);

		return rateCardRateResponse.body;
	}

	async getRateCardRate(destroy$: Subject<void>, rateCardId: Guid, rateCardRateId: Guid): Promise<RateCardRate> {
		const rateCardRateResponse = await this.http.promise(destroy$).get<RateCardRate>(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${rateCardId}/rates/${rateCardRateId}`);
		return rateCardRateResponse.body;
	}

	async getTollsRatingTypes(destroy$: Subject<void>): Promise<CallTypesRatingMethod[]> {
		const tollsRatingResponse = await this.http.promise(destroy$).get<ApiData<CallTypesRatingMethod[]>>(`${this.config.apiUrl}${this.config.apiVersion}/tolls-rating-types`);
		return tollsRatingResponse.body?.data;
	}

	public addRate(ratecardId: Guid, rate: RateCardRate): Observable<number> {
		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${ratecardId}/rates`, rate)
				.pipe(
					map(res => {
						const newId = res.body.data;
						rate.id = newId;

						return newId;
					}));

		return observable;
	}

	public updateRate(ratecardId: Guid, rate: RateCardRate): Observable<void> {
		const cacheKey = 'rating-' + ratecardId;

		const observable =
			this.http.put(
				`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${ratecardId}/rates/${rate.id}`, null, rate)
				.pipe(
					map(res => {
						this.cache.get<RateCard>(cacheKey);
					}));

		return observable;
	}

	public deleteRate(ratecardId: Guid, rateId: Guid): Observable<void> {
		const cacheKey = 'rating-' + ratecardId;

		const observable =
			this.http.delete(
				`${this.config.apiUrl}${this.config.apiVersion}/rating/ratecards/${ratecardId}/rates/${rateId}`)
				.pipe(
					map(res => {

						const card = this.cache.get<RateCard>(cacheKey);

						this.cache.set(cacheKey, card);
					}));

		return observable;
	}

}

@Injectable({
	providedIn: 'root'
})
export class PricebookRepository {

	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig,
		private readonly cache: CacheService
	) {

	}

	getById(id: Guid): Observable<PriceBook> {
		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/rating-pricebooks/${id}`)
				.pipe(map(res => new PriceBook().deserialize(res.body.data)));

		return observable;
	}

	addPricebook(pricebook: PriceBook): Observable<number> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/rating-pricebooks`, pricebook)
			.pipe(
				map(res => {
					const newId = res.body.data;
					pricebook.id = newId;

					const cacheKey = 'rating-' + newId;
					this.cache.set(cacheKey, pricebook);

					return newId;
				}));
	}

	clonePricebook(item: Rating, id: Guid): Observable<Guid> {
		const pricebook = {
			name: item.name,
			description: item.description
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/rating/pricebooks/${id}/clone`, pricebook)
			.pipe(map(res => res.body.data));
	}

	updatePricebook(pricebook: PriceBook): Observable<HttpResponse<any>> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/rating-pricebook/${pricebook.id}`, null, pricebook);
	}

	public addPricebookProduct(pricebookId: Guid, product: PriceBookProduct): Observable<number> {
		const cacheKey = 'rating-' + pricebookId;

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/rating-pricebooks/${pricebookId}/product`, product)
			.pipe(
				map(res => {
					const newId = res.body.data;
					product.id = newId;

					const pb = this.cache.get<PriceBook>(cacheKey);
					if (pb) {
						pb.products?.push(product);
					}

					return newId;
				}));
	}

	public updatePricebookProduct(pricebookId: Guid, product: PriceBookProduct): Observable<void> {
		const cacheKey = 'rating-' + pricebookId;

		return this.http.put(
			`${this.config.apiUrl}${this.config.apiVersion}/rating-pricebooks/${pricebookId}/product/${product.id}`, null, product)
			.pipe(
				map(res => {
					this.cache.set('priceBooks-needsRefresh', true);
					const pb = this.cache.get<PriceBook>(cacheKey);

					if (pb) {
						const i = pb.products?.findIndex(x => x.id.equals(product.id));
						pb.products[i] = product;
					}
				}));

	}

	public deletePricebookProduct(pricebookId: Guid, productId: Guid): Observable<void> {
		const cacheKey = 'rating-' + pricebookId;

		return this.http.delete(
			`${this.config.apiUrl}${this.config.apiVersion}/rating-pricebook/${pricebookId}/delete/${productId}`)
			.pipe(
				map(res => {
					const pb = this.cache.get<PriceBook>(cacheKey);
					if (pb) {
						const i = pb.products?.findIndex(x => x.id.equals(productId));

						if (i > -1) {
							pb.products.splice(i, 1);
						}

						this.cache.set(cacheKey, pb);
					}

				}));
	}

	getRatingType(): Observable<RatingType> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/product-rating-types`)
			.pipe(map(res => res.body.data.map(x => new RatingType().deserialize(x))));
	}
}

@Injectable({
	providedIn: 'root'
})
export class BundleRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	public getAllBundles$(): Observable<Bundle[]> {

		const cacheKey = 'bundles';

		if (this.cache.get(cacheKey) != null) {
			return of(this.cache.get(cacheKey));
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/bundles`)
				.pipe(
					map(res => {
						const bundles = res.body.data.map(x => new Bundle().deserialize(x));

						this.cache.set(cacheKey, bundles);
						this.cache.set$(cacheKey, null);

						return bundles;
					}));

		this.cache.set$(cacheKey, observable);

		return observable;
	}

	async getAllBundles(destroy$?: Subject<void>): Promise<Bundle[]> {
		const res = await this.http.promise(destroy$).get<ApiData<Bundle[]>>(`${this.config.apiUrl}${this.config.apiVersion}/bundles`)
		return res.body.data.map(x => new Bundle().deserialize(x));
	}

	getBundle(id: Guid): Observable<Bundle> {
		const cacheKey = `bundles-${id}`;

		if (this.cacheHasKey(cacheKey)) {
			return this.getCache<Bundle[]>(cacheKey)
				.pipe(map(bundles => bundles.find(x => x.id.equals(id))));
		}

		return this.getAllBundles$().pipe(map(bundles => bundles.find(x => x.id.equals(id))));
	}

	public createBundle(bundle: Bundle): Observable<Guid> {
		const key = 'bundles';

		return super.createEntity(`/bundles`, bundle)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateBundle(bundle: Bundle): Observable<any> {
		const key = 'bundles';
		return this.updateEntity(`/bundles`, bundle)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));
	}

	public deleteBundle(id: Guid): Observable<void> {

		const key = 'bundles';

		return super.deleteEntity(`/bundles/${id}`)
			.pipe(
				tap(s => this.removeFromCacheCollection(key, id))
				, map(s => null));
	}

	public getBundleQuantities(id: Guid): Observable<BundleQuantity[]> {

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/bundles/${id}/quantities`)
				.pipe(
					map(res => {
						const bq = res.body.data.map(x => new BundleQuantity().deserialize(x));

						return bq;
					}));

		return observable;
	}

	getBundleQuantity(id: Guid, bundleId: Guid): Observable<BundleQuantity> {
		return this.getBundleQuantities(bundleId).pipe(map(bundlesQ => bundlesQ.find(x => x.id.equals(id))));
	}

	async createBundleQuantity(bundleId: Guid, instance: BundleQuantity, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/bundles/${bundleId}/quantities`, instance);
		return res.body;
	}

	async updateBundleQuantity(bundleId: Guid, instance: BundleQuantity, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/bundles/${bundleId}/quantities`, instance);
		return res.body;
	}

	public deleteBundleQuantity(bundleId: Guid, id: Guid): Observable<void> {
		return super.deleteEntity(`/bundles/${bundleId}/quantities/${id}`)
			.pipe(map(s => null));
	}
}
