import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateParams, HashMap } from '@ngneat/transloco';
import { GridOptions, ColDef, IRowNode } from 'ag-grid-community';
import { Notification } from 'domain/models';
import { Guid } from 'domain/types';
import { NotificationRepository } from 'repositories';
import { takeUntil, Subject, ReplaySubject } from 'rxjs';
import { LabelCellRendererComponent, CheckboxCellRendererComponent, DgAgGridBase } from 'shared/ag-grid';
import { translateHeaderFunc } from 'tools/grid-constants';
import { FormBuilder } from '@angular/forms';
import { NotificationType } from 'domain/enums';

@Component({
	selector: 'dg-notifications-list',
	templateUrl: './dg-notifications-list.component.html',
	styleUrls: ['./dg-notifications-list.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgNotificationsListComponent extends DgAgGridBase<Notification> implements OnInit, OnDestroy {
	gridOptions: GridOptions<Notification> = {
		...this.gridOptions,
		accentedSort: true,
		suppressRowClickSelection: true,
		rowHeight: 37,
		columnDefs: this.notificationsColumns(this.t),
		onGridReady: event => {
			super.BaseOnGridReady(event);

			this.notificationsResult$.pipe(takeUntil(this.unsubscribe$))
				.subscribe(notifications => event.api.setRowData(notifications));

			this.RefreshGridLocale(event.api, this.unsubscribe$);
		}
	};
	private notificationsResult$ = new ReplaySubject<Notification[]>();
	private readonly unsubscribe$ = new Subject<void>();
	private customerId: Guid;
	selectedNotification!: Notification;
	form = this.fb.group({
		emailTo: this.fb.control<string>(undefined),
	});
	isSaving = false;
	constructor(
		router: Router,
		route: ActivatedRoute,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private readonly notificationsRepo: NotificationRepository,
		protected readonly injector: Injector,
	) {
		super(router, injector);

		this.customerId = new Guid(route.snapshot.params.customerId);
	}

	async ngOnInit(): Promise<void> {
		const results = await this.notificationsRepo.getNotifications(this.unsubscribe$, this.customerId);

		this.notificationsResult$.next(
			results.filter(({ typeId }) => typeId !== NotificationType.UsageAmountLimit) // DAT-8994
		);
	}

	notificationSettings(rowNode: IRowNode<Notification>): void {
		this.selectedNotification = rowNode.data;

		this.form.patchValue({
			emailTo: this.selectedNotification.settings.emailTo
		});

		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onNotificationSettingsClose(): void {
		this.selectedNotification = undefined;
		this.form.reset();
	}

	async onNotificationUpdate(): Promise<void> {
		this.selectedNotification.settings.emailTo = this.form.value.emailTo;
		this.isSaving = true;

		await this.notificationsRepo.updateNotification(this.selectedNotification, this.unsubscribe$);
		this.isSaving = false;
		this.selectedNotification = undefined;
		this.form.reset();

		this.cdr.detectChanges();
	}

	async onNotificationSetActive(rowNode: IRowNode<Notification>): Promise<void> {
		rowNode.data.isLoading = true;
		rowNode.data.active = !rowNode.data.active;

		this.gridOptions.api.refreshCells({ rowNodes: [rowNode], force: true });

		await this.notificationsRepo.updateNotification(rowNode.data, this.unsubscribe$);

		rowNode.data.isLoading = false;
		this.gridOptions.api.refreshCells({ rowNodes: [rowNode], force: true });
	}

	private notificationsColumns(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T): ColDef<Notification>[] {
		return [
			{
				headerName: 'dg-notifications-list.grid-fields_name',
				field: 'typeDescription',
				filter: 'agTextColumnFilter',
				headerValueGetter: translateHeaderFunc(t)
			},
			{
				headerName: 'dg-notifications-list.grid-fields_active',
				field: 'active',
				cellClass: 'ag-cell-checkbox',
				cellRenderer: CheckboxCellRendererComponent<Notification>,
				cellRendererParams: params => ({
					id: params.data.id,
					busy: params.data.isLoading,
					checked: params.data.active,
					onClick: (rowNode: IRowNode<Notification>) => this.onNotificationSetActive(rowNode),
				}),
				headerValueGetter: translateHeaderFunc(t)
			},
			{
				headerName: 'dg-notifications-list.grid-fields_settings',
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				width: 150,
				maxWidth: 150,
				cellStyle: { 'white-space': 'normal' },
				cellRenderer: LabelCellRendererComponent<Notification>,
				cellRendererParams: params => ({
					label: 'Edit',
					onClick: (rowNode: IRowNode<Notification>) => this.notificationSettings(rowNode),
				}),
				headerValueGetter: translateHeaderFunc(t)
			},
		];
	}
}
