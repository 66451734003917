import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { ResellerUser } from 'domain/entities';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class ResellerUsersRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	async getAllResellerUsers(destroy?: Subject<void>): Promise<ResellerUser[]> {
		const res = await this.http.promise(destroy).get<ApiData<ResellerUser[]>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users`);
		return res.body.data.map(x => new ResellerUser().deserialize(x));
	}

	async getResellerUserById(userId: Guid, destroy?: Subject<void>): Promise<ResellerUser> {
		const users = await this.getAllResellerUsers(destroy);
		return users.find(x => x.id.equals(userId));
	}

	public async updateResellerUsers(user: ResellerUser, destroy?: Subject<void>): Promise<void> {
		await this.http.promise(destroy).put<ApiData<void>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users/${user.id}`, user);
	}

	public async addResellerUsers(user: ResellerUser, destroy?: Subject<void>): Promise<Guid> {
		const res = await this.http.promise(destroy).post<ApiData<Guid>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users`, user);
		return res.body.data;
	}

	public async deleteResellerUser(id: Guid, destroy?: Subject<void>): Promise<void> {
		await this.http.promise(destroy).delete<ApiData<void>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users/${id}`);
	}

	public async resetUser2fa(id: Guid, destroy?: Subject<void>): Promise<void> {
		await this.http.promise(destroy).post<ApiData<void>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users/${id}/2fa-reset`, null);
	}
}
