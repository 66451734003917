import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import {
	AutoTaskProducts,
	AutotaskExportMultilineModel,
	AutotaskInvoiceLines, AutotaskInvoices, AutotaskMapping,
	AutotaskSurcharge,
	AutotaskSurchargesWithTaxes,
	AutotaskTaxesLines,
	AutotaskTaxesLinesState, AutotaskTotalTaxes, AutotaskTransaction, AutotaskWizardSettings, Invoice
} from 'domain/entities';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { NavigatorService } from 'services';
import { IntegrationRepository } from 'repositories';
import { AutotaskExportRepository } from 'repositories/autotask-export-repository';
import { CacheModel, CacheService } from 'services/cache.service';
import { EMPTY, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { DgAlertService } from 'tools/alert';
import { DgDialogService } from 'tools/dialog';

@Component({
	selector: 'dg-autotask-export',
	templateUrl: './autotask-export.component.html',
	styleUrls: ['./autotask-export.component.sass']
})

export class AutotaskExportComponent implements OnInit, OnDestroy {
	currentStep = 1;
	invoices: Invoice[];
	excluded: Invoice[];
	selectedTypeId = 0;
	autotaskMapping = new AutotaskMapping();
	invoiceLinesMapping: AutotaskInvoiceLines[];
	surchargeMapping: AutotaskSurcharge[];
	surchargesWithTaxesMapping: AutotaskSurchargesWithTaxes[];
	taxLinesMapping: AutotaskTaxesLines[];
	taxesLinesStateMapping: AutotaskTaxesLinesState[];
	totalTaxesMapping: AutotaskTotalTaxes[];
	transactionsMapping: AutotaskTransaction[];
	products: AutoTaskProducts[];
	loading = false;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public wizardSettings = new AutotaskWizardSettings();
	public isExported = false;
	public exportedInvoices: string[];
	public isTaxMapping = true;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly repository: AutotaskExportRepository,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly integrationRepo: IntegrationRepository,
		private readonly dialogService: DgDialogService,
		private readonly transloco: TranslocoService,
		private readonly alertService: DgAlertService,
		private readonly location: Location
	) {
		this.t = transloco.translate.bind(transloco);

		this.cacheModel = this.cache.get('autotaskExportInvoices');
		this.cacheModelExcluded = this.cache.get('autotaskExportInvoices-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (!this.invoices.length) {
			this.location.back();
			return;
		}
	}

	async ngOnInit(): Promise<void> {
		const [wizardSettings, autoTaskSettings] = await Promise.all([
			this.repository.getAtWizardSettings(this.destroy$),
			this.integrationRepo.getAutotaskIntegration(this.destroy$)
		]).withBusy();

		this.wizardSettings = wizardSettings;
		this.isTaxMapping = autoTaskSettings.dontExportTaxes;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	isActiveStep(step: number): boolean {
		return this.currentStep === step;
	}

	public ContractTypesSelected(force: boolean = false): void {
		if (this.invoices) {
			this.loading = true;
			const contractType = this.selectedTypeId === 0 ? null : this.selectedTypeId;
			const invoiceIds = this.invoices.map(x => x.id);
			const actionFilters = this.cache.get('invoices-action-filterParams');
			const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';
			this.repository.contractsTypesPost(contractType, invoiceIds, force, this.excluded, filterParams)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (res) => {
						if (res) {
							this.autotaskMapping = res;
							this.products = this.autotaskMapping.products;
							this.NextStep();
						} else {
							this.alertService.show(this.t('at-export.not-found-warning'), 'error');
						}
						this.loading = false;
					},
					error: (res) => {
						this.loading = false;
						if (Array.isArray(res.error.references)) {
							this.isExported = true;
							this.exportedInvoices = res.error.references;
							return EMPTY;
						} else {
							throw res;
						}
					}
				});
		}
	}

	public onChangeContract(item: AutotaskInvoices): void {
		item.selectedAtContractId = +item.selectedAtContractId;
	}

	public ExistingItemsSelected(): void {
		if (this.wizardSettings.deleteExisting) {
			const s = this.dialogService.confirm$(
				this.t('at-export.delete_confirmation-title'),
				this.t('at-export.delete_confirmation-msg'))
				.subscribe(confirm => {
					if (confirm) {
						this.NextStep();
					}
					s.unsubscribe();
				});
		} else {
			this.NextStep();
		}
	}

	get transactionMapFiltered(): AutotaskTransaction[] {
		switch (this.wizardSettings.selectedExportProductTransactions) {
			case 0: {
				return [];
			}
			case 1: {
				return this.transactionsMapping?.filter(t => t.isOneOff);
			}
			case 2:
			default: {
				return this.transactionsMapping;
			}
		}
	}

	public GroupingTypeSelected(): void {
		this.loading = true;
		const groupingTypeId = +this.wizardSettings.selectedGroupingTypeId;
		const autotaskMappingInvoices = this.autotaskMapping.invoices.map(x => x.invoiceId);
		this.repository.contractsGroupingTypePost(groupingTypeId, autotaskMappingInvoices)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res) => {
					this.invoiceLinesMapping = res.invoiceLinesMapping;
					this.surchargeMapping = res.surchargeMapping;
					this.surchargesWithTaxesMapping = res.surchargesWithTaxesMapping;
					this.taxLinesMapping = res.taxLinesMapping;
					this.taxesLinesStateMapping = res.taxesLinesStateMapping;
					this.totalTaxesMapping = res.totalTaxesMapping;
					this.transactionsMapping = res.transactionsMapping;

					this.NextStep();
					this.loading = false;
				},
				error: () => this.loading = false
			});
	}

	public Export(): void {
		this.loading = true;
		const exportModel = new AutotaskExportMultilineModel();
		exportModel.invoices = this.autotaskMapping.invoices;
		exportModel.transactionResponses = this.transactionsMapping;
		exportModel.invoiceLinesResponses = this.invoiceLinesMapping;
		exportModel.surchargeResponses = this.surchargeMapping;
		exportModel.surchargesWithTaxesResponses = this.surchargesWithTaxesMapping;
		exportModel.totalTaxesResponses = this.totalTaxesMapping;
		exportModel.taxesLinesStateResponses = this.taxesLinesStateMapping;
		exportModel.taxesLinesResponses = this.taxLinesMapping;
		exportModel.selectedGroupingTypeId = this.wizardSettings.selectedGroupingTypeId;
		exportModel.selectedPriceCalculationTypeId = this.wizardSettings.selectedPriceCalculationTypeId;
		exportModel.selectedExportProductTransactions = this.wizardSettings.selectedExportProductTransactions;
		exportModel.deleteExisting = this.wizardSettings.deleteExisting;
		exportModel.combineSurchargesWithTaxes = this.wizardSettings.combineSurchargesWithTaxes;
		exportModel.taxMultiline = this.wizardSettings.sureTaxMultiline;
		exportModel.taxForState = this.wizardSettings.groupTaxByState;

		exportModel.tag = this.cacheModel.Tag;

		this.repository.export(exportModel)
			.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false))
			.subscribe({
				next: (result) => {
					if (result.operationId) {
						this.navigator.toOperation(result.operationId);
					}
				}
			});
	}

	NextStep(): void {
		if (this.currentStep < 9) {
			this.currentStep = this.currentStep + 1;
		}
	}

	public BackStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	onExportConfirm(): void {
		this.isExported = false;
		this.exportedInvoices = [];
		this.ContractTypesSelected(true);
	}
}
