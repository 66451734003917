<div *ngIf="loading" class="app-spinner-qb"></div>
<form *ngIf="!loading" class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps {{CheckStep(1)}}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(2)}}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(3)}}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step3_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(4)}}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(5)}}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step5_title' | transloco}}</span>
		</li>

		<!-- TODO provide proper solution to manage steps START-->
		<li  *ngIf="qbSteps.isTaxLinesMapping" class="steps {{CheckStep(6)}}">
			<span class="steps__label">{{'global-export.step6_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step6_title' | transloco}}</span>
		</li>

		<li class="steps {{CheckStep(qbSteps.isTaxLinesMapping ? 7 : 6)}}">
			<span class="steps__label">{{'global-export.step' + (qbSteps.isTaxLinesMapping ? '7' : '6') + '_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step7_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(qbSteps.isTaxLinesMapping ? 8 : 7)}}">
			<span class="steps__label">{{'global-export.step' + (qbSteps.isTaxLinesMapping ? '8' : '7') + '_label' | transloco}}</span>
			<span class="steps__label">{{'qb-export.step8_title' | transloco}}</span>
		</li>

		<!-- TODO provide proper solution to manage steps END -->
	</ul>
	<div class="form__content">
		<fieldset class="form__section {{CheckStep(1)}}">
			<h3 class="form__section-title">
				{{'qb-export.step1_title' | transloco}}
			</h3>
			<form #qbGeneralSettings="ngForm" >
				<div class="form__group block" >
					<div class="form__group-item form__group-item--500"
						[ngStyle]="{'justify-content': !isUseInvoiceDueDate ? 'center' : 'inherit'}">
						<p class="width-54p">{{'qb-export.step1_field-selectedEntityTypeId' | transloco}}</p>
						<ng-select #inputProduct
								style="width: 225px;"
								name="selectedEntityTypeId"
								required
								[searchable]="true"
								[clearable]="false"
								[(ngModel)]="selectedEntityTypeId">
								<ng-option [value]="1">{{'qb-export.field-entityTypes-opt-0' | transloco}}</ng-option>
								<ng-option [value]="2">{{'qb-export.field-entityTypes-opt-1' | transloco}}</ng-option>
						</ng-select>
					</div>
					<div class="form__group-item form__group-item--500"
						 [ngStyle]="{'justify-content': !isUseInvoiceDueDate ? 'center' : 'inherit'}">
						<p class="width-54p">
							{{'qb-export.step1_field-selectedPaymentTermId' | transloco}}
						</p>
						<p *ngIf="!isUseInvoiceDueDate">
							{{'qb-export.step1_field-selectedPaymentTermId-due' | transloco}}
						</p>
						<ng-select #inputProduct
									style="width: 225px;"
								   name="selectedPaymentTermsId"
								   [items]="qbTerms"
								   [searchable]="true"
								   [(ngModel)]="selectedPaymentTermId"
								   bindLabel="name"
								   [clearable]="true"
								   bindValue="id"
								   *ngIf="isUseInvoiceDueDate">
							<ng-template>
								<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)"/>
							</ng-template>
						</ng-select>
					</div>
					<div *ngIf="quickBooksDetails.allowAdjustmentLine" class="form__group-item form__group-item--500"
						 [ngStyle]="{'justify-content': !isUseInvoiceDueDate ? 'center' : 'inherit'}">
						 <p class="width-54p">{{'qb-export.step1_field-adjustmentLine' | transloco}}</p>
						<ng-select
							style="width: 225px;"
							#inputProduct bindLabel="identifier" required
							bindValue="id" name="selectedProductId"
							[items]="products"
							[searchable]="true"
							[clearable]="false"
							[(ngModel)]="adjustmentLineProduct">
							<ng-template>
								<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
							[disabled]="!qbGeneralSettings.valid"
							(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(2)}}">
			<h3 class="form__section-title">
				{{'qb-export.step2_title' | transloco}}
			</h3>

			<form #ProductsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'qb-export.step2_header-service' | transloco}}</p>
						<p>{{'qb-export.step2_header-record' | transloco}}</p>
						<p>{{'qb-export.step2_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of productsMapping | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedProductId']; let i1 = index" class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="identifier" required
									bindValue="id" name="selectedProductId{{i}}_{{i1}}" [items]="products"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
									<ng-template>
										<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="PreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(3)}}">
			<h3 class="form__section-title">
				{{'qb-export.step3_title' | transloco}}
			</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div *ngIf="!quickBooksDetails.consolidateProducts" class="form__group-item form__group-item--800 flex-start">
						<p class="width-54p">
							{{'qb-export.step3_field-uniteProducts' | transloco}}
						</p>
						<dg-checkbox
							name="uniteProducts"
							ngDefaultControl
							[id]="'uniteProducts'"
							[(ngModel)]="uniteProducts">
						</dg-checkbox>
					</div>

					<div *ngFor="let item of transactionsMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.code}}-{{item.transactionName}}</p>
						<ng-select #inputProduct bindLabel="identifier" required bindValue="id"
							name="selectedProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedProductId">
							<ng-template>
								<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="PreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(4)}}">
			<h3 class="form__section-title">
				{{'qb-export.step4_title' | transloco}}
			</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'qb-export.step4_field-combineSurchargesWithTaxes' | transloco}}
						</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [(ngModel)]="combineSurchargesWithTaxes"></dg-checkbox>
					</div>
					<div *ngIf="!combineSurchargesWithTaxes && (resellerSurcharges$ | async)?.length > 1" class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'qb-export.step4_field-surchargeSeparatelyMapping' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>{{'qb-export.step4_field-surchargeSeparatelyMapping-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="surchargeSeparatelyMapping" ngDefaultControl
							[id]="'surchargeSeparatelyMapping'" [(ngModel)]="surchargeSeparately"></dg-checkbox>
					</div>

					<div *ngIf="!combineSurchargesWithTaxes && !surchargeSeparately">
						<div *ngFor="let item of surchargeMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>

					<div *ngIf="surchargeSeparately">
						<div *ngFor="let item of surchargeSeparatelyMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>

				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="PreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<!-- TODO provide proper solution to manage steps START-->
		<fieldset class="form__section {{CheckStep(5)}}">
			<h3 *ngIf="!combineSurchargesWithTaxes" class="form__section-title">{{'qb-export.step5_title' | transloco}}</h3>
			<h3 *ngIf="combineSurchargesWithTaxes" class="form__section-title">{{'qb-export.step5_title-combineSurchargesWithTaxes' | transloco}}</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p class="width-70p">{{'qb-export.step5_field-useProductsTaxRates' | transloco}}</p>
						<dg-checkbox
							name="useProductsTaxRates"
							ngDefaultControl
							[id]="'useProductsTaxRates'"
							[(ngModel)]="useProductsTaxRates"></dg-checkbox>
					</div>

					<div class="form__group-item form__group-item--500 flex-start">
						<p class="width-70p">{{'qb-export.step5_field-sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1"aria-hidden="true">
								<span>{{'qb-export.step5_field-sureTaxMultiline-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox
							name="sureTaxMultiline"
							ngDefaultControl
							[id]="'sureTaxMultiline'"
							[(ngModel)]="sureTaxMultiline">
						</dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p class="width-70p">
							{{'qb-export.step5_field-groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>{{'qb-export.step5_field-groupTaxByState-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox
							name="groupTaxByState"
							ngDefaultControl
							[id]="'groupTaxByState'"
							[(ngModel)]="groupTaxByState">
						</dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && groupTaxByState">
						<div *ngFor="let line of sureTaxByStateLineMapping; let i = index"
							 class="form__group-item form__group-item--800">
							 <p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
									   bindValue="id" name="selectedProductId{{i}}" [items]="products" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="sureTaxMultiline && !groupTaxByState">
						<div *ngFor="let line of sureTaxLineMapping; let i = index"
							 class="form__group-item form__group-item--800">
							 <p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}} {{line.selectedProductId}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
									   bindValue="id" name="selectedProductId{{i}}" [items]="products" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!sureTaxMultiline">
						<div *ngFor="let item of taxMapping; let i = index"
							 class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" bindValue="id"
									   required name="selectedProductId{{i}}" [items]="products"
									   [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div *ngIf="combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p class="width-70p">{{'qb-export.step5_field-useProductsTaxRates' | transloco}}</p>
						<dg-checkbox
							name="useProductsTaxRates"
							ngDefaultControl
							[id]="'useProductsTaxRates'"
							[(ngModel)]="useProductsTaxRates">
						</dg-checkbox>
					</div>
					<div *ngFor="let item of surchargeWithTaxMapping; let i = index"
						 class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="identifier" bindValue="id" required
								   name="selectedProductId{{i}}" [items]="products" [searchable]="true"
								   [clearable]="false" [(ngModel)]="item.selectedProductId">
							<ng-template>
								<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="PreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset *ngIf="qbSteps.isTaxLinesMapping" class="form__section {{CheckStep(6)}}">
			<h3 class="form__section-title">
				{{'qb-export.step6_title' | transloco}}
			</h3>
			<form #TaxForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p class="width-54p">
							{{'qb-export.step6_field-groupTaxByStateForInvoiceTaxes' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>{{'qb-export.step6_field-groupTaxByStateForInvoiceTaxes' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox
							name="groupTaxByStateForInvoiceTaxes"
							ngDefaultControl
							[id]="'groupTaxByStateForInvoiceTaxes'"
							[(ngModel)]="groupTaxByStateForInvoiceTaxes">
						</dg-checkbox>
					</div>
					<div *ngIf="groupTaxByStateForInvoiceTaxes">
						<div *ngFor="let line of sureTaxByStateLinesMappingForInvoiceTaxes; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ?
								('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" required
									   bindValue="value" name="selectedProductId{{i}}" [items]="taxRates" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!groupTaxByStateForInvoiceTaxes">
						<div *ngFor="let line of sureTaxLinesMappingForInvoiceTaxes; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) :
								'global-export.inclusive_label' | transloco}} {{line.selectedProductId}}</p>
							<ng-select #inputProduct bindLabel="name" required
									   bindValue="value" name="selectedProductId{{i}}" [items]="taxRates" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="PreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid"
							(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<!-- TODO provide proper solution to manage steps END -->

		<fieldset class="form__section {{CheckStep(qbSteps.isTaxLinesMapping ? 7 : 6)}}">
			<h3 class="form__section-title">
				{{'qb-export.step7_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'qb-export.step7_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input type="radio" id="KeepItems" name="radio-delete" [value]="false"
							[(ngModel)]="deleteExisting">
						<label class="radiobox-label" for="KeepItems">
							{{'qb-export.step7_field-deleteExisting-false' | transloco}}
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="DeleteItems" name="radio-delete" [value]="true"
							[(ngModel)]="deleteExisting">
						<label class="radiobox-label" for="DeleteItems">
							{{'qb-export.step7_field-deleteExisting-true' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="PreviosStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" (click)="NextStep()">
					{{'global.export_btn-next' | transloco}}
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section {{CheckStep(qbSteps.isTaxLinesMapping ? 8 : 7)}}">
			<h3 class="form__section-title">
				{{'qb-export.step8_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'qb-export.step8_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'qb-export.field-priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'qb-export.field-priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="PreviosStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
				</button>
			</div>
		</fieldset>
	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title">
					<b>
						{{'qb-export.confirmation_title' | transloco}}
					</b>
				</div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'qb-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item" >
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
