import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { XeroTrackingCategoryEntity } from 'domain/entities';
import { ApiData } from 'domain/types';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class XeroRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {
	}

	async getTrackingCategories(destroy$?: Subject<void>): Promise<XeroTrackingCategoryEntity[]> {
		const res = await this.http.promise(destroy$).get<ApiData<XeroTrackingCategoryEntity[]>>(
			`${this.config.apiUrl}${this.config.apiVersion}/xero/tracking-categories`);

		return res?.body?.data?.map(x => new XeroTrackingCategoryEntity().deserialize(x));
	}
}
