export enum TransactionGenerationType {
	NotGenerateTransactions = 1,
	DeleteExistingTransactions = 2,
	RetainTransactions = 3,
	GenerateTransactionsWithNoTransactions = 4
}

export enum InvoiceStatus {
	None = 0,
	Generating = 1,
	Generated = 2,
	Failed = 3,
	Emailed = 4,
	Sent = 5,
	Deleted = 6,
	Sending = 7,
	SendFailed = 8,
	SentManually = 9,
	Uninvoiced = 10,
	Paid = 11,
	PaidFailed = 12,
	PaidManually = 13,
	PartiallyPaid = 14,
	Draft = 15,
	PartiallyPaidManually = 16
}

export enum InvoiceTemplateType {
	Unknown = 0,
	Invoice = 1,
	CreditNote = 2
}

export enum InvoiceTemplateTextType {
	Unknown = '',
	Invoice = 'Invoice',
	CreditNote = 'CreditNote'
}

export enum InvoiceType {
	Unknown = 0,
	Agreement = 1,
	Miscellaneous = 2
}
