import { InvoiceStatus, InvoiceType } from 'domain/enums';

export class InvoiceHelper {
	static mapInvoiceType(type: InvoiceType): string {
		if (!type) return '';

		const typeLabels: Record<InvoiceType, string> = {
			[InvoiceType.Unknown]: '',
			[InvoiceType.Agreement]: 'Agreement',
			[InvoiceType.Miscellaneous]: 'Miscellaneous',
		};

		return typeLabels[type] || '';
	}

	static getInvoiceStatusMapper(): Record<InvoiceStatus, string> {
		return {
			[InvoiceStatus.None]: 'None',
			[InvoiceStatus.Generating]: 'Generating',
			[InvoiceStatus.Generated]: 'Generated',
			[InvoiceStatus.Failed]: 'Gen. Failed',
			[InvoiceStatus.Emailed]: 'Emailed',
			[InvoiceStatus.Sent]: 'Sent',
			[InvoiceStatus.Deleted]: 'Deleted',
			[InvoiceStatus.Sending]: 'Sending',
			[InvoiceStatus.SendFailed]: 'Send Failed',
			[InvoiceStatus.SentManually]: 'Sent Manually',
			[InvoiceStatus.Uninvoiced]: 'Uninvoiced',
			[InvoiceStatus.Paid]: 'Paid',
			[InvoiceStatus.PaidFailed]: 'Paid Failed',
			[InvoiceStatus.PaidManually]: 'Paid Manually',
			[InvoiceStatus.PartiallyPaid]: 'Paid Partially',
			[InvoiceStatus.Draft]: 'Draft',
			[InvoiceStatus.PartiallyPaidManually]: 'Partially Paid Manually',
		};
	}

	static getInvoiceStatusDictionary(): { key: InvoiceStatus; value: string; }[] {
		return Object.entries(InvoiceHelper.getInvoiceStatusMapper()).map(([key, value]) => ({ key: Number(key) as InvoiceStatus, value }));
	}

	static mapInvoiceStatus(status: InvoiceStatus): string {
		if (!status) return '';

		const statusLabels = this.getInvoiceStatusMapper();
		return statusLabels[status] || '';
	}
}
