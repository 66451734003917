export enum PaymentGatewayType {
	Unknown = 0,
	Stripe = 1,
	AuthorizeNet = 2,
	IPPay = 4,
	Moneris = 5,
	Pinch = 6,
	Manually = 7,
	Nexio = 8,
	Check = 9
}
