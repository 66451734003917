import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DgTileInputComponent } from '../dg-tile-input.component';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { AddressLookupRepository, RetrieveResultData, SearchResult } from 'repositories';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgSearchInputComponent),
	multi: true
};

@Component({
	selector: 'dg-search-input',
	templateUrl: './dg-search-input.component.html',
	styleUrls: ['./dg-search-input.component.css'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class DgSearchInputComponent extends DgTileInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
	private defaultTooltipText = 'dg-search-input.icon-not-verified_hint';
	private defaultIconName = 'shield-refresh.png';

	@Input() initialValue = '';
	@Input() debounceTime = 500;
	@Input() iconName: string = this.defaultIconName;

	@Output() onAddressData = new EventEmitter<RetrieveResultData>();
	@Output() onValidationSubmit = new EventEmitter();
	@Output() onValidationAnswer = new EventEmitter<boolean>();

	results: SearchResult | null = null;
	tooltip: string;
	validated: boolean = null;

	provider: boolean;
	@Input() loading: boolean;

	searchRequestSubscriptions: Subscription[] = [];
	show: boolean;

	inputValue = new Subject<string>();
	trigger = this.inputValue.pipe(
		debounceTime(this.debounceTime),
		distinctUntilChanged()
	);

	subscriptions: Subscription[] = [];
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	constructor(
		private readonly addressLookupApiService: AddressLookupRepository,
		public readonly transloco: TranslocoService
	) {
		super();
		this.t = transloco.translate.bind(transloco);
		this.tooltip = this.t(this.defaultTooltipText);
	}

	ngOnInit(): void {
		const subscription = this.trigger.subscribe(currentValue => {
			this.onTextChange(currentValue);
		});
		this.subscriptions.push(subscription);
		this.findProvider();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	onTextChange(changedText: string): void {
		if (!this.provider) {
			return;
		}
		if (!changedText) {
			return;
		}
		this.cancelPendingRequests();
		this.loading = true;
		const addressLookupSubscription = this.addressLookupApiService
			.searchAddress(changedText)
			.subscribe({
				next: response => {
					this.results = response;
					this.loading = false;
				},
				error: () => this.loading = false
			});
		this.searchRequestSubscriptions.push(addressLookupSubscription);
	}

	onInput(e: any): void {
		this.inputValue.next(e.target.value);
	}

	cancelPendingRequests(): void {
		this.searchRequestSubscriptions.forEach(sub => sub.unsubscribe());
	}

	onItemClick(recoddId: string): void {
		this.show = false;
		this.results = null;
		this.loading = true;
		this.addressLookupApiService.retrieveAddress(recoddId)
			.subscribe({
				next: response => {
					this.onAddressData.emit(response.data);
					this.loading = false;
				},
				error: () => this.loading = false
			});
	}

	onClickedOutside(e: Event): void {
		this.show = false;
		this.results = null;
	}

	onValidateClick(): void {
		if (this.validated) {
			return;
		}
		this.onValidationSubmit.emit();
	}

	@Input()
	set refreshIcon(validated: boolean) {
		switch (validated) {
			case true: {
				this.iconName = 'shield-check.png';
				this.tooltip = this.t('dg-search-input.icon-verified_hint');
				this.validated = true;
				break;
			}
			case false: {
				this.iconName = 'shield-remove.png';
				this.tooltip = this.t('dg-search-input.icon-invalid_hint');
				this.validated = false;
				break;
			}
			default: {
				this.iconName = this.defaultIconName;
				this.tooltip = this.t(this.defaultTooltipText);
				this.validated = null;
			}
		}
	}

	findProvider(): void {
		this.addressLookupApiService.findProvider()
			.subscribe({
				next: response => {
					if (response.data.provider) {
						this.provider = true;
					}
				}
			});
	}

	@Input()
	set validateAddressFields(addressData: RetrieveResultData) {
		if (!addressData) {
			return;
		}
		this.loading = true;
		this.addressLookupApiService.validate(addressData)
			.subscribe({
				next: response => {
					this.loading = false;
					this.onValidationAnswer.emit(response.data.status === 'OK');
				},
				error: () => {
					this.loading = false;
					this.onValidationAnswer.emit(false);
				}
			});
	}
}
