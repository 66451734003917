export enum EmailType {
	Unknown = 0,
	RPPasswordReset = 1,
	Invoice = 2,
	CreditNote = 3,
	ServiceItemNotification = 10,
	NewResellerUserInvite = 19,
	CustomerPortalPasswordReset = 20,
	OverdueNotification = 21,
	PaymentReceipt = 22,
	NewCustomerUserInvite = 23,
	FailedToPayInvoiceNotification = 24,
	FailedToSendInvoiceNotification = 25
}
