import { Serializable } from 'domain/models';
export interface HaloExportContractsPrepareResponse {
	invoices: HaloExportInvoiceMapping[];
	products: HaloExportProduct[];
}

export interface HaloExportDataPrepareResponse {
	invoiceLinesResponses: HaloChargesInvoiceLineMapping[];
	transactionResponses: HaloChargesTransactionMapping[];
	surchargeResponses: HaloChargesSurchargeMapping[];
	surchargesWithTaxesResponses: HaloChargesSurchargeWithTaxMapping[];
	totalTaxesResponses: HaloChargesTaxMapping[];
	taxesLinesResponses: HaloChargesTaxLineMapping[];
	taxesLinesStateResponses: HaloChargesTaxLineStateMapping[];
}

export interface HaloExportInvoiceMapping {
	invoiceId: number;
	invoiceNumber: string;
	companyName: string;
	customerId: number;
	selectedHaloContractId: number;
	selectedHaloInvoiceId: number;
	selectedContratInvoices: HaloExportInvoice[];
	contracts: HaloExportContract[];
}

export interface HaloExportProduct {
	id: number;
	name: string;
}

export interface HaloExportContract {
	id: number;
	name: string;
	invoices: HaloExportInvoice[];
}

export interface HaloExportInvoice {
	id: number;
	invoiceNumber: string;
}

export interface HaloChargesExportModel {
	invoices: HaloExportInvoiceMapping[];
	transactionResponses: HaloChargesTransactionMapping[];
	invoiceLinesResponses: HaloChargesInvoiceLineMapping[];
	surchargeResponses: HaloChargesSurchargeMapping[];
	surchargesWithTaxesResponses: HaloChargesSurchargeWithTaxMapping[];
	totalTaxesResponses: HaloChargesTaxMapping[];
	taxesLinesResponses: HaloChargesTaxLineMapping[];
	taxesLinesStateResponses: HaloChargesTaxLineStateMapping[];
	selectedGroupingTypeId: number;
	selectedPriceCalculationTypeId: number;
	deleteExisting: boolean;
	combineSurchargesWithTaxes: boolean;
	doNotExportProducts: boolean;
	taxMultiline: boolean;
	taxForState: boolean;
	tag: string;
}

export interface HaloChargesTransactionMapping {
	transactionName: string;
	code: string;
	selectedHaloItemId: number;
}

export interface HaloChargesInvoiceLineMapping {
	serviceId: number;
	siteId: number;
	recordType: number;
	groupName: string;
	description: string;
	selectedHaloItemId: number;
}

export interface HaloChargesSurchargeMapping {
	surchargeName: string;
	selectedHaloItemId: number;
}

export interface HaloChargesSurchargeWithTaxMapping {
	combineName: string;
	selectedHaloItemId: number;
}

export interface HaloChargesTaxMapping {
	taxName: string;
	selectedHaloItemId: number;
	taxInclusive: boolean;
}

export interface HaloChargesTaxLineMapping {
	taxTypeDesc: string;
	selectedHaloItemId: number;
	taxInclusive: boolean;
}

export interface HaloChargesTaxLineStateMapping {
	taxTypeDesc: string;
	stateCode: string;
	selectedHaloItemId: number;
	taxInclusive: boolean;
}

export class HaloMultilineWizardSettings implements Serializable<HaloMultilineWizardSettings> {
	public uniteSameProductsInOne = false;
	public combineSurchargesWithTaxes = false;
	public selectedGroupingTypeId = 1;
	public selectedPriceCalculationTypeId = 1;
	public sureTaxMultiline = false;
	public groupTaxByState = false;
	public deleteExisting = false;

	deserialize(json: any): HaloMultilineWizardSettings {
		this.uniteSameProductsInOne = json['unite-same-products-in-one-invoice-line'];
		this.combineSurchargesWithTaxes = json['combine-surcharges-with-taxes'];
		this.selectedGroupingTypeId = json['grouping-type'];
		this.selectedPriceCalculationTypeId = json['price-calculation-type'];
		this.sureTaxMultiline = json['multiline-taxes'];
		this.groupTaxByState = json['split-taxes-by-state'];
		this.deleteExisting = json['delete-existing'];

		return this;
	}
}

export class HaloChargesWizardSettings implements Serializable<HaloChargesWizardSettings> {
	public combineSurchargesWithTaxes = false;
	public selectedGroupingTypeId = 1;
	public selectedPriceCalculationTypeId = 1;
	public sureTaxMultiline = false;
	public groupTaxByState = false;
	public deleteExisting = false;
	public doNotExportProducts = false;

	deserialize(json: any): HaloChargesWizardSettings {
		this.combineSurchargesWithTaxes = json['combine-surcharges-with-taxes'];
		this.selectedGroupingTypeId = json['grouping-type'];
		this.selectedPriceCalculationTypeId = json['price-calculation-type'];
		this.sureTaxMultiline = json['multiline-taxes'];
		this.groupTaxByState = json['split-taxes-by-state'];
		this.deleteExisting = json['delete-existing'];
		this.doNotExportProducts = json['do-not-export-products'];

		return this;
	}
}