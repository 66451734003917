import { NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { TranslocoRootModule } from 'transloco-root.module';
import { AutosizeModule } from 'ngx-autosize';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';

import { BusyModule } from './modules/busy';

import { DgInvoiceActionsModule } from './modules/invoice-actions';

import {
	DgTrimDirective,
	DgTwoDigitDecimaNumberDirective,
	DgFourDigitDecimaNumberDirective,
	DgNumberTrimDirective
} from './directives';
import {
	DgCrumbsComponent,
	DgCrumbsService,
	DgPageComponent,
	DgJobProgressPageComponent,
	DgCustomerSiteComponent,
	CustomerSiteService,
	DgTileInputComponent,
	DgSearchInputComponent,
	DgTileSelectComponent,
	DgIpndIntegrationSettingsComponent,
	DgCheckboxComponent,
	DgPasswordStrengthComponent,
	DgSliderComponent,
	DgModalProgressComponent,
	DgDdButtonComponent,
	DgGridPaginatorComponent,
	DgModalComponent,
	DgMultiInputComponent,
	DgDeleteEntityComponent,
	InvoiceNumberNextValidator,
	DgTileComponent,
	DgButtonComponent,
	DgFilterBoxComponent,
	DgDateSelectorComponent,
	DgDdInadvanceComponent,
	DgPeriodSelectorComponent,
	DgTaxDetailsComponent,
	DgSmartDatepickerComponent,
	DgNotificationsListComponent,
	DgScrollableComponent
} from './components';

import { DgPendingChangesGuard } from './guards';
import { ArraySortPipe, GroupByPipe, MomentDoDate, MomentFullDate, OrdinalNumberPipe, SanitizeHtmlPipe, SplitByCapsPipe } from './pipes';

import {
	ChildLinkRenderer,
	ChildLinkViewRendererComponent,
	ChildLinkIconRenderer,
	ChildLinkInvoiceRendererComponent,
	InvoiceStatusCellRendererComponent,
	ChildLinkTooltipRenderer,
	ChildCellProductCodeRendererComponent,
	ChildCellDoubleDateRenderer,
	ChildCellDoubleServiceRenderer,
	ChildCellIconLabel,
	ChildCellCustomerRendererComponent,
	ChildCellChargesRenderer,
	CustomDateComponent,
	ChildCellServiceItemDescriptionComponent,
	ChildCellInvoiceRendererComponent,
	ChildDefaultCellRendererComponent,
	ChildQuoterRendererComponent,
	CheckboxCellRendererComponent,
	LabelCellRendererComponent
} from 'shared/ag-grid';

export function breadcrumbServiceFactory(router: Router): DgCrumbsService {
	return new DgCrumbsService(router);
}

const sharedModules = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule,
	BusyModule,
	TranslocoRootModule,
	ScrollingModule,
	AutosizeModule,
	UiSwitchModule,
	NgSelectModule,
	NgxPermissionsModule,
	DgInvoiceActionsModule,
	NgxDaterangepickerMd,
	AgGridModule,
];

const sharedDirectives = [
	DgTrimDirective,
	DgTwoDigitDecimaNumberDirective,
	DgFourDigitDecimaNumberDirective,
	DgNumberTrimDirective,
];

const sharedComponents = [
	// components, dialogs, etc...
	DgJobProgressPageComponent,
	DgPageComponent,
	DgCrumbsComponent,
	DgTileInputComponent,
	DgSearchInputComponent,
	DgCustomerSiteComponent,
	DgTileSelectComponent,
	DgIpndIntegrationSettingsComponent,
	DgCheckboxComponent,
	DgPasswordStrengthComponent,
	DgSliderComponent,
	DgModalProgressComponent,
	DgDdButtonComponent,
	DgModalComponent,
	DgGridPaginatorComponent,
	DgMultiInputComponent,
	DgDeleteEntityComponent,
	InvoiceNumberNextValidator,
	DgTileComponent,
	DgButtonComponent,
	DgFilterBoxComponent,
	DgDateSelectorComponent,
	DgDdInadvanceComponent,
	DgPeriodSelectorComponent,
	DgSmartDatepickerComponent,
	DgTaxDetailsComponent,
	DgNotificationsListComponent,
	DgScrollableComponent
];

const sharedPipes = [
	OrdinalNumberPipe,
	GroupByPipe,
	ArraySortPipe,
	MomentFullDate,
	MomentDoDate,
	SplitByCapsPipe,
	SanitizeHtmlPipe,
];

const sharedServices = [
	// services, guards, etc...
	DgPendingChangesGuard,
	{ provide: DgCrumbsService, useFactory: breadcrumbServiceFactory, deps: [Router] },
	CustomerSiteService,
	NgSelectConfig
];

const sharedAgGridRenderers = [
	ChildLinkRenderer,
	ChildLinkViewRendererComponent,
	ChildLinkIconRenderer,
	ChildCellProductCodeRendererComponent,
	ChildCellServiceItemDescriptionComponent,
	ChildCellDoubleDateRenderer,
	ChildCellDoubleServiceRenderer,
	ChildCellChargesRenderer,
	CustomDateComponent,
	ChildLinkInvoiceRendererComponent,
	InvoiceStatusCellRendererComponent,
	ChildLinkTooltipRenderer,
	ChildCellIconLabel,
	ChildCellCustomerRendererComponent,
	ChildCellInvoiceRendererComponent,
	ChildDefaultCellRendererComponent,
	ChildQuoterRendererComponent,

	LabelCellRendererComponent,
	CheckboxCellRendererComponent
];

@NgModule({
	declarations: [
		...sharedDirectives,
		...sharedComponents,
		...sharedPipes,
		...sharedAgGridRenderers
	],
	imports: [
		...sharedModules,
		UiSwitchModule.forRoot({
			size: 'small',
			color: '#00a9c0',
			switchColor: 'white',
			defaultBgColor: 'lightgrey',
			checkedLabel: 'on',
			checkedTextColor: 'white',
			uncheckedLabel: 'off',
			uncheckedTextColor: '#8a8a8a'
		}),
		NgxPermissionsModule.forChild(),
		NgxDaterangepickerMd.forRoot()
	],
	exports: [
		...sharedModules,
		...sharedDirectives,
		...sharedComponents,
		...sharedPipes
	],
	providers: [
		...sharedServices
	]
})
export class SharedModule { }
