<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(1)}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(2)}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(3)}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step3_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(4)}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(5)}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step5_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep(6)}">
			<span class="steps__label">{{'global-export.step6_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step6_title' | transloco}}</span>
		</li>

		<li *ngIf="!isTaxMapping" class="steps" [ngClass]="{'steps--active': isActiveStep(7)}">
			<span class="steps__label">{{'global-export.step7_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step7_title' | transloco}}</span>
		</li>

		<li class="steps" [ngClass]="{'steps--active': isActiveStep((!isTaxMapping ? 8 : 7))}">
			<span class="steps__label">{{'global-export.step' + (!isTaxMapping ? '8' : '7') + '_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step8_title' | transloco}}</span>
		</li>
		<li class="steps" [ngClass]="{'steps--active': isActiveStep((!isTaxMapping ? 9 : 8))}">
			<span class="steps__label">{{'global-export.step' + (!isTaxMapping ? '9' : '8') + '_label' | transloco}}</span>
			<span class="steps__label">{{'at-export.step9_title' | transloco}}</span>
		</li>
	</ul>
	<div *ngIf="wizardSettings" class="form__content">
		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(1)}">
			<h3 class="form__section-title">
				{{'at-export.step1_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'at-export.step1-field_selectedTypeId' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item form__group-item--500-fs flex-center flex-center">
					<ng-select
						#inputFocus
						name="selectedTypeId"
						[clearable]="false"
						[(ngModel)]="selectedTypeId">
						<ng-option [value]="0">{{'at-export.field_selectedTypeId-opt-0' | transloco}}</ng-option>
						<ng-option [value]="1">{{'at-export.field_selectedTypeId-opt-1' | transloco}}</ng-option>
						<ng-option [value]="3">{{'at-export.field_selectedTypeId-opt-2' | transloco}}</ng-option>
						<ng-option [value]="4">{{'at-export.field_selectedTypeId-opt-3' | transloco}}</ng-option>
						<ng-option [value]="6">{{'at-export.field_selectedTypeId-opt-4' | transloco}}</ng-option>
						<ng-option [value]="7">{{'at-export.field_selectedTypeId-opt-5' | transloco}}</ng-option>
						<ng-option [value]="8">{{'at-export.field_selectedTypeId-opt-6' | transloco}}</ng-option>
					</ng-select>
				</div>
			</div>
			<div class="form__bottom form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="!(selectedTypeId >= 0) || loading"
					(click)="ContractTypesSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin btn-spinner"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(2)}">
			<h3 class="form__section-title">{{'at-export.step2_title' | transloco}}</h3>
			<form #ContractsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--750-fs">
						<p>
							{{'at-export.step2_header-agreement-name' | transloco}}
						</p>
						<p>
							{{'at-export.step2_header-invoice' | transloco}}
						</p>
						<p>
							{{'at-export.step2_header-company-name' | transloco}}
						</p>
						<p>
							{{'at-export.step2_header-contract' | transloco}}
						</p>
					</div>
					<div *ngFor="let item of autotaskMapping.invoices; let i = index"
						class="form__group-item form__group-item--750-fs">
						<p>{{item.agreementName}}</p>
						<p>{{item.invoiceNumber}}</p>
						<p>{{item.companyName}}</p>

						<ng-select *ngIf="item.contracts" required
							bindLabel="name" bindValue="id" name="selectedContractId{{i}}"
							[items]="item.contracts" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAtContractId"
							(change)="onChangeContract(item)">
						</ng-select>
						<p *ngIf="!item.contracts">
							{{'at-export.step2_agreements-not-found' | transloco}}
						</p>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
						[disabled]="!ContractsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>

		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(3)}">
			<h3 class="form__section-title">
				{{'at-export.step3_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'at-export.step3_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-1"
							[value]="1" [(ngModel)]='wizardSettings.selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-1">
							{{'at-export.field-selectedGroupingTypeId-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-2"
							[value]="2" [(ngModel)]='wizardSettings.selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-2">
							{{'at-export.field-selectedGroupingTypeId-opt-1' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-3"
							[value]="3" [(ngModel)]='wizardSettings.selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-3">
							{{'at-export.field-selectedGroupingTypeId-opt-2' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="GroupingTypeSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(4)}">
			<h3 class="form__section-title">
				{{'at-export.step4_title' | transloco}}
			</h3>
			<form #ProductsForm="ngForm">
				<div *ngIf="wizardSettings.selectedGroupingTypeId === 1" class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'at-export.step4_header-service' | transloco}}</p>
						<p>{{'at-export.step4_header-record' | transloco}}</p>
						<p>{{'at-export.step4_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of invoiceLinesMapping | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedAtProductId']; let i1 = index"class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="name" required
									bindValue="id" name="selectedAtProductId{{i}}_{{i1}}" [items]="products"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAtProductId">
									<ng-template>
										<input class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="wizardSettings.selectedGroupingTypeId === 2" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'at-export.step4_header-cost-centre' | transloco}}</p>
						<p>{{'at-export.step4_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoiceLinesMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="name" required
							bindValue="id" name="selectedAtProductId{{i}}" [items]="products"
							[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAtProductId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div *ngIf="wizardSettings.selectedGroupingTypeId === 3" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px" >
						<p>{{'at-export.step4_header-site' | transloco}}</p>
						<p>{{'at-export.step4_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoiceLinesMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="name" required
							bindValue="id" name="selectedAtProductId{{i}}" [items]="products"
							[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAtProductId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none padding-b__150" >
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(5)}">
			<h3 class="form__section-title">
				{{'at-export.step5_title' | transloco}}
			</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-wrapper form__group-item form__group-item--800 flex-start">
						<p>
							{{'at-export.step5-field_selectedExportProductTransactions' | transloco}}
						</p>
						<ng-select #inputProduct
							name="selectedExportProductTransactions"
							[clearable]="false"
							[(ngModel)]="wizardSettings.selectedExportProductTransactions">
							<ng-option [value]="2">{{'at-export.field_selectedExportProductTransactions-opt-0' | transloco}}</ng-option>
							<ng-option [value]="1">{{'at-export.field_selectedExportProductTransactions-opt-1' | transloco}}</ng-option>
							<ng-option [value]="0">{{'at-export.field_selectedExportProductTransactions-opt-2' | transloco}}</ng-option>
						</ng-select>
					</div>
					<div class="form__group-item--800 block" [ngClass]="{'form__group-wrapper-np': transactionMapFiltered && transactionMapFiltered.length }">
						<div *ngFor="let item of transactionMapFiltered; let i = index" class="form__group-item form__group-item--800 ">
							<p>{{item.code}}-{{item.transactionName}}</p>
							<ng-select #inputProduct bindLabel="name" required bindValue="id"
								name="selectedAtProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
								[(ngModel)]="item.selectedAtProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(6)}">
			<h3 class="form__section-title">
				{{'at-export.step6_title' | transloco}}
			</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'at-export.step6-field_combineSurchargesWithTaxes' | transloco}}</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [disabled]="isTaxMapping" [(ngModel)]="wizardSettings.combineSurchargesWithTaxes"></dg-checkbox>
					</div>
					<div *ngIf="!wizardSettings.combineSurchargesWithTaxes">
						<div *ngFor="let item of surchargeMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedAtProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAtProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset *ngIf="!isTaxMapping" class="form__section" [ngClass]="{'steps--active': isActiveStep(7)}">
			<h3 class="form__section-title">
				{{wizardSettings.combineSurchargesWithTaxes ? ('at-export.step7_title-combineSurchargesWithTaxes' | transloco) : 'at-export.step7_title' | transloco }}
			</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!wizardSettings.combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'at-export.step7-field_sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'at-export.step7-field_sureTaxMultiline-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
							[(ngModel)]="wizardSettings.sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="wizardSettings.sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>{{'at-export.step7-field_groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'at-export.step7-field_groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
							[(ngModel)]="wizardSettings.groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="wizardSettings.sureTaxMultiline && wizardSettings.groupTaxByState">
						<div *ngFor="let line of taxesLinesStateMapping; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" required
									   bindValue="id" name="selectedAtProductId{{i}}" [items]="products" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedAtProductId">
								<ng-template>
									<input class="custom-input"type="text"
										   (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="wizardSettings.sureTaxMultiline && !wizardSettings.groupTaxByState">
						<div *ngFor="let line of taxLinesMapping; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}} {{line.selectedAtProductId}}</p>
							<ng-select #inputProduct bindLabel="name" required
									   bindValue="id" name="selectedAtProductId{{i}}" [items]="products" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedAtProductId">
								<ng-template>
									<input class="custom-input" type="text"
										   (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!wizardSettings.sureTaxMultiline">
						<div *ngFor="let item of totalTaxesMapping; let i = index"
							 class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" bindValue="id"
									   required name="selectedAtProductId{{i}}" [items]="products"
									   [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAtProductId">
								<ng-template>
									<input  class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div *ngIf="wizardSettings.combineSurchargesWithTaxes" class="form__group block">
					<div *ngFor="let item of surchargesWithTaxesMapping; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="name" bindValue="id" required
							name="selectedAtProductId{{i}}" [items]="products" [searchable]="true"
							[clearable]="false" [(ngModel)]="item.selectedAtProductId">
							<ng-template>
								<input  class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(!isTaxMapping ? 8 : 7)}">
			<h3 class="form__section-title">
				{{'at-export.step8_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'at-export.step8_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input type="radio" id="KeepItems" name="radio-delete" [value]="false"
							[(ngModel)]="wizardSettings.deleteExisting">
						<label class="radiobox-label" for="KeepItems">
							{{'at-export.step8-field_deleteExisting-false' | transloco}}
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="DeleteItems" name="radio-delete" [value]="true"
							[(ngModel)]="wizardSettings.deleteExisting">
						<label class="radiobox-label" for="DeleteItems">
							{{'at-export.step8-field_deleteExisting-true' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" (click)="ExistingItemsSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section" [ngClass]="{'steps--active': isActiveStep(!isTaxMapping ? 9 : 8)}">
			<h3 class="form__section-title">
				{{'at-export.step9_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'at-export.step9_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='wizardSettings.selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'at-export.field_priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='wizardSettings.selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'at-export.field_priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button"class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>
	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title"> <b>{{'at-export.confirmation_title' | transloco}}</b></div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'at-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item">
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row" >
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
