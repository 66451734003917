import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'core/toaster-service';
import { Invoice } from 'domain/entities';
import { HaloChargesExportModel, HaloChargesInvoiceLineMapping, HaloChargesSurchargeMapping, HaloChargesSurchargeWithTaxMapping, HaloChargesTaxLineMapping, HaloChargesTaxLineStateMapping, HaloChargesTaxMapping, HaloChargesTransactionMapping, HaloChargesWizardSettings, HaloExportInvoiceMapping, HaloExportProduct } from 'domain/models/halo-export';
import { HaloRepository } from 'repositories';
import { Subject } from 'rxjs';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-halo-charges-export',
	templateUrl: './halo-charges-export.component.html',
	styleUrls: ['./halo-charges-export.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HaloChargesExportComponent implements OnInit, OnDestroy {
	private readonly unsubscribe$ = new Subject<void>();
	currentStep = 1;
	invoices: Invoice[];
	excluded: Invoice[];
	invoicesMapping: HaloExportInvoiceMapping[] = [];
	productsMapping: HaloExportProduct[] = [];
	loading = false;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public isExported = false;
	public exportedInvoices: string[];

	invoiceLinesResponses: HaloChargesInvoiceLineMapping[];
	transactionResponses: HaloChargesTransactionMapping[];
	surchargeResponses: HaloChargesSurchargeMapping[];
	surchargesWithTaxesResponses: HaloChargesSurchargeWithTaxMapping[];
	totalTaxesResponses: HaloChargesTaxMapping[];
	taxesLinesResponses: HaloChargesTaxLineMapping[];
	taxesLinesStateResponses: HaloChargesTaxLineStateMapping[];
	wizardSettings: HaloChargesWizardSettings;
	constructor(
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly cdr: ChangeDetectorRef,
		private readonly repository: HaloRepository,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly toasterService: ToasterService,
		private readonly location: Location
	) {
		this.cacheModel = this.cache.get('haloChargesExportInvoices');
		this.cacheModelExcluded = this.cache.get('haloChargesExportInvoices-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];
	}

	get isContractInvoicesValid(): boolean {
		return this.invoicesMapping?.every(inv => inv.selectedHaloInvoiceId);
	}

	async ngOnInit(): Promise<void> {
		if (!this.invoices.length) {
			this.location.back();
		}

		const [haloWizardSettings] = await Promise.all([
			this.repository.getHaloChargesSettings(this.unsubscribe$),
			this.onContractPrepare()
		]).withBusy();
		
		this.wizardSettings = haloWizardSettings;

		this.cdr.markForCheck();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async onExportConfirm(): Promise<void> {
		this.isExported = false;
		this.exportedInvoices = [];
		await this.onContractPrepare(true);
	}

	private async onContractPrepare(force: boolean = false): Promise<void> {
		this.loading = true;

		const invoiceIds = this.invoices.map(x => x.id);
		const excludedInvoicesIds = this.excluded.map(x => x.id);
		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';

		try {
			const contractsPrepareRes = await this.repository.haloChargesContractsPrepare(invoiceIds, force, excludedInvoicesIds, filterParams);

			if (contractsPrepareRes) {
				this.invoicesMapping = contractsPrepareRes.invoices;

				this.invoicesMapping?.forEach(invoice => {
					const hasContracts = invoice.contracts?.length > 0;
					const hasSelectedContractId = Boolean(invoice.selectedHaloContractId);

					if (hasContracts && hasSelectedContractId) {
						const matchingContract = invoice.contracts.find(contract => contract.id === invoice.selectedHaloContractId);
						invoice.selectedContratInvoices = matchingContract?.invoices ?? [];
					}
				});

				this.productsMapping = contractsPrepareRes.products;
			} else {
				this.toasterService.error('halo-export.not-found-warning');
			}

		} catch (errors) {
			this.exportedInvoices = errors['error'].references;

			if (Object.keys(this.exportedInvoices).length > 0) {
				this.isExported = true;
			}

			this.loading = false;
		}

		this.loading = false;
		this.cdr.markForCheck();
	}

	async onGroupingTypeSelected(): Promise<void> {
		this.loading = true;

		const groupingTypeId = +this.wizardSettings.selectedGroupingTypeId;
		const haloMappingInvoices = this.invoicesMapping.map(x => x.invoiceId);

		const dataPrepareRes = await this.repository.haloChargesDataPrepare(groupingTypeId, haloMappingInvoices, this.unsubscribe$);

		if (dataPrepareRes) {
			this.transactionResponses = dataPrepareRes.transactionResponses;
			this.invoiceLinesResponses = dataPrepareRes.invoiceLinesResponses;
			this.surchargeResponses = dataPrepareRes.surchargeResponses;
			this.surchargesWithTaxesResponses = dataPrepareRes.surchargesWithTaxesResponses;
			this.totalTaxesResponses = dataPrepareRes.totalTaxesResponses;
			this.taxesLinesResponses = dataPrepareRes.taxesLinesResponses;
			this.taxesLinesStateResponses = dataPrepareRes.taxesLinesStateResponses;
			this.NextStep();
		}

		this.loading = false;
		this.cdr.markForCheck();
	}

	async Export(): Promise<void> {
		this.loading = true;

		const exportModel = <HaloChargesExportModel>{
			invoices: this.invoicesMapping,
			transactionResponses: this.transactionResponses,
			invoiceLinesResponses: this.invoiceLinesResponses,
			surchargeResponses: this.surchargeResponses,
			surchargesWithTaxesResponses: this.surchargesWithTaxesResponses,
			totalTaxesResponses: this.totalTaxesResponses,
			taxesLinesStateResponses: this.taxesLinesStateResponses,
			taxesLinesResponses: this.taxesLinesResponses,
			selectedGroupingTypeId: this.wizardSettings.selectedGroupingTypeId,
			selectedPriceCalculationTypeId: this.wizardSettings.selectedPriceCalculationTypeId,
			deleteExisting: this.wizardSettings.deleteExisting,
			combineSurchargesWithTaxes: this.wizardSettings.combineSurchargesWithTaxes,
			doNotExportProducts: this.wizardSettings.doNotExportProducts,
			taxMultiline: this.wizardSettings.sureTaxMultiline,
			taxForState: this.wizardSettings.groupTaxByState,
			tag: this.cacheModel.Tag
		};

		try {
			const exportResponse = await this.repository.exportHaloCharges(exportModel, this.unsubscribe$);

			if (exportResponse.operationId){
				this.navigator.toOperation(exportResponse.operationId);
			}

		} finally {
			this.loading = false;
			this.cdr.markForCheck();
		}
	}

	isActiveStep(step: number): boolean {
		return this.currentStep === step;
	}

	onChangeContract(item: HaloExportInvoiceMapping): void {
		item.selectedHaloContractId = +item.selectedHaloContractId;
		item.selectedContratInvoices = item.contracts.find(contract => contract.id === item.selectedHaloContractId).invoices ?? [];
		item.selectedHaloInvoiceId = null;
	}

	onChangeInvoice(item: HaloExportInvoiceMapping): void {
		item.selectedHaloInvoiceId = +item.selectedHaloInvoiceId;
	}

	NextStep(): void {
		if (this.currentStep < 9) {
			this.currentStep = this.currentStep + 1;
		}
	}

	BackStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}
}
