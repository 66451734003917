import { FormControl, FormControlState, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HashMap, TranslateParams } from '@ngneat/transloco';
import { Guid } from 'domain/types';
import * as Moment from 'moment-timezone';
import { Constants } from './constants';

// Interface to store positions of day, month, year in a date string
interface DatePartPositions {
	dayPos: number;
	monthPos: number;
	yearPos: number;
}

// Interface to store the parsed day, month, and year from a date string
interface ParsedDate {
	day: number;
	month: number;
	year: number;
}

interface SelectableControlGroup<T> {
	selected: FormControl<boolean>;
	value: FormControl<T>;
}

export class Utils {
	public static getAgreementBillingChargeHelpMessage(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T, billingFrequencyTypeId: number, billingStartDate: string): string {
		if (!billingFrequencyTypeId || !billingStartDate) return '';

		const mDate = Moment.utc(billingStartDate);
		const mLastDayDate = Moment.utc(mDate).endOf('month');
		const daysToEnd = mLastDayDate.diff(mDate, 'days');
		const isShortMonth = mLastDayDate.daysInMonth() <= 30;

		let msg = '';

		switch (daysToEnd) {
			case 0:
				msg = t(`utils.getAgreementBillingChargeHelpMessage-last-freq-${billingFrequencyTypeId}_msg`);
				break;
			case 1:
				msg = t(`utils.getAgreementBillingChargeHelpMessage-relative-freq-${billingFrequencyTypeId}_msg`, { days: daysToEnd });
				break;
			case 2:
				msg = isShortMonth
					? t(`utils.getAgreementBillingChargeHelpMessage-direct-freq-${billingFrequencyTypeId}_msg`, { dateFormat: mDate.format('Do') })
					: t(`utils.getAgreementBillingChargeHelpMessage-relative-freq-${billingFrequencyTypeId}_msg`, { days: daysToEnd });
				break;
			default:
				msg = t(`utils.getAgreementBillingChargeHelpMessage-direct-freq-${billingFrequencyTypeId}_msg`, { dateFormat: mDate.format('Do') });
				break;
		}

		return msg;
	}

	public static toMomentUtcFormat(date: Date | string): string {
		return date ? Moment.utc(date).format('L') : '';
	}

	public static toMomentLocalFormat(date: Date | string): string {
		return date ? Moment(date).format('L') : '';
	}

	public static toMomentTzFormat(date: Date): string {
		return date ? Moment.tz(date, Constants.DefaultTimzone).format('L') : '';
	}

	public static toMomentTzFormatTime(date: Date): string {
		return date ? Moment.tz(date, Constants.DefaultTimzone).format('L HH:mm:ss') : '';
	}

	/**
     * Get the positions of day, month, and year based on locale date format
     */
	static getCurrentLocaleDatePartPositions(): DatePartPositions {
		const localeFormat = Moment.localeData().longDateFormat('L'); // 'L' is the localized short date format

		const formatParts = localeFormat.split(/[^A-Za-z]+/); // Split format by non-alphabetic characters

		const dayPos = formatParts.indexOf('DD');
		const monthPos = formatParts.indexOf('MM');
		const yearPos = formatParts.indexOf('YYYY');

		return { dayPos, monthPos, yearPos };
	}

	/**
     * Parse a date string or a Moment date object into day, month, and year based on locale or object
     *
     * @param {string | Moment.Moment} date - The date string or Moment object to parse.
     * @returns {ParsedDate} - An object containing the parsed day, month, and year.
     */
	static getParsedDate(date: string | Moment.Moment): ParsedDate {
		// If the input is a Moment date object, extract the day, month, and year
		if (Moment.isMoment(date)) {
			const day = date.date();  // Day of the month
			const month = date.month() + 1; // Moment month is 0-indexed, add 1
			const year = date.year();
			return { day, month, year };
		}

		// For string input, proceed with the current locale-based parsing
		const positions = this.getCurrentLocaleDatePartPositions();
		const dateParts = date.split(/[^\d]+/); // Split date by non-numeric characters

		if (dateParts.length !== 3) {
			throw Error(`Invalid date format! (${date})`);
		}

		const day = +dateParts[positions.dayPos];
		const month = +dateParts[positions.monthPos];
		const year = +dateParts[positions.yearPos];

		return { day, month, year };
	}

	/**
     * Convert a date string or a Moment object into a comparable number (YYYYMMDD)
     *
     * @param {string | Moment.Moment} date - The date to convert.
     * @returns {number | undefined} - Returns the date as a comparable number, or undefined if invalid.
     */
	static dateToComparableNumber(date: string | Moment.Moment): number | undefined {
		const parsedDate = this.getParsedDate(date);

		const { day, month, year } = parsedDate;
		return year * 10000 + month * 100 + day; // Return date as comparable number (YYYYMMDD)
	}

	/**
     * Compares two dates, which can be either strings or Moment objects, and returns a number indicating their relative order.
     *
     * The comparison is based on converting the dates to a comparable number
     * using the `dateToComparableNumber` method.
     *
     * @param {string | Moment.Moment} date1 - The first date to compare.
     * @param {string | Moment.Moment} date2 - The second date to compare.
     * @returns {number} - Returns:
     *   - 0 if the dates are equal,
     *   - -1 if `date1` is earlier than `date2`,
     *   - 1 if `date1` is later than `date2`.
     */
	static compareDates(date1: string | Moment.Moment, date2: string | Moment.Moment): number {
		// Helper to convert an input into a Moment object.
		const toMoment = (input: string | Moment.Moment): Moment.Moment => {
			if (Moment.isMoment(input)) {
				return input;
			}
			// Split the string by non-digit characters and filter out empty strings.
			const parts = input.split(/[^\d]+/).filter(Boolean);
			// If exactly 3 parts, assume the input is a date-only string.
			if (parts.length === 3) {
				const { day, month, year } = Utils.getParsedDate(input);
				// Create a Moment object at midnight (no time part).
				return Moment({ year, month: month - 1, day });
			} else {
			// Otherwise, assume the string includes a time part and let Moment parse it.
				return Moment(input);
			}
		};

		const m1 = toMoment(date1);
		const m2 = toMoment(date2);

		// If one or both dates are invalid, mimic the previous behavior.
		if (!m1.isValid() && !m2.isValid()) return 0;
		if (!m1.isValid()) return -1;
		if (!m2.isValid()) return 1;

		// Compare using the full datetime (millisecond precision)
		const diff = m1.valueOf() - m2.valueOf();
		return diff < 0 ? -1 : diff > 0 ? 1 : 0;
	}

	public static isNumberArray = <T>(arr: T[]): boolean => {
		return Array.isArray(arr) && arr.every((value) => typeof value === 'number');
	};

	public static isStringArray = <T>(arr: T[]): boolean => {
		return Array.isArray(arr) && arr.every((value) => typeof value === 'string');
	};

	public static isGuidArray = <T>(arr: T[]): boolean => {
		return Array.isArray(arr) && arr.every((value) => value instanceof Guid);
	};

	public static roundTwoDecimals(val: number | string, roundTo: number = 2): string {
		if (typeof val === 'number') {
			if (roundTo === 2) {

				if (val === 0 || val % 1 === 0) {
					return val.toFixed(2);
				}
				return (Math.round((val + Number.EPSILON) * 100) / 100).toFixed(2);
			}
			if (roundTo === 4) {
				if (val === 0 || val % 1 === 0) {
					return val.toFixed(4);
				}
				return (Math.round((val + Number.EPSILON) * 10000) / 10000).toFixed(4);
			}
		}

		if (typeof val === 'string') {
			// remove % and $ symbols from pinned row
			return val.replace(/[|&;$%@"<>()+,]/g, '');
		}
	}

	public static convertToRecord(...args: any[]): Record<string, string> {
		const result: Record<string, string> = {};

		args.forEach(obj => {
			for (const key in obj) {
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					// if the value is an object or an array, stringify it
					result[key] = JSON.stringify(obj[key]);
				} else {
					// if the value is a number, boolean, or null, convert it to string
					// if the value is undefined, it becomes 'undefined'
					result[key] = String(obj[key]);
				}
			}
		});

		return result;
	}

	public static selectableControlGroup<T>(formState: T | FormControlState<T>): FormGroup<SelectableControlGroup<T>> {
		return new FormGroup({
			selected: new FormControl<boolean>(false),
			value: new FormControl<T>(formState)
		});
	}

	/**
	 * Retrieves a unique identifier for a component instance.
	 * @template TComp
	 * @param {TComp} ngComponent - The Angular component instance.
	 * @returns {string} - The unique identifier for the component.
	 * @throws Will throw an error if ngComponent is not available.
	 */
	public static getComponentLocation<TComp>(ngComponent: TComp): string {
		if (!ngComponent) throw Error('ngComponent is not available.');

		const id = ngComponent['injector']?.get(ActivatedRoute)?.snapshot?.data?.breadcrumb
			?? ngComponent['route']?.snapshot?.data?.breadcrumb
			?? ngComponent['activatedRoute']?.snapshot?.data?.breadcrumb
			?? ngComponent.constructor['__NG_ELEMENT_ID__']
			?? ngComponent.constructor.name;

		return id;
	}
}
