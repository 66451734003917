import { HttpParams } from '@angular/common/http';
import { ApiEntityColumn, ApiFilterByColumn, ApiFilterModel, ApiFilterPaging, ApiSortDirection } from 'domain/types';

export function getApiFilterModel<T>(apiFilter: ApiFilterPaging<T>): ApiFilterModel<T> {
	return {
		apiFilter
	};
}

/**
 *  @deprecated DO NOT USE - will be removed. Left for compatibility reasons to supply query string params.
 */
export function fillApiFilterParams<T>(params: HttpParams, apiFilter: ApiFilterPaging<T>): HttpParams {
	if (apiFilter.page !== undefined) {
		params = params.set('page', apiFilter.page.toString());
	}
	if (apiFilter.pageSize !== undefined) {
		params = params.set('pagesize', apiFilter.pageSize.toString());
	}
	if (apiFilter.sortBy) {
		params = params.set('order', apiFilter.sortBy.toString());
	}
	if (apiFilter.sortDirection) {
		params = params.set('orderDirection', apiFilter.sortDirection);
	}
	if (apiFilter.quickFilter) {
		params = params.set('filter', apiFilter.quickFilter);
	}
	if (apiFilter.filterByColumns && apiFilter.filterByColumns.length > 0) {
		apiFilter.filterByColumns.forEach((column) => {
			Object.entries<string>(column).forEach(([key, value]) => {
				params = params.set(key, value);
			});
		});
	}

	return params;
}

export function updateApiFilterByColumn<T>(columnFilters: ApiFilterByColumn<T>[], filterColumn: ApiEntityColumn<T>, filterValue?: string): ApiFilterByColumn<T>[] {
	const existingFilterIndex = columnFilters?.findIndex(filter => filter.hasOwnProperty(filterColumn)) ?? -1;
	if (existingFilterIndex > -1) {
		if (filterValue) {
			columnFilters[existingFilterIndex] = { [filterColumn]: filterValue } as ApiFilterByColumn<T>;
		} else {
			columnFilters.splice(existingFilterIndex, 1);
		}
	} else if (filterValue) {
		if (!columnFilters) {
			columnFilters = [];
		}
		columnFilters.push({ [filterColumn]: filterValue } as ApiFilterByColumn<T>);
	}

	return columnFilters;
}

export class ApiFilterState<T> {
	private _apifilter: ApiFilterPaging<T> = {
		page: 1,
		pageSize: 50,
		sortBy: undefined,
		sortDirection: undefined,
		quickFilter: undefined,
		filterByColumns: []
	};

	build(): ApiFilterModel<T> {
		return getApiFilterModel(this._apifilter);
	}

	setPage(page: number): void {
		this._apifilter.page = page;
	}

	setPageSize(pageSize: number): void {
		this._apifilter.pageSize = pageSize;
	}

	setSortBy(sortBy: ApiEntityColumn<T>): void {
		this._apifilter.sortBy = sortBy;
	}

	setSortDirection(sortDirection: ApiSortDirection): void {
		this._apifilter.sortDirection = sortDirection;
	}

	setQuickFilter(quickFilter: string): void {
		this._apifilter.quickFilter = quickFilter;
	}

	setFilterByColumn(filterColumn: ApiEntityColumn<T>, filterValue?: string): void {
		this._apifilter.filterByColumns = updateApiFilterByColumn(this._apifilter.filterByColumns, filterColumn, filterValue);
	}

	reset(): void {
		this._apifilter = {
			page: 1,
			pageSize: 50,
			sortBy: undefined,
			sortDirection: undefined,
			quickFilter: undefined,
			filterByColumns: []
		};
	}
}
