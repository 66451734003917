import { QBOPaymentMethod, Serializable } from 'domain/models';
import * as Integrations from 'pages/+portal-settings/portal-integrations/integration-service';
import { Guid } from 'domain/types';
import { InvoiceTemplateType } from 'tools/invoice-constants';

export class ConnectwiseSettings implements Serializable<ConnectwiseSettings> {
	id: Guid;
	company: string;
	publicKey: number;
	privateKey: string;
	url: string;
	enabledProductSync: boolean;
	enabledInvoicesSync: boolean;
	productType: string;
	productClass: string;
	productCategory: string;
	productSubcategory: string;
	inAdvance: number;
	proRata: boolean;
	taxEngineTypeId: number;
	dateLastSync: string;
	invoiceDueDateId: number;
	useProductDescriptionId: number;
	agreementType: string;
	defaultAgreement: boolean;
	agreementSiteUsed: boolean;
	enabledMergeSimilarTransactions: boolean;
	dontExportZeroAmount: boolean;
	additionDate: number;
	agreementForSyncedProducts: number;
	defaultAgreementSettingForNewCustomers: number;
	enabledDeliveryMethodsSync: boolean;
	useDefaultBillingStartDate: boolean;
	productSyncFilterFormula: string;
	productId: Guid;
	enabledProductCategoriesSync: boolean;
	importZeroInvoice: boolean;
	invoiceType: string;
	enabledOpeningBalanceAgreementInvoicesSync: boolean;
	enabledOpeningBalanceStandardInvoicesSync: boolean;
	enabledOpeningBalanceMiscellaneousSync: boolean;
	enabledAdvancedProductSyncFilters: boolean;
	syncAgreementsWithoutProducts: boolean;
	syncAgreementsInvoicingSettings: boolean;
	updateSites: number;
	importInvoicesFrom: string;
	alt: string = Integrations.IntegrationService.Wise;
	iconSrc = 'ConnectWise_logo.png';
	title = 'ConnectWise Manage';
	isConfigured = false;
	invoicesImportWithStatus: string;
	statusForImportInvoices: number;
	taxesExportInvoicesStatus: number;

	deserialize(json: any): ConnectwiseSettings {
		this.id = json.id;
		this.company = json.company;
		this.publicKey = json.publicKey;
		this.privateKey = json.privateKey;
		this.url = json.url;
		this.productId = json.productId;
		this.invoiceType = json.invoiceType ?? '0';
		this.enabledInvoicesSync = json.enabledInvoicesSync;
		this.enabledProductSync = json.enabledProductSync;
		this.importZeroInvoice = json.importZeroInvoice;
		this.productType = json.productType;
		this.productClass = json.productClass;
		this.dateLastSync = json.dateLastSync;
		this.syncAgreementsWithoutProducts = json.syncAgreementsWithoutProducts;
		this.productCategory = json.productCategory;
		this.productSubcategory = json.productSubcategory;
		this.inAdvance = json.inAdvance;
		this.proRata = json.proRata;
		this.invoiceDueDateId = json.invoiceDueDateId;
		this.enabledMergeSimilarTransactions = json.enabledMergeSimilarTransactions;
		this.dontExportZeroAmount = json.dontExportZeroAmount;
		this.agreementSiteUsed = json.agreementSiteUsed;
		this.useDefaultBillingStartDate = json.useDefaultBillingStartDate;
		this.taxEngineTypeId = json.taxEngineTypeId;
		this.additionDate = json.additionDate;
		this.useProductDescriptionId = json.useProductDescriptionId || 1;
		this.agreementForSyncedProducts = json.agreementForSyncedProducts;
		this.defaultAgreementSettingForNewCustomers = json.defaultAgreementSettingForNewCustomers;
		this.updateSites = json.updateSites;
		this.importInvoicesFrom = json.importInvoicesFrom;
		this.agreementType = json.agreementType;
		this.defaultAgreement = json.defaultAgreement;

		this.enabledOpeningBalanceAgreementInvoicesSync = json.enabledOpeningBalanceAgreementInvoicesSync;
		this.enabledOpeningBalanceStandardInvoicesSync = json.enabledOpeningBalanceStandardInvoicesSync;
		this.enabledOpeningBalanceMiscellaneousSync = json.enabledOpeningBalanceMiscellaneousSync;

		this.enabledDeliveryMethodsSync = json.enabledDeliveryMethodsSync;
		this.productSyncFilterFormula = json.productSyncFilterFormula;
		this.enabledProductCategoriesSync = json.enabledProductCategoriesSync;
		this.enabledAdvancedProductSyncFilters = json.enabledAdvancedProductSyncFilters;
		this.syncAgreementsInvoicingSettings = json.syncAgreementsInvoicingSettings;
		this.invoicesImportWithStatus = json.invoicesImportWithStatus;
		this.statusForImportInvoices = json.statusForImportInvoices;
		this.taxesExportInvoicesStatus = json.taxesExportInvoicesStatus;

		if (this.company?.length) {
			this.isConfigured = true;
		}
		return this;
	}
}

export class WebHookEntity implements Serializable<WebHookEntity> {
	id: Guid;
	webhookUrl: string;
	actionId: number;
	sortOrder: number;

	alt: string = Integrations.IntegrationService.WebHooks;
	iconSrc = 'WebHook_logo.png';
	title: string = Integrations.IntegrationService.WebHooks;
	isConfigured = false;

	deserialize(json: any): WebHookEntity {
		this.id = json.id;
		this.webhookUrl = json.webhookUrl;
		this.actionId = json.actionId;
		this.sortOrder = json.sortOrder;

		if (this.webhookUrl.length) {
			this.isConfigured = true;
		}

		return this;
	}
}

export class WebHookSettings {
	isAdding = false;
	isEditing = false;
	saving = false;
}

export class StripeEntity implements Serializable<StripeEntity> {
	secretKey: string;
	currency: string;
	publishableKey: string;
	paymentTypeDisclaimer: string;
	feeAmount: number;
	feePercent: number;
	directDebitPaymentMethodId: number = null;
	alt: string = Integrations.IntegrationService.Stripe;
	iconSrc = 'Stripe_logo.png';
	title: string = Integrations.IntegrationService.Stripe;
	isConfigured = false;
	isOtherPayment = false;
	directDebitFeeAmount: number;
	directDebitFeePercent: number;
	directDebitFeeLimit: number;
	sendPaymentReceipts: boolean;
	deserialize(json: any): StripeEntity {
		this.secretKey = json.secretKey;
		this.currency = json.currency;
		this.publishableKey = json.publishableKey;
		this.feeAmount = json.feeAmount ? json.feeAmount : '0.00';
		this.feePercent = json.feePercent ? json.feePercent : '0.0000';
		this.paymentTypeDisclaimer = json.paymentTypeDisclaimer;
		this.directDebitPaymentMethodId = json.directDebitPaymentMethodId;
		this.sendPaymentReceipts = json.sendPaymentReceipts;

		this.directDebitFeeAmount = json.directDebitFeeAmount ? json.directDebitFeeAmount : '0.00';
		this.directDebitFeePercent = json.directDebitFeePercent ? json.directDebitFeePercent : '0.0000';
		this.directDebitFeeLimit = json.directDebitFeeLimit ? json.directDebitFeeLimit : '0.00';

		if (this.secretKey?.length) {
			this.isConfigured = true;
		}

		return this;
	}
}
// QBD

export class QBDEntity implements Serializable<QBDEntity> {
	password: string;
	syncCustomers = 0;
	dateLastSync: string;
	alt = 'Quickbooks Desktop';
	iconSrc = 'QuickBooks_logo.png';
	title: string = Integrations.IntegrationService.QuickBooksDesktop;
	isConfigured = false;
	defaultSalesTaxCode: string;

	deserialize(input: any): QBDEntity {
		this.syncCustomers = input.syncCustomers;
		this.password = input.password;
		this.dateLastSync = input.dateLastSync;
		this.defaultSalesTaxCode = input.defaultSalesTaxCode;

		if (this.password === undefined) {
			this.isConfigured = false;
		} else {
			this.isConfigured = true;
		}

		return this;
	}
}

export class QuickBooksEntity implements Serializable<QuickBooksEntity> {
	alt = 'QuickBooks';
	iconSrc = 'QuickBooks_logo.png';
	title: string = Integrations.IntegrationService.QuickBooks;
	isConfigured = false;
	unfoldKits = false;
	defaultSaleTax: string;
	exportTelcoTaxesAsTaxes: boolean;
	allowOnlineCreditCardPayment: boolean;
	allowOnlineACHPayment: boolean;
	exportPayments: boolean;
	invoiceDueDateTypeId: number;
	dontExportZeroAmount: boolean;
	allowAdjustmentLine: boolean;
	populatePONumber: boolean;
	paymentMethodMappings: QBOPaymentMethod[];

	deserialize(json: any): QuickBooksEntity {
		this.defaultSaleTax = json.defaultSaleTax;
		this.exportTelcoTaxesAsTaxes = json.exportTelcoTaxesAsTaxes;
		this.invoiceDueDateTypeId = json.invoiceDueDateTypeId;
		this.allowOnlineCreditCardPayment = json.allowOnlineCreditCardPayment;
		this.allowOnlineACHPayment = json.allowOnlineACHPayment;
		this.unfoldKits = json.unfoldKits;
		this.exportPayments = json.exportPayments;
		this.dontExportZeroAmount = json.dontExportZeroAmount;
		this.allowAdjustmentLine = json.allowAdjustmentLine;
		this.populatePONumber = json.populatePONumber;
		this.paymentMethodMappings = json.paymentMethodMappings;

		return this;
	}
}

export class QuickBookExportType implements Serializable<QuickBookExportType> {
	id: string;
	name: string;

	deserialize(json: any): QuickBookExportType {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class AuthorizeEntity implements Serializable<AuthorizeEntity> {
	id: Guid;
	loginID: string;
	transactionKey: string;
	directDebitPaymentMethodId: number;
	alt: string = Integrations.IntegrationService.Authorize;
	iconSrc = 'Authorize_logo.png';
	title = 'Authorize.net';
	isConfigured = false;
	isOtherPayment = false;
	deserialize(json: any): AuthorizeEntity {
		this.id = json.id;
		this.loginID = json.loginID;
		this.transactionKey = json.transactionKey;
		this.directDebitPaymentMethodId = json.directDebitPaymentMethodId;

		if (this.loginID.length) {
			this.isConfigured = true;
		}

		return this;
	}
}

export class PinchEntity implements Serializable<PinchEntity> {
	id: Guid;
	merchantID: string;
	secretKey: string;
	publishableKey: string;
	sandbox: string;

	alt: string = Integrations.IntegrationService.Pinch;
	iconSrc = 'Pinch_logo.png';
	title = 'Pinch';
	isConfigured = false;
	isOtherPayment = false;

	deserialize(json: any): PinchEntity {
		this.id = json.id;
		this.merchantID = json.merchantID;
		this.secretKey = json.secretKey;
		this.publishableKey = json.publishableKey;
		this.sandbox = json.sandbox;

		if (this.merchantID.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): PinchEntity {
		return Object.assign(new PinchEntity(), this);
	}
}

export class MonerisEntity implements Serializable<MonerisEntity> {
	id: Guid;
	storeID: string;
	apiToken: string;
	sandbox: string;
	webhookURL: string;

	alt: string = Integrations.IntegrationService.Moneris;
	iconSrc = 'Moneris_logo.png';
	title = 'Moneris';
	isConfigured = false;
	isOtherPayment = false;

	deserialize(json: any): MonerisEntity {
		this.id = json.id;
		this.storeID = json.storeID;
		this.apiToken = json.apiToken;
		this.sandbox = json.sandbox;
		this.webhookURL = json.webhookURL;

		if (this.storeID.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): MonerisEntity {
		return Object.assign(new MonerisEntity(), this);
	}
}

export class KaseyaEntity implements Serializable<KaseyaEntity> {
	id: Guid;
	userName: string;
	password: string;
	companyName: string;
	apiUrl: string;
	dateLastSync: string;
	inAdvance: number;
	updateSites: number;
	enabledInvoicesSync: boolean;
	importZeroInvoice: boolean;
	enabledProductSync: boolean;
	useDefaultAgreement: boolean;
	enableUseSiteZipTax: boolean;
	proRata: boolean;
	contractTypes: number[];
	matchInventory: string;

	alt: string = Integrations.IntegrationService.Kaseya;
	iconSrc = 'Kaseya_logo.png';
	title = 'Kaseya BMS';
	isConfigured = false;

	deserialize(json: any): KaseyaEntity {
		this.id = json.id;
		this.userName = json.userName;
		this.password = json.password;
		this.companyName = json.companyName;
		this.apiUrl = json.apiUrl;
		this.enabledInvoicesSync = json.enabledInvoicesSync;
		this.importZeroInvoice = json.importZeroInvoice;

		this.enabledProductSync = json.enabledProductSync;
		this.inAdvance = json.inAdvance;
		this.useDefaultAgreement = json.useDefaultAgreement;
		this.updateSites = json.updateSites;
		this.enableUseSiteZipTax = json.enableUseSiteZipTax;
		this.proRata = json.proRata;
		this.dateLastSync = json.dateLastSync;
		this.contractTypes = json.contractTypes;
		this.matchInventory = json.matchInventory;

		if (this.userName.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): KaseyaEntity {
		return Object.assign(new KaseyaEntity(), this);
	}
}

export class IppayEntity implements Serializable<IppayEntity> {
	id: Guid;
	merchantId: string;
	securityKey: string;
	customerUniqueId: number;
	sandbox: boolean;

	alt: string = Integrations.IntegrationService.IPPay;
	iconSrc = 'IPPay_logo.png';
	title = 'IPPay';
	isConfigured = false;
	isOtherPayment = false;
	deserialize(json: any): IppayEntity {
		this.id = json.id;
		this.merchantId = json.merchantId;
		this.securityKey = json.securityKey;
		this.customerUniqueId = json.customerUniqueId;
		this.sandbox = json.sandbox;

		if (this.merchantId?.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): IppayEntity {
		return Object.assign(new IppayEntity(), this);
	}
}

export class NexioEntity implements Serializable<NexioEntity> {
	currency: string;
	username: string;
	password: string;
	merchantId: string;
	confirmationMessage: string;
	sandbox = false;
	isOtherPayment = false;
	title = 'Datagate Payments (powered by Nexio)';
	iconSrc = 'Nexio_logo.png';
	alt = Integrations.IntegrationService.Nexio;
	isConfigured = false;
	directDebitPaymentMethodId: number = null;

	deserialize(json: any): NexioEntity {
		this.username = json.username;
		this.password = json.password;
		this.currency = json.currency;
		this.sandbox = json.sandbox;
		this.merchantId = json.merchantId;
		this.confirmationMessage = json.confirmationMessage;
		this.directDebitPaymentMethodId = json.directDebitPaymentMethodId;

		if (this.username?.length || this.sandbox) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): NexioEntity {
		return Object.assign(new NexioEntity(), this);
	}
}

export class SureTaxRegCodeEntity implements Serializable<SureTaxRegCodeEntity> {
	id: number;
	code: string;
	description: string;
	type: string;

	deserialize(input: any): SureTaxRegCodeEntity {
		this.id = input.id;
		this.code = input.code;
		this.description = input.description;
		this.type = input.type;
		return this;
	}
}

export class XeroTrackingCategoryEntity implements Serializable<XeroTrackingCategoryEntity> {
	name: string;
	trackingCategoryID: string;
	options: XeroTrackingOptionEntity[];

	deserialize(json: any): XeroTrackingCategoryEntity {
		this.name = json.name;
		this.trackingCategoryID = json.trackingCategoryID;
		this.options = json.options;
		return this;
	}

}

export class XeroTrackingOptionEntity implements Serializable<XeroTrackingOptionEntity> {
	name: string;
	trackingOptionID: string;

	deserialize(json: any): XeroTrackingOptionEntity {
		this.name = json.name;
		this.trackingOptionID = json.trackingOptionID;
		return this;
	}
}

export class SureTaxEntity implements Serializable<SureTaxEntity> {
	id: Guid;
	clientNumber: string;
	accessKey: string;
	regulatoryCodeId: string;
	sandbox: boolean;

	alt: string = Integrations.IntegrationService.SureTax;
	iconSrc = 'SureTax_logo.png';
	title: string = Integrations.IntegrationService.SureTax;
	isConfigured = false;

	deserialize(input: any): SureTaxEntity {
		this.id = input.id;
		this.clientNumber = input.clientNumber;
		this.accessKey = input.accessKey;
		this.regulatoryCodeId = input.regulatoryCodeId;
		this.sandbox = input.sandbox;

		if (this.clientNumber.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): SureTaxEntity {
		return Object.assign(new SureTaxEntity(), this);
	}
}

export interface AutotaskContractCategory {
	value: number;
	name: string;
}

export class AutotaskEntity implements Serializable<AutotaskEntity> {
	userName: string;
	secret: string;
	enabledProductSync: boolean;
	inAdvance: number;
	taxEngineTypeId: number;
	contractCategory: number;
	contractType: number;
	defaultAgreement: boolean;
	agreementSiteUsed: boolean;
	dontExportTaxes: boolean;
	proRata: boolean;
	updateSites: boolean;
	enabledProductCategoriesSync: boolean;
	dateLastSync: string;
	alt: string = Integrations.IntegrationService.Autotask;
	iconSrc = 'Autotask_logo.png';
	title: string = Integrations.IntegrationService.Autotask;
	isConfigured = false;
	productSyncFilterFormula: string;
	productCategory: string;
	additionDate = 0;
	deserialize(input: any): AutotaskEntity {
		this.userName = input.userName;
		this.secret = input.secret;
		this.enabledProductSync = input.enabledProductSync;
		this.inAdvance = input.inAdvance;
		this.taxEngineTypeId = input.taxEngineTypeId;
		this.contractType = input.contractType;
		this.defaultAgreement = input.defaultAgreement;
		this.agreementSiteUsed = input.agreementSiteUsed;
		this.proRata = input.proRata;
		this.updateSites = input.updateSites;
		this.enabledProductCategoriesSync = input.enabledProductCategoriesSync;
		this.dateLastSync = input.dateLastSync;
		this.contractType = input.contractType === null ? 0 : input.contractType;
		this.contractCategory = input.contractCategory === null ? 0 : input.contractCategory;
		this.productSyncFilterFormula = input.productSyncFilterFormula;
		this.productCategory = input.productCategory;
		this.dontExportTaxes = input.dontExportTaxes;
		this.additionDate = input.additionDate;

		if (this.userName.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): AutotaskEntity {
		return Object.assign(new AutotaskEntity(), this);
	}
}

export class CensusEntity {
	alt: string = Integrations.IntegrationService.Census;
	iconSrc = 'Census_logo.svg';
	title = 'Census Geocoder';
	isConfigured = false;
}

export class CsiTaxEntity implements Serializable<CsiTaxEntity> {
	authenticationKey: string;
	providerType: number;
	safeHarborOverride: string;
	additionalExemptList: string;
	alt: string = Integrations.IntegrationService.CsiTax;
	iconSrc = 'Csi_logo.png';
	title = 'CSI Tax Rating';
	isConfigured = false;

	deserialize(input: any): CsiTaxEntity {
		this.authenticationKey = input.authenticationKey;
		this.providerType = input.providerType;
		this.safeHarborOverride = input.safeHarborOverride;
		this.additionalExemptList = input.additionalExemptList;
		
		if (this.authenticationKey.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): CsiTaxEntity {
		return Object.assign(new CsiTaxEntity(), this);
	}
}

export class XeroEntity implements Serializable<XeroEntity> {
	alt: string = Integrations.IntegrationService.Xero;
	iconSrc = 'Xero_logo.png';
	title: string = Integrations.IntegrationService.Xero;
	isConfigured = false;

	syncExternalInvoices = false;
	extendedExport = false;
	attachPDF = false;
	tenantName: string;
	useXeroBrandingTheme: boolean;

	deserialize(input: any): XeroEntity {
		this.syncExternalInvoices = input.syncExternalInvoices;
		this.extendedExport = input.extendedExport;
		this.tenantName = input.tenantName;
		this.attachPDF = input.attachPDF;
		this.useXeroBrandingTheme = input.useXeroBrandingTheme;

		return this;
	}

	clone(): XeroEntity {
		return Object.assign(new XeroEntity(), this);
	}
}

export class XeroTenants implements Serializable<XeroTenants> {
	value: string;
	name: string;

	deserialize(json: any): XeroTenants {
		this.value = json.value;
		this.name = json.name;
		return this;
	}
}

export class AvalaraEntity implements Serializable<AvalaraEntity> {
	id: Guid;
	accountNumber: string;
	licenseKey: string;
	serviceUrl: string;
	companyCode: string;

	alt: string = Integrations.IntegrationService.Avalara;
	iconSrc = 'Avalara_logo.png';
	title: string = Integrations.IntegrationService.Avalara;
	isConfigured = false;

	deserialize(input: any): AvalaraEntity {
		if (!input) return this;

		this.id = input.id;
		this.accountNumber = input.accountNumber;
		this.licenseKey = input.licenseKey;
		this.serviceUrl = input.serviceUrl;
		this.companyCode = input.companyCode;

		if (this.accountNumber.length) {
			this.isConfigured = true;
		}

		return this;
	}

	clone(): AvalaraEntity {
		return Object.assign(new AvalaraEntity(), this);
	}
}

export class AvalaraCompany implements Serializable<AvalaraCompany> {
	id: number;
	companyCode: string;
	isDefault: boolean;

	deserialize(input: any): AvalaraCompany {
		this.id = input.id;
		this.companyCode = input.companyCode;
		this.isDefault = input.isDefault;

		return this;
	}
}

export class AvalaraCommunication implements Serializable<AvalaraCommunication> {
	userName: string;
	password: string;
	clientId: string;
	clientProfileId: string;
	businessClassId: number;
	serviceClassId: number;
	facilities: boolean;
	franchise: boolean;
	regulated: boolean;
	defaultSaleTypeId: number;
	sandbox: boolean;

	alt: string = Integrations.IntegrationService.AvalaraCommunication;
	iconSrc = 'Avalara_logo.png';
	title = 'Avalara Communications';
	isConfigured = false;

	deserialize(input: any): AvalaraCommunication {
		this.userName = input.userName;
		this.password = input.password;
		this.clientId = input.clientId;
		this.clientProfileId = input.clientProfileId;
		this.businessClassId = input.businessClassId;
		this.serviceClassId = input.serviceClassId;
		this.facilities = input.facilities;
		this.franchise = input.franchise;
		this.regulated = input.regulated;
		this.defaultSaleTypeId = input.defaultSaleTypeId;
		this.sandbox = input.sandbox;

		if (this.userName.length) {
			this.isConfigured = true;
		}

		return this;
	}
}

// Quoter Integration Entities
export class QuoterEntity implements Serializable<QuoterEntity>, QuoterPreviewParams {
	webhookUrl: string;
	hashKey: string;
	useInAdvance = false;
	alt: string = Integrations.IntegrationService.Quoter;
	iconSrc = 'Quoter_logo.png';
	title = 'Quoter';
	isConfigured = false;
	useProductDescriptionFromQuote = false;
	invoiceOneOffItems = false;
	invoiceRecurringItem = false;

	deserialize(input: any): QuoterEntity {
		this.webhookUrl = input.webhookUrl;
		this.hashKey = input.hashKey;
		this.useInAdvance = input.useInAdvance;

		if (this.webhookUrl?.length) {
			this.isConfigured = true;
		}

		this.useProductDescriptionFromQuote = input.useProductDescriptionFromQuote;
		this.invoiceOneOffItems = input.invoiceOneOffItems;
		this.invoiceRecurringItem = input.invoiceRecurringItem;

		return this;
	}

	clone(): QuoterEntity {
		return Object.assign(new QuoterEntity(), this);
	}
}
export interface QuoterPreviewParams {
	invoiceOneOffItems: boolean;
	invoiceRecurringItem: boolean;
}

// ItQuoter Integration Entities
export class ItQuoterEntity implements Serializable<ItQuoterEntity>, QuoterPreviewParams {
	webhookUrl: string;
	apiUrl: string;
	accessKey: string;
	secretKey: string;
	useInAdvance = false;
	alt: string = Integrations.IntegrationService.ItQuoter;
	iconSrc = 'ItQuoter_logo.png';
	title = 'ITQuoter';
	isConfigured = false;
	useProductDescriptionFromQuote = false;
	invoiceRecurringItem = false;
	invoiceOneOffItems = false;

	deserialize(input: any): ItQuoterEntity {
		this.webhookUrl = input.webhookUrl;
		this.apiUrl = input.apiUrl;
		this.accessKey = input.accessKey;
		this.secretKey = input.secretKey;
		this.useInAdvance = input.useInAdvance;

		if (this.apiUrl?.length) {
			this.isConfigured = true;
		}

		this.useProductDescriptionFromQuote = input.useProductDescriptionFromQuote;
		this.invoiceOneOffItems = input.invoiceOneOffItems;
		this.invoiceRecurringItem = input.invoiceRecurringItem;

		return this;
	}

	clone(): ItQuoterEntity {
		return Object.assign(new ItQuoterEntity(), this);
	}
}

export class QuoteEntity implements Serializable<QuoteEntity> {
	uuid: string;
	name: string;
	number: string;
	totalUpfront: number;
	totalRecurring: number;
	owner: string;
	accepted: boolean;
	organization: string;

	deserialize(input: any): QuoteEntity {
		this.uuid = input.uuid;
		this.name = input.name;
		this.number = input.number;
		this.totalUpfront = input.totalUpfront;
		this.totalRecurring = input.totalRecurring;
		this.owner = input.owner;
		this.accepted = input.accepted;
		this.organization = input.organization;

		return this;
	}
}

export class QuoteDetails implements Serializable<QuoteDetails> {
	uuid: string;
	name: string;
	number: string;
	totalUpfront: number;
	totalRecurring: number;
	owner: {
		[keys: string]: {
			name: string;
			email: string;
		};
	};
	person: {
		[keys: string]: {
			name: string;
			email: string;
		};
	};
	accepted: boolean;
	organization: string;
	expiryDate: string;
	lineItems: QuoteLineItem[];

	deserialize(input: any): QuoteDetails {
		this.uuid = input.uuid;
		this.name = input.name;
		this.number = input.number;
		this.totalUpfront = input.totalUpfront;
		this.totalRecurring = input.totalRecurring;
		this.owner = input.owner;
		this.person = input.person;
		this.accepted = input.accepted;
		this.organization = input.organization;
		this.expiryDate = input.expiryDate;
		this.lineItems = input.lineItems;

		return this;
	}
}

export class QuotePreviewItem implements Serializable<QuotePreviewItem> {
	category: string;
	name: string;
	datagateProduct: string;
	type: string;

	deserialize(input: any): QuotePreviewItem {
		this.category = input.category;
		this.name = input.name;
		this.datagateProduct = input.datagateProduct;
		this.type = input.type;

		return this;
	}
}

export class QuoteLineItem implements Serializable<QuoteLineItem> {
	name: string;
	type: string;
	recurring: boolean;
	quantity: string;
	unitCost: string;
	unitPrice: string;
	discount: string;
	total: string;

	deserialize(input: any): QuoteLineItem {
		this.name = input.name;
		this.type = input.type;
		this.recurring = input.recurring;
		this.quantity = input.quantity;
		this.unitCost = input.unitCost;
		this.unitPrice = input.unitPrice;
		this.discount = input.discount;
		this.total = input.total;

		return this;
	}
}

export class AvalaraTaxExemption implements Serializable<AvalaraTaxExemption> {
	id: Guid;
	name: string;
	typeId: number;
	categoryId: number;
	categoryName: string;
	taxTypeId: number;
	taxTypeName: string;
	federal: boolean;
	state: boolean;
	county: boolean;
	local: boolean;
	domainId: number;
	domainLabel: string;
	addressId: number;
	avalaraLocation: AvalaraLocation;
	scopeLabel: string;
	locationLabel: string;
	exemptNonBillable: boolean;
	forceLevelExemptible: boolean;

	deserialize(input: any): AvalaraTaxExemption {
		this.id = input.id;
		this.name = input.name;
		this.typeId = input.typeId;
		this.categoryId = input.categoryId;
		this.taxTypeId = input.taxTypeId;
		this.federal = input.federal;
		this.state = input.state;
		this.county = input.county;
		this.local = input.local;
		this.domainId = input.domainId;
		this.addressId = input.addressId;

		this.exemptNonBillable = input.exemptNonBillable;
		this.forceLevelExemptible = input.forceLevelExemptible;
		this.locationLabel = this.getLocalLabel(input);

		return this;
	}

	private getLocalLabel(tax: any): string {
		const local = [];

		if (tax.addressLine1) {
			local.push(tax.addressLine1);
		}
		if (tax.addressLine2) {
			local.push(tax.addressLine2);
		}
		if (tax.addressCountry) {
			local.push(tax.addressCountry);
		}
		if (tax.addressCounty) {
			local.push(tax.addressCounty);
		}
		if (tax.addressCity) {
			local.push(tax.addressCity);
		}
		if (tax.addressRegion) {
			local.push(tax.addressRegion);
		}
		if (tax.addressState) {
			local.push(tax.addressState);
		}
		if (tax.postalCode) {
			local.push(tax.postalCode);
		}

		return local.join(', ');
	}

	clone(): AvalaraTaxExemption {
		return Object.assign(new AvalaraTaxExemption(), this);
	}
}

export class AvalaraLocation implements Serializable<AvalaraLocation> {
	public id: Guid;
	public addressLine1: string;
	public addressLine2: string;
	public country: string;
	public state: string;
	public region: string;
	public county: string;
	public city: string;
	public postalCode: string;
	deserialize(input: any): AvalaraLocation {
		this.id = input.id;
		this.addressLine1 = input.addressLine1;
		this.addressLine2 = input.addressLine2;
		this.country = input.country;
		this.state = input.state;
		this.region = input.region;
		this.county = input.county;
		this.city = input.city;
		this.postalCode = input.postalCode;
		return this;
	}

	clone(): AvalaraLocation {
		return Object.assign(new AvalaraLocation(), this);
	}
}

export class AvalaraTaxTypes implements Serializable<AvalaraTaxTypes> {
	taxType: number;
	taxDescription: string;

	deserialize(input: any): AvalaraTaxTypes {
		this.taxType = input.taxType;
		this.taxDescription = input.taxDescription;
		return this;
	}
}

export class AvalaraSalesAndUse implements Serializable<AvalaraSalesAndUse> {
	id: number;
	group: string;
	name: string;

	deserialize(input: any): AvalaraSalesAndUse {
		this.id = input.id;
		this.group = input.group;
		this.name = input.name;
		return this;
	}
}

export class InvoiceLayoutEntity implements Serializable<InvoiceLayoutEntity> {
	id: Guid;
	invoiceTemplateId: number;
	name: string;
	description: string;
	additionalFilePath: string;
	invoiceTemplateTypeId: InvoiceTemplateType;

	deserialize(input: any): InvoiceLayoutEntity {
		this.id = input.id;
		this.name = input.name;
		this.description = input.description;
		this.invoiceTemplateId = input.invoiceTemplateId;
		this.additionalFilePath = input.additionalFilePath;
		this.invoiceTemplateTypeId = input.invoiceTemplateTypeId;
		return this;
	}

	clone(): InvoiceLayoutEntity {
		return Object.assign(new InvoiceLayoutEntity(), this);
	}
}

export class HaloEntity implements Serializable<HaloEntity> {
	clientId: string;
	clientSecret: Guid;
	tenant: string;
	authorizationServerUrl: string;
	resourceServerUrl: string;
	enabledAdvancedProductSyncFilters: boolean;
	enabledProductSync: boolean;
	taxEngineTypeId: number;
	defaultAgreement: boolean;
	inAdvance: number;
	agreementSiteUsed: boolean;
	updateSites: boolean;
	useRecurringInvoiceSites: boolean;
	proRata: boolean;
	enabledOpeningBalanceSync: boolean;
	dateLastSync: string;
	contractType: string;
	itemGroup: string;
	productSyncFilterFormula: string;
	invoiceReference: string;
	alt: string = Integrations.IntegrationService.Halo;
	iconSrc = 'Halo_logo.png';
	title = 'Halo PSA';
	isConfigured = false;
	populateLongDescription: boolean;
	addDatesToLongDescription: boolean;
	unfoldKits: boolean;
	autoPay: boolean;
	attachPdf: boolean;
	useXeroBrandingTheme: boolean;
	enabledServiceItemsSync: boolean;

	deserialize(json: any): HaloEntity {
		this.clientId = json.clientId;
		this.clientSecret = json.clientSecret;
		this.tenant = json.tenant;
		this.authorizationServerUrl = json.authorizationServerUrl;
		this.resourceServerUrl = json.resourceServerUrl;
		this.useRecurringInvoiceSites = json.useRecurringInvoiceSites;
		this.enabledProductSync = json.enabledProductSync;
		this.taxEngineTypeId = json.taxEngineTypeId;
		this.defaultAgreement = json.defaultAgreement;
		this.inAdvance = json.inAdvance;
		this.agreementSiteUsed = json.agreementSiteUsed;
		this.updateSites = json.updateSites;
		this.proRata = json.proRata;
		this.enabledOpeningBalanceSync = json.enabledOpeningBalanceSync;
		this.dateLastSync = json.dateLastSync;
		this.contractType = json.contractType;
		this.itemGroup = json.itemGroup;
		this.productSyncFilterFormula = json.productSyncFilterFormula;
		this.enabledAdvancedProductSyncFilters = json.enabledAdvancedProductSyncFilters;
		this.populateLongDescription = json.populateLongDescription;
		this.addDatesToLongDescription = json.addDatesToLongDescription;
		this.unfoldKits = json.unfoldKits;
		this.autoPay = json.autoPay;
		this.attachPdf = json.attachPdf;
		this.useXeroBrandingTheme = json.useXeroBrandingTheme;
		this.enabledServiceItemsSync = json.enabledServiceItemsSync;
		this.invoiceReference = json.invoiceReference;
		
		if (Guid.isValid(this.clientId)) {
			this.isConfigured = true;
		}

		return this;
	}
}
