import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { BaseRepository } from 'repositories';
import { CacheService } from 'services/cache.service';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

export interface SearchResultItem {
	addressLine: string;
	recordId: string;
}

export interface SearchResult {
	data: SearchResultItem[];
}

export interface RetrieveResultData {
	addressLine1: string;
	addressLine2: string;
	buildingFloorNumber: string;
	buildingFloorNumberSuffix: string;
	buildingFloorType: string;
	buildingLocation: string;
	buildingProperty: string;
	city: string;
	country: string;
	locality: string;
	postalCode: string;
	state: string;
	streetFirstName: string;
	streetFirstSuffix: string;
	streetFirstType: string;
	streetHouseFirstNumber: string;
	streetHouseFirstNumberSuffix: string;
	streetHouseSecondNumber: string;
	streetHouseSecondNumberSuffix: string;
	streetSecondName: string;
	streetSecondSuffix: string;
	streetSecondType: string;
	unitFirstNumber: string;
	unitFirstSuffix: string;
	unitSecondNumber: string;
	unitSecondSuffix: string;
	unitType: string;
}

export interface RetrieveResult {
	data: RetrieveResultData;
}

export interface ProviderNameResult {
	data: Provider;
}

export interface Provider {
	provider: string;
}

@Injectable({
	providedIn: 'root'
})
export class AddressLookupRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	validate(entity: RetrieveResultData): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/addressLookup/validate`,
			entity)
			.pipe(map(res => res.body));
	}

	findProvider(): Observable<ProviderNameResult> {
		return this.http.get<ProviderNameResult>(`${this.config.apiUrl}${this.config.apiVersion}/addressLookup/provider`)
			.pipe(map(res => res.body));
	}

	searchAddress(searchTerm: string): Observable<SearchResult> {
		return this.http.get<SearchResult>(`${this.config.apiUrl}${this.config.apiVersion}/addressLookup/find?addressLine=${searchTerm}`)
			.pipe(map(res => res.body));
	}

	retrieveAddress(recordId: string): Observable<RetrieveResult> {
		return this.http.get<RetrieveResult>(`${this.config.apiUrl}${this.config.apiVersion}/addressLookup/retrieve?recordId=${recordId}`)
			.pipe(map(res => res.body));
	}
}
