import 'reflect-metadata';
import { UserStorageUtil } from './user-storage.util';
import { Utils } from './utils';

export function EntityName(singular: string, plural: string) {
	return (ctor: Function) => {
		Reflect.defineMetadata('EntityNameDecoratorKey', { singular, plural }, ctor);
	};
}

export function getEntityNames(ctor: Function): any {
	return Reflect.getOwnMetadata('EntityNameDecoratorKey', ctor);
}

/**
 * Decorator that automatically stores and retrieves the property value using UserStorageUtil.
 * @param {Object} target - The prototype of the class.
 * @param {string} propertyKey - The name of the property being decorated.
 */
export function DgAutoStore(target: Object, propertyKey: string): void {
	const privatePropertyKey = `__DgAutoStore_${propertyKey}`;
	const isInitializedKey = `__DgAutoStore_initialized_${propertyKey}`;

	Object.defineProperty(target, propertyKey, {
		get: function (this: any) {
			if (!this[isInitializedKey]) {
				const id = Utils.getComponentLocation(this);
				const key = `ID-${id}_${propertyKey}`;
				const storedValue = UserStorageUtil.getValue(key);
				if (storedValue !== undefined) {
					this[privatePropertyKey] = storedValue;
				}
				this[isInitializedKey] = true;
			}
			return this[privatePropertyKey];
		},
		set: function (this: any, newVal: any) {
			this[privatePropertyKey] = newVal;
			const id = Utils.getComponentLocation(this);
			const key = `ID-${id}_${propertyKey}`;
			if (this[isInitializedKey]) {
				UserStorageUtil.setValue(key, newVal);
			}
		},
		enumerable: true,
		configurable: true,
	});
}
