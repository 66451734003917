import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';
import { CustomerTransaction } from './customer-entities';

export class MiscellaneousInvoice implements Serializable<MiscellaneousInvoice> {
	id: Guid;
	invoiceNumber: string;
	invoiceId: Guid;
	customerId: Guid;
	date: string;
	dueDate: string;
	billingSiteId: Guid;
	shippingSiteId: Guid;
	shippingSiteCustomerId: Guid;
	transactions: CustomerTransaction[];
	customer?: {
		id: Guid;
		name: string;
	};
	dateDue?: string;
	invoiceDate?: string;
	deserialize(json: any): MiscellaneousInvoice {

		this.id = json.id;

		this.invoiceNumber = json.number;
		this.date = json.date;
		this.dueDate = json.dueDate;
		this.customerId = json.customer.id;
		this.transactions = json.transactions;
		this.billingSiteId = json.billingSiteId;
		this.shippingSiteId = json.shippingSiteId;
		this.shippingSiteCustomerId = json.shippingSiteCustomerId;
		this.dateDue = json.dateDue;
		this.invoiceDate = json.invoiceDate;
		this.customer = json.customer;
		return this;
	}
}

export class InvoiceDetails implements Serializable<InvoiceDetails> {
	id: Guid;
	invoiceNumber: string;
	isOnHold: boolean;
	invoiceDate: Date;
	dateDue: Date;
	dateGenerated: Date;
	status: any;
	customer: any;
	canEmail: boolean;
	amount: number;
	tax: number;
	subtotal: number;
	billingPeriod: string;
	canCharge: boolean;
	reason: string;
	taxes: InvoiceTaxes[];
	surcharges: InvoiceSurcharges[];
	usage: InvoiceUsage[];
	transactions: InvoiceTransactions[];
	payments: InvoicePayments[];
	emails: InvoiceEmails[];
	billingSiteId?: Guid;
	shippingSiteId?: Guid;
	shippingSiteCustomerId?: Guid;
	deserialize(json: any): InvoiceDetails {
		this.id = json.id;

		this.invoiceNumber = json.number;
		this.isOnHold = json.isOnHold;
		this.canEmail = json.canEmail;
		this.invoiceDate = json.date ? new Date(json.date) : null;
		this.status = json.status;
		this.customer = json.customer;
		this.reason = json.reason;
		this.dateGenerated = json.dateGenerated ? new Date(json.dateGenerated) : null;
		this.dateDue = json.dateDue ? new Date(json.dateDue) : null;
		this.amount = json.amount ? json.amount.toFixed(2) : 0;
		this.subtotal = json.subtotal ? json.subtotal.toFixed(2) : 0;
		this.tax = json.tax;
		this.status = json.status;
		this.billingPeriod = json.billingPeriod;
		this.billingSiteId = json.billingSiteId;
		this.shippingSiteId = json.shippingSiteId;
		this.shippingSiteCustomerId = json.shippingSiteCustomerId;
		this.taxes = json.taxes;
		this.surcharges = json.surcharges;
		this.usage = json.usage;
		this.transactions = json.transactions;
		this.canCharge = json.canCharge;
		this.payments = json['integration-ar-transactions'];
		this.emails = json.emails;


		return this;
	}

	clone(): InvoiceDetails {
		return Object.assign(new InvoiceDetails(), this);
	}
}

export class InvoiceTransactions implements Serializable<InvoiceTransactions> {
	id: Guid;
	productCode: string;
	productLabel: string;
	quantity: number;
	invoiceAmount: number;
	invoiceTax: number;
	invoiceTotal: number;
	productKitId: number;

	deserialize(json: any): InvoiceTransactions {

		this.id = json.id;
		this.productCode = json.productCode;
		this.productLabel = json.productLabel;
		this.quantity = json.quantity;
		this.invoiceAmount = json.invoiceAmount;
		this.invoiceTotal = json.invoiceTotal;
		this.invoiceTax = json.invoiceTax;
		this.productKitId = json.productKitId;

		return this;
	}
}

export class InvoiceUsage implements Serializable<InvoiceUsage> {
	id: Guid;
	quantity: number;
	invoiceAmount: number;
	invoiceTax: number;
	invoiceTotal: number;
	recordType: string;
	service: string;
	serviceItem: string;

	deserialize(json: any): InvoiceUsage {

		this.id = json.id;
		this.quantity = json.quantity;
		this.invoiceAmount = json.invoiceAmount;
		this.invoiceTotal = json.invoiceTotal;
		this.invoiceTax = json.invoiceTax;
		this.recordType = json.recordType;
		this.service = json.service;
		this.serviceItem = json.serviceItem;

		return this;
	}
}

export class InvoiceTaxes implements Serializable<InvoiceTaxes> {
	name: string;
	value: number;
	taxInclusive: boolean;
	taxTypeCode: string;

	deserialize(json: any): InvoiceTaxes {

		this.name = json.name;
		this.value = json.value;
		this.taxInclusive = json.taxInclusive;
		this.taxTypeCode = json.taxTypeCode;

		return this;
	}
}

export class InvoiceSurcharges implements Serializable<InvoiceSurcharges> {
	id: Guid;
	name: string;
	invoiceTotal: number;

	deserialize(json: any): InvoiceSurcharges {

		this.id = json.id;
		this.name = json.name;
		this.invoiceTotal = json.invoiceTotal.toFixed(2);

		return this;
	}
}

export class InvoicePayments implements Serializable<InvoicePayments> {
	id: Guid;
	date: Date;
	reference: string;
	amount: number;
	gatewayType: string;
	checkId: Guid;

	deserialize(json: any): InvoicePayments {
		this.id = json.id;
		this.date = json.date;
		this.reference = json.reference;
		this.amount = json.amount;
		this.gatewayType = json.gatewayType;
		this.checkId = json.checkId;

		return this;
	}
}

export class InvoicePayment implements Serializable<InvoicePayment> {
	amount: number;
	paymentDate: string;
	deserialize(json: any): InvoicePayment {
		this.paymentDate = json.paymentDate;
		return this;
	}
}

export class InvoiceEmails implements Serializable<InvoiceEmails> {
	id: Guid;
	email: string;
	type: string;
	status: string;
	message: string;
	lastUpdate: Date;
	hidden: boolean;
	resend: boolean;
	allEmails: any;

	deserialize(json: any): InvoiceEmails {
		this.id = json.id;
		this.email = json.email;
		this.type = json.type;
		this.status = json.status;
		this.message = json.message;
		this.lastUpdate = json.lastUpdate;
		this.hidden = json.hidden;
		return this;
	}
}

export class InvoiceTaxDetails implements Serializable<InvoiceTaxDetails> {
	invoiceId: Guid;
	taxTypeCode: string;
	taxTypeDesc: string;
	taxAmount: number;
	taxRate: number;
	taxInclusive: boolean;

	deserialize(json: any): InvoiceTaxDetails {
		this.invoiceId = json.invoiceId;
		this.taxTypeCode = json.taxTypeCode;
		this.taxTypeDesc = json.taxTypeDesc;
		this.taxAmount = Number(json.taxAmount);
		this.taxRate = json.taxRate;
		this.taxInclusive = json.taxInclusive;
		return this;
	}
}

export enum InvoiceTaxTypes {
	Usages = 'usages',
	Surcharges = 'surcharges'
}
