import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrencySymbol, CustomerPortalServiceSettings, CustomerPortalSettings } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpAuth } from 'core/auth';
import { AppConfig } from 'core/app-config';
import { ApiData } from 'domain/types';
import { CustomerPortalServiceItem } from 'domain/models/customer-portal';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { CustomerPortalPayment } from 'domain/models';

@Injectable({
	providedIn: 'root'
})
export class CustomerPortalRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	async getCustomerPortalSettings(destroy$: Subject<void>): Promise<CustomerPortalSettings> {
		const settings = await this.http.promise(destroy$).get<ApiData<CustomerPortalSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings`);
		return new CustomerPortalSettings().deserialize(settings.body.data);
	}

	async getServiceItemSettings(destroy$: Subject<void>): Promise<CustomerPortalServiceItem> {
		const serviceItemSettings = await this.http.promise(destroy$).get<ApiData<CustomerPortalServiceItem>>(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/service-item`);
		return serviceItemSettings.body.data;
	}

	async getServiceToDisplay(destroy$: Subject<void>): Promise<CustomerPortalServiceSettings[]> {
		const services = await this.http.promise(destroy$).get<ApiData<CustomerPortalServiceSettings[]>>(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/services-display`);
		return services.body.data;
	}

	async getCurrencySymbols(destroy$: Subject<void>): Promise<CurrencySymbol[]> {
		const symbols = await this.http.promise(destroy$).get<ApiData<CurrencySymbol[]>>(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/currency-symbols`);
		return symbols.body.data;
	}

	async getPaymentSettings(destroy$: Subject<void>): Promise<CustomerPortalPayment> {
		const payments = await this.http.promise(destroy$).get<ApiData<CustomerPortalPayment>>(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/payments`);
		return payments.body.data;
	}

	async updatePaymentSettings(settings: CustomerPortalPayment, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/payments`, settings);
		return res.body;
	}

	getBrandSettings(brandId: string): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/brands-settings/${brandId}`)
			.pipe(map(res => {
				return res.body.data;
			}));
	}

	uploadLoginBanner(file: File): Observable<any> {

		const formData: FormData = new FormData();
		formData.append('file', file, file.name);

		const headers = new HttpHeaders();
		const options = { headers: headers };

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/login-banner`, options, formData);
	}

	uploadLogo(file: File): Observable<any> {

		const formData: FormData = new FormData();
		formData.append('file', file, file.name);

		const headers = new HttpHeaders();
		const options = { headers: headers };

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/logo`, options, formData);
	}

	deleteLogo(): Observable<void> {
		const observable =
			this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/logo`)
				.pipe(map(() => { }));
		return observable;
	}

	deleteLoginBanner(): Observable<void> {
		const observable =
			this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/login-banner`)
				.pipe(map(() => { }));
		return observable;
	}

	updateColors(settings: CustomerPortalSettings): Observable<void> {

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings`, settings, null)
				.pipe(map(() => { }));

		return observable;
	}

	updateServiceToDisplay(settings: CustomerPortalServiceSettings[]): Observable<void> {

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/end-portal-settings/services-display`, settings, null)
				.pipe(map(() => { }));

		return observable;
	}

	public updateServiceItemSettings(settings: CustomerPortalServiceItem): Observable<unknown> {

		return this.createEntity(`/end-portal-settings/service-item`, settings);
	}
}
