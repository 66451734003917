import { Guid } from 'domain/types';

export interface InvoiceResendingEmailsParameters {
	invoiceId?: Guid;
	emailsId: number[];
	tag: string;
}

export class InvoiceGenerationParameters {

	public billingPeriodId?: any;
	public customers: number[];
	public invoiceDate: Date;

	// defaultDueDate is not required by api. Used for UI purposes only.
	public defaultDueDate = true;
	public customInvoiceDueDate?: Date;
	public transactionGenerationType: number;
	public taxGroupId: number | string;
	public generateAdditionalTransactions = false;
	public tag: string;
	public suppressZeroInvoices = false;
}
