import { Serializable } from 'domain/models';
import { Cloneable, Restoreable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Utils } from 'tools/utils';
import { Tag } from './tag-entities';
import { DueDateOption, ProductRatePlan } from './main-entities';
import { SurchargeTypesOption } from './surcharges-entities';
import { Guid } from 'domain/types';
import { ProductTypeId } from 'domain/enums';

export interface IIdentity {
	id: Guid | number;
}

export interface ICloneable<T> {
	clone(): T;

	restore(src: T): void;
}

@EntityName('Customer', 'Customers')
export class CustomerDetails implements IIdentity, Serializable<CustomerDetails> {
	public id: Guid;
	public name: string;
	public identificator: string;
	public accountCode: string;
	public isActive: boolean;
	public isReseller: boolean;
	public isActiveToStatus: string;

	public billingAddress1: string;
	public billingAddress2: string;
	public billingCity: string;
	public billingPostalCode: string;
	public billingContactEmail: string;
	public billingContactName: string;
	public billingContactLastName: string;
	public billingContactPhone: string;
	public invoicePoNumber: string;
	public state: string;
	public country: string;
	public invoiceLayoutId: number;
	public creditNoteLayoutId: number;

	public creditLimit: number;
	public customerCode: string;

	public doNotInvoice: boolean;
	public notGenerateTaxes: boolean;
	public useSiteZipTax: boolean;

	public extraField1: string;
	public extraField2: string;
	public extraField3: string;
	public extraField4: string;
	public extraField5: string;

	public groupId?: Guid;
	public groupName: string;

	public businessUnitId?: Guid;
	public businessUnitName: string;

	public accountManagerId?: Guid;
	public accountManagerName: string;

	public paymentMethod: string;
	public accSysExportId: string;
	public invoiceLayoutName: string;
	public bankAccount: string;

	public invoiceTemplateId: number;
	public tags: Tag[];
	// TODO: As for now taxRateId field holds value of taxRateTypeId,
	//	 		 will be refactored in scope of issue DAT-2512
	public taxRateId: Guid;
	public ccBillingContactEmail: string;
	public customerType: number;
	public paymentPortalId: number;
	public salesTypeCode: string;
	public exemptCode: string;
	public exemptList: string;
	public deliveryMethodId: Guid;
	public defaultInvoiceDueDateType: string;
	public defaultInvoiceDueDateTypeId: number;
	public qbId: number;
	public qbdId: string;
	public kaseyaId: number;
	public autoTaskId: number;
	public haloId: number;
	public qbExportTelcoTaxesAsTaxes = false;
	public qbDefaultSaleTax: string;
	public xeroId: string;
	public avalaraCustomerType: number;
	public avalaraSaleType: number;
	public avalaraLifeline: boolean;
	public useDefaultAgreementForSyncCW: boolean;
	public disabledPaymentFees: boolean;
	public avalaraIncorporated: boolean;
	public avalaraTaxExemptions: { id: Guid; }[];
	public ceretaxBusinessTypeId: number;
	public ceretaxSellerTypeId: number;
	public ceretaxCustomerTypeId: number;
	public ceretaxTaxExemptions: { id: Guid; }[];
	public subOrganizationId: Guid;
	public taxNumber: string;
	public dateCreatedMoment: string;
	public dateUpdatedMoment: string;

	//Hidden fields:
	public buildingFloorNumber: string;
	public buildingFloorNumberSuffix: string;
	public buildingFloorType: string;
	public buildingLocation: string;
	public buildingProperty: string;
	public locality: string;
	public streetFirstName: string;
	public streetFirstSuffix: string;
	public streetFirstType: string;
	public streetHouseFirstNumber: string;
	public streetHouseFirstNumberSuffix: string;
	public streetHouseSecondNumber: string;
	public streetHouseSecondNumberSuffix: string;
	public streetSecondName: string;
	public streetSecondSuffix: string;
	public streetSecondType: string;
	public unitFirstNumber: string;
	public unitFirstSuffix: string;
	public unitSecondNumber: string;
	public unitSecondSuffix: string;
	public unitType: string;
	public validated: boolean;
	public sureTaxExemptionCodeId: number;
	public useDifferentLayouts: boolean;
	public agreementInvoiceLayoutId: Guid;
	public miscellaneousInvoiceLayoutId: Guid;
	public allowResellerCharge: boolean;
	public taxInclusiveOnUsages: boolean;
	public currencyId: number;
	public schemeId: string;
	public electronicAddressId: string;
	public vatIdentifier: string;
	public commissionRate: number;

	deserialize(json: any): CustomerDetails {

		this.id = json.id;
		this.name = json.name;
		this.customerCode = json.customerCode;
		this.identificator = json.identificator;
		this.accountCode = json.accountCode;
		this.isActive = json.isActive;
		this.isReseller = json.isReseller;
		this.isActiveToStatus = json.isActive === true ? json.isActiveToStatus = 'Active' : json.isActiveToStatus = 'Inactive';
		this.invoiceLayoutId = json.invoiceLayoutId;
		this.useDifferentLayouts = json.useDifferentLayouts;
		this.agreementInvoiceLayoutId = json.agreementInvoiceLayoutId;
		this.miscellaneousInvoiceLayoutId = json.miscellaneousInvoiceLayoutId;
		this.creditNoteLayoutId = json.creditNoteLayoutId;
		this.billingAddress1 = json.billingAddress1;
		this.billingAddress2 = json.billingAddress2;
		this.billingPostalCode = json.billingPostalCode;
		this.billingCity = json.billingCity;
		this.billingContactEmail = json.billingContactEmail;
		this.billingContactName = json.billingContactName;
		this.billingContactLastName = json.billingContactLastName;
		this.billingContactPhone = json.billingContactPhone;
		this.state = json.state;
		this.country = json.country;
		this.dateCreatedMoment = Utils.toMomentTzFormat(json.dateCreated);
		this.dateUpdatedMoment = Utils.toMomentTzFormat(json.dateUpdated);
		this.sureTaxExemptionCodeId = json.sureTaxExemptionCodeId;
		this.useDefaultAgreementForSyncCW = json.useDefaultAgreementForSyncCW;
		this.disabledPaymentFees = json.disabledPaymentFees;

		this.invoicePoNumber = json.invoicePoNumber;

		if (typeof json.creditLimit !== 'number') {
			this.creditLimit = Number(json.creditLimit) || 0;
		} else {
			this.creditLimit = json.creditLimit || '';
		}

		this.invoiceLayoutName = json.invoiceLayoutName;
		this.accSysExportId = json.accSysExportId;
		this.paymentPortalId = json.paymentPortalId;
		this.paymentMethod = json.paymentMethod === '' ? null : json.paymentMethod;
		this.bankAccount = json.bankAccount;
		this.notGenerateTaxes = json.notGenerateTaxes;
		this.doNotInvoice = json.doNotInvoice;
		this.useSiteZipTax = json.useSiteZipTax;
		this.accountManagerId = json.accountManagerId === 0 ? null : json.accountManagerId;
		this.accountManagerName = json.accountManagerName;

		this.groupId = json.groupId;
		this.groupName = json.groupName;

		this.businessUnitId = json.businessUnitId;
		this.businessUnitName = json.businessUnitName;

		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;
		this.kaseyaId = json.kaseyaId;
		this.haloId = json.haloId;
		this.invoiceTemplateId = json.invoiceTemplateId;
		this.tags = json.tags;
		this.taxRateId = json.taxRateId;
		this.ccBillingContactEmail = json.ccBillingContactEmail;
		this.customerType = json.customerType;
		this.exemptCode = json.exemptCode;
		this.salesTypeCode = json.salesTypeCode;
		this.exemptList = json.exemptList;
		this.deliveryMethodId = json.deliveryMethodId;
		this.defaultInvoiceDueDateTypeId = json.defaultInvoiceDueDateTypeId;
		this.allowResellerCharge = json.allowResellerCharge;

		const dueDateOptions: DueDateOption[] = DueDateOption.allOptions;

		// tslint:disable-next-line:max-line-length
		this.defaultInvoiceDueDateType = this.defaultInvoiceDueDateTypeId ? dueDateOptions.find(x => x.id === this.defaultInvoiceDueDateTypeId).name : '';
		this.qbId = json.qbId;
		this.qbdId = json.qbdId;
		this.autoTaskId = json.autoTaskId;
		this.qbExportTelcoTaxesAsTaxes = json.qbExportTelcoTaxesAsTaxes;
		this.qbDefaultSaleTax = json.qbDefaultSaleTax;
		this.xeroId = json.xeroId;

		this.avalaraCustomerType = json.avalaraCustomerType;
		this.avalaraSaleType = json.avalaraSaleType;
		this.avalaraLifeline = json.avalaraLifeline;
		this.avalaraIncorporated = json.avalaraIncorporated;
		this.avalaraTaxExemptions = json.avalaraTaxExemptions;

		this.ceretaxBusinessTypeId = json.ceretaxBusinessTypeId;
		this.ceretaxSellerTypeId = json.ceretaxSellerTypeId;
		this.ceretaxCustomerTypeId = json.ceretaxCustomerTypeId;
		this.ceretaxTaxExemptions = json.ceretaxTaxExemptions;
		this.subOrganizationId = json.subOrganizationId;
		this.taxNumber = json.taxNumber;

		this.buildingFloorNumber = json.buildingFloorNumber;
		this.buildingFloorNumberSuffix = json.buildingFloorNumberSuffix;
		this.buildingFloorType = json.buildingFloorType;
		this.buildingLocation = json.buildingLocation;
		this.buildingProperty = json.buildingProperty;
		this.locality = json.locality;
		this.streetFirstName = json.streetFirstName;
		this.streetFirstSuffix = json.streetFirstSuffix;
		this.streetFirstType = json.streetFirstType;
		this.streetHouseFirstNumber = json.streetHouseFirstNumber;
		this.streetHouseFirstNumberSuffix = json.streetHouseFirstNumberSuffix;
		this.streetHouseSecondNumber = json.streetHouseSecondNumber;
		this.streetHouseSecondNumberSuffix = json.streetHouseSecondNumberSuffix;
		this.streetSecondName = json.streetSecondName;
		this.streetSecondSuffix = json.streetSecondSuffix;
		this.streetSecondType = json.streetSecondType;
		this.unitFirstNumber = json.unitFirstNumber;
		this.unitFirstSuffix = json.unitFirstSuffix;
		this.unitSecondNumber = json.unitSecondNumber;
		this.unitSecondSuffix = json.unitSecondSuffix;
		this.unitType = json.unitType;
		this.validated = json.validated;
		this.taxInclusiveOnUsages = json.taxInclusiveOnUsages;
		this.currencyId = json.currencyId;
		this.schemeId = json.schemeId;
		this.electronicAddressId = json.electronicAddressId;
		this.vatIdentifier = json.vatIdentifier;
		this.commissionRate = json.commissionRate;

		return this;
	}

	clone(): CustomerDetails {
		return Object.assign(new CustomerDetails(), this);
	}

	restore(src: CustomerDetails): void {
		Object.assign(this, src);
	}
}

export interface ProductServiceItem {
	serviceItemId: Guid;
	serviceItem: string;
	serviceItemDescription?: string;
}

@EntityName('Product', 'Products')
export class CustomerProduct implements Serializable<CustomerProduct>,
	Cloneable<CustomerProduct>,
	Restoreable<CustomerProduct> {
	public id: Guid;
	public code: string;
	public label: string;
	public isHidden: boolean;
	public description: string;
	public serviceId: number;
	public listOrder: number;
	public serviceName: string;
	public siteId: Guid;
	public siteName: string;
	public siteZName: string;
	public serviceItemId: Guid;
	public serviceItemCode: string;
	public supplierId: Guid;
	public inAdvance: number;
	public proRata: boolean;
	public doNotExportToCWAgreements: boolean;
	public cost: number;
	public sell: number;
	public quantity: number;
	public activated: string;
	public deactivated: string;
	public bundleId: Guid;
	public bundleName: string;
	public transactionCodeId?: Guid;
	public templateProductId?: Guid;
	public glCodeId?: Guid;
	public productCategoryId?: Guid;
	public productKitId?: number;
	public rateCardId: Guid;
	public rateCardName: string;
	public costCentre: string;
	public taxRateId: number;
	public productRatePlans: ProductRatePlan[];
	public siteZId: Guid;
	public extraField1: string;
	public extraField2: string;
	public extraField3: string;
	public extraField4: string;
	public extraField5: string;
	public agreementId: Guid;
	public agreementName: string;
	public excludeSurcharges: boolean;
	public taxInclusive: boolean;
	public avalaraSaleType: number;
	public avalaraSalesAndUseId: number;
	public avalaraPrivateLineSplit: number;
	public siteZCustomerId: Guid;
	public invoiced: boolean;
	public situsCode: string;
	public unitTypeCode: string;
	public regulatoryCodeId?: number;
	public exemptionCodeId?: number;
	public serviceItems: ProductServiceItem[];
	public serviceItemsLabel: string;
	public serviceItemsDescription: string;
	public xeroTrackingCategoryId?: string;
	public xeroTrackingOptionId?: string;
	public telecomSpecialTermId: number;
	public agreementDateStart: string;
	public agreementDateEnd: string;
	public commissionRate: number;

	public typeId: ProductTypeId;
	deserialize(json: any): CustomerProduct {
		this.id = json.id;
		this.code = json.code;
		this.label = json.label;
		this.isHidden = json.isHidden;
		this.description = json.description;
		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;
		this.siteId = json.siteId;
		this.siteZCustomerId = json.siteZCustomerId;
		this.siteName = json.siteName;
		this.serviceItemId = json.serviceItemId;
		this.serviceItemCode = json.serviceItemCode;
		this.supplierId = json.supplierId ? json.supplierId : null;
		this.inAdvance = json.inAdvance;
		this.proRata = json.proRata;
		this.cost = json.cost;
		this.sell = json.sell;
		this.quantity = json.quantity;
		this.bundleId = json.bundleId;
		this.bundleName = json.bundleName;
		this.transactionCodeId = json.transactionCodeId;
		this.glCodeId = json.glCodeId;
		this.productCategoryId = json.productCategoryId;
		this.activated = json.activated;
		this.deactivated = json.deactivated;
		this.productKitId = json.productKitId;
		this.costCentre = json.costCentre;
		this.taxRateId = json.taxRateId;
		this.siteZId = json.siteZId;
		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;
		this.agreementId = json.agreementId;
		this.agreementName = json.agreementName;
		this.excludeSurcharges = json.excludeSurcharges;
		this.taxInclusive = json.taxInclusive;
		this.invoiced = json.invoiced;
		this.avalaraSaleType = json.avalaraSaleType;
		this.avalaraSalesAndUseId = json.avalaraSalesAndUseId;
		this.avalaraPrivateLineSplit = json.avalaraPrivateLineSplit;
		this.listOrder = json.listOrder;
		this.situsCode = json.situsCode;
		this.unitTypeCode = json.unitTypeCode;
		this.regulatoryCodeId = json.regulatoryCodeId;
		this.exemptionCodeId = json.exemptionCodeId;
		this.doNotExportToCWAgreements = json.doNotExportToCWAgreements;
		this.serviceItems = json.serviceItems;
		this.serviceItemsLabel = this.serviceItems?.map(si => si.serviceItem).join('; ') ?? '';
		this.serviceItemsDescription = this.serviceItems?.filter(si => si.serviceItemDescription).map(si => si.serviceItemDescription).join('; ') ?? '';
		this.xeroTrackingCategoryId = json.xeroTrackingCategoryId;
		this.xeroTrackingOptionId = json.xeroTrackingOptionId;
		this.telecomSpecialTermId = json.telecomSpecialTermId;
		this.rateCardId = json.rateCardId;
		this.rateCardName = json.rateCardName;
		this.agreementDateStart = json.agreementDateStart;
		this.agreementDateEnd = json.agreementDateEnd;
		this.typeId = json.typeId;
		this.commissionRate = json.commissionRate;

		return this;
	}

	clone(): CustomerProduct {
		return Object.assign(new CustomerProduct(), this);
	}

	restore(src: CustomerProduct): void {
		Object.assign(this, src);
	}
}

@EntityName('Site', 'Sites')
export class CustomerSite implements IIdentity, ICloneable<CustomerSite>, Serializable<CustomerSite> {
	id: Guid;
	name: string;
	siteCode: string;
	address1: string;
	address2: string;
	city: string;
	country: string;
	cchCode: string;
	postalCode: string;
	referenceCode: string;
	exemptionCode: string;
	exemptionCodeId: number;
	csiTaxExemptionCode: string;
	csiTaxExemptionList: string;
	rateCardId: number;
	rateCardName: string;
	incorporated: boolean;
	hideTaxes: boolean;
	externalReference: string;
	state: string;
	siteState: string;
	contactPhone: string;
	extraField1: string;
	extraField2: string;
	extraField3: string;
	extraField4: string;
	extraField5: string;

	//Hidden fields:
	public buildingFloorNumber: string;
	public buildingFloorNumberSuffix: string;
	public buildingFloorType: string;
	public buildingLocation: string;
	public buildingProperty: string;
	public locality: string;
	public streetFirstName: string;
	public streetFirstSuffix: string;
	public streetFirstType: string;
	public streetHouseFirstNumber: string;
	public streetHouseFirstNumberSuffix: string;
	public streetHouseSecondNumber: string;
	public streetHouseSecondNumberSuffix: string;
	public streetSecondName: string;
	public streetSecondSuffix: string;
	public streetSecondType: string;
	public unitFirstNumber: string;
	public unitFirstSuffix: string;
	public unitSecondNumber: string;
	public unitSecondSuffix: string;
	public unitType: string;
	public validated: boolean;

	deserialize(json: any): CustomerSite {

		this.id = json.id;
		this.name = json.name;
		this.siteCode = json.siteCode;
		this.address1 = json.address1;
		this.address2 = json.address2;
		this.city = json.city;
		this.country = json.country;
		this.cchCode = json.cchCode;
		this.postalCode = json.postalCode;
		this.referenceCode = json.referenceCode;
		this.exemptionCode = json.exemptionCode;
		this.exemptionCodeId = json.exemptionCodeId;
		this.csiTaxExemptionCode = json.csiTaxExemptionCode;
		this.csiTaxExemptionList = json.csiTaxExemptionList;
		this.rateCardId = json.rateCardId;
		this.rateCardName = json.rateCardName;
		this.incorporated = json.incorporated;
		this.externalReference = json.externalReference;
		this.hideTaxes = json.hideTaxes;
		this.buildingFloorNumber = json.buildingFloorNumber;
		this.buildingFloorNumberSuffix = json.buildingFloorNumberSuffix;
		this.buildingFloorType = json.buildingFloorType;
		this.buildingLocation = json.buildingLocation;
		this.buildingProperty = json.buildingProperty;
		this.locality = json.locality;
		this.streetFirstName = json.streetFirstName;
		this.streetFirstSuffix = json.streetFirstSuffix;
		this.streetFirstType = json.streetFirstType;
		this.streetHouseFirstNumber = json.streetHouseFirstNumber;
		this.streetHouseFirstNumberSuffix = json.streetHouseFirstNumberSuffix;
		this.streetHouseSecondNumber = json.streetHouseSecondNumber;
		this.streetHouseSecondNumberSuffix = json.streetHouseSecondNumberSuffix;
		this.streetSecondName = json.streetSecondName;
		this.streetSecondSuffix = json.streetSecondSuffix;
		this.streetSecondType = json.streetSecondType;
		this.unitFirstNumber = json.unitFirstNumber;
		this.unitFirstSuffix = json.unitFirstSuffix;
		this.unitSecondNumber = json.unitSecondNumber;
		this.unitSecondSuffix = json.unitSecondSuffix;
		this.unitType = json.unitType;
		this.validated = json.validated;
		this.state = json.state;
		this.siteState = json.siteState;
		
		this.contactPhone = json.contactPhone;
		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		return this;
	}

	clone(): CustomerSite {
		return Object.assign(new CustomerSite(), this);
	}

	restore(src: CustomerSite): void {
		Object.assign(this, src);
	}
}

export class CustomerSurcharge implements Serializable<CustomerSurcharge> {
	id: Guid;
	name: string;
	value: number;
	active: boolean;
	enabled: boolean;
	busy: boolean;
	surchargeTypeId: number;
	surchargeTypeLabel: string;
	surchargeTypes: SurchargeTypesOption[] = SurchargeTypesOption.allOptions;
	categoryType: string;
	categoryTypeId: number;
	customerSurchargeSettingId: Guid;
	surchargeSettingId: Guid;

	deserialize(json: any): CustomerSurcharge {
		this.id = json.surchargeSettingId;
		this.name = json.name;
		this.value = json.value;
		this.active = json.active;
		this.enabled = json.enabled;
		this.surchargeTypeId = json.surchargeTypeId;
		this.categoryTypeId = json.categoryTypeId;
		this.categoryType = json.categoryType;
		this.customerSurchargeSettingId = json.customerSurchargeSettingId;
		this.surchargeSettingId = json.surchargeSettingId;
		this.surchargeTypeLabel = this.surchargeTypeId ? this.surchargeTypes.find(s => s.id === this.surchargeTypeId).name : '';
		return this;
	}
}

@EntityName('User', 'Users')
export class CustomerUser implements Serializable<CustomerUser> {
	id: Guid;
	userName: string;
	displayName: string;
	dateCreated: Date;
	dateCreatedLabel: string;
	active: boolean;
	password: string;
	isActiveToStatus: string;
	invitationSent: boolean;
	registrationComplete: boolean;
	deserialize(json: any): CustomerUser {
		this.id = json.id;
		this.userName = json.userName;
		this.displayName = json.displayName;
		this.dateCreated = json.dateCreated;
		this.dateCreatedLabel = Utils.toMomentTzFormat(json.dateCreated);
		this.active = json.active;
		this.invitationSent = json.invitationSent;
		this.registrationComplete = json.registrationComplete;
		this.isActiveToStatus = this.defineStatus(json);

		return this;
	}

	clone(): CustomerUser {
		return Object.assign(new CustomerUser(), this);
	}

	restore(src: CustomerUser): void {
		Object.assign(this, src);
	}

	defineStatus(json: any): string {
		if (json.active) {
			return 'Active';
		}
		if (json.registrationComplete) {
			return 'Inactive';
		}
		if (json.invitationSent) {
			return 'Invitation Sent';
		}

		return 'New';
	}
}

@EntityName('Transaction', 'Transactions')
export class CustomerTransaction implements Serializable<CustomerTransaction> {
	id: Guid;
	productName: string;
	productLabel: string;
	templateProductId: Guid;
	cost: number;
	totalCost: number;
	totalSell: number;
	sell: number;
	quantity: number;
	isHidden: boolean;

	dateFrom: string;
	dateTo: string;
	from: string;
	to: string;

	dateFromMoment: string;
	dateToMoment: string;

	isOneOff: boolean;
	isFromCdr: boolean;
	supplierId: number;
	listOrder: number;
	billingPeriodId: Guid;
	productId: Guid;

	serviceItemId: Guid;
	serviceItem: string;

	siteId: Guid;
	siteName: string;
	siteZName: string;
	siteZId: Guid;

	serviceId: number;
	serviceName: string;

	invoiceNumber: string;
	transactionCodeId: Guid;
	productCategoryId: Guid;
	glCodeId: Guid;
	productKitId: number;
	discount: number;
	doNotExportToCWAgreements: boolean;
	costCentre: string;

	public extraField1: string;
	public extraField2: string;
	public extraField3: string;
	public extraField4: string;
	public extraField5: string;

	agreementId: Guid;
	agreementName: string;

	public avalaraSaleType: number;
	public avalaraSalesAndUseId: number;
	public avalaraPrivateLineSplit: number;
	public taxInclusive: boolean;
	public excludeSurcharges: boolean;
	public charge: number;
	public situsCode: string;
	public unitTypeCode: string;
	public regulatoryCodeId?: number;
	public exemptionCodeId?: number;
	public serviceItems: ProductServiceItem[];
	public serviceItemsLabel: string;
	public serviceItemsDescription: string;
	public xeroTrackingCategoryId?: string;
	public xeroTrackingOptionId?: string;
	public telecomSpecialTermId: number;
	public commissionRate: number;
	deserialize(json: any): CustomerTransaction {

		this.id = json.id;
		this.productName = json.productName;
		this.productLabel = json.productLabel;
		this.templateProductId = json.templateProductId;
		this.cost = json.cost;
		this.sell = json.sell;
		this.quantity = json.quantity;
		this.charge = json.charge;
		this.doNotExportToCWAgreements = json.doNotExportToCWAgreements;
		this.totalSell = this.charge;
		this.totalCost = Math.round((json.cost * json.quantity) * 10000) / 10000;

		this.isHidden = json.isHidden;

		this.dateFrom = json.dateFrom;
		this.dateTo = json.dateTo;
		this.from = json.dateFrom;
		this.to = json.dateTo;

		this.dateFromMoment = Utils.toMomentUtcFormat(json.dateFrom);
		this.dateToMoment = Utils.toMomentUtcFormat(json.dateTo);

		this.isOneOff = json.isOneOff;
		this.isFromCdr = json.isFromCdr;
		this.supplierId = json.supplierId ? json.supplierId : null;
		this.listOrder = json.listOrder;
		this.billingPeriodId = json.billingPeriodId;
		this.productId = json.productId;

		this.serviceItemId = json.serviceItemId;
		this.serviceItem = json.serviceItem;

		this.siteId = json.siteId;
		this.siteName = json.siteName;
		this.siteZId = json.siteZId;

		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;

		this.invoiceNumber = json.invoiceNumber;
		this.transactionCodeId = json.transactionCodeId;
		this.glCodeId = json.glCodeId;
		this.productCategoryId = json.productCategoryId;

		this.productKitId = json.productKitId;

		this.discount = json.discount;
		this.agreementId = json.agreementId;
		this.agreementName = json.agreementName;
		this.telecomSpecialTermId = json.telecomSpecialTermId;
		this.costCentre = json.costCentre;
		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		this.avalaraSaleType = json.avalaraSaleType;
		this.avalaraSalesAndUseId = json.avalaraSalesAndUseId;
		this.avalaraPrivateLineSplit = json.avalaraPrivateLineSplit;

		this.taxInclusive = json.taxInclusive;
		this.serviceItems = json.serviceItems;
		this.serviceItemsLabel = this.serviceItems?.map(si => si.serviceItem).join('; ') ?? '';
		this.serviceItemsDescription = this.serviceItems?.filter(si => si.serviceItemDescription).map(si => si.serviceItemDescription).join('; ') ?? '';
		this.excludeSurcharges = json.excludeSurcharges;
		this.situsCode = json.situsCode;
		this.unitTypeCode = json.unitTypeCode;
		this.regulatoryCodeId = json.regulatoryCodeId;
		this.exemptionCodeId = json.exemptionCodeId;
		this.xeroTrackingCategoryId = json.xeroTrackingCategoryId;
		this.xeroTrackingOptionId = json.xeroTrackingOptionId;
		this.commissionRate = json.commissionRate;
		return this;
	}

	constructor(tran?: CustomerTransaction) {
		if (tran) {
			Object.assign(this, tran);
		}
	}

	clone(): CustomerTransaction {
		return Object.assign(new CustomerTransaction(), this);
	}

	restore(src: CustomerTransaction): void {
		Object.assign(this, src);
	}
}

export class CustomerArTransactionResponse {
	transactions: CustomerArTransaction[];
	customerBalance: number;
}

export class CustomerArTransaction implements Serializable<CustomerArTransaction> {
	invoiceNumber: string;
	invoiceId: Guid;
	paymentId: number;
	id: Guid;
	paymresellerIdentId: number;
	customerId: Guid;
	dateMoment: string;
	amount: number;
	notes: string;
	typeId: number;
	typeName: string;

	deserialize(json: any): CustomerArTransaction {
		this.invoiceNumber = json.invoiceNumber;
		this.invoiceId = json.invoiceId;
		this.paymentId = json.paymentId;
		this.id = json.transactionId;
		this.paymresellerIdentId = json.paymresellerIdentId;
		this.customerId = json.customerId;
		this.dateMoment = Utils.toMomentUtcFormat(json.date);
		this.amount = json.amount;
		this.notes = json.notes;
		this.typeId = json.typeId;

		this.typeName = json.typeName;

		return this;
	}

	clone(): CustomerArTransaction {
		return Object.assign(new CustomerArTransaction(), this);
	}

	restore(src: CustomerArTransaction): void {
		Object.assign(this, src);
	}
}

export class CustomerUsageLine implements Serializable<CustomerUsageLine> {
	serviceId: number;
	serviceName: string;
	siteId: number;
	siteName: string;
	serviceItemId: number;
	serviceItemName: string;
	serviceItem2: string;
	serviceItem3: string;
	cost: number;
	sell: number;
	margin: number;
	marginPercent: number;
	date: Date;
	recordType: string;
	units: number;
	supplierName: string;
	supplierCodes: string;
	errorCode: number;
	agreementName: string;
	customerCode: string;
	customerLabel: string;
	serviceLabel: string;
	description: string;
	costCenter: string;

	deserialize(json: any): CustomerUsageLine {

		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;
		this.siteId = json.siteId;
		this.siteName = json.siteName;
		this.serviceItemId = json.serviceItemId;
		this.serviceItemName = json.serviceItemName;
		this.serviceItem2 = json.serviceItem2;
		this.serviceItem3 = json.serviceItem3;
		this.cost = json.cost;
		this.sell = json.sell;
		this.margin = json.margin ? json.margin : 0;
		this.marginPercent = json.marginPercent;
		this.date = json.date;
		this.recordType = json.recordType;
		this.units = json.units;
		this.supplierName = json.supplierName;
		this.supplierCodes = json.supplierCodes;
		this.errorCode = json.errorCode;
		this.agreementName = json.agreementName;
		this.customerCode = json.customerCode;
		this.customerLabel = json.customerLabel;
		this.serviceLabel = json.serviceLabel;
		this.description = json.description;
		this.costCenter = json.costCenter;

		return this;
	}
}

export class RatingType implements Serializable<RatingType> {
	id: string;
	name: string;
	description: string;

	deserialize(json: any): RatingType {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		return this;
	}

	clone(): RatingType {
		return Object.assign(new RatingType(), this);
	}

	restore(src: RatingType): void {
		Object.assign(this, src);
	}
}

export class ChargeTypes implements Serializable<ChargeTypes> {
	id: number;
	name: string;
	description: string;

	deserialize(json: any): ChargeTypes {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		return this;
	}
}

export class ScopeTypes implements Serializable<ScopeTypes> {
	id: number;
	name: string;
	description: string;

	deserialize(json: any): ScopeTypes {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		return this;
	}
}

export enum ChargeType {
	PerUnit = 1
}

export interface CustomerTaxExemption {
	id: Guid;
}
