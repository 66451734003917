import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'core/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import { VersionRepository } from 'repositories';
import { CacheService } from 'services/cache.service';
import { MyDetailsRepository } from 'repositories/my-details-repository';
import { Subject, takeUntil } from 'rxjs';
import * as semver from 'semver';
import { Breadcrumb, DgCrumbsService } from 'shared/components/dg-crumbs';
import { AppConfig } from 'core/app-config';
import { GenericEvent } from 'domain/types';
import { Globals } from 'tools/globals';
import { VersionProvider } from 'tools/version-provider';
import { permissions } from 'tools/permissions';

@Component({
	selector: 'dg-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.sass']
})

export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('headerContent') headerContent: ElementRef;
	shouldStick = false;
	displayName: string;
	latestVersion: string;
	currentVersion: string;
	headerContentTop: number;
	timerHandle: NodeJS.Timer;
	availableLangs: LangDefinition[];
	activeLang: string;
	private destroy$ = new Subject<void>();
	protected readonly permissions = permissions;

	constructor(
		public transloco: TranslocoService,
		private readonly route: ActivatedRoute,
		private readonly authService: AuthService,
		private readonly globals: Globals,
		private readonly router: Router,
		private readonly myDetailsRepository: MyDetailsRepository,
		private readonly versionRepository: VersionRepository,
		private readonly cacheService: CacheService,
		private readonly titleService: Title,
		private readonly dgCrumbsService: DgCrumbsService,
		private readonly permissionsService: NgxPermissionsService,
		private readonly config: AppConfig
	) {
		this.displayName = config.myDetails.displayName;
		const REFRESH_INTERVAL = 33500;
		this.currentVersion = VersionProvider.version.toString();

		this.availableLangs = <LangDefinition[]>transloco.getAvailableLangs();
		const activeLangId = transloco.getActiveLang();
		this.activeLang = this.availableLangs.find(l => l.id === activeLangId).label;

		this.timerHandle = setInterval(() => this.getLatestVersion(), REFRESH_INTERVAL);
		this.getLatestVersion();

		myDetailsRepository.myDetails$?.pipe(takeUntil(this.destroy$)).subscribe(me => {
			if (me) {
				this.displayName = me.displayName;
			}
		});
	}

	ngOnInit(): void {

	}

	ngAfterViewInit(): void {
		this.calculateHeaderTop();
		this.bingScrollEvent();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private calculateHeaderTop(): void {
		this.headerContentTop = this.headerContent.nativeElement.getBoundingClientRect().top;
	}

	private getLatestVersion(): void {
		this.versionRepository.getLatestVersion()?.pipe(takeUntil(this.destroy$)).subscribe(r =>
			this.latestVersion = r);
	}

	public get versionIsOld(): boolean {
		if (this.currentVersion && this.latestVersion) {
			return semver.gt(this.latestVersion, this.currentVersion);
		} else {
			return false;
		}
	}

	public refreshVersion(): void {
		location.reload();
	}

	signout(event: GenericEvent<HTMLAnchorElement>): void {
		event.stopPropagation();

		this.authService.signout();
		this.cacheService.reset();
		this.permissionsService.flushPermissions();

		clearTimeout(this.timerHandle);
	}

	bingScrollEvent(): void {
		window.addEventListener('scroll', (e) => {
			if (window.scrollY > this.headerContentTop) {
				this.shouldStick = true;
			} else {
				this.shouldStick = false;
			}
		});
	}

	onLangSelect(lang: LangDefinition): void {
		this.transloco.setActiveLang(lang.id);
		this.activeLang = lang.label;
	}
}
