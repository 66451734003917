<ng-container>
	<div class="btn-fixed">
		<a *ngIf="selected.length === 0" class="btn btn--secondary btn-floating btn-floating--empty">
			<i>0</i>
		</a>
		<a *ngIf="selected.length !== 0" class="btn btn--secondary btn-floating ">
			<i>{{ selected.length }}</i>
		</a>
		<ul>
			<li *ngFor="let action of actionCollection?.actions">
				<!-- add span with .mobile-fab-tip here after the <li> tag... -->
				<div class="btn-floating__desription-wrapper">
					<span class="btn-floating__desription">{{action.name}}</span>
				</div>
				<!-- ...and before the <a> tag -->
				<a *ngIf="action.available" class="btn btn--secondary btn-floating btn-floating--small"
				   (click)="onGroupAction(action)">
					<i class="fa {{action.icon}}"></i>
				</a>
			</li>
		</ul>
	</div>
</ng-container>

