import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ApiError } from 'domain/types';
import { getApiErrorTitle, getApiErrorMessage, hasApiError } from 'domain/utils';
import { ReplaySessionService } from 'services';
import { ToasterService } from './toaster-service';
import * as Sentry from '@sentry/angular';

class ErrorOutput {
	title = 'Unexpected Server Error';
	toastAlert = 'Please contact support.';
	internalError = undefined;
	stack = undefined;

	setApiError(apiError: ApiError): void {
		this.title = getApiErrorTitle(apiError);
		this.toastAlert = getApiErrorMessage(apiError);

		this.internalError = undefined;
		this.stack = undefined;
	}

	setCustomError(title: string, message: string, error?: any): void {
		this.title = title;
		this.toastAlert = `${message}`;
		this.internalError = error;
		this.stack = error?.stack;
	}
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

	constructor(
		@Inject(Injector) private readonly injector: Injector
	) {
		super();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async handleError(error: any): Promise<void> {
		super.handleError(error);

		// Workaround for ChunkLoadError
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message)) {
			window.location.reload();
			return;
		}

		const errorOutput = new ErrorOutput();

		if (hasApiError(error)) {
			errorOutput.setApiError(error);
		} else if (error.rejection) {
			// It is failed Promise
			if ('canceledRequest' in error.rejection) {
				// Skip process canceled requests
				return;
			}

			let apiError: ApiError | undefined = undefined;
			if (error.rejection?.status === 401) {
				errorOutput.setCustomError(
					'Unauthorized',
					error.rejection.error?.Message ?? 'Permission has been denied for this request.',
					error.rejection);
			} else if (hasApiError(error.rejection)) {
				apiError = error.rejection;
			} else if (hasApiError(error.rejection.error)) {
				apiError = error.rejection.error;
			} else if (error.rejection.error instanceof Blob) {
				const blobBody = JSON.parse(await error.rejection.error.text());
				if (hasApiError(blobBody)) {
					apiError = blobBody;
				} else if (hasApiError(blobBody.error)) {
					apiError = blobBody.error;
				} else if (hasApiError(blobBody.error?.data)) {
					apiError = blobBody.error?.data;
				}
			} else if (hasApiError(error.rejection.error?.data)) {
				apiError = error.rejection.error.data;
			} else if (hasApiError(error.rejection.data)) {
				apiError = error.rejection.data;
			}

			if (apiError) {
				errorOutput.setApiError(apiError);
			} else if (typeof error.rejection === 'object') {
				errorOutput.internalError = `${error.rejection.name}\n${error.rejection.message}`;
				errorOutput.stack = error.rejection.stack;
			} else {
				errorOutput.internalError = `${error.rejection}`;
				errorOutput.stack = error.stack;
			}
		} else if (error instanceof HttpErrorResponse) {
			if (error.error?.status === 401) {
				errorOutput.setCustomError(
					'Unauthorized',
					error.error.error?.Message ?? 'Permission has been denied for this request.',
					error.error);
			} else if (hasApiError(error.error)) {
				errorOutput.setApiError(error.error);
			} else if (error.error instanceof Blob) {
				const blobBody = JSON.parse(await error.error.text());
				if (hasApiError(blobBody)) {
					errorOutput.setApiError(blobBody);
				} else if (hasApiError(blobBody.error)) {
					errorOutput.setApiError(blobBody.error);
				} else if (hasApiError(blobBody.error?.data)) {
					errorOutput.setApiError(blobBody.error?.data);
				}
			} else if (hasApiError(error.error?.data)) {
				errorOutput.setApiError(error.error?.data);
			} else if ('data' in error && hasApiError(error.data)) {
				errorOutput.setApiError(error.data);
			} else {
				errorOutput.internalError = `${error.message}\n${error.error}`;
			}
		} else if (error.message) {
			errorOutput.toastAlert = `${error.message}`;
			errorOutput.stack = error.stack;
		} else {
			errorOutput.internalError = `${error.toString()}`;
			errorOutput.stack = error.stack;
		}

		this.toasterService.error(errorOutput.toastAlert, errorOutput.title, { onActivateTick: true });

		let errorLog = errorOutput.toastAlert;
		if (errorOutput.internalError)
			errorLog += '\n' + errorOutput.internalError;
		if (errorOutput.stack)
			errorLog += '\n' + errorOutput.stack;

		this.replaySessionService.tracker?.handleError(new Error(error), { errorLog });

		// Report error to Sentry
		Sentry.captureException(error);
		Sentry.captureMessage(errorLog);
	}

	private get toasterService(): ToasterService {
		return this.injector.get(ToasterService);
	}

	private get replaySessionService(): ReplaySessionService {
		return this.injector.get(ReplaySessionService);
	}
}
