import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { AgreementEntity } from 'domain/entities';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';
import { HttpResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AgreementsRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	async getCustomerAgreementsAsync(customerId: Guid, displayOption: number, destroy$: Subject<void>): Promise<AgreementEntity[]> {
		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/agreements?displayOption=${displayOption}`);
		const agreements = res.body.data.map(x => new AgreementEntity().deserialize(x));

		return agreements;
	}

	getCustomerAgreements(customerId: Guid): Observable<AgreementEntity[]> {
		//DAT-2765 - somehow got undefined customer Id
		if (!customerId) {
			return of([]);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/agreements`)
				.pipe(
					map(res => {
						const agreements = res.body.data.map(x => new AgreementEntity().deserialize(x));
						return agreements;
					}));

		return observable;
	}

	getCustomerAgreementById(customerId: Guid, id: Guid): Observable<AgreementEntity> {
		return this.getCustomerAgreements(customerId).pipe(map(agreement => agreement.find(x => x.id.equals(id))));
	}

	public addCustomerAgreement(customerId: Guid, agreement: AgreementEntity): Observable<Guid> {
		return this.createEntity(`/customers/${customerId}/agreements`, agreement)
			.pipe(map(s => s.id));
	}

	public updateCustomerAgreement(customerId: Guid, agreement: AgreementEntity): Observable<HttpResponse<void>> {
		return this.updateEntity(`/customers/${customerId}/agreements/${agreement.id}`, agreement)
			.pipe(map(s => null));
	}

	public deleteAgreement(customerId: Guid, agreementId: Guid): Observable<void> {
		return super.deleteEntity(`/customers/${customerId}/agreements/${agreementId}`)
			.pipe(map(s => null));
	}
}
