import { AuthHelper } from 'core/auth';
import { Utils } from './utils';

const DG_USER_STORAGE = 'dgUserStorage';

interface UserStorage {
	[userId: string]: { [key: string]: any; };
}

/**
 * Utility class for managing user-specific storage.
 */
export class UserStorageUtil {

	/**
	 * Stores a value associated with a key for the current user.
	 * @template T
	 * @param {string} key - The key under which the value is stored.
	 * @param {T} value - The value to store.
	 * @throws Will throw an error if user token data is not available.
	 */
	static setValue<T>(key: string, value: T): void {
		const token = AuthHelper.getJwtTokenData();
		if (!token?.nameid) {
			throw Error('User token data is not available.');
		}

		const userStorage: UserStorage = JSON.parse(localStorage.getItem(DG_USER_STORAGE)) || {};
		const userCache = userStorage[token.nameid] || {};
		userCache[key] = value;
		userStorage[token.nameid] = userCache;
		localStorage.setItem(DG_USER_STORAGE, JSON.stringify(userStorage));
	}

	/**
	 * Retrieves a value associated with a key for the current user.
	 * @template T
	 * @param {string} key - The key under which the value is stored.
	 * @returns {T} - The retrieved value.
	 * @throws Will throw an error if user token data is not available.
	 */
	static getValue<T>(key: string): T {
		const token = AuthHelper.getJwtTokenData();
		if (!token?.nameid) {
			throw Error('User token data is not available.');
		}

		const userStorage: UserStorage = JSON.parse(localStorage.getItem(DG_USER_STORAGE)) || {};
		const userCache = userStorage[token.nameid] || {};
		return userCache[key];
	}

	/**
	 * Stores a property value from a component instance for the current user.
	 * @template TComp
	 * @param {TComp} ngComponent - The Angular component instance.
	 * @param {keyof TComp} variableName - The name of the property to store.
	 */
	static put<TComp>(ngComponent: TComp, variableName: keyof TComp): void {
		const value = ngComponent[variableName];
		const id = Utils.getComponentLocation(ngComponent);
		const key = `ID-${id}_${String(variableName)}`;
		this.setValue(key, value);
	}

	/**
	 * Retrieves a property value for a component instance for the current user.
	 * @template TComp
	 * @param {TComp} ngComponent - The Angular component instance.
	 * @param {keyof TComp} variableName - The name of the property to retrieve.
	 * @returns {any} - The retrieved property value.
	 */
	static receive<TComp>(ngComponent: TComp, variableName: keyof TComp): any {
		const id = Utils.getComponentLocation(ngComponent);
		const key = `ID-${id}_${String(variableName)}`;
		return this.getValue<any>(key);
	}
}


