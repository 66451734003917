import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Tag } from './tag-entities';
import { Utils } from 'tools/utils';
import { Guid } from 'domain/types';
import { CustomerDetails } from './customer-entities';
import { ExtraFieldValue } from './extra-fields-entities';


export class Invoice implements Serializable<Invoice> {
	id: Guid;
	invoiceNumber: string;
	isOnHold: boolean;
	isLoading: boolean;
	invoiceDate: Date;
	invoiceDateMoment: string;
	status: InvoiceStatus;
	statusToField: string;
	statusString: string;
	dateGenerated: Date;
	dateGeneratedMoment: string;
	dateDue: Date;
	dateDueMoment: string;
	customerId: Guid;
	customerName: string;
	customer: CustomerDetails;
	customerGroup: string;
	canBeEmailed: boolean;
	amount: number;
	taxAmount: number;
	isCreditNote: boolean;
	subTotal: number;
	creditNoteId?: number;

	billingPeriodId: number;
	billingPeriodName: string;
	deliveryMethod: string;
	paymentMethod: string;
	canCharge: boolean;
	existNotes: boolean;
	email: boolean;
	print: boolean;
	cwExported: boolean;
	qbExported: boolean;
	qbdExported: boolean;
	xeroExported: boolean;
	atExported: boolean;
	haloExported: boolean;
	public tags: Tag[];
	public periodStart: Date;
	public periodEnd: Date;
	public periodStartMoment: string;
	public periodEndMoment: string;
	public agreementName: string;
	public agreementId: number;
	public invoiceType: string;
	amountDue: number;
	amountPaid: number;
	accountManager: string;
	invoiceDetailsNavLink: string;
	isGenerating = false;
	isDraft = false;
	public reason: string;
	isArchived: boolean;

	deserialize(json: any): Invoice {

		this.id = json.id;
		this.isLoading = false;
		this.invoiceNumber = json.invoiceNumber;
		this.isOnHold = json.isOnHold;

		this.status = json.statusId;
		this.statusToField = Invoice.getStatusField(json.statusId);
		this.isGenerating = json.statusId === 1;
		this.isDraft = json.statusId === 15;
		this.dateGenerated = json.dateGenerated ? new Date(json.dateGenerated) : null;
		this.invoiceType = Invoice.getInvoiceType(json.invoiceTypeId);
		this.customerId = json.customerId;
		this.customerName = json.customerName;
		this.customerGroup = json.customerGroup;
		this.canBeEmailed = json.canBeEmailed;
		this.taxAmount = json.taxAmount ? json.taxAmount : 0;
		this.isCreditNote = json.isCreditNote;
		this.creditNoteId = json.creditNoteId;
		this.amount = json.amount ? json.amount : 0;
		this.amountDue = json.amount ? json.amountDue : 0;
		this.amountPaid = json.amountPaid ? json.amountPaid : 0;
		this.subTotal = json.subTotal ? json.subTotal : 0;
		this.invoiceDetailsNavLink = Invoice.getInvoiceDetailsNavLink(json);

		this.billingPeriodId = json.billingPeriodId;
		this.billingPeriodName = json.billingPeriodName;
		this.canCharge = json.canCharge;
		this.existNotes = json.existNotes;
		this.email = json.email;
		this.print = json.print;
		this.cwExported = json.cwExported;
		this.qbExported = json.qbExported;
		this.qbdExported = json.qbdExported;
		this.xeroExported = json.xeroExported;
		this.atExported = json.atExported;
		this.haloExported = json.haloExported;
		this.deliveryMethod = json.deliveryMethod;
		this.paymentMethod = json.paymentMethod;
		this.tags = json.tags;

		this.dateDue = json.dateDue;
		this.invoiceDate = json.invoiceDate;
		this.dateDueMoment = Utils.toMomentUtcFormat(json.dateDue);
		this.invoiceDateMoment = Utils.toMomentLocalFormat(json.invoiceDate);

		this.periodStart = json.periodStart;
		this.periodEnd = json.periodEnd;
		this.periodStartMoment = Utils.toMomentUtcFormat(json.periodStart);
		this.periodEndMoment = Utils.toMomentUtcFormat(json.periodEnd);

		this.agreementName = json.agreementName;
		this.agreementId = json.agreementId;
		this.accountManager = json.accountManager;
		this.isArchived = json.isArchived;
		return this;
	}

	static getInvoiceDetailsNavLink(inv: any): string {
		// allow edit miscellaneous ivnoices gen failed / draft status
		if (inv.invoiceTypeId === 2 && (inv.statusId === 3 || inv.statusId === 15)) {
			return `/invoicing/miscellaneous/${inv.id}`;
		} else {
			return `/customers/${inv.customerId}/invoices/${inv.id}/details`;
		}
	}

	static getInvoiceType(id: number): string {
		if (id) {
			switch (id) {
				case 1:
					return 'Agreement';
				case 2:
					return 'Miscellaneous';
			}
		}
	}

	static getStatusField(id: number): string {
		if (id) {
			switch (id) {
				case 0:
					return 'None';
				case 1:
					return 'Generating';
				case 2:
					return 'Generated';
				case 3:
					return 'Gen. Failed';
				case 4:
					return 'Emailed';
				case 5:
					return 'Sent';
				case 6:
					return 'Deleted';
				case 7:
					return 'Sending';
				case 8:
					return 'Send Failed';
				case 9:
					return 'Sent Manually';
				case 10:
					return 'Uninvoiced';
				case 11:
					return 'Paid';
				case 12:
					return 'Paid Failed';
				case 13:
					return 'Paid Manually';
				case 14:
					return 'Paid Partially';
				case 15:
					return 'Draft';
				case 16:
					return 'Partially Paid Manually';
			}
		}
	}
}

export interface InvoicesResponse {
	data: Invoice[];
	records: number;
	pages: number;
	page: number;
	pageSize: number;
}

export enum InvoiceStatus {
	None = 0,
	Generating = 1,
	Generated = 2,
	Failed = 3,
	Emailed = 4,
	Sent = 5,
	Deleted = 6,
	Sending = 7,
	SendFailed = 8,
	SentManually = 9,
	Uninvoiced = 10,
	Paid = 11,
	PaidFailed = 12,
	PaidManually = 13,
	PartiallyPaid = 14,
	Draft = 15,
	PartiallyPaidManually = 16
}

export class GenerateCreditNote implements Serializable<GenerateCreditNote> {
	public id?: number;
	public invoiceNumber: string;
	public invoiceDate: any;
	public reason: string;

	constructor(id: number, invoiceDate: Date, reason: string) {
		this.id = id;
		this.invoiceDate = invoiceDate;
		this.reason = reason;
	}

	deserialize(json: any): GenerateCreditNote {
		this.id = json.id;
		this.invoiceNumber = json.invoiceNumber;
		this.invoiceDate = json.invoiceDate;
		this.reason = json.reason;
		return this;
	}
}

@EntityName('Template', 'Templates')
export class ProductTemplate implements Serializable<ProductTemplate> {
	public id: Guid;
	public code: string;
	public label: string;
	public description: string;
	public cost: number;
	public sell: number;
	public inAdvance: number;
	public supplierId: Guid;
	public bundleId: Guid;
	public rateCardId: Guid;
	public bundleName: string;
	public transactionCodeId: Guid;
	public taxCode: string;
	public taxCodeDescription: string;
	public glCodeId: Guid;
	public productCategoryId: Guid;
	public productKitId: Guid;
	public costCentre: string;
	public taxRateId: number;
	public productRatePlans: ProductRatePlan[];
	public extraField1: string;
	public extraField2: string;
	public extraField3: string;
	public extraField4: string;
	public extraField5: string;
	public chargeLine: string;
	public taxInclusive: boolean;
	public proRata: boolean;
	public isHidden: boolean;
	public doNotExportToCWAgreements: boolean;
	public excludeSurcharges: boolean;
	public situsCode: string;
	public unitTypeCode: string;
	public regulatoryCodeId?: number;
	public exemptionCodeId?: number;
	public xeroTrackingCategoryId?: string;
	public xeroTrackingOptionId?: string;
	public telecomSpecialTermId: number;
	public commissionRate: number;
	public assignedGroups: ExtraFieldValue[];

	deserialize(json: any): ProductTemplate {

		this.id = json.id;
		this.code = json.code;
		this.label = json.label;
		this.description = json.description;
		this.cost = json.cost ? json.cost : 0;
		this.sell = json.sell;
		this.inAdvance = json.inAdvance;
		this.supplierId = json.supplierId;
		this.bundleId = json.bundleId;
		this.bundleName = json.bundleName;
		this.transactionCodeId = json.transactionCodeId;
		this.taxCode = json.taxCode;
		this.taxCodeDescription = json.taxCodeDescription;
		this.isHidden = json.isHidden;
		this.glCodeId = json.glCodeId;
		this.productKitId = json.productKitId;
		this.productCategoryId = json.productCategoryId;
		this.costCentre = json.costCentre;
		this.taxRateId = json.taxRateId;
		this.rateCardId = json.rateCardId;

		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		this.taxInclusive = json.taxInclusive;
		this.proRata = json.proRata;
		this.doNotExportToCWAgreements = json.doNotExportToCWAgreements;
		this.excludeSurcharges = json.excludeSurcharges;
		this.situsCode = json.situsCode;
		this.unitTypeCode = json.unitTypeCode;
		this.regulatoryCodeId = json.regulatoryCodeId;
		this.exemptionCodeId = json.exemptionCodeId;
		this.xeroTrackingCategoryId = json.xeroTrackingCategoryId;
		this.xeroTrackingOptionId = json.xeroTrackingOptionId;
		this.telecomSpecialTermId = json.telecomSpecialTermId;
		this.commissionRate = json.commissionRate;
		this.assignedGroups = json.assignedGroups;

		return this;
	}

	clone(): ProductTemplate {
		return Object.assign(new ProductTemplate(), this);
	}
}

export class ProductRatePlan implements Serializable<ProductRatePlan> {
	id: Guid;
	name: string;
	description: string;
	productId: Guid;
	charges: ProductRatePlanCharge[];

	deserialize(json: any): ProductRatePlan {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.productId = json.productId;
		this.charges = json.charges;
		return this;
	}
}

export class ProductAdjustCharge implements Serializable<ProductAdjustCharge> {
	id: Guid;
	date: string;
	cost: number;
	sell: number;
	quantity: number;
	amount: number;
	amountPercentage: number;
	createAdjustmentTransaction: boolean;
	customerId: Guid;
	deserialize(json: any): ProductAdjustCharge {

		this.id = json.id;
		this.date = json.dateStart;
		this.cost = json.cost;
		this.sell = json.sell;
		this.quantity = json.quantity;
		this.amount = json.amount;
		this.customerId = json.customerId;
		this.amountPercentage = json.amountPercentage;
		this.createAdjustmentTransaction = json.createAdjustmentTransaction;
		return this;
	}
}

export class ProductRatePlanChargeUnit implements Serializable<ProductRatePlanChargeUnit> {
	id: Guid;
	dateStart: string;
	dateEnd: string;
	cost: number;
	sell: number;
	quantity: number;
	amount: number;
	amountPercentage: number;
	current: boolean;

	deserialize(json: any): ProductRatePlanChargeUnit {

		this.id = json.id;
		this.dateStart = json.dateStart;
		this.dateEnd = json.dateEnd;
		this.cost = json.cost;
		this.sell = json.sell;
		this.quantity = json.quantity;
		this.amount = json.amount;
		this.amountPercentage = json.amountPercentage;
		this.current = json.current;
		return this;
	}
}

export class ProductRatePlanCharge implements Serializable<ProductRatePlanCharge> {
	id: Guid;
	historical = false;
	isExpanded = false;
	name: string;
	description: string;
	productRatePlanId: Guid;
	chargeTypeId: number;
	cost: number;
	sell: number;
	quantity: number;
	amount: number;
	amountPercentage: number;
	scopeTypeId: number;
	sortOrder: number;
	kitId: Guid;
	dateStart: string;
	dateEnd: string;
	units: ProductRatePlanChargeUnit[];
	origUnits: ProductRatePlanChargeUnit[];
	createAdjustmentTransaction: boolean;
	deserialize(json: any): ProductRatePlanCharge {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.productRatePlanId = json.productRatePlanId;
		this.chargeTypeId = json.chargeTypeId;
		this.cost = json.cost;
		this.sell = json.sell;
		this.quantity = json.quantity;
		this.amount = json.amount;
		this.amountPercentage = json.amountPercentage;
		this.scopeTypeId = json.scopeTypeId;
		this.sortOrder = json.sortOrder;
		this.dateStart = json.dateStart;
		this.dateEnd = json.dateEnd;
		this.units = json.units;
		this.createAdjustmentTransaction = json.createAdjustmentTransaction;
		return this;
	}
}

export class Service implements Serializable<Service> {
	id: number;
	name: string;
	serviceItemName: string;

	deserialize(json: any): Service {

		this.id = json.id;
		this.name = json.name;
		this.serviceItemName = json.serviceItemName;

		return this;
	}
}

export class ServiceUsageRecordType implements Serializable<ServiceUsageRecordType> {
	id: number;
	name: string;

	// used only in UI
	value: boolean;
	bundleQuantityTypeId?: number;

	deserialize(json: any): ServiceUsageRecordType {

		this.id = json.id;
		this.name = json.name;

		return this;
	}
}

export class Supplier implements Serializable<Supplier> {
	id: number;
	name: string;
	code: string;

	deserialize(json: any): Supplier {

		this.id = json.id;
		this.name = json.name;
		this.code = json.code;

		return this;
	}
}

@EntityName('Service Item', 'Service Items')
export class ServiceItem implements Serializable<ServiceItem> {
	id: Guid;
	code: string;
	description: string;
	status: string;

	customerId: Guid;
	customerName: string;

	serviceId: number;
	serviceName: string;

	siteId: Guid;
	siteName: string;

	activated: string;
	deactivated: string;
	internalNotes: string;
	isActive: boolean;
	isGroup: boolean;
	parentGroupId: Guid;
	isActiveToStatus: string;
	costCentre: string;

	extraField1: string;
	extraField2: string;
	extraField3: string;
	extraField4: string;
	extraField5: string;
	ipndStatus: string;

	public agreementId: Guid;
	public agreementName: string;

	public serviceItemType: string;
	public serviceItemTypeId: Guid;
	public avalaraSaleType: number;
	public aliases: string;

	public isCustomerActive: boolean;
	public customerStatus: string;
	public assignedProducts: string;

	deserialize(json: any): ServiceItem {
		this.id = json.id;
		this.code = json.code;
		this.costCentre = json.costCentre;
		this.description = json.description;
		this.customerId = json.customerId;
		this.customerName = json.customerName;
		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;
		this.siteId = json.siteId;
		this.siteName = json.siteName;

		this.activated = json.activated;
		this.deactivated = json.deactivated;
		this.ipndStatus = json.ipndStatus;
		this.internalNotes = json.internalNotes;
		this.isActive = json.isActive;
		this.isGroup = json.isGroup;
		this.parentGroupId = json.parentGroupId;
		this.isActiveToStatus = json.isActive === true ? json.isActiveToStatus = 'Active' : json.isActiveToStatus = 'Inactive';

		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		this.agreementId = json.agreementId;
		this.agreementName = json.agreementName;
		this.serviceItemType = json.serviceItemType;
		this.serviceItemTypeId = json.serviceItemTypeId;
		this.avalaraSaleType = json.avalaraSaleType;
		this.aliases = json.aliases;

		this.isCustomerActive = json.isCustomerActive;
		this.customerStatus = json.isCustomerActive ? 'Active' : 'Inactive';
		this.assignedProducts = json.assignedProducts ?? '';

		return this;
	}

	deserialize2(json: any): ServiceItem {

		this.id = json.id;
		this.code = json.code;
		this.description = json.description;
		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;
		this.siteId = json.siteId;
		this.costCentre = json.costCentre;
		this.siteName = json.siteName;
		this.activated = json.activated;
		this.deactivated = json.deactivated;
		this.internalNotes = json.internalNotes;
		this.isActive = json.isActive;
		this.ipndStatus = json.ipndStatus;
		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		this.agreementId = json.agreementId;
		this.agreementName = json.agreementName;
		this.serviceItemType = json.serviceItemType;
		this.serviceItemTypeId = json.serviceItemTypeId;
		this.avalaraSaleType = json.avalaraSaleType;
		this.aliases = json.aliases;

		this.isCustomerActive = json.isCustomerActive;
		this.customerStatus = json.isCustomerActive ? 'Active' : 'Inactive';
		this.assignedProducts = json.assignedProducts ?? '';

		return this;
	}

	clone(): ServiceItem {
		return Object.assign(new ServiceItem(), this);
	}
}

export class ServiceItemTypes implements Serializable<ServiceItemTypes> {
	id: Guid;
	name: string;
	createdBy: number;
	dateCreatedMoment: string;
	dateModifiedMoment: string;

	deserialize(json: any): ServiceItemTypes {

		this.id = json.id;
		this.name = json.name;
		this.createdBy = json.createdBy;
		this.dateCreatedMoment = Utils.toMomentTzFormat(json.dateCreated);
		this.dateModifiedMoment = Utils.toMomentTzFormat(json.dateModified);

		return this;
	}
	clone(): ServiceItemTypes {
		const clone = Object.assign(new ServiceItemTypes(), this);
		return clone;
	}
}

export class ServiceItemAliasDto implements Serializable<ServiceItemAliasDto> {
	id: Guid;
	name: string;
	serviceItemId: Guid;
	resellerId: Guid;
	userId: Guid;
	dateCreated: Date;
	dateModified: Date;
	active: boolean;

	deserialize(json: any): ServiceItemAliasDto {

		this.id = json.id;
		this.name = json.name;
		this.serviceItemId = json.serviceItemId;
		this.resellerId = json.resellerId;
		this.userId = json.userId;
		this.dateCreated = json.dateCreated;
		this.dateModified = json.dateModified;
		this.active = json.active;

		return this;
	}
}

export class ResellerService implements Serializable<ResellerService> {
	serviceId: number;
	serviceName: string;
	serviceItemLabel: string;
	active: boolean;

	deserialize(json: any): ResellerService {

		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;
		this.serviceItemLabel = json.serviceItemLabel;
		this.active = json.active;

		return this;
	}
}

export class InvoiceGroupingMethod implements Serializable<InvoiceGroupingMethod> {
	id: number;
	name: string;
	description: string;

	deserialize(json: any): InvoiceGroupingMethod {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;

		return this;
	}
}

export class PortalGroupingMethod implements Serializable<PortalGroupingMethod> {
	id: number;
	name: string;
	description: string;

	deserialize(json: any): PortalGroupingMethod {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;

		return this;
	}
}

export class UsageRecordType implements Serializable<UsageRecordType> {
	id: Guid;
	recordTypeId: number;
	description: string;
	glCodeId?: Guid;
	serviceId: number;
	objectTypeId: number;
	groupName: string;
	resellerId?: Guid;
	transactionCodeId?: number;
	portalGroupingMethodId: number;
	invoiceGroupingMethodId: number;
	transactionCode: string;
	glCode: string;
	serviceName: string;
	portalGroupingMethodName: string;
	invoiceGroupingMethodName: string;
	usageUnitId: number;

	deserialize(json: any): UsageRecordType {

		this.id = json.id;
		this.recordTypeId = json.recordTypeId;
		this.description = json.description;
		this.glCodeId = json.glCodeId;
		this.serviceId = json.serviceId;
		this.objectTypeId = json.objectTypeId;
		this.groupName = json.groupName;
		this.resellerId = json.resellerId;
		this.transactionCodeId = json.transactionCodeId;
		this.portalGroupingMethodId = json.portalGroupingMethodId;
		this.invoiceGroupingMethodId = json.invoiceGroupingMethodId;
		this.transactionCode = json.transactionCode;
		this.glCode = json.glCode;
		this.serviceName = json.serviceName;
		this.portalGroupingMethodName = json.portalGroupingMethodName;
		this.invoiceGroupingMethodName = json.invoiceGroupingMethodName;
		this.usageUnitId = json.usageUnitId;

		return this;
	}
	clone(): UsageRecordType {
		const clone = Object.assign(new UsageRecordType(), this);
		return clone;
	}
}

export class Job implements Serializable<Job> {
	id: number;
	title: string;
	percentComplete: number;
	isCompleted: boolean;
	isFailed: boolean;
	dateStarted: Date;
	dateCompleted: Date;
	jobType: number;
	isSucceded: boolean;
	messages: { date: string; message: string; }[];
	stats: any;
	tag: any;

	deserialize(json: any): Job {

		this.id = json.id;
		this.title = json.title;
		this.percentComplete = json.percentComplete;
		this.isCompleted = json.isCompleted;
		this.isFailed = json.isFailed;
		this.dateStarted = json.dateStarted;
		this.dateCompleted = json.dateCompleted;
		this.jobType = json.jobType;
		this.isSucceded = json.isSucceded;
		this.messages = json.messages;
		this.stats = json.stats;

		this.tag = null;
		if (json.tag) {
			this.tag = JSON.parse(json.tag);
		}

		return this;
	}
}

export class CustomerPortalSettings {

	brandId: number;
	colour1: string;
	colour2: string;
	colour3: string;
	colour4: string;
	colour5: string;
	colour6: string;
	colour7: string;
	invoiceLabel: string;
	endServiceItemDisplayName: string;
	endServiceId: number;
	endShowInvoices: boolean;
	endShowDashboard: boolean;
	endShowTransactions: boolean;
	endShowUsage: boolean;
	endShowCostCentre: boolean;
	endShowServiceItem: boolean;
	endShowUsageCostCentre: boolean;
	currencySymbol: CurrencySymbol;

	deserialize(json: any): CustomerPortalSettings {

		this.brandId = json.brandId;
		this.colour1 = json.colour1;
		this.colour2 = json.colour2;
		this.colour3 = json.colour3;
		this.colour4 = json.colour4;
		this.colour5 = json.colour5;
		this.colour6 = json.colour6;
		this.colour7 = json.colour7;
		this.invoiceLabel = json.invoiceLabel;
		this.endShowInvoices = json.endShowInvoices;
		this.endShowDashboard = json.endShowDashboard;
		this.endShowTransactions = json.endShowTransactions;
		this.endShowUsage = json.endShowUsage;
		this.endShowCostCentre = json.endShowCostCentre;
		this.currencySymbol = json.currencySymbol;
		this.endServiceId = json.endServiceId;
		this.endServiceItemDisplayName = json.endServiceItemDisplayName;
		this.endShowServiceItem = json.endShowServiceItem;
		this.endShowUsageCostCentre = json.endShowUsageCostCentre;

		return this;
	}

	clone(): CustomerPortalSettings {
		return Object.assign(new CustomerPortalSettings(), this);
	}
}

export class CustomerPortalServiceSettings {

	serviceName: string;
	serviceId: number;
	usageDisplaySettingId: number;

	deserialize(json: any): CustomerPortalServiceSettings {
		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;
		this.usageDisplaySettingId = json.usageDisplaySettingId;

		return this;
	}
}

export class CurrencySymbol {
	id: number;
	symbol: string;
	name: string;
	description: string;

	deserialize(json: any): CurrencySymbol {
		this.id = json.id;
		this.symbol = json.symbol;
		this.name = json.name;
		this.description = json.description;

		return this;
	}

}

export class DueDateOption {

	constructor(public id: number, public name: string) {

	}

	public static get allOptions(): DueDateOption[] {

		const result = [];
		result.push(new DueDateOption(1, '20th of Current Month'));
		result.push(new DueDateOption(2, '20th of Next Month'));
		result.push(new DueDateOption(8, '15th of Next Month'));
		result.push(new DueDateOption(3, 'Invoice Date + 7 Days'));
		result.push(new DueDateOption(4, 'Invoice Date + 14 Days'));
		result.push(new DueDateOption(9, 'Invoice Date + 15 Days'));
		result.push(new DueDateOption(5, 'Invoice Date + 21 Days'));
		result.push(new DueDateOption(6, 'Invoice Date + 30 Days'));

		return result;
	}

	public static getById(id: number): DueDateOption {
		return DueDateOption.allOptions.find(x => x.id === id);
	}
}

export class TimeZoneOption {
	constructor(public value: string, public label: string) { }

	public static get allOptions(): TimeZoneOption[] {

		const result = [];

		result.push(new TimeZoneOption('Pacific/Midway', '(GMT-11:00) Midway Island, Samoa'));
		result.push(new TimeZoneOption('America/Adak', '(GMT-10:00) Hawaii-Aleutian'));
		result.push(new TimeZoneOption('Etc/GMT+10', '(GMT-10:00) Hawaii'));
		result.push(new TimeZoneOption('Pacific/Marquesas', '(GMT-09:30) Marquesas Islands'));
		result.push(new TimeZoneOption('Pacific/Gambier', '(GMT-09:00) Gambier Islands'));
		result.push(new TimeZoneOption('America/Anchorage', '(GMT-09:00) Alaska'));
		result.push(new TimeZoneOption('America/Ensenada', '(GMT-08:00) Tijuana, Baja California'));
		result.push(new TimeZoneOption('Etc/GMT+8', '(GMT-08:00) Pitcairn Islands'));
		result.push(new TimeZoneOption('America/Los_Angeles', '(GMT-08:00) Pacific Time (US and Canada)'));
		result.push(new TimeZoneOption('America/Denver', '(GMT-07:00) Mountain Time (US and Canada)'));
		result.push(new TimeZoneOption('America/Chihuahua', '(GMT-07:00) Chihuahua, La Paz, Mazatlan'));
		result.push(new TimeZoneOption('America/Dawson_Creek', '(GMT-07:00) Arizona'));
		result.push(new TimeZoneOption('America/Belize', '(GMT-06:00) Saskatchewan, Central America'));
		result.push(new TimeZoneOption('America/Cancun', '(GMT-06:00) Guadalajara, Mexico City, Monterrey'));
		result.push(new TimeZoneOption('Chile/EasterIsland', '(GMT-06:00) Easter Island'));
		result.push(new TimeZoneOption('America/Chicago', '(GMT-06:00) Central Time (US and Canada)'));
		result.push(new TimeZoneOption('America/New_York', '(GMT-05:00) Eastern Time (US and Canada)'));
		result.push(new TimeZoneOption('America/Havana', '(GMT-05:00) Cuba'));
		result.push(new TimeZoneOption('America/Bogota', '(GMT-05:00) Bogota, Lima, Quito, Rio Branco'));
		result.push(new TimeZoneOption('America/Caracas', '(GMT-04:30) Caracas'));
		result.push(new TimeZoneOption('America/Santiago', '(GMT-04:00) Santiago'));
		result.push(new TimeZoneOption('America/La_Paz', '(GMT-04:00) La Paz'));
		result.push(new TimeZoneOption('Atlantic/Stanley', '(GMT-04:00) Faukland Islands'));
		result.push(new TimeZoneOption('America/Campo_Grande', '(GMT-04:00) Brazil'));
		result.push(new TimeZoneOption('America/Goose_Bay', '(GMT-04:00) Atlantic Time (Goose Bay)'));
		result.push(new TimeZoneOption('America/Glace_Bay', '(GMT-04:00) Atlantic Time (Canada)'));
		result.push(new TimeZoneOption('America/St_Johns', '(GMT-03:30) Newfoundland'));
		result.push(new TimeZoneOption('America/Araguaina', '(GMT-03:00) UTC-3'));
		result.push(new TimeZoneOption('America/Montevideo', '(GMT-03:00) Montevideo'));
		result.push(new TimeZoneOption('America/Miquelon', '(GMT-03:00) Miquelon, St. Pierre'));
		result.push(new TimeZoneOption('America/Godthab', '(GMT-03:00) Greenland'));
		result.push(new TimeZoneOption('America/Argentina/Buenos_Aires', '(GMT-03:00) Buenos Aires'));
		result.push(new TimeZoneOption('America/Sao_Paulo', '(GMT-03:00) Brasilia'));
		result.push(new TimeZoneOption('America/Noronha', '(GMT-02:00) Mid-Atlantic'));
		result.push(new TimeZoneOption('Atlantic/Cape_Verde', '(GMT-01:00) Cape Verde Is.'));
		result.push(new TimeZoneOption('Atlantic/Azores', '(GMT-01:00) Azores'));
		result.push(new TimeZoneOption('Europe/Belfast', '(GMT+00:00) Greenwich Mean Time : Belfast'));
		result.push(new TimeZoneOption('Europe/Dublin', '(GMT+00:00) Greenwich Mean Time : Dublin'));
		result.push(new TimeZoneOption('Europe/Lisbon', '(GMT+00:00) Greenwich Mean Time : Lisbon'));
		result.push(new TimeZoneOption('Europe/London', '(GMT+00:00) Greenwich Mean Time : London'));
		result.push(new TimeZoneOption('Africa/Abidjan', '(GMT+00:00) Monrovia, Reykjavik'));
		result.push(new TimeZoneOption('Europe/Amsterdam', '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'));
		result.push(new TimeZoneOption('Europe/Belgrade', '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'));
		result.push(new TimeZoneOption('Europe/Brussels', '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris'));
		result.push(new TimeZoneOption('Africa/Algiers', '(GMT+01:00) West Central Africa'));
		result.push(new TimeZoneOption('Africa/Windhoek', '(GMT+01:00) Windhoek'));
		result.push(new TimeZoneOption('Asia/Beirut', '(GMT+02:00) Beirut'));
		result.push(new TimeZoneOption('Africa/Cairo', '(GMT+02:00) Cairo'));
		result.push(new TimeZoneOption('Asia/Gaza', '(GMT+02:00) Gaza'));
		result.push(new TimeZoneOption('Africa/Blantyre', '(GMT+02:00) Harare, Pretoria'));
		result.push(new TimeZoneOption('Asia/Jerusalem', '(GMT+02:00) Jerusalem'));
		result.push(new TimeZoneOption('Europe/Minsk', '(GMT+02:00) Minsk'));
		result.push(new TimeZoneOption('Asia/Damascus', '(GMT+02:00) Syria'));
		result.push(new TimeZoneOption('Europe/Moscow', '(GMT+03:00) Moscow, St. Petersburg, Volgograd'));
		result.push(new TimeZoneOption('Africa/Addis_Ababa', '(GMT+03:00) Nairobi'));
		result.push(new TimeZoneOption('Asia/Tehran', '(GMT+03:30) Tehran'));
		result.push(new TimeZoneOption('Asia/Dubai', '(GMT+04:00) Abu Dhabi, Muscat'));
		result.push(new TimeZoneOption('Asia/Yerevan', '(GMT+04:00) Yerevan'));
		result.push(new TimeZoneOption('Asia/Kabul', '(GMT+04:30) Kabul'));
		result.push(new TimeZoneOption('Asia/Yekaterinburg', '(GMT+05:00) Ekaterinburg'));
		result.push(new TimeZoneOption('Asia/Tashkent', '(GMT+05:00) Tashkent'));
		result.push(new TimeZoneOption('Asia/Kolkata', '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi'));
		result.push(new TimeZoneOption('Asia/Katmandu', '(GMT+05:45) Kathmandu'));
		result.push(new TimeZoneOption('Asia/Dhaka', '(GMT+06:00) Astana, Dhaka'));
		result.push(new TimeZoneOption('Asia/Novosibirsk', '(GMT+06:00) Novosibirsk'));
		result.push(new TimeZoneOption('Asia/Rangoon', '(GMT+06:30) Yangon (Rangoon)'));
		result.push(new TimeZoneOption('Asia/Bangkok', '(GMT+07:00) Bangkok, Hanoi, Jakarta'));
		result.push(new TimeZoneOption('Asia/Krasnoyarsk', '(GMT+07:00) Krasnoyarsk'));
		result.push(new TimeZoneOption('Asia/Hong_Kong', '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi'));
		result.push(new TimeZoneOption('Asia/Irkutsk', '(GMT+08:00) Irkutsk, Ulaan Bataar'));
		result.push(new TimeZoneOption('Australia/Perth', '(GMT+08:00) Perth'));
		result.push(new TimeZoneOption('Australia/Eucla', '(GMT+08:45) Eucla'));
		result.push(new TimeZoneOption('Asia/Tokyo', '(GMT+09:00) Osaka, Sapporo, Tokyo'));
		result.push(new TimeZoneOption('Asia/Seoul', '(GMT+09:00) Seoul'));
		result.push(new TimeZoneOption('Asia/Yakutsk', '(GMT+09:00) Yakutsk'));
		result.push(new TimeZoneOption('Australia/Adelaide', '(GMT+09:30) Adelaide'));
		result.push(new TimeZoneOption('Australia/Darwin', '(GMT+09:30) Darwin'));
		result.push(new TimeZoneOption('Australia/Brisbane', '(GMT+10:00) Brisbane'));
		result.push(new TimeZoneOption('Australia/Hobart', '(GMT+10:00) Hobart'));
		result.push(new TimeZoneOption('Asia/Vladivostok', '(GMT+10:00) Vladivostok'));
		result.push(new TimeZoneOption('Australia/Lord_Howe', '(GMT+10:30) Lord Howe Island'));
		result.push(new TimeZoneOption('Etc/GMT-11', '(GMT+11:00) Solomon Is., New Caledonia'));
		result.push(new TimeZoneOption('Asia/Magadan', '(GMT+11:00) Magadan'));
		result.push(new TimeZoneOption('Pacific/Norfolk', '(GMT+11:30) Norfolk Island'));
		result.push(new TimeZoneOption('Asia/Anadyr', '(GMT+12:00) Anadyr, Kamchatka'));
		result.push(new TimeZoneOption('Pacific/Auckland', '(GMT+12:00) Auckland, Wellington'));
		result.push(new TimeZoneOption('Etc/GMT-12', '(GMT+12:00) Fiji, Kamchatka, Marshall Is.'));
		result.push(new TimeZoneOption('Pacific/Chatham', '(GMT+12:45) Chatham Islands'));
		result.push(new TimeZoneOption('Pacific/Tongatapu', '(GMT+13:00) Nuku`alofa'));
		result.push(new TimeZoneOption('Pacific/Kiritimati', '(GMT+14:00) Kiritimati'));

		return result;
	}
}

export class CurrencyOption {
	constructor(public value: string, public label: string) { }

	public static get allOptions(): CurrencyOption[] {

		const result = [];

		result.push(new CurrencyOption('ARS', 'Argentine Peso'));
		result.push(new CurrencyOption('AUD', 'Australian Dollar'));
		result.push(new CurrencyOption('BRL', 'Brazilian Real'));
		result.push(new CurrencyOption('CAD', 'Canadian Dollar'));
		result.push(new CurrencyOption('CHF', 'Swiss Franc'));
		result.push(new CurrencyOption('CLP', 'Chilean Peso'));
		result.push(new CurrencyOption('CNY', 'Chinese Yuan Renminbi'));
		result.push(new CurrencyOption('COP', 'Colombian Peso'));
		result.push(new CurrencyOption('EGP', 'Egypt Pound'));
		result.push(new CurrencyOption('EUR', 'Euro'));
		result.push(new CurrencyOption('GBP', 'British Pound Sterling'));
		result.push(new CurrencyOption('IDR', 'Indonesian Rupiah'));
		result.push(new CurrencyOption('ILS', 'Israeli New Shekel'));
		result.push(new CurrencyOption('INR', 'Indian Rupee'));
		result.push(new CurrencyOption('JPY', 'Japanese Yen'));
		result.push(new CurrencyOption('KRW', 'South Korean Won'));
		result.push(new CurrencyOption('MXN', 'Mexican Peso'));
		result.push(new CurrencyOption('MYR', 'Malaysian Ringgit'));
		result.push(new CurrencyOption('NOK', 'Norwegian Krone'));
		result.push(new CurrencyOption('NZD', 'New Zealand Dollar'));
		result.push(new CurrencyOption('PAB', 'Panamanian Balboa'));
		result.push(new CurrencyOption('PEN', 'Peruvian Sol'));
		result.push(new CurrencyOption('PHP', 'Philippines Peso'));
		result.push(new CurrencyOption('PKR', 'Pakistani Rupee'));
		result.push(new CurrencyOption('RUB', 'Russian Ruble'));
		result.push(new CurrencyOption('SAR', 'Saudi Arabia Riyal'));
		result.push(new CurrencyOption('SEK', 'Swedish krona'));
		result.push(new CurrencyOption('SGD', 'Singapore Dollar'));
		result.push(new CurrencyOption('THB', 'Thai Baht'));
		result.push(new CurrencyOption('TRY', 'Turkish Lira'));
		result.push(new CurrencyOption('UAH', 'Ukrainian Hryvnia'));
		result.push(new CurrencyOption('USD', 'United States Dollar'));
		result.push(new CurrencyOption('VES', 'Venezuelan Bolívar Soberano'));
		result.push(new CurrencyOption('VND', 'Vietnamese Dong'));
		result.push(new CurrencyOption('ZAR', 'South African Rand'));

		return result;
	}
}
export class UsageUnit implements Serializable<UsageUnit> {
	id: number;
	name: string;

	deserialize(json: any): UsageUnit {

		this.id = json.id;
		this.name = json.name;

		return this;
	}
}

export class DisplayUnit implements Serializable<DisplayUnit> {
	id: number;
	name: string;

	deserialize(json: any): DisplayUnit {

		this.id = json.id;
		this.name = json.name;

		return this;
	}
}
