import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Globals } from 'tools/globals';

import { Breadcrumb } from './breadcrumb';
import { DgCrumbsService } from './dg-crumbs.service';

@Component({
	selector: 'dg-crumbs',
	templateUrl: './dg-crumbs.component.html',
	styleUrls: ['./dg-crumbs.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgCrumbsComponent implements OnDestroy {
	breadcrumbs: Breadcrumb[];
	isCrumbDisabled = true;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly dgCrumbsService: DgCrumbsService,
		private readonly globals: Globals,
		private readonly cdr: ChangeDetectorRef,
		private readonly ngZone: NgZone
	) {
		dgCrumbsService.breadcrumbChanged.pipe(takeUntil(this.destroy$)).subscribe((crumbs) => {
			this.breadcrumbs = crumbs;
			this.cdr.markForCheck();

			ngZone.onStable.pipe(takeUntil(this.destroy$)).subscribe(() => {
				const isCustomerRoute = this.router.url.indexOf('/customers/' + this.globals.customerId) > -1;
				const isSettingsRoute = this.router.url.indexOf('/settings/') > -1;
				const isQuoterExternalQuotes = this.router.url.indexOf('/external-quotes/quoter/') > -1;
				const isItQuoterExternalQuotes = this.router.url.indexOf('/external-quotes/it-quoter/') > -1;

				if (isCustomerRoute || isSettingsRoute) {
					this.isCrumbDisabled = false;
				} else {
					this.isCrumbDisabled = true;
				}

				// provide breadcrumb link on details page if we on customer
				if (isCustomerRoute && crumbs?.length > 0 && crumbs[1]) {
					crumbs[1].url = '/customers/' + this.globals.customerId + '/details';
				}

				// prevent to navigate to blank page
				if (isQuoterExternalQuotes && crumbs?.length > 0 && crumbs[0]) {
					crumbs[0].url = '/external-quotes/quoter/quotes/';
				}

				// prevent to navigate to blank page
				if (isItQuoterExternalQuotes && crumbs?.length > 0 && crumbs[0]) {
					crumbs[0].url = '/external-quotes/it-quoter/quotes/';
				}

				this.resolveCrumbNames(crumbs);

				this.cdr.markForCheck();
			});
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private resolveCrumbNames(crumbs: Breadcrumb[]): void {

		const titles = crumbs.filter((route) => route.displayName);

		if (this.router.url.indexOf('/customers/' + this.globals.customerId) > -1 && titles.length > 1 && titles[1]) {
			titles[1].displayName = this.globals.customerName;
		}

		if (this.router.url.indexOf('/rating/pricebook/') > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.priceBookName;
			this.globals.priceBookProductId = undefined;
		}

		if (this.router.url.indexOf('/rating/ratecard/') > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.rateCardName;
			this.globals.rateCardRateId = undefined;
		}

		if (this.router.url.indexOf('/product-kits/' + this.globals.productKitId) > -1 && titles.length > 1 && titles[1]) {
			titles[1].displayName = this.globals.productKitName;
		}

		if (this.router.url.indexOf('/bundles/' + this.globals.bundleId) > -1 && titles.length > 1 && titles[1]) {
			titles[1].displayName = this.globals.bundleName;
		}

		if (this.router.url.indexOf('/quantities/' + this.globals.bundleQuantityId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.bundleQuantityName;
		}

		if (this.router.url.indexOf('/products/' + this.globals.kitProductCodeId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.kitProductCode;
		}

		if (this.router.url.indexOf('/products/' + this.globals.customerProductId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.customerProductName;
		}

		if (this.router.url.indexOf('/service-items/' + this.globals.customerServiceItemId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.customerServiceItemName;
		}

		if (this.router.url.indexOf('/sites/' + this.globals.customerSiteId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.customerSiteName;
		}

		if (this.router.url.indexOf('/users/' + this.globals.customerUserId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.customerUserName;
		}

		if (this.router.url.indexOf('/customers/' + this.globals.customerId + '/invoices/') > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.invoiceName;
		}

		if (this.router.url.indexOf('/customers/' + this.globals.customerId + '/assignments/' + this.globals.customerAssignmentId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.customerAssignmentName;
		}

		if (this.router.url.indexOf('/transactions/' + this.globals.customerTransactionId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.customerTransactionName;
		}

		if (this.router.url.indexOf('/feed/' + this.globals.feedId) > -1 && titles.length > 1 && titles[1]) {
			titles[1].displayName = this.globals.feedName;
		}

		if (this.router.url.indexOf('/product-templates/' + this.globals.productTemplateId) > -1 && titles.length > 1 && titles[1]) {
			titles[1].displayName = this.globals.productTemplateName;
		}

		if (this.router.url.indexOf('/agreements/' + this.globals.agreementId) > -1 && titles.length > 1 && titles[3]) {
			titles[3].displayName = this.globals.agreementName;
		}

		if (this.router.url.indexOf('/reseller-users/users/' + this.globals.resellerUserId) > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.resellerUserName;
		}

		if (this.router.url.indexOf('/reseller-users/roles/' + this.globals.resellerRoleId) > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.resellerRoleName;
		}

		if (this.router.url.indexOf('/service-items/' + this.globals.serviceItemId) > -1 && titles.length > 1 && titles[1]) {
			titles[1].displayName = this.globals.serviceItemName;
		}

		if (this.router.url.indexOf('/settings/surcharges/' + this.globals.surchargeId) > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.surchargeName;
		}

		if (this.router.url.indexOf('/settings/payment-terms/' + this.globals.paymentTermId) > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.paymentTerm;
		}

		if (this.router.url.indexOf('/settings/service-item-types/' + this.globals.serviceItemTypeId) > -1 && titles.length > 1 && titles[2]) {
			titles[2].displayName = this.globals.serviceItemType;
		}

		if (this.router.url.indexOf('/tax-exemptions/' + this.globals.taxExemptionId) > -1 && titles.length > 1 && titles[4]) {
			titles[4].displayName = this.globals.taxExemptionName;
		}
	}
}
