import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class TransactionRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	public exportTransactions(tag: string, periodId: Guid, id: Guid) {
		const data = {
			tag: tag
		};
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { headers: headers };

		let url = '';
		if (!id.isEmpty()) {
			url = '/transactions/export?period=' + periodId + '&customer=' + id;
		} else {
			url = '/transactions/export?period=' + periodId;
		}

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}${url}`, data, options)
			.pipe(
				map(res => {
					return res.body.data;
				}));
	}

	generate(request: GenerationRequest): Observable<HttpResponse<void>> {

		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options = { headers: headers };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/transactions/generate`, request, options);
	}

	async createMiscInvoices(body: MiscCreationRequest, destroy$?: Subject<void>): Promise<{
		operationId: number;
	}> {

		const res=  await this.http.promise(destroy$).post(
			`${this.config.apiUrl}${this.config.apiVersion}/transactions/create-miscellaneous`, body);
		return res.body.data;
	}
}

export class GenerationRequest {
	public billingPeriodId?: Guid;
	public customerId?: Guid;
	public deleteExisting: boolean;
	public tag: string;
	public transactionGenerationType: number;
}

export class MiscCreationRequest {
	public billingPeriodId?: Guid;
	public customerId?: Guid;
	public tag: string;
	public agreementId?: Guid;
}
