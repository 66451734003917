<header>
	<div #headerContent class="header__content-wrapper" [class.header__content--fixed]="shouldStick">
		<div *ngIf="versionIsOld" class="header__notification-panel" (click)="refreshVersion()">
			<span class="header__notification-panel-message">{{'layout-header.outdated-version-message_title' | transloco}}</span>
			<button type="button" class="btn btn--default header__notification-panel-button"
				[title]="'layout-header.notifications_hint' | transloco">
				<i class="fa fa-refresh" aria-hidden="true"></i>
			</button>
		</div>
		<div class="header__content">
			<dg-crumbs />

			<div class="header__item-user flex">
				<div class="navbar">
					<div class="navbar__item">
						<a class="navbar__item-group-link">{{activeLang}}
							<i class="fa fa-caret-down" aria-hidden="true"></i></a>
						<ul class="navbar__item-group navbar__item-group--left">
							<li *ngFor="let lang of availableLangs" class="navbar__subitem">
								<a class="navbar__link navlink" (click)="onLangSelect(lang)">
									{{lang.label}}
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="header__notifications-container"
					[title]="'layout-header.notifications_hint' | transloco">
					<div class="notification__container">
						<button type="button" id="beamerButton" class="header__button">
							<a>
								<i class="fa fa-bell" aria-hidden="true"></i>
							</a>
						</button>
					</div>
				</div>
				<div class="navbar">
					<div class="navbar__item">
						<a class="navbar__item-group-link">{{displayName}} <i class="fa fa-caret-down"
								aria-hidden="true"></i></a>
						<ul class="navbar__item-group navbar__item-group--left">
							<li *ngxPermissionsOnly="[permissions.ManageSettings]" class="navbar__subitem"><a class="navbar__link navlink"
									routerLink="/settings">{{'layout-header.profile-menu-labels_settings' |
									transloco}}</a></li>
							<li class="navbar__subitem"><a class="navbar__link navlink"
									routerLink="/settings/my-details">{{'layout-header.profile-menu-labels_details' |
									transloco}}</a>
							</li>
							<li class="navbar__subitem">
								<a class="navbar__link navlink" href (click)="signout($event); false;">
									{{'layout-header.profile-menu-labels_sign-out' | transloco}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
