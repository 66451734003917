import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';
import { Utils } from 'tools/utils';
import { CustomerSite } from './customer-entities';

export class AgreementEntity implements Serializable<AgreementEntity> {
	public id: Guid;
	public agreementName: string;
	public billingFrequencyTypeId: number;
	public dateStart: string;
	public dateEnd: string;
	public billingStartDate: string;
	public productsStartDate: string;
	public active: boolean;
	paymentTermId: Guid;
	paymentTermLabel: string;
	dateStartMoment: string;
	dateEndMoment: string;
	billingStartDateMoment: string;
	currencyId: number;
	taxRateId: Guid;
	poNumber: string;
	billingContactEmail: string;
	ccBillingContactEmail: string;
	billingContactFirstName: string;
	billingContactLastName: string;
	public extraField1: string;
	public extraField2: string;
	public extraField3: string;
	public extraField4: string;
	public extraField5: string;
	invoiceLayoutId: Guid;
	public siteId: Guid;
	public productsSiteId: number;
	public productsSiteZId: number;
	public transactionsCount: number;
	public site: string;
	public billingFrequencyType: string;
	public locked: boolean;
	public isDefault: boolean;
	public customerId: Guid;
	public billingStartDateLocked: boolean;
	notes: string;
	ignoreCodes: string[];
	invoiceOneOffItems: boolean;
	invoiceRecurringItem: boolean;
	invoiceReference: string;
	newSites?: CustomerSite[];
	selectedProductSiteNew?: CustomerSite;
	selectedProductSiteZNew?: CustomerSite;
	selectedAgreementSiteNew?: CustomerSite;

	deserialize(json: any): AgreementEntity {
		this.id = json.id;
		this.agreementName = json.agreementName;
		this.billingFrequencyTypeId = json.billingFrequencyTypeId;
		this.billingFrequencyType = json.billingFrequencyType;
		this.active = json.active;
		this.invoiceLayoutId = json.invoiceLayoutId;
		this.dateStart = json.dateStart;
		this.dateEnd = json.dateEnd;
		this.billingStartDate = json.billingStartDate;
		this.taxRateId = json.taxRateId;
		this.invoiceReference = json.invoiceReference;
		this.dateStartMoment = Utils.toMomentUtcFormat(json.dateStart);
		this.dateEndMoment = Utils.toMomentUtcFormat(json.dateEnd);
		this.billingStartDateMoment = Utils.toMomentUtcFormat(json.billingStartDate);

		this.paymentTermId = json.paymentTermId;
		this.paymentTermLabel = json.paymentTermLabel;
		this.siteId = json.siteId;
		this.productsSiteId = json.productsSiteId;
		this.productsSiteZId = json.productsSiteZId;
		this.site = json.site;
		this.locked = json.locked;
		this.isDefault = json.isDefault;
		this.transactionsCount = json.transactionsCount;
		this.notes = json.notes;
		this.billingStartDateLocked = json.billingStartDateLocked;

		this.currencyId = json.currencyId;
		this.poNumber = json.poNumber;
		this.billingContactEmail = json.billingContactEmail;
		this.ccBillingContactEmail = json.ccBillingContactEmail;
		this.billingContactFirstName = json.billingContactFirstName;
		this.billingContactLastName = json.billingContactLastName;

		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		this.ignoreCodes = json.ignoreCodes;
		this.invoiceOneOffItems = json.invoiceOneOffItems;
		this.invoiceRecurringItem = json.invoiceRecurringItem;

		return this;
	}

	clone(): AgreementEntity {
		return Object.assign(new AgreementEntity(), this);
	}

	restore(src: AgreementEntity): void {
		Object.assign(this, src);
	}
}
