import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { FusReport } from 'domain/models/report-fus';
import { InvoiceRegisterReport } from 'domain/models/report-invoice-register';
import { ApiData, ApiOperation, Guid } from 'domain/types';
import {
	CommissionReport, CsiTaxReport, CsiTaxReportDetailed, CustomReport, CustomReportScheduler,
	CustomReportTypes, ErrorReport, FccReport, NoPricingErrors, ProfitabilityReportDto, SuretaxTaxesReportDetailed, TransactionErrorModelResponse, UnmatchedErrors, UsageCallTypeDto, UsageDetailsDto, UsageTotalCallsDto, CommissionReportFilters
} from 'domain/entities';
import { Observable, Subject, lastValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { AgedBalanceReport } from 'domain/models/report-aged-balance';
import { CustomerInvoiceReport, StandardReportsResult, StandardReportGeneratePayload } from 'domain/models';

@Injectable({
	providedIn: 'root'
})
export class ReportRepository extends BaseRepository {

	apiUrl: string;

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);

		this.apiUrl = `${this.config.apiUrl}${this.config.apiVersion}`;
	}

	async getErrors(periodStart: string, periodEnd: string, isShort: boolean, destroy$?: Subject<void>): Promise<ErrorReport> {
		const errorsUrl = isShort ? 'errors' : 'errors-short';

		const res = await this.http.promise(destroy$).get<ErrorReport>(`${this.apiUrl}/reports/${errorsUrl}?startDate=${periodStart}&endDate=${periodEnd}`);
		const errorReportModel = new ErrorReport();
		errorReportModel.errors110 = res.body.errors110.map(x => new UnmatchedErrors().deserialize(x));
		errorReportModel.errors130 = res.body.errors130.map(x => new NoPricingErrors().deserialize(x));
		errorReportModel.errors110Count = res.body.errors110Count;
		errorReportModel.errors130Count = res.body.errors130Count;
		return errorReportModel;
	}

	async getTransactionErrorsResponse(periodStart: string, periodEnd: string, destroy$?: Subject<void>): Promise<TransactionErrorModelResponse> {
		const res = await this.http.promise(destroy$).get<TransactionErrorModelResponse>(`${this.apiUrl}/reports/transaction-errors?startDate=${periodStart}&endDate=${periodEnd}`);
		return new TransactionErrorModelResponse().deserialize(res.body);
	}

	public usageSuppressNoPricing(firstUsageIds: number[], periodStart: string, periodEnd: string): Observable<void> {
		return this.http.post(`${this.apiUrl}/usage/suppress-nopricing?startDate=${periodStart}&endDate=${periodEnd}`, { firstUsageIds })
			.pipe(
				map(() => { }));
	}

	public usageReset(firstUsageIds: number[], processId: number, periodStart: string, periodEnd: string, resetAll: boolean): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.apiUrl}/usage/reset/${processId}?startDate=${periodStart}&endDate=${periodEnd}`, { firstUsageIds, resetAll });
	}

	public usageSuppressUnmatched(firstUsageIds: number[], periodStart: string, periodEnd: string): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.apiUrl}/usage/suppress-unmatched?startDate=${periodStart}&endDate=${periodEnd}`, { firstUsageIds });
	}

	public productsReset(firstTransactionIds: number[], processId: number, periodStart: string, periodEnd: string, resetAll: boolean): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.apiUrl}/transactions/reset/${processId}?startDate=${periodStart}&endDate=${periodEnd}`, { firstTransactionIds, resetAll });
	}

	public transactionsSuppress(firstTransactionIds: number[], periodStart: string, periodEnd: string): Observable<HttpResponse<void>> {
		return this.http.post<void>(`${this.apiUrl}/transactions/suppress?startDate=${periodStart}&endDate=${periodEnd}`, { firstTransactionIds });
	}

	getCsiTaxReport(periodStart: string, periodEnd: string): Observable<CsiTaxReport[]> {
		return this.http.get(`${this.apiUrl}/reports/csi-taxes?startDate=${periodStart}&endDate=${periodEnd}`)
			.pipe(
				map(res => res.body.map(x => new CsiTaxReport().deserialize(x)))
			);
	}

	getCsiTaxDetailedReport(periodStart: string, periodEnd: string): Observable<CsiTaxReportDetailed[]> {
		return this.http.get(`${this.apiUrl}/reports/csi-taxes-detailed?startDate=${periodStart}&endDate=${periodEnd}`)
			.pipe(
				map(res => res.body.map(x => new CsiTaxReportDetailed().deserialize(x)))
			);
	}

	getSuretaxTaxesDetailedReport(periodStart: string, periodEnd: string): Observable<SuretaxTaxesReportDetailed[]> {
		return this.http.get(`${this.apiUrl}/reports/suretax-taxes-detailed?startDate=${periodStart}&endDate=${periodEnd}`)
			.pipe(
				map(res => res.body.map(x => new SuretaxTaxesReportDetailed().deserialize(x)))
			);
	}

	getCommissionReport(periodStart: string, periodEnd: string): Observable<CommissionReport[]> {
		return this.http.get(`${this.apiUrl}/reports/commission?startDate=${periodStart}&endDate=${periodEnd}`)
			.pipe(
				map(res => res.body.data.map(x => new CommissionReport().deserialize(x)))
			);
	}

	getUsageCallTypeReport(startDate: string, endDate: string): Observable<UsageCallTypeDto[]> {
		return this.http.get<ApiData<UsageTotalCallsDto[]>>(`${this.apiUrl}/reports/usage-call-types/by-period/from/${startDate}/to/${endDate}`)
			.pipe(
				map(res => res.body.data.map(x => new UsageCallTypeDto().deserialize(x))),
				catchError(() => of([])));
	}

	getUsageTotalCallsReport(startDate: string, endDate: string): Observable<UsageTotalCallsDto[]> {
		return this.http.get<ApiData<UsageTotalCallsDto[]>>(`${this.apiUrl}/reports/usage-total-calls/by-period/from/${startDate}/to/${endDate}`)
			.pipe(
				map(res => res.body.data.map(x => new UsageTotalCallsDto().deserialize(x)))
			);
	}

	getUsageDetailsReport(startDate: string, endDate: string): Observable<UsageDetailsDto[]> {
		return this.http.get<ApiData<UsageDetailsDto[]>>(`${this.apiUrl}/reports/usage-details-line/by-period/from/${startDate}/to/${endDate}`)
			.pipe(
				map(res => res.body.data.map(x => new UsageDetailsDto().deserialize(x)))
			);
	}

	async getProfitabilityReport(customerId: Guid, startDate: string, endDate: string, destroy$?: Subject<void>): Promise<ProfitabilityReportDto[]> {
		const res = await this.http.promise(destroy$).get<ApiData<ProfitabilityReportDto[]>>(`${this.apiUrl}/reports/profitability/customers/${customerId}/by-period/from/${startDate}/to/${endDate}`);
		return res.body.data.map(x => new ProfitabilityReportDto().deserialize(x));
	}

	public getfccReport(periodStart: string, periodEnd: string): Observable<FccReport[]> {
		return this.http.get(`${this.apiUrl}/reports/fcc477?startDate=${periodStart}&endDate=${periodEnd}`)
			.pipe(
				map(res => res.body.map(x => new FccReport().deserialize(x)))
			);
	}

	async getFusReport(periodStart: string, periodEnd: string, destroy$?: Subject<void>): Promise<FusReport[]> {
		const res = await this.http.promise(destroy$).get<FusReport[]>(`${this.config.apiUrl}${this.config.apiVersion}/reports/fus?startDate=${periodStart}&endDate=${periodEnd}`);

		return res.body;
	}

	async getAgedReport(destroy$?: Subject<void>): Promise<AgedBalanceReport[]> {
		const res = await this.http.promise(destroy$).get<ApiData<AgedBalanceReport[]>>(`${this.config.apiUrl}${this.config.apiVersion}/reports/aged-balance`);

		return res.body?.data;
	}

	async getCustomerInvoices(reportDate: string, destroy$?: Subject<void>): Promise<CustomerInvoiceReport[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CustomerInvoiceReport[]>>(`${this.config.apiUrl}${this.config.apiVersion}/reports/invoice-differences?reportDate=${reportDate}`);
		return res.body?.data;
	}

	async getInvoiceRegisterReport(periodStart: string, periodEnd: string, destroy$?: Subject<void>): Promise<InvoiceRegisterReport[]> {
		const res = await this.http.promise(destroy$).get<InvoiceRegisterReport[]>(`${this.config.apiUrl}${this.config.apiVersion}/reports/invoice-register?startDate=${periodStart}&endDate=${periodEnd}`);

		return res.body;
	}

	public async exportToExcel(periodStart: string, periodEnd: string, isShort: boolean, fileType: string, fileName: string): Promise<void> {
		const errorsUrl = isShort ? 'errors' : 'errors-short';

		await this.http.promise().downloadGet(`${this.apiUrl}/reports/${errorsUrl}/export?startDate=${periodStart}&endDate=${periodEnd}`, fileType, fileName);
	}

	// ----------------- CUSTOM REPORTS --------------

	async getCustomReports(destroy$?: Subject<void>): Promise<CustomReport[]> {
		const res = await this.http.promise(destroy$).get(`${this.apiUrl}/custom-reports`);
		return res.body.data.map(x => new CustomReport().deserialize(x));
	}

	async getCustomReportById(reportId: Guid, destroy$?: Subject<void>): Promise<CustomReport> {
		const reports = await this.getCustomReports();
		return reports.find(x => x.id.equals(reportId));
	}

	async getCustomReportTypes(destroy$?: Subject<void>): Promise<CustomReportTypes[]> {
		const res = await this.http.promise(destroy$).get(`${this.apiUrl}/custom-reports/types`);
		return res.body.data.map(x => new CustomReportTypes().deserialize(x));
	}

	public addCustomReport(report: CustomReport): Observable<CustomReport> {
		return this.createEntity(`/custom-reports`, report);
	}

	public updateCustomReport(report: CustomReport): Observable<CustomReport> {
		return this.updateEntity(`/custom-reports/${report.id}`, report);
	}

	public testCustomReport(id: Guid, invoiceId: number): Observable<any> {
		return this.http.post(`${this.apiUrl}/custom-reports/${id}/test?InvoiceId=${invoiceId}`, undefined);
	}

	public deleteCustomReport(id: Guid): Observable<HttpResponse<void>> {
		return this.deleteEntity(`/custom-reports/${id}`);
	}

	async getSchedules(destroy$?: Subject<void>): Promise<CustomReportScheduler[]> {
		const res = await this.http.promise(destroy$).get(`${this.apiUrl}/custom-reports/scheduler`);
		return res.body.data.map(x => new CustomReportScheduler().deserialize(x));
	}

	async getScheduleById(scheduleId: Guid, destroy$?: Subject<void>): Promise<CustomReportScheduler> {
		const schedules = await this.getSchedules();
		return schedules.find(x => x.id.equals(scheduleId));
	}

	public addSchedule(schedule: CustomReportScheduler): Observable<CustomReportScheduler> {
		return this.createEntity(`/custom-reports/scheduler`, schedule);
	}

	public updateSchedule(schedule: CustomReportScheduler): Observable<CustomReportScheduler> {
		return this.updateEntity(`/custom-reports/scheduler/${schedule.id}`, schedule);
	}

	public deleteCustomSchedule(id: Guid): Observable<HttpResponse<void>> {
		return this.deleteEntity(`/custom-reports/scheduler/${id}`);
	}

	async getReportCommissionFilters(destroy$?: Subject<void>): Promise<CommissionReportFilters> {
		const res = await this.http.promise(destroy$).get<ApiData<CommissionReportFilters>>(`${this.apiUrl}/reports/commission/filters`);

		return res.body?.data;
	}

	async getReportCommissionSettings(destroy$?: Subject<void>): Promise<CommissionReportFilters> {
		const res = await this.http.promise(destroy$).get<ApiData<CommissionReportFilters>>(`${this.apiUrl}/reports/commission/settings`);

		return res.body?.data;
	}

	async postSettingsFilters(settings: CommissionReportFilters, destroy$?: Subject<void>): Promise<HttpResponse<void>> {
		return await this.http.promise(destroy$).post<void>(`${this.apiUrl}/reports/commission/settings`, settings );
	}

	async getCustomerInvoicesSettings(destroy$?: Subject<void>): Promise<CommissionReportFilters> {
		const res = await this.http.promise(destroy$).get<ApiData<CommissionReportFilters>>(`${this.apiUrl}/reports/commission/settings`);

		return res.body?.data;
	}

	async updateCustomerInvoices(isColumnVisible: boolean, destroy$?: Subject<void>): Promise<HttpResponse<void>> {
		return await this.http.promise(destroy$).post<void>(`${this.apiUrl}/reports/commission/settings`, { isColumnVisible });
	}

	async generateStandardReport(params: StandardReportGeneratePayload, destroy$?: Subject<void>): Promise<ApiOperation> {
		const response = await this.http.promise(destroy$).post<ApiOperation>(`${this.apiUrl}/standard-reports/generate`, params);
		return response.body;
	}

	async getStandardReportResults(destroy$?: Subject<void>): Promise<StandardReportsResult[]> {
		const res = await this.http.promise(destroy$).get<ApiData<StandardReportsResult[]>>(`${this.apiUrl}/standard-reports/list`);

		return res.body?.data;
	}

	async deleteReportResults(ids: Guid[], destroy$?: Subject<void>): Promise<void> {
		await this.http.promise(destroy$).post<void>(`${this.apiUrl}/standard-reports/delete-results`, { records: ids });
	}

	async downloadReportResult(reportId: Guid, destroy$?: Subject<void>): Promise<HttpResponse<Blob>> {
		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/standard-reports/${reportId}/download`, { responseType: 'blob' });
		return res?.body;
	}
}
