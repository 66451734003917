import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { MyDetails } from 'domain/entities/my-details-entities';

@Injectable({
	providedIn: 'root'
})

export class LocalSettingsRepository {
	me: MyDetails = new MyDetails();

	constructor(config: AppConfig) {
		this.me = config.myDetails;
	}

	public getUserId(): string {
		return this.me.userId.toString() || '';
	}

	public GetGridCustomerFilter() {
		const userId = this.getUserId();
		if (!userId)
			return;
		const key = `${userId}-customers-filter`;
		const storageColumns = JSON.parse(localStorage.getItem(key));

		return storageColumns;
	}

	public SetGridCustomerFilter(data: any): void {
		const userId = this.getUserId();
		if (!userId)
			return;

		const key = `${userId}-customers-filter`;
		localStorage.setItem(key, JSON.stringify(data));
	}

	public SetXeroWizardSetting(data: any) {
		const userId = this.getUserId();
		if (!userId)
			return;

		const key = `${userId}-xero-selected-wizard-settings`;

		localStorage.setItem(key, JSON.stringify(data));
	}

	public GetXeroWizardSetting() {
		const userId = this.getUserId();
		if (!userId)
			return;

		const key = `${userId}-xero-selected-wizard-settings`;

		return JSON.parse(localStorage.getItem(key));
	}

	public SetXeroExtendedWizardSetting(data: any): void {
		const userId = this.getUserId();
		if (!userId)
			return;

		const key = `${userId}-xero-selected-extended-wizard-settings`;

		localStorage.setItem(key, JSON.stringify(data));
	}

	public GetXeroExtendedWizardSetting() {
		const userId = this.getUserId();
		if (!userId)
			return;

		const key = `${userId}-xero-selected-extended-wizard-settings`;

		return JSON.parse(localStorage.getItem(key));
	}

	public GetGridServieItemType() {
		const key = `si-type`;
		const storageColumns = JSON.parse(localStorage.getItem(key));

		return storageColumns;
	}

	public SetGridServieItemType(data: any): void {
		const key = `si-type`;
		localStorage.setItem(key, JSON.stringify(data));
	}

	public setWithExpiry(key: string, ttl: number): void {
		const userId = this.getUserId();

		if (!userId)
			return;

		const now = new Date();

		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			expiry: now.getTime() + ttl
		};

		localStorage.setItem(`${userId}-${key}`, JSON.stringify(item));
	}

	public getWithExpiry(key: string): boolean {
		const userId = this.getUserId();

		if (!userId)
			return;

		const localItem = localStorage.getItem(`${userId}-${key}`);
		// if the item doesn't exist, return null
		if (!localItem) {
			return false;
		}

		const item = JSON.parse(localItem);
		const now = new Date();

		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(localItem);
			return false;
		}
		return true;
	}
}
