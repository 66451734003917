import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { Surcharge } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class SurchargeRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getSurcharges$(): Observable<Surcharge[]> {
		const key = 'surcharge';

		if (this.cache.get$(key) != null) {
			return this.cache.get$(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/surcharge-settings`)
				.pipe(
					map(res => {
						const surcharges = res.body?.data?.map(x => new Surcharge().deserialize(x));
						this.setCacheValue(key, surcharges, null);
						return surcharges;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getSurcharges(destroy$?: Subject<void>): Promise<Surcharge[]> {
		const surcharges = await this.http.promise(destroy$).get<ApiData<Surcharge[]>>(`${this.config.apiUrl}${this.config.apiVersion}/surcharge-settings`);

		return surcharges.body?.data?.map(x => new Surcharge().deserialize(x)) || [];
	}

	getSurchargeById(surchargeId: Guid): Observable<Surcharge> {
		const key = `surcharge-${surchargeId}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<Surcharge[]>(key)
				.pipe(map(site => site.find(x => x.id.equals(surchargeId))));
		}

		return this.getSurcharges$().pipe(map(site => site.find(x => x.id.equals(surchargeId))));
	}

	public addSurcharge(rate: Surcharge): Observable<Guid> {

		const key = 'surcharge';

		return this.createEntity(`/surcharge-settings`, rate)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateSurcharge(surcharge: Surcharge): Observable<any> {
		const key = 'surcharge';

		return this.updateEntity(`/surcharge-settings/${surcharge.id}`, surcharge)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));
	}

	public deleteSurcharge(id: Guid): Observable<void> {

		const key = 'surcharge';

		return this.deleteEntity(`/surcharge-settings/${id}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, id))
				, map(w => {
				}));
	}
}
