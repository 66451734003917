export enum JobType {
	RerateUsage = 1,
	InvoicesGenerate = 2,
	InvoicesDelete = 3,
	InvoicesSend = 4,
	InvoicesReGenerate = 5,
	InvoicesZip = 6,
	InvoicesUpdate = 7,
	GenerateTransactions = 8,
	GenerateReport = 9,
	InvoiceCredit = 10,
	ExportUsage = 11,
	InvoiceExportXero = 12,
	InvoiceExportFile = 13,
	ImportFromCsv = 14,
	CwCustomersSync = 15,
	CwInvoicesExport = 16,
	ExportTransactions = 17,
	ExportCustomers = 18,
	ExportInvoicesMultiline = 19,
	CwAdditionsExport = 20,
	Test = 21,
	CwExportInvoicesMultiline = 22,
	QbCustomersSync = 23,
	ExportRateCard = 24,
	QbExportInvoicesMultiline = 25,
	CloneRateCard = 26,
	ChargeInvoices = 27,
	InvoiceExportXeroMultiline = 28,
	ResendingEmails = 29,
	XeroCustomersSync = 30,
	ServiceItemsExport = 31,
	CwInvoiceNumberSync = 32,
	AtCustomersSync = 33,
	AtExportInvoiceMultiline = 34,
	AtInvoiceNumberSync = 35,
	QbdExportInvoices = 36,
	CwReturnTaxes = 37,
	KaseyaCustomersSync = 38,
	HaloCustomersSync = 39,
	FakePrint = 40,
	FakeMarkPaid = 41,
	FakeZip = 42,
	FakeOnHold = 43,
	FakeOffHold = 44,
	FakeMarkAsSent = 45,
	FakeEmail = 46,
	FakeReEmail = 47,
	FakeMiscellaneousInvoicesGenerate = 48,
	HaloExportInvoiceMultiline = 49,
	MarkAsPartiallyPaid = 50,
	QbdExportInvoicesCsvMultiline = 51,
	InvoicesArchive = 52,
	InvoicesUnArchive = 53,
	GenerateEcpReport = 54,
	HaloExportCharges = 55,
	ReceiptPayment = 56,
	GenerateProductTransAndMisc = 57,
	GenerateStandardReport = 58,
	GenerateUsagesByPeriodReport = 59,
	Alerts = 60
}